import { GithubActionVersionsCard } from '@internal/plugin-tomtom-github-actions';
import { EntityGithubInsightsContent } from '@roadiehq/backstage-plugin-github-insights';
import React from 'react';

import {
  EntityAboutCard,
  EntityLayout,
  EntitySwitch,
} from '@backstage/plugin-catalog';

import { Grid } from '@material-ui/core';

import { ActionContent, EntityWarningContent } from '../Content';
import { isGitHubAvailable } from '../entityUtils';

export const GithubActionAllowListPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        <EntityWarningContent />
        {ActionContent}
        <Grid item md={6}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntitySwitch>
            <EntitySwitch.Case>
              <GithubActionVersionsCard />
            </EntitySwitch.Case>
          </EntitySwitch>
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route
      if={isGitHubAvailable}
      path="/code-insights"
      title="Code Insights"
    >
      <EntityGithubInsightsContent />
    </EntityLayout.Route>
  </EntityLayout>
);
