import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import { ScorePerTier } from '../../types';
import { ScoreBox } from '../ScoreBox/ScoreBox';

export interface TiersBoxProps {
  scoresPerTier: ScorePerTier;
}

export const TiersBox = (props: TiersBoxProps) => {
  return (
    <Grid
      item
      container
      md={12}
      style={{
        alignItems: 'center',
        alignContent: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <Grid item md={12} style={{ textAlign: 'center' }}>
        <Typography variant="h5">Services tiers distribution</Typography>
      </Grid>
      <Grid item md={6}>
        <ScoreBox
          tier="Gold"
          label="Score > 90"
          score={props.scoresPerTier.Gold}
        />
      </Grid>
      <Grid item md={6}>
        <ScoreBox
          tier="Silver"
          label="Score 75-90"
          score={props.scoresPerTier.Silver}
        />
      </Grid>
      <Grid item md={6}>
        <ScoreBox
          tier="Bronze"
          label="Score 25-75"
          score={props.scoresPerTier.Bronze}
        />
      </Grid>
      <Grid item md={6}>
        <ScoreBox
          tier="Black"
          label="Score < 25"
          score={props.scoresPerTier.Black}
        />
      </Grid>
    </Grid>
  );
};
