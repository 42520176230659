import { FieldValidation } from '@rjsf/utils';

import { parseFormData } from './utils/formUtils';
import { isValidName } from './utils/validationUtils';

export const catalogTeamNameValidation = (
  value: string,
  validation: FieldValidation,
) => {
  const { value: nameInput, validation: catalogNameValidation } =
    parseFormData(value);

  if (nameInput && !isValidName(nameInput)) {
    validation.addError(
      'Name must start and end with an alphanumeric character, and contain only alphanumeric characters, hyphens, underscores, and periods. Maximum length is 63 characters.',
    );
    return;
  }

  if (catalogNameValidation?.isNameTaken) {
    validation.addError(
      'Name must be unique. The given name is already taken.',
    );
  }
};
