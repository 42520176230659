import useAsync from 'react-use/lib/useAsync';

import { GetEntitiesResponse } from '@backstage/catalog-client';
import { Entity } from '@backstage/catalog-model';
import {
  BackstageUserIdentity,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { CatalogApi, catalogApiRef } from '@backstage/plugin-catalog-react';

const GITHUB_PROJECT_SLUG_ANNOTATION = 'github.com/project-slug';

const hasGithubSlug = (entity: Entity): boolean => {
  return (
    (entity?.metadata.annotations?.[GITHUB_PROJECT_SLUG_ANNOTATION] ?? '') !==
    ''
  );
};

const getCurrentUserTeams = (profile: BackstageUserIdentity) => {
  return profile.ownershipEntityRefs?.filter(ownershipEntityRef =>
    ownershipEntityRef.startsWith('group:'),
  );
};

const getTeamsGithubComponentAPIEntities = async (
  catalogApi: CatalogApi,
  teamEntityRefs: string[],
  entityKinds: string[],
): Promise<Entity[]> => {
  const promises = teamEntityRefs.flatMap((teamEntityRef: string) => {
    return catalogApi.getEntities({
      filter: {
        kind: entityKinds,
        'relations.ownedBy': teamEntityRef,
      },
    });
  });

  return (await Promise.allSettled(promises))
    .filter(res => res.status === 'fulfilled')
    .flatMap(res =>
      (res as PromiseFulfilledResult<GetEntitiesResponse>).value?.items.filter(
        entity => hasGithubSlug(entity),
      ),
    );
};

const getGithubEntitiesByUserProfile = async (
  profile: BackstageUserIdentity,
  catalogApi: CatalogApi,
  entityKinds: string[],
): Promise<boolean> => {
  const teamEntityRefs = getCurrentUserTeams(profile);
  const entities = await getTeamsGithubComponentAPIEntities(
    catalogApi,
    teamEntityRefs,
    entityKinds,
  );
  return entities?.length > 0 ?? false;
};

export const useGHWidgets = (): boolean => {
  const identityApi = useApi(identityApiRef);
  const catalogApi: CatalogApi = useApi(catalogApiRef);

  const { value } = useAsync(async (): Promise<boolean> => {
    const profile = await identityApi.getBackstageIdentity();
    return await getGithubEntitiesByUserProfile(profile, catalogApi, [
      'Component',
      'API',
    ]);
  });

  return value ?? false;
};
