import { JSON_RULE_ENGINE_CHECK_TYPE } from '@backstage-community/plugin-tech-insights-backend-module-jsonfc';

import { TechInsightsCheckID } from '../../checkIDs';

export const teamOnboardingChecks = [
  {
    id: TechInsightsCheckID.MANAGER_CHECK,
    type: JSON_RULE_ENGINE_CHECK_TYPE,
    name: 'Team Managers Check',
    description: 'Verifies that the team has set who their managers are',
    factIds: ['teamManagerFactRetriever'],
    rule: {
      conditions: {
        all: [
          {
            fact: 'hasManagers',
            operator: 'equal',
            value: true,
          },
          {
            fact: 'hasManagerNames',
            operator: 'equal',
            value: true,
          },
          {
            fact: 'hasManagerTitles',
            operator: 'equal',
            value: true,
          },
          {
            fact: 'hasManagerEmails',
            operator: 'equal',
            value: true,
          },
        ],
      },
    },
  },
  {
    id: TechInsightsCheckID.SLACK_CHANNEL_CHECK,
    type: JSON_RULE_ENGINE_CHECK_TYPE,
    name: 'Team Slack Channel Check',
    description: 'Verifies that the team has set their slack channel',
    factIds: ['teamSlackChannelFactRetriever'],
    rule: {
      conditions: {
        all: [
          {
            fact: 'hasSlackChannel',
            operator: 'equal',
            value: true,
          },
        ],
      },
    },
  },
  {
    id: TechInsightsCheckID.CONFLUENCE_CHECK,
    type: JSON_RULE_ENGINE_CHECK_TYPE,
    name: 'Team Confluence Check',
    description: 'Verifies that the team has set their confluence',
    factIds: ['teamConfluenceFactRetriever'],
    rule: {
      conditions: {
        all: [
          {
            fact: 'hasConfluence',
            operator: 'equal',
            value: true,
          },
        ],
      },
    },
  },
  {
    id: TechInsightsCheckID.BUG_REPORT_CHECK,
    type: JSON_RULE_ENGINE_CHECK_TYPE,
    name: 'Team Bug Report Check',
    description: 'Verifies that the team has set a link to report bugs',
    factIds: ['teamBugReportFactRetriever'],
    rule: {
      conditions: {
        all: [
          {
            fact: 'hasBugReport',
            operator: 'equal',
            value: true,
          },
        ],
      },
    },
  },
  {
    id: TechInsightsCheckID.COMPONENT_OR_API_CHECK,
    type: JSON_RULE_ENGINE_CHECK_TYPE,
    name: 'Team Component or API onboarding Check',
    description:
      'Verifies that the team has onboard at least one component or API',
    factIds: ['teamComponentOrAPIOnboardingFactRetriever'],
    rule: {
      conditions: {
        all: [
          {
            fact: 'isComponentOrAPIOnboard',
            operator: 'equal',
            value: true,
          },
        ],
      },
    },
  },
];
