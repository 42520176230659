import {
  PluginEndpointDiscovery,
  TokenManager,
} from '@backstage/backend-common';
import { CatalogClient } from '@backstage/catalog-client';
import { JsonObject } from '@backstage/types';

// This interface is defined in @backstage/backend-plugin-api
// All of the fact retriever handlers take a FactRetrieverContext as an argument, which contains this logger object
// rather than add yet another import to import this interface, I'm just going to define it here
interface LoggerService {
  error(message: string, meta?: Error | JsonObject): void;
  warn(message: string, meta?: Error | JsonObject): void;
  info(message: string, meta?: Error | JsonObject): void;
  debug(message: string, meta?: Error | JsonObject): void;
  child(meta: JsonObject): LoggerService;
}

export const getEntities = async (options: {
  entityFilter:
    | Record<string, string | symbol | (string | symbol)[]>
    | Record<string, string | symbol | (string | symbol)[]>[]
    | undefined;
  tokenManager: TokenManager;
  discovery: PluginEndpointDiscovery;
  logger?: LoggerService;
}) => {
  const { logger, tokenManager, entityFilter, discovery } = options;
  const { token } = await tokenManager.getToken();
  const catalogClient = new CatalogClient({
    discoveryApi: discovery,
  });
  const start = process.hrtime();
  const entities = await catalogClient.getEntities(
    { filter: entityFilter },
    { token },
  );
  const end = process.hrtime(start);
  if (logger && logger.info) {
    logger?.info(
      `catalogClient Fetched ${entities.items.length} entities in ${end[0]}s ${
        end[1] / 1000000
      }ms time for filter ${JSON.stringify(entityFilter)}`,
    );
  }
  return entities;
};
