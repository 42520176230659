import { Tenant } from '../types';

export const deserialize = (json: Record<string, any>): Tenant => {
  const instance = new Tenant();
  instance.name = json.name;
  instance.tier = json.tier;
  instance.ownerObjectId = json.owner_object_id;
  instance.clusterId = json.cluster_id;
  instance.costCenterCode = json.cost_center_code;
  instance.resourceQuota = {
    cpuLimit: json.resource_quota.cpu_limit,
    cpuRequest: json.resource_quota.cpu_request,
    memoryLimit: json.resource_quota.memory_limit,
    memoryRequest: json.resource_quota.memory_request,
  };
  instance.namespaceQuota = json.namespace_quota;
  instance.slackChannel = json.slack_channel;
  instance.kubeConfig = json.kubeconfig;
  instance.customerSubscriptionId = json.customer_subscription_id;
  instance.nodeGroup = json.node_group;
  instance.argoUrl = json.argocd_url;

  if (json.argocd_project) {
    instance.argoProject = {
      repositories: json.argocd_project.repositories,
      rootPath: json.argocd_project.root_path,
    };
  }

  return instance;
};

export const serialize = (obj: Tenant): Record<string, any> => {
  const json: Record<string, any> = {};

  json.name = obj.name;
  json.tier = obj.tier;
  json.owner_object_id = obj.ownerObjectId;
  json.cluster_id = obj.clusterId;
  json.cost_center_code = obj.costCenterCode;
  json.resource_quota = {
    cpu_limit: obj.resourceQuota.cpuLimit,
    cpu_request: obj.resourceQuota.cpuRequest,
    memory_limit: obj.resourceQuota.memoryLimit,
    memory_request: obj.resourceQuota.memoryRequest,
  };
  json.namespace_quota = obj.namespaceQuota;
  json.slack_channel = obj.slackChannel;
  json.customer_subscription_id = obj.customerSubscriptionId;
  json.node_group = obj.nodeGroup;
  json.argocd_url = obj.argoUrl;
  if (obj.argoProject) {
    json.argocd_project = {
      repositories: obj.argoProject.repositories,
      root_path: obj.argoProject.rootPath,
    };
  } else {
    json.argocd_project = null;
  }

  return json;
};
