import React from 'react';
import { useAsync } from 'react-use';

import { CatalogApi } from '@backstage/catalog-client';
import { getCompoundEntityRef } from '@backstage/catalog-model';
import { SidebarItem } from '@backstage/core-components';
import {
  IconComponent,
  identityApiRef,
  useApi,
  useRouteRef,
} from '@backstage/core-plugin-api';
import { catalogApiRef, entityRouteRef } from '@backstage/plugin-catalog-react';

export const UserSidebarItem = (props: {
  title?: string;
  icon: IconComponent;
}) => {
  const { title, icon } = props;

  const identityApi = useApi(identityApiRef);
  const catalogApi: CatalogApi = useApi(catalogApiRef);
  const catalogEntityRoute = useRouteRef(entityRouteRef);

  const { value: user } = useAsync(async () => {
    const { userEntityRef } = await identityApi.getBackstageIdentity();

    return await catalogApi.getEntityByRef(userEntityRef);
  }, []);

  const userFullName =
    user?.metadata.name.split('_')[0].replace('.', ' ').toLocaleUpperCase() ??
    (user?.metadata.name.toLocaleUpperCase() || '');

  return user ? (
    <SidebarItem
      data-testid="user-profile-sidebar"
      text={title ?? userFullName}
      to={catalogEntityRoute(getCompoundEntityRef(user))}
      icon={icon}
    />
  ) : null;
};
