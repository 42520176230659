import React, { useCallback } from 'react';

import { DependencyGraph, Progress } from '@backstage/core-components';

import { makeStyles } from '@material-ui/core';

import { MissingWorkflowDependencyEmptyState } from './MissingWorkflowDependencyEmptyState';
import { useWorkflowGraphData } from './hooks/useWorkflowGraphData';
import { WorkflowNode } from './types';

const useStyles = makeStyles(
  () => ({
    graphStyle: { border: '1px solid grey' },
    clickable: {
      cursor: 'pointer',
    },
    normalFontWeight: { fontWeight: 'normal' },
    bolderFontWeight: { fontWeight: 'bolder' },
    lighterFontWeight: { fontWeight: 'lighter' },
  }),
  { name: 'GithubWorkflowDependencyGraph' },
);

export const GithubWorkflowDependencyGraph = () => {
  const classes = useStyles();
  const { loading, nodes, edges } = useWorkflowGraphData();

  const onNodeClick = useCallback((node: WorkflowNode) => {
    window.open(
      `https://github.com/tomtom-internal/${node.repository}/actions/runs/${node.runId}`,
      '_blank',
    );
  }, []);

  if (loading) {
    return <Progress />;
  }

  if (nodes.length === 0 || edges.length === 0) {
    return <MissingWorkflowDependencyEmptyState />;
  }

  return (
    <DependencyGraph
      className={classes.graphStyle}
      nodes={nodes}
      edges={edges}
      paddingX={50}
      paddingY={50}
      zoom="enable-on-click"
      renderNode={props => (
        <g
          onClick={() => onNodeClick(props.node)}
          className={classes.clickable}
        >
          <rect width={250} height={100} rx={20} fill={props.node.color} />
          <text
            className={classes.bolderFontWeight}
            x={125}
            y={30}
            textAnchor="middle"
            alignmentBaseline="baseline"
          >
            {props.node.id}
          </text>
          <text
            className={classes.normalFontWeight}
            x={125}
            y={40}
            textAnchor="middle"
            alignmentBaseline="hanging"
          >
            {props.node.commitMessage}
          </text>
          <text
            className={classes.normalFontWeight}
            x={125}
            y={58}
            textAnchor="middle"
            alignmentBaseline="hanging"
          >
            {props.node.commitBranch}
          </text>
          <text
            className={classes.lighterFontWeight}
            x={125}
            y={76}
            textAnchor="middle"
            alignmentBaseline="hanging"
          >
            {props.node.commitActor}
          </text>
        </g>
      )}
    />
  );
};
