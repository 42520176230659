import {
  createUnifiedTheme,
  createBaseThemeOptions,
  palettes,
} from '@backstage/theme';

import blackHeader from '../images/black-header.svg';
import { MuiThemeOverrides } from './MuiThemeOverrides';
import { backstageThemeOverrides } from './backstageThemeOverrides';
import { colors } from './colors';
import { fonts } from './fonts';

export const tomtomTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: colors.primary.main,
        contrastText: colors.primary.white,
      },
      secondary: {
        main: colors.secondary.main,
        contrastText: colors.primary.white,
      },
      navigation: {
        ...palettes.light.navigation,
        background: colors.primary.white,
        color: colors.primary.black,
        navItem: {
          hoverBackground: colors.primary.main,
        },
      },
    },
  }),
  fontFamily: fonts.sansSerif,
  pageTheme: {
    home: {
      colors: [colors.primary.black],
      shape: '',
      backgroundImage: `url(${blackHeader})`,
      fontColor: colors.primary.white,
    },
  },
  defaultPageTheme: 'home',
  components: { ...backstageThemeOverrides, ...MuiThemeOverrides },
});
