import React from 'react';

import { EntitySwitch, isComponentType } from '@backstage/plugin-catalog';

import { DefaultEntityPage } from './DefaultEntityPage';
import { InfrastructureEntityPage } from './InfrastructureEntityPage';
import { ServiceEntityPage } from './ServiceEntityPage';
import { WebsiteEntityPage } from './WebsiteEntityPage';

export const ComponentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {ServiceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      {WebsiteEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('infrastructure')}>
      {InfrastructureEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case>{DefaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
