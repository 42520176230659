export const AZURE_ANNOTATION_RESOURCE_TAG_SELECTOR =
  'azure.com/resource-tag-selector';
export const AZURE_ANNOTATION_AKS_RESOURCE_NAME_FILTERS =
  'azure.com/aks-resourcename-filters';
export const AZURE_ANNOTATION_COMPLIANCY_RESOURCE_NAME_FILTERS =
  'azure.com/compliancy-resourcename-filters';

export * from './azureResourceTypes';
export * from './azureResourceCompliancyTypes';
export * from './azureManagedClusterTypes';
export * from './azureRequestTypes';
export * from './dateTypes';
