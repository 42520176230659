/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React, { useState, useEffect } from 'react';
import useAsyncFn from 'react-use/lib/useAsyncFn';

import {
  HeaderIconLinkRow,
  IconLinkVerticalProps,
  LinkButton,
} from '@backstage/core-components';
import {
  useApi,
  identityApiRef,
  alertApiRef,
} from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Divider,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Description from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PublishIcon from '@material-ui/icons/Publish';
import ShareIcon from '@material-ui/icons/Share';
import { Alert } from '@material-ui/lab';

import { tomtomLabApiRef } from '../../api';
import { useParticipant } from '../../hooks/useParticipant';
import { Member, Idea, Status } from '../../types';
import { useIsAdministrator } from '../../util/administratorMethods';
import {
  fetchIdeaMembers,
  fetchSelfAssessments,
  fetchUser,
} from '../../util/fetchMethods';
import { useIsJuryMember } from '../../util/juryAssessmentMethods';
import {
  isSharedIdeaLink,
  parseTomtomLabResponse,
} from '../../util/parseMethods';
import { CardContentFields } from '../CardContentFields/CardContentFields';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { NonParticipantAlert } from './NonParticipantAlert';

const useStyles = makeStyles({
  wordBreak: {
    wordBreak: 'break-all',
    whiteSpace: 'normal',
    margin: '-0.25rem 0',
  },
});

type Props = {
  initIdea: Idea;
  handleClose: () => void;
};

export const IdeaDetailsDialog = ({ initIdea, handleClose }: Props) => {
  const classes = useStyles();
  const tomtomLabApi = useApi(tomtomLabApiRef);
  const identity = useApi(identityApiRef);
  const catalogApi = useApi(catalogApiRef);
  const alertApi = useApi(alertApiRef);
  const { isUserParticipant } = useParticipant();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [isDoneOpenConfirmation, setIsDoneOpenConfirmation] = useState(false);
  const [isMember, setIsMember] = useState(false);

  const isJuryMember = useIsJuryMember();
  const isAdministrator = useIsAdministrator();

  const [idea, fetchTomtomLabProject] = useAsyncFn(async () => {
    if (initIdea.id) {
      const response = await tomtomLabApi.getIdeaById(initIdea.id);
      return await parseTomtomLabResponse(response);
    }
    return null;
  });

  const [members, fetchMembers] = useAsyncFn(async () => {
    return fetchIdeaMembers(tomtomLabApi, idea.value ?? initIdea);
  });

  const [selfAssessment, fetchSelfAssessment] = useAsyncFn(async () => {
    return fetchSelfAssessments(tomtomLabApi, idea.value ?? initIdea);
  });

  const [userId, fetchUserId] = useAsyncFn(async () => {
    const entityRef = (await identity.getBackstageIdentity()).userEntityRef;
    const user = await fetchUser(catalogApi, entityRef);
    if (user) {
      return user.metadata.uid;
    }
    return '';
  });

  useEffect(() => {
    fetchMembers();
    fetchSelfAssessment();
    fetchTomtomLabProject();
    fetchUserId();
  }, [fetchMembers, fetchSelfAssessment, fetchTomtomLabProject, fetchUserId]);

  useEffect(() => {
    if (members.value && userId.value) {
      setIsMember(
        members.value
          ?.map((member: Member) => member.userId)
          .indexOf(userId.value) >= 0,
      );
    }
  }, [idea.value, members, identity, userId.value]);

  const handleMembersClick = async () => {
    if (userId.value && idea.value!.id) {
      if (!isMember) {
        await tomtomLabApi.addMember(idea.value!.id, userId.value);
      } else {
        await tomtomLabApi.deleteMember(idea.value!.id, userId.value);
      }
      setIsMember(!isMember);
      fetchMembers();
    }
  };

  const links: IconLinkVerticalProps[] = [
    {
      label: isMember ? 'Leave' : 'Join',
      icon: isMember ? <ExitToAppIcon /> : <PersonAddIcon />,
      disabled: !isUserParticipant,
      href: '',
      onClick: async () => {
        handleMembersClick();
      },
    },
    {
      label: 'Docs',
      icon: <Description />,
      href: idea.value?.docs,
      disabled: idea.value?.docs === null || idea.value?.docs === '',
    },
  ];

  if (idea.error) {
    return <Alert severity="error">{idea?.error?.message}</Alert>;
  } else if (members.error) {
    return <Alert severity="error">{members?.error?.message}</Alert>;
  }

  const isSelfAssessmentNotFullyPopulated = () => {
    const isNotPopulated = selfAssessment.value?.scores.some(score => {
      return score.description === undefined || score.score === 0;
    });
    return isNotPopulated;
  };

  const isReadyforJury = () => {
    let message: string = '';
    let isReady: Boolean = true;
    if (idea.value?.solution === '') {
      message = `You must have a solution in place before submitting your idea to jury. `;
      isReady = false;
    }

    if (isSelfAssessmentNotFullyPopulated()) {
      message = `${message} You must enter a description and score for all self-assessment criteria. `;
      isReady = false;
    }
    return { message, isReady };
  };

  const isDoneHacking = () => {
    let message: string = '';
    let isDone: Boolean = true;
    if (idea.value?.solution === '') {
      message = `You must have a solution in place before submitting your idea to the final jury. `;
      isDone = false;
    }
    return { message, isDone };
  };

  const handlePublish = async (status: Status) => {
    const ideaToPublish = {
      ...idea.value,
      status: status,
    };
    const response = await tomtomLabApi.updateIdea(ideaToPublish);
    if (response.status === 'ok') {
      fetchTomtomLabProject();
      setOpenConfirmation(false);
      setIsDoneOpenConfirmation(false);
    }
  };

  const handleShare = async () => {
    try {
      const ideaToBeUsed = idea.value ?? initIdea;
      let path;
      if (isSharedIdeaLink()) {
        path = window.location.href;
      } else {
        path = `${window.location.href}/${ideaToBeUsed.id}`;
      }
      await navigator.clipboard.writeText(path);
      alertApi.post({
        severity: 'info',
        message: 'Idea link copied to clipboard',
        display: 'transient',
      });
    } catch {
      alertApi.post({
        severity: 'error',
        message: 'There was a problem copying the idea link',
        display: 'transient',
      });
    }
  };

  const handleSubmitToJury = () => {
    const { message, isReady } = isReadyforJury();
    if (isReady) {
      setOpenConfirmation(true);
    } else {
      alertApi.post({
        severity: 'error',
        message: message,
      });
    }
  };

  const handleIsDoneHacking = () => {
    const { message, isDone } = isDoneHacking();
    if (isDone) {
      setIsDoneOpenConfirmation(true);
    } else {
      alertApi.post({
        severity: 'error',
        message: message,
      });
    }
  };

  const ShareButton = () => (
    <Button
      onClick={async () => await handleShare()}
      startIcon={<ShareIcon />}
    />
  );

  const ScoresButton = () => (
    <LinkButton to={`/tomtom-lab/jury-scores/${idea.value?.id}`}>
      SCORES
    </LinkButton>
  );

  return (
    <div>
      <Card>
        <CardHeader
          title={
            <Typography
              variant="h3"
              component="h2"
              className={classes.wordBreak}
            >
              {idea.value?.title || initIdea.title}
            </Typography>
          }
          action={
            <div>
              {isMember && idea.value?.status === 'draft' && (
                <ButtonGroup aria-label="outlined primary button group">
                  <LinkButton
                    to={`/tomtom-lab/register-idea/${idea.value?.id}`}
                  >
                    <EditIcon />
                  </LinkButton>
                  <ShareButton />
                  <Button
                    onClick={async () => await handlePublish('proposed')}
                    startIcon={<PublishIcon />}
                  >
                    Ready for Hacking
                  </Button>
                </ButtonGroup>
              )}
              {isMember && idea.value?.status === 'proposed' && (
                <ButtonGroup aria-label="outlined primary button group">
                  <LinkButton
                    to={`/tomtom-lab/register-idea/${idea.value?.id}`}
                  >
                    <EditIcon />
                  </LinkButton>
                  <ShareButton />
                  <Button
                    onClick={handleSubmitToJury}
                    startIcon={<PublishIcon />}
                  >
                    Ready for Jury
                  </Button>
                </ButtonGroup>
              )}
              {(isMember || isJuryMember) &&
                idea.value?.status === 'readyForJury' && (
                  <ButtonGroup aria-label="outlined primary button group">
                    <ShareButton />
                    <ScoresButton />
                  </ButtonGroup>
                )}
              {isAdministrator && idea.value?.status === 'readyForJury' && (
                <ButtonGroup aria-label="outlined primary button group">
                  <Button
                    onClick={async () => await handlePublish('readyForFinals')}
                    startIcon={<PublishIcon />}
                  >
                    Ready for Finals
                  </Button>
                </ButtonGroup>
              )}
              {isMember && idea.value?.status === 'readyForFinals' && (
                <ButtonGroup aria-label="outlined primary button group">
                  <LinkButton
                    to={`/tomtom-lab/register-idea/${idea.value?.id}`}
                  >
                    <EditIcon />
                  </LinkButton>
                  <ShareButton />
                  <Button
                    onClick={handleIsDoneHacking}
                    startIcon={<PublishIcon />}
                  >
                    Done Hacking
                  </Button>
                </ButtonGroup>
              )}
              {isMember &&
                !isAdministrator &&
                idea.value?.status === 'doneHacking' && (
                  <ButtonGroup aria-label="outlined primary button group">
                    <ShareButton />
                    <Button disabled startIcon={<PublishIcon />}>
                      Done Hacking
                    </Button>
                  </ButtonGroup>
                )}
              {isAdministrator && idea.value?.status === 'doneHacking' && (
                <ButtonGroup aria-label="outlined primary button group">
                  <ShareButton />
                  <Button
                    startIcon={<PublishIcon />}
                    onClick={async () => await handlePublish('readyForFinals')}
                  >
                    Revert Done Hacking
                  </Button>
                </ButtonGroup>
              )}
              {!isMember && !isJuryMember && (
                <ButtonGroup aria-label="outlined primary button group">
                  <ShareButton />
                </ButtonGroup>
              )}
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              {openConfirmation && (
                <ConfirmationDialog
                  open={openConfirmation}
                  handleClose={() => setOpenConfirmation(false)}
                  title="Submit for Jury Evaluation"
                  message={[
                    'Are you sure you want to submit your idea for jury evaluation?',
                  ]}
                  handleSubmit={async () => await handlePublish('readyForJury')}
                />
              )}
              {isDoneOpenConfirmation && (
                <ConfirmationDialog
                  open={isDoneOpenConfirmation}
                  handleClose={() => setIsDoneOpenConfirmation(false)}
                  title="Submit for Final Jury Evaluation"
                  message={[
                    'Are you sure you want to mark your idea as Done? Once an Idea is Done Hacking it can no longer be edited.',
                  ]}
                  handleSubmit={async () => await handlePublish('doneHacking')}
                />
              )}
            </div>
          }
          subheader={<HeaderIconLinkRow links={links} />}
        />
        {!isUserParticipant && <NonParticipantAlert />}
        <Divider />
        <CardContentFields
          idea={idea.value || initIdea}
          members={members.value || []}
          selfAssessment={selfAssessment.value}
        />
      </Card>
    </div>
  );
};
