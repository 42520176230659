import { Entity } from '@backstage/catalog-model';

export const GITHUB_TEAM_DEPLOYMENTS = 'enable-github-team-deploymentS';

/** @public */
export const isGithubTeamDeploymentDisabled = (entity: Entity) =>
  (entity?.metadata.annotations?.[GITHUB_TEAM_DEPLOYMENTS] ?? 'yes') === 'yes';

export {
  githubTeamDeploymentsPlugin,
  GithubTeamDeploymentsPage,
} from './plugin';

export { columnFactories } from './components/DeploymentTable';
