import { EntityManagerMeta } from '@internal/plugin-catalog-model';
import React, { Fragment } from 'react';

import { EmptyState, Link } from '@backstage/core-components';

import {
  Box,
  Button,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';

import { ManagerAvatar } from '../GroupMember/ManagerAvatar';
import { useGetUserJobTitle } from './hooks/useGetUserJobTitle';

const GroupManager = (props: { manager: EntityManagerMeta }) => {
  const { manager } = props;
  const jobTitle = useGetUserJobTitle(manager.email);

  return (
    <>
      <ManagerAvatar email={manager.email} />
      <Typography style={{ paddingLeft: '3em' }}>
        {manager.email ? (
          <Link
            color="secondary"
            to={manager.email ? `mailto:${manager.email}` : '#'}
          >
            {manager.name || 'Unknown'}
          </Link>
        ) : (
          <span color="secondary">{manager.name || 'Unknown'}</span>
        )}{' '}
        ({manager.title || jobTitle})
      </Typography>
    </>
  );
};

export const GroupProfileManagersListItem = (props: {
  managers: EntityManagerMeta[];
}) => {
  return props.managers.length > 0 ? (
    <>
      {props.managers?.map((manager: EntityManagerMeta, index: number) => {
        return (
          <Fragment key={index}>
            <ListItem data-testid="manager-list">
              <ListItemText primary={<GroupManager manager={manager} />} />
            </ListItem>
          </Fragment>
        );
      })}
    </>
  ) : (
    <Box data-testid="manager-empty-state">
      <EmptyState
        missing="data"
        title="Managers not found"
        description="For information about adding team manager(s), click on Read More."
        action={
          <Button
            variant="contained"
            color="primary"
            href="/docs/default/component/sp-sde-portal-frontstage/onboarding/team-space/#provide-your-team-managers"
          >
            Read more
          </Button>
        }
      />
    </Box>
  );
};
