import { GroupEntity } from '@internal/plugin-catalog-model';
import useAsync from 'react-use/lib/useAsync';

import { CatalogApi } from '@backstage/catalog-client';
import { CompoundEntityRef, RELATION_CHILD_OF } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';

export const useGetEntityAndRelations = (
  childEntityRef: CompoundEntityRef,
): {
  group: GroupEntity | undefined;
  parents: CompoundEntityRef[];
} => {
  const catalogApi: CatalogApi = useApi(catalogApiRef);

  const { value: entityWithRelations } = useAsync(async () => {
    const groupEntity = (await catalogApi.getEntityByRef(
      childEntityRef,
    )) as GroupEntity;

    const relations = getEntityRelations(groupEntity, RELATION_CHILD_OF, {
      kind: 'group',
    });

    return {
      groupEntity,
      relations,
    };
  });

  return {
    group: entityWithRelations?.groupEntity,
    parents: entityWithRelations?.relations ?? [],
  };
};
