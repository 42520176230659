import {
  createPlugin,
  createRoutableExtension,
  createApiFactory,
  discoveryApiRef,
  fetchApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { glossaryApiRef, GlossaryClient } from './api';
import { rootRouteRef } from './routes';

export const glossaryPlugin = createPlugin({
  id: 'glossary',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: glossaryApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ discoveryApi, fetchApi, identityApi }) =>
        new GlossaryClient({ fetchApi, discoveryApi, identityApi }),
    }),
  ],
});

export const GlossaryPage = glossaryPlugin.provide(
  createRoutableExtension({
    name: 'GlossaryPage',
    component: () =>
      import('./components/GlossaryPage').then(m => m.GlossaryPage),
    mountPoint: rootRouteRef,
  }),
);
