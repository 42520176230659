export enum TommeeStatus {
  'Pending' = 'Pending',
  'Approved' = 'Approved',
  'Rejected' = 'Rejected',
}

export const RECOGNITION_EFFECTIVE_NAME = 'Tommee';

export interface TommeeOwner {
  id: number;
  tommeeId: number;
  owner: string;
}

export interface TommeeUsers {
  // id: number;
  tommeeId: number;
  users: string[];
}

export interface UserTommee {
  id: number;
  name: string;
  description: string;
  icon: string;
  createdAt: string;
  updatedAt: string;
  status: TommeeStatus;
}

export interface PendingTomeeRequest {
  id: number;
  name: string;
  description: string;
  userRef: string;
  requestedBy: string;
  createdAt: string;
}

export interface Tommee {
  id: number;
  name: string;
  description: string;
  icon: string;
  allowMultipleAssignment: boolean;
  owned: boolean;
}

export type TommeeDetails = Tommee & {
  requests: TommeeRequestInfo[];
};

export type CreateTommeeData = Omit<Tommee, 'id'>;

export interface TommeeRequest {
  tommeeId: number;
  user: string;
}

export interface TommeeRequestInfo extends TommeeRequest {
  userName: string;
  userEmail: string;
  status: TommeeStatus;
}

export interface RecognitionApi {
  getTommees(): Promise<Tommee[]>;
  getTommee(id: number): Promise<TommeeDetails>;
  getOwnersByTommees(): Promise<TommeeOwner[]>;
  approveRequest(tommeeId: number, userRef: string): Promise<void>;
  rejectRequest(tommeeId: number, userRef: string): Promise<void>;
  requestTommee(
    request: TommeeRequest,
  ): Promise<{ tommee_id: number; user_ref: string }>;
  requestTommees(
    tommeeId: number,
    requests: TommeeRequest[],
  ): Promise<{ tommee_id: number; user_ref: string }[]>;
  getCurrentUserTommees(): Promise<UserTommee[]>;
  getPendingRequests(): Promise<PendingTomeeRequest[]>;
  getUserTommees(userId: string): Promise<UserTommee[]>;
  getTommeeUsers(tommeeId: number): Promise<TommeeUsers>; // TODO: check what this will return
  getUsersByTommees(
    status?: TommeeStatus,
  ): Promise<{ [key in number]: string[] }>;
  createTommee(
    tommeeData: CreateTommeeData,
    ownerRefs: string[],
  ): Promise<string>;
  editTommee(
    tommeeId: number,
    tommeeData: CreateTommeeData,
    ownerRefs: string[],
  ): Promise<string>;
  deleteTommee(tommeeId: number): Promise<string>;
}
