import { useAsync } from 'react-use';

import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef, useEntity } from '@backstage/plugin-catalog-react';
import {
  TemplateEntityV1beta3,
  TemplateParametersV1beta3,
} from '@backstage/plugin-scaffolder-common';

import { formDataBuilderFactory } from '../formDataBuilders';

export const useBuildTemplateFormData = (templateName: string | undefined) => {
  const { entity } = useEntity();
  const identityApi = useApi(identityApiRef);
  const catalogApi = useApi(catalogApiRef);

  const formDataBuilder = formDataBuilderFactory(templateName);

  const data = useAsync(async () => {
    const template = (await catalogApi.getEntityByRef({
      kind: 'Template',
      namespace: 'default',
      name: templateName ?? '',
    })) as TemplateEntityV1beta3;

    if (!formDataBuilder || !template?.spec?.parameters) return {};

    const parameters = template.spec?.parameters;
    const formFields = (parameters as TemplateParametersV1beta3[])
      .map((param: TemplateParametersV1beta3) => param.properties)
      .map((prop: any) => Object.keys(prop))
      .flat();

    const formData = await formDataBuilder(
      entity,
      formFields,
      identityApi,
      catalogApi,
    );

    return formData;
  });

  return {
    formData: data.value ?? {},
    loading: data.loading,
    error: data.error,
  };
};
