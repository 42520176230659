import { Workflow, WorkflowNode, WorkflowState } from '../types';

const colors: Record<WorkflowState, string> = {
  success: '#49c402',
  failure: '#fc3f19',
  pending: 'gray',
  running: '#fcba03',
  skipped: '#e8e6e1',
};

export const generateNodes = (workflows: Workflow[]): WorkflowNode[] => {
  const nodes = workflows.map(node => ({
    ...node,
    color: colors[node.state],
  }));

  return nodes;
};
