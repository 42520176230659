import { GroupEntity } from '@internal/plugin-catalog-model';
import { Octokit } from '@octokit/rest';

import { CompoundEntityRef, Entity } from '@backstage/catalog-model';
import { CatalogApi } from '@backstage/plugin-catalog-react';

import { RepoNamePickerState, RepoNamePickerValidation } from './types';

export const parseRepoNamePickerFormData = (
  formData: string | undefined,
): RepoNamePickerState => {
  let host = '';
  let owner = '';
  let repoName = '';
  let organization = '';
  let workspace = '';
  let project = '';
  let entityRef = '';
  const validation: RepoNamePickerValidation = {
    isFollowingTeamNameConvention: true,
    isRepoExists: false,
  };

  const repoUrlIndex = 0;
  const groupEntityIndex = 1;
  const isRepoExistsIndex = 2;
  const isFollowingTeamNameConventionIndex = 3;

  try {
    if (formData && formData.indexOf(':') !== -1) {
      const array = formData.split(':');
      const repoUrl = new URL(`https://${array[repoUrlIndex]}`);

      host = repoUrl.host;
      owner = repoUrl.searchParams.get('owner') || '';
      repoName = repoUrl.searchParams.get('repo') || '';
      organization = repoUrl.searchParams.get('organization') || '';
      workspace = repoUrl.searchParams.get('workspace') || '';
      project = repoUrl.searchParams.get('project') || '';

      entityRef = array[groupEntityIndex].split('=')?.[1] ?? '';

      validation.isRepoExists =
        array[isRepoExistsIndex].split('=')?.[1] === 'true';

      validation.isFollowingTeamNameConvention =
        array[isFollowingTeamNameConventionIndex].split('=')?.[1] === 'true';
    }
  } catch {
    /* ok */
  }
  return {
    host,
    owner,
    repoName,
    organization,
    workspace,
    project,
    entityRef,
    validation,
  };
};

export const serializeRepoNamePickerData = (
  data: RepoNamePickerState,
): string | undefined => {
  if (!data.host || !data.entityRef) {
    return undefined;
  }

  const params = new URLSearchParams();
  if (data.owner) {
    params.set('owner', data.owner);
  }
  if (data.repoName) {
    params.set('repo', data.repoName);
  }
  if (data.organization) {
    params.set('organization', data.organization);
  }
  if (data.workspace) {
    params.set('workspace', data.workspace);
  }
  if (data.project) {
    params.set('project', data.project);
  }

  const repoUrl = `${data.host}?${params.toString()}`;
  const groupEntityRef = data.entityRef;
  const isFollowingTeamNameConvention =
    data.validation?.isFollowingTeamNameConvention ?? true;
  const isRepoExists = data.validation?.isRepoExists ?? false;

  return `${repoUrl}:group=${groupEntityRef}:repoExists=${isRepoExists}:nameConvention=${isFollowingTeamNameConvention}`;
};

export const serializeGroupEntityName = (entity: Entity) => {
  if (entity.metadata.namespace && entity.metadata.namespace !== 'default') {
    return `${entity.metadata.namespace}:${entity.metadata.name}`;
  }
  return entity.metadata.name;
};

export const parseGroupEntityName = (
  groupEntityName: string,
): CompoundEntityRef => {
  if (groupEntityName.indexOf(':') !== -1) {
    const array = groupEntityName.split(':');
    return {
      kind: 'Group',
      namespace: array[0],
      name: array[1],
    };
  }
  return {
    kind: 'Group',
    namespace: 'default',
    name: groupEntityName,
  };
};

export const isFollowingTeamNamingConvention = async (
  catalogApi: CatalogApi,
  entityRef: string,
  repoName: string,
): Promise<boolean> => {
  const entity =
    entityRef && catalogApi
      ? await catalogApi.getEntityByRef(parseGroupEntityName(entityRef))
      : undefined;

  const patterns = entity
    ? (entity as GroupEntity)?.metadata.projectNamePatterns
    : [];

  const regExps: RegExp[] = [];
  if (patterns) {
    for (let i = 0; i < patterns?.length; i++) {
      try {
        regExps.push(new RegExp(patterns[i], 'i'));
      } catch (e) {
        /* ok */
      }
    }
  }

  if (regExps && regExps?.length > 0) {
    const re = regExps.find(regex => regex.test(repoName ?? ''));
    if (!re) {
      return false;
    }
  }

  return true;
};

export const isGithubRepoExists = async (
  token: string,
  owner: string,
  repo: string,
): Promise<boolean> => {
  const githubApiBaseUrl = 'https://api.github.com';
  try {
    const octokit = new Octokit({
      token,
      githubApiBaseUrl,
    });

    const repository = await octokit.request('GET /repos/{owner}/{repo}', {
      owner,
      repo,
      headers: {
        authorization: `token ${token}`,
      },
    });

    if (repository?.data?.name) {
      return true;
    }
  } catch (e) {
    return false;
  }

  return false;
};
