import React from 'react';

import { Button, Typography } from '@material-ui/core';
import FeedbackIcon from '@material-ui/icons/Feedback';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Alert, AlertTitle } from '@material-ui/lab';

import { useHomePageStyles } from './hooks/useHomePageStyles';

export type FeedbackBannerProps = {
  onDismiss: (state: string | null) => void;
};

export const FeedbackBanner = (props: FeedbackBannerProps) => {
  const { onDismiss } = props;
  const classes = useHomePageStyles();
  return (
    <Alert
      classes={{
        icon: classes.supportBannerIconWrapper,
        action: classes.supportBannerActionWrapper,
      }}
      severity="info"
      variant="filled"
      icon={<FeedbackIcon />}
      action={
        <>
          <Button
            color="inherit"
            size="small"
            onClick={() => {
              localStorage.setItem(
                'homepage/feedbackBannerClosed',
                JSON.stringify(true),
              );
              onDismiss(JSON.stringify(true));
            }}
          >
            Dismiss
          </Button>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            className={classes.supportBannerSendFeedbackButton}
            href="https://github.com/tomtom-internal/sp-sde-portal/issues/new?assignees=&labels=&template=bug_report.md&title="
            target="_blank"
            endIcon={<OpenInNewIcon />}
          >
            Send feedback
          </Button>
        </>
      }
    >
      <AlertTitle>
        <Typography variant="h6" gutterBottom={false}>
          Help us to improve Frontstage
        </Typography>
      </AlertTitle>
      Send us your comments or report any problems you experienced while using
      TomTom Frontstage. You can always report any issues by clicking the
      toolkit link below.
    </Alert>
  );
};
