import { Entity } from '@backstage/catalog-model';

import { GithubWorkflowClient, WorkflowRunRequest } from '../api';

export const getLatestWorkflowRuns = async (
  workflowEntities: (Entity | undefined)[],
  githubWorkflowClient: GithubWorkflowClient,
) => {
  const workflowRunRequests: WorkflowRunRequest[] = [];
  workflowEntities.forEach(entity => {
    if (
      entity?.metadata.annotations?.['github.com/repository-name'] &&
      entity?.metadata.annotations?.['github.com/workflow-id'] &&
      !isNaN(Number(entity.metadata.annotations?.['github.com/workflow-id']))
    ) {
      workflowRunRequests.push({
        owner: 'tomtom-internal',
        repo: entity.metadata.annotations?.['github.com/repository-name'],
        workflow_id: Number(
          entity.metadata.annotations?.['github.com/workflow-id'],
        ),
        pageSize: 1,
        page: 0,
      } as WorkflowRunRequest);
    }
  });

  const runs = await githubWorkflowClient.listBatchWorkflowRuns(
    workflowRunRequests,
  );

  const latestRuns = runs.map(run => run.workflow_runs[0]);
  return latestRuns;
};
