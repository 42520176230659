import { RepositoryEntity } from '@internal/plugin-catalog-model';

import { FactRetrieverContext } from '@backstage-community/plugin-tech-insights-node';
import { stringifyEntityRef } from '@backstage/catalog-model';

import { GroupRepositoryEntityFact, TypedTechInsightFact } from '../../types';
import { getEntities } from '../utils/getEntities';
import { getChildGroups } from './utils/groupRepository/getChildGroups';
import { getRepositoriesByChildGroups } from './utils/groupRepository/getRepositoriesByChildGroups';

export const groupRepositoryEntityHandler = async ({
  discovery,
  entityFilter,
  tokenManager,
}: FactRetrieverContext): Promise<
  TypedTechInsightFact<GroupRepositoryEntityFact>[]
> => {
  // get all groups
  const groupEntities = await getEntities({
    entityFilter,
    tokenManager,
    discovery,
  });

  // retrieve all the children groups of each group entity
  const groupWithChildGroups = await getChildGroups(
    groupEntities.items,
    tokenManager,
    discovery,
  );

  const promises = groupWithChildGroups.map(async group => {
    // get all the repositories by child groups
    const childGroupsRepositories = await getRepositoriesByChildGroups({
      owners: group.children,
      kind: 'Repository',
      tokenManager,
      discovery,
    });

    let confirmedRepositoryCount = 0;
    let proposedRepositoryCount = 0;
    const ownedRepositoryEntityRefs: string[] = [];

    childGroupsRepositories.items.forEach(entity => {
      if ((entity as RepositoryEntity)?.metadata.tags?.includes('confirmed')) {
        confirmedRepositoryCount++;
      } else {
        proposedRepositoryCount++;
      }
      ownedRepositoryEntityRefs.push(stringifyEntityRef(entity));
    });

    return {
      entity: {
        namespace: group.entity.metadata.namespace!,
        kind: group.entity.kind,
        name: group.entity.metadata.name,
      },
      facts: {
        confirmedRepositoryCount,
        proposedRepositoryCount,
        ownedRepositoryEntityRefs: [...new Set(ownedRepositoryEntityRefs)],
      },
    };
  });

  return (await Promise.allSettled(promises))
    .filter(res => res.status === 'fulfilled')
    .flatMap(
      ins =>
        (
          ins as PromiseFulfilledResult<
            TypedTechInsightFact<GroupRepositoryEntityFact>
          >
        ).value,
    );
};
