import { FieldValidation } from '@rjsf/utils';

import { ApiHolder } from '@backstage/core-plugin-api';
import { scmIntegrationsApiRef } from '@backstage/integration-react';

import { parseRepoNamePickerFormData } from './utils';

const isValidSCMRepoName = (
  repoName: string,
  host: string,
  context: { apiHolder: ApiHolder },
) => {
  const integrationApi = context?.apiHolder?.get(scmIntegrationsApiRef);

  const hostType =
    (host && integrationApi && integrationApi.byHost(host)?.type) ?? null;

  if (hostType === 'github') {
    const re = new RegExp('^([a-zA-Z]+)([a-zA-Z0-9-]+)([a-zA-Z0-9]+)$', 'i');
    return re.test(repoName ?? '');
  }
  return true;
};

export const repoNamePickerValidation = (
  value: string,
  validation: FieldValidation,
  context: { apiHolder: ApiHolder },
) => {
  try {
    const {
      host,
      owner,
      repoName,
      entityRef,
      validation: repoNameValidation,
    } = parseRepoNamePickerFormData(value);

    if (!entityRef) {
      validation.addError('Repository team is required');
    } else if (!repoName) {
      validation.addError('Repository name is required');
    } else if (!host) {
      validation.addError('Repository host is required');
    } else if (!owner) {
      validation.addError('Repository owner is required');
    } else if (repoName) {
      // validate the repoName
      // validate against scm naming convention
      if (!isValidSCMRepoName(repoName, host, context)) {
        validation.addError(
          `Repository name ${repoName} is not a valid ${host} repo name!`,
        );
      }

      // validate against any team name convention is defined
      if (!repoNameValidation?.isFollowingTeamNameConvention) {
        validation.addError(
          `Repository name ${repoName} does not follow your team naming convention!`,
        );
      }

      // validate the uniqueness of the repo name
      if (repoNameValidation?.isRepoExists) {
        validation.addError(
          `Repository name ${repoName} already exists, please choose a different name!`,
        );
      }
    }
  } catch {
    validation.addError(
      'Unable to validate your input. Please try again later!',
    );
  }
};
