import { Entity } from '@backstage/catalog-model';

import { AZURE_ANNOTATION_COMPLIANCY_RESOURCE_NAME_FILTERS } from '../types';

export const isCompliancyResourceNameFiltersAvailable = (
  entity: Entity,
): boolean => {
  return Boolean(
    entity?.metadata.annotations?.[
      AZURE_ANNOTATION_COMPLIANCY_RESOURCE_NAME_FILTERS
    ],
  );
};
