import { AuthorizationManagementClient } from '@azure/arm-authorization';
import {
  ContainerServiceClient,
  ManagedClustersGetUpgradeProfileResponse,
  ManagedClustersRunCommandResponse,
  RunCommandRequest,
} from '@azure/arm-containerservice';
import { ResourceGraphClient } from '@azure/arm-resourcegraph';
import { ResourcesResponse } from '@azure/arm-resourcegraph/esm/models';
import { Subscription, SubscriptionClient } from '@azure/arm-subscriptions';
import { InteractiveBrowserCredential } from '@azure/identity';
import { Client } from '@microsoft/microsoft-graph-client';
import { TokenCredentialAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/azureTokenCredentials';
import jwt_decode from 'jwt-decode';

import { ConfigApi } from '@backstage/core-plugin-api';

import { DateRange } from '../types';
import { getPayloadForRequest } from '../utils/getPayloadForRequest';
import { getURLForAzureAPI } from '../utils/getURLForAzureAPI';
import { parseCostManagementResponse } from '../utils/parseCostManagementResponse';
import {
  AzureCostsResponse,
  AzureResourcesApi,
  AzureSubscriptionCost,
} from './AzureResourcesApi';

const USER_IMPERSONATION_SCOPE =
  'https://management.azure.com/user_impersonation';

async function getCostsBySubscription(
  subscriptionId: string,
  accessToken: string,
  dateRange: DateRange,
) {
  const API_VERSION = '2021-10-01';
  const URL = getURLForAzureAPI(
    subscriptionId,
    'Microsoft.CostManagement',
    'query',
    API_VERSION,
  );
  const byServicePayload = JSON.stringify(
    getPayloadForRequest('CostPerService', dateRange),
  );

  const byResourceGroupPayload = JSON.stringify(
    getPayloadForRequest('CostPerResourceGroup', dateRange),
  );

  const headers = {
    Accept: '*/*',
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const costByService = await fetch(URL, {
    method: 'POST',
    headers,
    body: byServicePayload,
  });
  const byServiceData = await costByService.json();

  await new Promise(f => setTimeout(f, 1000)); // add sleep between the two fetches
  const costByResourceGroup = await fetch(URL, {
    method: 'POST',
    headers,
    body: byResourceGroupPayload,
  });
  const byResourceGroupData = await costByResourceGroup.json();

  return {
    byService: parseCostManagementResponse(byServiceData),
    byResourceGroup: parseCostManagementResponse(byResourceGroupData),
  };
}

export class AzureResourcesClient implements AzureResourcesApi {
  private readonly credential: InteractiveBrowserCredential;
  private readonly resourcegraphClient: ResourceGraphClient;
  private readonly subscriptionClient: SubscriptionClient;
  private readonly configApi: ConfigApi;

  constructor(options: { configApi: ConfigApi }) {
    this.configApi = options.configApi;
    const tenantId = this.configApi.getOptionalString(
      'app.azureResources.tenantId',
    );
    const clientId = this.configApi.getOptionalString(
      'app.azureResources.clientId',
    );

    if (!tenantId || !clientId) {
      throw new Error(
        'tenantId and clientId must be provided in the app config!',
      );
    }

    this.credential = new InteractiveBrowserCredential({
      tenantId,
      clientId,
      loginStyle: 'popup',
    });
    this.resourcegraphClient = new ResourceGraphClient(this.credential);
    this.subscriptionClient = new SubscriptionClient(this.credential);
  }

  private initContainerServiceClient = (subscriptionId: string) =>
    new ContainerServiceClient(this.credential, subscriptionId);

  private initAuthorizationManagementClient = (subscriptionId: string) =>
    new AuthorizationManagementClient(this.credential, subscriptionId);

  runAzureManagedClusterCommand(
    subscriptionId: string,
    resourceGroupName: string,
    resourceName: string,
    request: RunCommandRequest,
  ): Promise<ManagedClustersRunCommandResponse> {
    const containerServiceClient =
      this.initContainerServiceClient(subscriptionId);

    return containerServiceClient.managedClusters.beginRunCommandAndWait(
      resourceGroupName,
      resourceName,
      request,
    );
  }

  getManagedClusterUpgradeProfile = (
    subscriptionId: string,
    resourceGroupName: string,
    resourceName: string,
  ): Promise<ManagedClustersGetUpgradeProfileResponse> => {
    const containerServiceClient =
      this.initContainerServiceClient(subscriptionId);
    return containerServiceClient.managedClusters.getUpgradeProfile(
      resourceGroupName,
      resourceName,
    );
  };

  getAzureManagedClusterSecurities(
    clusterResourceName: string,
  ): Promise<ResourcesResponse> {
    const query = `ResourceContainers
      | where type =~ "microsoft.resources/subscriptions/resourcegroups"
      | join (securityresources
          | where type == 'microsoft.security/assessments'
          | where properties.resourceDetails.Id contains "managedclusters"
          | extend statusCode=properties.status.code,
              resourceId=tolower(properties.resourceDetails.Id),
              severity = properties.metadata.severity,
              displayName = properties.metadata.displayName,
              link = properties.links.azurePortal
          | join kind=leftouter (
              resources | extend resourceName = name
                      | extend resourceId = tolower(id)
                      | extend resourceType = type
                      | project resourceName, resourceId, resourceType) on resourceId
        ) on resourceGroup
      | where statusCode =~"Unhealthy"
      | where resourceName =~ "${clusterResourceName}"
      | project resourceId, displayName, link, resourceName, resourceType, resourceGroup, severity, subscriptionId, tenantId`;

    return this.resourcegraphClient.resources(
      { query: query },
      { resultFormat: 'table' },
    );
  }

  getAzureManagedClusters(
    resourceGroupNameFilters?: string[],
  ): Promise<ResourcesResponse> {
    let clusterQuery = `resources
      | where type == "microsoft.containerservice/managedclusters"`;

    if (resourceGroupNameFilters?.length) {
      let rgFilterQuery = '| where ';
      let operator = '';

      resourceGroupNameFilters.forEach((filter: string) => {
        if (filter) {
          rgFilterQuery = `${rgFilterQuery}${operator}resourceGroup contains "${filter}"`;
          operator = ' or ';
        }
      });

      clusterQuery += rgFilterQuery;
    }

    return this.resourcegraphClient.resources(
      { query: clusterQuery },
      { resultFormat: 'table' },
    );
  }

  getAzurePolicyCompliancy(
    resourceGroupNameFilters?: string[],
  ): Promise<ResourcesResponse> {
    let policyStateQuery = `policyresources
      | where type == "microsoft.policyinsights/policystates"
      | where properties.complianceState == 'NonCompliant'
      | extend policyAssignmentParameters = todynamic(properties.policyAssignmentParameters),
      policyDefinitionAction = tostring(properties.policyDefinitionAction),
      policyAssignmentScope = tostring(properties.policyAssignmentScope),
      policyAssignmentName = tostring(properties.policyAssignmentName),
      policyDefinitionName = tostring(properties.policyDefinitionName),
      policyDefinitionId = tostring(properties.policyDefinitionId),
      policyAssignmentId = tostring(properties.policyAssignmentId),
      managementGroupIds = tostring(properties.managementGroupIds),
      policyDefinitionReferenceId = tostring(properties.policyDefinitionReferenceId),
      complianceState = tostring(properties.complianceState),
      policySetDefinitionCategory = tostring(properties.policySetDefinitionCategory),
      subscriptionId = tostring(properties.subscriptionId),
      policySetDefinitionName = tostring(properties.policySetDefinitionName),
      policySetDefinitionId = tostring(properties.policySetDefinitionId),
      resourceType = tostring(properties.resourceType),
      policyDefinitionGroupNames = todynamic(properties.policyDefinitionGroupNames),
      stateWeight = toint(properties.stateWeight),
      resourceId = tostring(properties.resourceId),
      isDeleted = tobool(properties.isDeleted),
      timestamp = tostring(properties.timestamp)
      | project timestamp,
        resourceId,
        resourceGroup,
        resourceType,
        complianceState,
        stateWeight,
        policyAssignmentName,
        policyAssignmentScope,
        policyAssignmentParameters,
        policySetDefinitionId,
        policySetDefinitionName,
        policySetDefinitionCategory,
        policyDefinitionId,
        policyDefinitionName,
        policyDefinitionAction,
        policyDefinitionReferenceId,
        policyDefinitionGroupNames,
        managementGroupIds,
        subscriptionId,
        id
      | where isnotempty(resourceGroup) 
      | where resourceType !contains "Microsoft.Compute/galleries/images"
      | order by ['policySetDefinitionName'] asc`;

    if (resourceGroupNameFilters?.length) {
      let rgFilterQuery = '| where ';
      let operator = '';
      resourceGroupNameFilters.forEach((filter: string) => {
        if (filter) {
          rgFilterQuery = `${rgFilterQuery}${operator}resourceGroup contains "${filter}"`;
          operator = ' or ';
        }
      });

      policyStateQuery += rgFilterQuery;
    }

    return this.resourcegraphClient.resources(
      { query: policyStateQuery },
      { resultFormat: 'table' },
    );
  }

  getAzureResourceTaggingCompliancy(
    resourceGroupNameFilters?: string[],
  ): Promise<ResourcesResponse> {
    let rgQuery = `resourcecontainers
      | where type == "microsoft.resources/subscriptions/resourcegroups"
      | where tags !contains "created-by" or tags !contains "deployer" or tags !contains "application" or tags !contains "stage" 
      | project  name,type,location,subscriptionId,tags,resourceGroup
      | union (
            resources 
                | where tags !contains "created-by" or tags !contains "deployer" or tags !contains "application" or tags !contains "stage"  
                | project name,type,location,subscriptionId,tags,resourceGroup,tenantId,id)
      | where type !contains 'microsoft.compute/galleries'
      | where type !contains 'microsoft.resources/templatespecs'`;

    if (resourceGroupNameFilters?.length) {
      let rgFilterQuery = '| where ';
      let operator = '';
      resourceGroupNameFilters.forEach((filter: string) => {
        if (filter) {
          rgFilterQuery = `${rgFilterQuery}${operator}resourceGroup contains "${filter}"`;
          operator = ' or ';
        }
      });

      rgQuery += rgFilterQuery;
    }

    return this.resourcegraphClient.resources(
      { query: rgQuery },
      { resultFormat: 'table' },
    );
  }

  getAzureResourceCostAdvice({
    tagKey,
    tagValue,
  }: {
    tagKey: string;
    tagValue: string;
  }): Promise<ResourcesResponse> {
    let query = `ResourceContainers
    | where type =~ "microsoft.resources/subscriptions/resourcegroups"`;

    if (tagKey && tagValue) {
      query += `| where tags["${tagKey}"] =~ "${tagValue}"`;
    }

    query += `| join (AdvisorResources
          | where type == 'microsoft.advisor/recommendations'
          | where properties.category == 'Cost'
          | extend
              resources = tostring(properties.resourceMetadata.resourceId),
              savings = todouble(properties.extendedProperties.savingsAmount),
              solution = tostring(properties.shortDescription.solution),
              currency = tostring(properties.extendedProperties.savingsCurrency),
              impact = tostring(properties.impact)
          )   on subscriptionId
      | summarize
        dcount(resources),
        bin(sum(savings), 0.01)
        by solution, currency, impact
      | project-away dcount_resources`;

    return this.resourcegraphClient.resources(
      { query: query },
      { resultFormat: 'table' },
    );
  }

  getAzureResourceRecommendations({
    tagKey,
    tagValue,
  }: {
    tagKey: string;
    tagValue: string;
  }): Promise<ResourcesResponse> {
    let query = `ResourceContainers
    | where type =~ "microsoft.resources/subscriptions/resourcegroups"`;

    if (tagKey && tagValue) {
      query += `| where tags["${tagKey}"] =~ "${tagValue}"`;
    }

    query += `| join (securityresources
    | where type == 'microsoft.security/assessments'
    | extend statusCode=properties.status.code,
        resourceId=tolower(properties.resourceDetails.Id),
        severity = properties.metadata.severity,
        displayName = properties.metadata.displayName,
        link = properties.links.azurePortal
    | join kind=leftouter(
        resources | extend resourceName = name
                | extend resourceId = tolower(id)
                | extend resourceType = type
                | project resourceName, resourceId, resourceType) on resourceId
      ) on resourceGroup
    | where statusCode =~"Unhealthy"
    | project resourceId, displayName, link, resourceName, resourceType, resourceGroup, severity, subscriptionId, tenantId`;

    return this.resourcegraphClient.resources(
      { query: query },
      { resultFormat: 'table' },
    );
  }

  getAzureResources({
    tagKey,
    tagValue,
  }: {
    tagKey: string;
    tagValue: string;
  }): Promise<ResourcesResponse> {
    let query = `ResourceContainers
      | where type =~ "microsoft.resources/subscriptions/resourcegroups"`;

    if (tagKey && tagValue) {
      query += `| where tags["${tagKey}"] =~ "${tagValue}"`;
    }
    return this.resourcegraphClient.resources(
      { query: query },
      { resultFormat: 'table' },
    );
  }

  async getAzureCostsBySubscription(
    dateRange: DateRange,
    subscription: Subscription | undefined,
  ): Promise<AzureSubscriptionCost> {
    const { token } = await this.credential.getToken(USER_IMPERSONATION_SCOPE);

    const { byService, byResourceGroup } = await getCostsBySubscription(
      subscription?.subscriptionId ?? '',
      token,
      dateRange,
    );

    return {
      subscriptionName: subscription?.displayName ?? '',
      byService,
      byResourceGroup,
    };
  }

  async getAzureCosts(
    dateRange: DateRange,
    subscriptionNameFilters: string[],
  ): Promise<AzureCostsResponse> {
    const { token } = await this.credential.getToken(USER_IMPERSONATION_SCOPE);
    const subscriptions = await this.getAzureSubscriptions(
      subscriptionNameFilters ?? [],
    );

    const costs = subscriptions
      .filter((subscription: Subscription) => !subscription.subscriptionId)
      .map(async (subscription: Subscription) => {
        const subscriptionId = subscription.subscriptionId ?? '';
        const subscriptionName = subscription.displayName ?? '';

        const { byService, byResourceGroup } = await getCostsBySubscription(
          subscriptionId,
          token,
          dateRange,
        );

        return {
          [subscriptionId]: {
            subscriptionName,
            byService,
            byResourceGroup,
          },
        };
      });
    const allCosts = await Promise.all(costs);
    const costsTotal = allCosts.reduce<any>((acc, current) => {
      return { ...acc, ...current };
    }, []);

    return Promise.resolve(costsTotal);
  }

  async getAzureOwnGroups(tenantId: string, clientId: string): Promise<any> {
    const credential = new InteractiveBrowserCredential({
      tenantId,
      clientId,
      loginStyle: 'popup',
    });

    const authProvider = new TokenCredentialAuthenticationProvider(credential, {
      scopes: ['User.Read', 'Group.Read.All'],
    });

    const graphClient = Client.initWithMiddleware({
      authProvider: authProvider,
      fetchOptions: {
        headers: { ConsistencyLevel: 'eventual' },
      },
    });

    const memberOf = await graphClient
      .api('/me/memberOf/$/microsoft.graph.group?$count=true')
      .filter('mailEnabled eq false and securityEnabled eq true')
      .select('id,displayName')
      .get();
    return memberOf;
  }

  getAzureSubscriptions = async (
    nameFilters?: string[],
    roleDefinitionIds?: string[],
  ): Promise<Subscription[]> => {
    const subscriptions: Subscription[] = [];

    // get current user principalId
    const principalId = await this.getUserPrincipalId();

    for await (const sub of this.subscriptionClient.subscriptions.list()) {
      if (!(nameFilters && nameFilters.length > 0)) {
        subscriptions.push(sub);
        continue;
      }

      // apply name filters
      if (
        !nameFilters.some(
          f =>
            sub.displayName
              ?.toLocaleLowerCase()
              .indexOf(f.trim().toLocaleLowerCase()) !== -1,
        )
      ) {
        continue;
      }

      // role assignments check
      if (!(roleDefinitionIds && roleDefinitionIds.length > 0)) {
        subscriptions.push(sub);
        continue;
      }

      const hasRole = await this.hasSubscriptionRoleAssignments(
        sub.subscriptionId!,
        roleDefinitionIds,
        principalId,
      );

      if (!hasRole) {
        continue;
      }

      subscriptions.push(sub);
    }
    return subscriptions;
  };

  private getUserPrincipalId = async () => {
    const accessToken = await this.credential.getToken(
      USER_IMPERSONATION_SCOPE,
    );
    const token = accessToken.token;
    const { oid } = jwt_decode<{ oid: string }>(token);

    return oid;
  };

  hasSubscriptionRoleAssignments = async (
    subscriptionId: string,
    roleDefinitionIds: string[],
    principalId: string,
  ): Promise<boolean> => {
    const client = this.initAuthorizationManagementClient(subscriptionId);

    for await (const roleAssignment of client.roleAssignments.listForScope(
      `subscriptions/${subscriptionId}`,
    )) {
      if (
        roleAssignment.principalId === principalId &&
        roleDefinitionIds.some(
          id => roleAssignment.roleDefinitionId?.indexOf(id) !== -1,
        )
      ) {
        return true;
      }
    }
    return false;
  };
}
