/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from 'react';

import { parseEntityRef } from '@backstage/catalog-model';
import { Avatar, Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';
import { AboutField } from '@backstage/plugin-catalog';
import { entityRouteRef } from '@backstage/plugin-catalog-react';

import {
  Grid,
  Card,
  CardContent,
  Typography,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useAddMemberFullname } from '../../hooks/useAddMemberFullname';
import { useFetchUserName } from '../../hooks/useFetchUserName';
import { Member, Idea, Assessment, Criteria } from '../../types';
import { StatusTag } from '../StatusTag';

type Props = {
  idea: Idea;
  members: Member[];
  selfAssessment: Assessment | null | undefined;
};

export const CardContentFields = ({ idea, members, selfAssessment }: Props) => {
  const useStyles = makeStyles(theme => ({
    description: {
      maxHeight: '400px',
      overflow: 'auto',
    },
    htmlBlock: {
      '& a': {
        color: theme.palette.primary.main,
      },
    },
  }));
  const classes = useStyles();

  const catalogEntityRoute = useRouteRef(entityRouteRef);
  const { value: namedMembers } = useAddMemberFullname(members);
  const { value: responsibleName } = useFetchUserName(idea.responsible);

  const techFeasibilityScore = selfAssessment?.scores.find(
    score => score.criteria === ('Technical Feasibility' as Criteria),
  );
  const strategicFitScore = selfAssessment?.scores.find(
    score => score.criteria === ('Strategic Fit' as Criteria),
  );
  const financialPotentialScore = selfAssessment?.scores.find(
    score => score.criteria === ('Financial Potential' as Criteria),
  );
  const innovativenessScore = selfAssessment?.scores.find(
    score => score.criteria === ('Innovativeness' as Criteria),
  );

  const isSelfAssessmentPopulated = () => {
    const isPopulated = selfAssessment?.scores.some(score => {
      return score.description !== undefined || score.score! !== 0;
    });
    return isPopulated;
  };

  return (
    <div>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xs={9}>
              <Typography variant="body1" paragraph>
                {idea.summary}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <AboutField label="Status">
                <StatusTag status={idea.status} />
              </AboutField>
            </Grid>
            <Grid item xs={9}>
              <AboutField label="Description">
                <Typography
                  className={classes.description}
                  variant="body1"
                  component="div"
                >
                  <div
                    className={classes.htmlBlock}
                    dangerouslySetInnerHTML={{
                      __html: idea.description,
                    }}
                  />
                </Typography>
              </AboutField>
            </Grid>
            <Grid
              style={{
                justifyContent: 'flex-end',
              }}
              item
              xs={3}
            >
              <>
                <AboutField label="Responsible">
                  <Typography variant="body2">
                    {responsibleName || ''}
                  </Typography>
                </AboutField>
                <AboutField label="Members">
                  {namedMembers && namedMembers.length ? (
                    <div style={{ maxHeight: 200, overflow: 'auto' }}>
                      {namedMembers.map((member: Member) => {
                        return (
                          <div
                            style={{
                              textAlign: 'left',
                              backgroundColor: '',
                              marginBottom: '0.3rem',
                              marginTop: '0.3rem',
                              display: 'block',
                            }}
                            key={member.userId}
                          >
                            <Avatar
                              displayName={member.userId}
                              customStyles={{
                                width: '19px',
                                height: '19px',
                                fontSize: '8px',
                                float: 'left',
                                marginRight: '0.3rem',
                                marginTop: '0rem',
                                marginBottom: '0rem',
                                alignItems: 'left',
                                textAlign: 'left',
                              }}
                              picture={member.picture}
                            />
                            <Link
                              target="_blank"
                              to={
                                member.userRef
                                  ? `${catalogEntityRoute(
                                      parseEntityRef(member.userRef),
                                    )}`
                                  : `http://github.com/${member.userId}`
                              }
                            >
                              {member?.fullName}
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div />
                  )}
                </AboutField>
              </>
            </Grid>
            {idea.solution && (
              <Grid item xs={12}>
                <Accordion TransitionProps={{ unmountOnExit: true }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h5">Solution</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      className={classes.htmlBlock}
                      dangerouslySetInnerHTML={{
                        __html: idea.solution,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )}
            {selfAssessment &&
              idea.status !== 'draft' &&
              isSelfAssessmentPopulated() && (
                <Grid item xs={12}>
                  <Accordion TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h5">Self-Assessment</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        display="flex"
                        flexDirection="column"
                        gridRowGap="20px"
                        flexGrow={1}
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          gridRowGap="20px"
                        >
                          <Typography variant="h6">
                            Technical Feasibility
                          </Typography>
                          <Grid container>
                            <Grid item xs={10}>
                              <b>Description: </b>
                              {techFeasibilityScore?.description}
                            </Grid>
                            <Grid item xs={2}>
                              <b>Score: </b> {techFeasibilityScore?.score}
                            </Grid>
                          </Grid>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          gridRowGap="20px"
                        >
                          <Typography variant="h6">Strategic Fit</Typography>
                          <Grid container>
                            <Grid item xs={10}>
                              <b>Description: </b>{' '}
                              {strategicFitScore?.description}
                            </Grid>
                            <Grid item xs={2}>
                              <b>Score: </b> {strategicFitScore?.score}
                            </Grid>
                          </Grid>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          gridRowGap="20px"
                        >
                          <Typography variant="h6">Impact</Typography>
                          <Grid container>
                            <Grid item xs={10}>
                              <b>Description: </b>{' '}
                              {financialPotentialScore?.description}
                            </Grid>
                            <Grid item xs={2}>
                              <b>Score: </b> {financialPotentialScore?.score}
                            </Grid>
                          </Grid>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          gridRowGap="20px"
                        >
                          <Typography variant="h6">Innovativeness</Typography>
                          <Grid container>
                            <Grid item xs={10}>
                              <b>Description: </b>{' '}
                              {innovativenessScore?.description}
                            </Grid>
                            <Grid item xs={2}>
                              <b>Score: </b>
                              {innovativenessScore?.score}
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              )}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};
