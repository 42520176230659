import { useAsync } from 'react-use';

import { useApi } from '@backstage/core-plugin-api';
import { CatalogApi, catalogApiRef } from '@backstage/plugin-catalog-react';

export const useGetUserJobTitle = (email: string | undefined): string => {
  const catalogApi: CatalogApi = useApi(catalogApiRef);

  const { value: userEntity } = useAsync(async () => {
    return await catalogApi.getEntityByRef({
      kind: 'User',
      namespace: 'default',
      name: email?.replace('@', '_') ?? '',
    });
  });

  return (
    userEntity?.metadata.annotations?.['graph.microsoft.com/user-job-title'] ||
    'Manager'
  );
};
