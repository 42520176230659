export const colors = {
  primary: {
    main: '#df1b12',
    mainFilter:
      'invert(22%) sepia(97%) saturate(2371%) hue-rotate(353deg) brightness(88%) contrast(99%)',
    white: '#fff',
    black: '#000',
    gray: '#d9d9d9',
    grayText: '#616161',
  },
  secondary: {
    main: '#004b7f',
    indigo: '#004b7f',
    bolt: '#00aaff',
    shamrock: '#00a65e',
    cadmium: '#fdc530',
    bright: '#ff5d00',
  },
  gray: {
    main: '#d9d9d9',
    disabled: '#dbdbdb',
  },
};
