import { WatchtowerReader } from '../../WatchtowerReader';
import { createConvCommitsFactHandler } from '../../handlers/github';
import { TechInsightsRetrieverIDs } from '../../retrieverIDs';
import { ConventionalCommitsFact, TypedFactRetriever } from '../../types';

export const createRepoConvCommitsFactRetriever = (
  reader: WatchtowerReader,
): TypedFactRetriever<ConventionalCommitsFact> => ({
  id: TechInsightsRetrieverIDs.GITHUB_REPO_USES_CONVENTIONAL_COMMITS_FACT_RETRIEVER,
  version: '0.0.1',
  title: 'Component or Repository entity scoring',
  description: 'Get README info from Github',
  entityFilter: [
    {
      kind: 'Component',
      'spec.type': 'service',
    },
  ],
  schema: {
    usesConventionalCommits: {
      type: 'boolean',
      description: 'verifies if a repository uses conventional commits',
    },
  },
  handler: createConvCommitsFactHandler(reader),
});
