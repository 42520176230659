import { JSON_RULE_ENGINE_CHECK_TYPE } from '@backstage-community/plugin-tech-insights-backend-module-jsonfc';

import { TechInsightsCheckID } from '../../checkIDs';
import { TechInsightsRetrieverIDs } from '../../retrieverIDs';

export const repoHasZeroDependabotPRCheck = {
  id: TechInsightsCheckID.REPO_HAS_ZERO_OPEN_DEPENDABOT_PR_CHECK,
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: 'Has no Dependabot alerts',
  description:
    'The service associated repositories do not currently have any open pull requests from Dependabot.',
  factIds: [
    TechInsightsRetrieverIDs.GITHUB_REPOSITORY_HAS_DEPENDABOT_PRS_FACT_RETRIEVER,
  ],
  rule: {
    conditions: {
      all: [
        {
          fact: 'open_dependabot_prs',
          operator: 'equal',
          value: 0,
        },
      ],
    },
  },
};
