import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const techInsightsPage = createPlugin({
  id: 'tech-insights-page',
  routes: {
    root: rootRouteRef,
  },
});

export const TechInsightsPage = techInsightsPage.provide(
  createRoutableExtension({
    name: 'TechInsightsPage',
    component: () =>
      import('./components/TechInsightsPage').then(m => m.TechInsightsPage),
    mountPoint: rootRouteRef,
  }),
);
