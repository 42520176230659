import { colors } from '@internal/plugin-tomtom-theme-react';
import React from 'react';

import { HomePageCompanyLogo } from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';

import { Grid, Link, Typography, makeStyles } from '@material-ui/core';

import LogoFull from '../Root/LogoFull';
import { useHomePageStyles, useLogoStyles } from './hooks/useHomePageStyles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    maxWidth: '50vw',
    height: '67px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: '8px 15px',
    borderRadius: '50px',
    margin: 'auto',
    '& button.Mui-disabled': {
      color: colors.primary.black,
    },
    '& input': {
      fontSize: theme.spacing(2),
    },
  },
  searchBarOutline: {
    border: 'none !important',
  },
}));

export const SearchAndLogo = () => {
  const homepageStyles = useHomePageStyles();
  const classes = useStyles();
  const { svg } = useLogoStyles();

  return (
    <SearchContextProvider>
      <Grid container justifyContent="center" direction="column">
        <Grid item xs={12}>
          <HomePageCompanyLogo
            className={homepageStyles.companyLogo}
            logo={<LogoFull cssClasses={{ svg }} />}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography align="center">
            Welcome to <strong>FrontStage</strong> – Our TomTom Internal
            Developer Portal!
            <Link
              href="docs/default/component/sp-sde-portal-frontstage"
              color="inherit"
            >
              {' '}
              Read more
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <HomePageSearchBar
            classes={classes}
            placeholder="Search"
            clearButton={false}
          />
        </Grid>
      </Grid>
    </SearchContextProvider>
  );
};
