import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const watchtowerDashboardPlugin = createPlugin({
  id: 'watchtower-dashboard',
  routes: {
    root: rootRouteRef,
  },
});

export const WatchtowerDashboardPage = watchtowerDashboardPlugin.provide(
  createRoutableExtension({
    name: 'WatchtowerDashboardPage',
    component: () =>
      import('./components/Dashboard').then(m => m.WatchtowerDashboard),
    mountPoint: rootRouteRef,
  }),
);
