import { KaaPAccountEntity } from '@internal/plugin-catalog-model';

import { Entity } from '@backstage/catalog-model';
import { LocationSpec } from '@backstage/plugin-catalog-common';
import {
  CatalogProcessor,
  CatalogProcessorEmit,
} from '@backstage/plugin-catalog-node';

import { emitOwnerRelations, emitSystemRelations } from './emitRelations';

const KaapAccountKind = 'KaaPAccount';

const validateEntitySpec = (entity: KaaPAccountEntity): boolean => {
  let isValidSpec = true;

  const {
    spec: { securityGroupId, type, costCenter },
  } = entity;

  if (!securityGroupId) {
    isValidSpec = false;
  }

  if (!type || !['azure', 'aws'].includes(type)) {
    isValidSpec = false;
  }

  if (!costCenter) {
    isValidSpec = false;
  }

  return isValidSpec;
};

export class KaaPAccountProcessor implements CatalogProcessor {
  getProcessorName = (): string => 'KaaPAccountProcessor';

  async validateEntityKind(entity: Entity): Promise<boolean> {
    if (
      entity.kind !== 'KaaPAccount' ||
      entity.apiVersion !== 'tomtom.com/v1alpha1'
    ) {
      return false;
    }

    const kaaPAccountEntity = entity as KaaPAccountEntity;

    if (!kaaPAccountEntity.spec) {
      return false;
    }

    if (!validateEntitySpec(kaaPAccountEntity)) {
      return false;
    }

    return true;
  }

  async postProcessEntity(
    entity: Entity,
    _location: LocationSpec,
    emit: CatalogProcessorEmit,
  ): Promise<Entity> {
    if (
      entity.apiVersion === 'tomtom.com/v1alpha1' &&
      entity.kind === KaapAccountKind
    ) {
      const kaapAccount = entity as KaaPAccountEntity;
      const entitySystem = kaapAccount?.spec?.system ?? '';
      if (entitySystem) {
        emitSystemRelations(emit, entity);
      }

      if (entity.spec?.owner) {
        this.transformOwner(entity);
        emitOwnerRelations(emit, entity);
      }
    }

    return entity;
  }

  transformOwner(entity: Entity) {
    if (entity.spec?.owner) {
      entity.spec.owner = String(entity.spec.owner)
        .toLowerCase()
        .replace('rg_az_cc_', 'rg_az_suporg_')
        .replace('_employees', '');
    }
  }
}
