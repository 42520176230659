import { z } from 'zod';

import { makeFieldSchemaFromZod } from '../utils';

export const DEFAULT_SECRET_KEY = 'GITHUB_USER_TOKEN';
export const DEFAULT_OWNER = 'tomtom-internal';

const GithubLoginFieldSchema = makeFieldSchemaFromZod(
  z.string(),
  z.object({
    requestUserCredentials: z
      .object({
        githubOwner: z
          .string()
          .describe('the github owner. Defaults to DEFAULT_OWNER')
          .default(DEFAULT_OWNER),
        githubRepo: z.string().describe('the github repository'),
        secretsKey: z
          .string()
          .describe(
            'key to access the token later. Defaults to GITHUB_USER_TOKEN',
          )
          .default(DEFAULT_SECRET_KEY),
        additionalScopes: z
          .object({
            github: z
              .array(z.string())
              .describe('Additional GitHub scopes to request'),
          })
          .optional()
          .describe('Additional permission scopes to request'),
      })
      .optional(),
  }),
);

export const GithubLoginSchema = GithubLoginFieldSchema.schema;

export type GithubLoginProps = typeof GithubLoginFieldSchema.type;
