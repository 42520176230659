import { useAsync } from 'react-use';

import {
  IdentityApi,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { CatalogApi, catalogApiRef } from '@backstage/plugin-catalog-react';

import { azureResourcesApiRef } from '../api';

export const AZURE_SUBSCRIPTION_NAME_FILTERS =
  'azure.com/subscription-name-filters';

const OwnerRoleDefinitionId = '8e3af657-a8ff-443c-a75c-2fe8c4bcb635';
const ContributorRoleDefinitionId = 'b24988ac-6180-42a0-ab88-20f7382dd24c';
const BillingReaderRoleDefinitionId = 'fa23ad8b-c56e-40d8-ac0c-ce449e1d2c64';

const loadSubscriptionNameFilters = async (
  identityApi: IdentityApi,
  catalogApi: CatalogApi,
) => {
  const { userEntityRef } = await identityApi.getBackstageIdentity();
  const user = await catalogApi.getEntityByRef(userEntityRef);

  const userGroupRelations = user?.relations?.filter(
    r => r.type === 'memberOf' && r.targetRef.startsWith('group'),
  );

  const promises = (userGroupRelations ?? []).map(r =>
    catalogApi.getEntityByRef(r.targetRef),
  );

  const groupEntities = await Promise.all(promises);

  const filters = groupEntities
    .filter(e => e?.metadata.annotations?.[AZURE_SUBSCRIPTION_NAME_FILTERS])
    .flatMap(e =>
      (e?.metadata.annotations?.[AZURE_SUBSCRIPTION_NAME_FILTERS] ?? '').split(
        ',',
      ),
    );

  return filters;
};

export const useAzureSubscriptions = () => {
  const azureApi = useApi(azureResourcesApiRef);
  const identityApi = useApi(identityApiRef);
  const catalogApi = useApi(catalogApiRef);

  const { value, loading, error } = useAsync(async () => {
    const nameFilters = await loadSubscriptionNameFilters(
      identityApi,
      catalogApi,
    );

    return await azureApi.getAzureSubscriptions(nameFilters, [
      OwnerRoleDefinitionId,
      ContributorRoleDefinitionId,
      BillingReaderRoleDefinitionId,
    ]);
  });

  return {
    subscriptions: value,
    loading,
    error,
  } as const;
};
