import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { SecretsSharing } from './components/SecretsSharingComponent';
import { rootRouteRef } from './routes';

export const secretsSharingFrontendPlugin = createPlugin({
  id: 'secrets-sharing-frontend',
  routes: {
    root: rootRouteRef,
  },
});

export const SecretsSharingFrontendPage = secretsSharingFrontendPlugin.provide(
  createRoutableExtension({
    name: 'SecretsSharingFrontendPage',
    component: async () => SecretsSharing,
    mountPoint: rootRouteRef,
  }),
);
