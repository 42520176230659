import { TopLevelCondition } from 'json-rules-engine';
import { DateTime } from 'luxon';

import {
  FactRetriever,
  TechInsightFact,
} from '@backstage-community/plugin-tech-insights-node';
import { JsonValue } from '@backstage/types';

interface FactSchemaProperty {
  type:
    | 'integer'
    | 'float'
    | 'string'
    | 'boolean'
    | 'datetime'
    | 'set'
    | 'object';
  description: string;
  since?: string;
  metadata?: Record<string, any>;
}

export interface TypedFactRetriever<T> extends FactRetriever {
  schema: Record<keyof T, FactSchemaProperty>;
}

export type TypedTechInsightFact<T> = {
  facts: Record<
    keyof T,
    | number
    | string
    | boolean
    | DateTime
    | number[]
    | string[]
    | boolean[]
    | DateTime[]
    | JsonValue
  >;
} & Omit<TechInsightFact, 'facts'>;

export type GroupRepositoryEntityFact = {
  confirmedRepositoryCount: number;
  proposedRepositoryCount: number;
  ownedRepositoryEntityRefs: string[];
};

export type GithubWorkflowEntityFact = {
  runners?: Record<string, Record<string, string[] | string>>;
  actions?: Record<string, Record<string, string[] | string>>;
  groupRef: string;
};

export type ServiceAnnotationFact = {
  hasSlackChannel: boolean;
  hasSonarQubeProject: boolean;
  hasMonitoringDashboard: boolean;
  hasPagerDutyIntegration: boolean;
  hasOperationalStatusDefined: string | null;
  hasOperationalTierDefined: string | null;
};

export type SonarqubeQualityGateFact = {
  qualityGatePassed: boolean;
};
export interface BranchProtectionFact {
  defaultBranch: string;
  branchAllowsForcePushes: boolean;
  mergeRequiresReviews: boolean;
  mergeRequiresStatusChecks: boolean;
  mergeApprovalReviewCount: number;
  mergeDismissesStaleReviews: boolean;
  mergeNeedsConversationResolution: boolean;
}

export type CISucessPercentFact = {
  successPercent: number;
};

export type CIPRMedianMergeTimeFact = {
  medianPRTimeToMergeHours: number;
};

export type HasReadmeFact = {
  hasReadme: boolean;
};
export type ConventionalCommitsFact = {
  usesConventionalCommits: boolean;
};

export type CIMedianBuildDuration = {
  medianBuildDurationSecs: number;
};
export type DependabotPRsFact = {
  open_dependabot_prs: number;
};

export enum ServiceOperationalTier {
  P1 = 'p1',
  P2 = 'p2',
  P3 = 'p3',
  unknown = 'unknown',
}
export enum ServiceOperationalStatus {
  Experimental = 'experimental',
  Production = 'production',
  Deprecated = 'deprecated',
  Decommissioned = 'decommissioned',
}

export type Check = {
  id: string;
  name: string;
  description: string;
  factIds: string[];
  check?: { type: string };
  rule: { conditions: TopLevelCondition };
};

export type CheckResult = Omit<Check, 'rule' | 'factIds'> & {
  result: boolean;
};
export type SonarqubeStatuschecksFact = {
  passesSecurityReview: boolean;
  hasVulnerabilities: boolean;
  hasSecurityHotspots: boolean;
  totalIssues: number;
  qualityGatePassed: boolean;
  hasSufficientCodeCoverage: boolean;
};
