import { useAsync } from 'react-use';

import { CompoundEntityRef } from '@backstage/catalog-model';
import {
  discoveryApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';

import { ScorecardNode } from '../../types';
import { getHeaders } from '../../utils';

export const useGetScorecardData = (
  entityRef: CompoundEntityRef,
): { scorecard: ScorecardNode; loading: boolean; error: any } => {
  const identityApi = useApi(identityApiRef);
  const discoveryApi = useApi(discoveryApiRef);

  const {
    value: scorecard,
    loading,
    error,
  } = useAsync(async () => {
    const { token } = await identityApi.getCredentials();
    const scorecardUrl = await discoveryApi.getBaseUrl('scorecard');
    const scorecardChecksUrl = `${scorecardUrl}/${entityRef.kind}/${entityRef.namespace}/${entityRef.name}`;
    const scorecardChecksRes = await fetch(scorecardChecksUrl, {
      headers: getHeaders(token),
    });
    const response = await scorecardChecksRes.json();
    return response;
  }, [entityRef.kind, entityRef.namespace, entityRef.name]);

  return { scorecard, loading, error };
};
