/**
 * A relation with KaaPTenant entity. Reversed direction of
 * {@link RELATION_IS_TENANT_OF}.
 *
 * @public
 */
export const RELATION_HAS_TENANT = 'hasTenant';

/**
 * A relation with KaaPAccount entity. Reversed direction of
 * {@link RELATION_HAS_TENANT}.
 *
 * @public
 */
export const RELATION_IS_TENANT_OF = 'tenantOf';
