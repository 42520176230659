import React from 'react';

import { CompoundEntityRef } from '@backstage/catalog-model';
import { EntityRefLinks } from '@backstage/plugin-catalog-react';

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import AccountTreeIcon from '@material-ui/icons/AccountTree';

export const GroupProfileParentsListItem = (props: {
  parents: CompoundEntityRef[];
}) => {
  if (props.parents.length === 0) {
    return <></>;
  }
  return (
    <ListItem data-testid="parent-list">
      <ListItemIcon>
        <Tooltip title="Parent Group">
          <AccountTreeIcon />
        </Tooltip>
      </ListItemIcon>
      <ListItemText
        primary={
          props.parents.length ? (
            <EntityRefLinks entityRefs={props.parents} defaultKind="Group" />
          ) : (
            'N/A'
          )
        }
        secondary="Parent Group"
      />
    </ListItem>
  );
};
