import SearchBar from 'material-ui-search-bar';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useAsyncFn from 'react-use/lib/useAsyncFn';

import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { Content, SupportButton } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

import { Box, Button, IconButton, makeStyles } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Alert } from '@material-ui/lab';

import { tomtomLabApiRef } from '../../api';
import { useEvents } from '../../hooks/useEvents';
import { useParticipant } from '../../hooks/useParticipant';
import { Idea, IdeaExportData } from '../../types';
import { exportIdeasAsCSV } from '../../util/exportIdeasAsCSV';
import { fetchCatalogItems, fetchIdeaMembers } from '../../util/fetchMethods';
import { parseIdea } from '../../util/parseMethods';
import { sortByDate, sortByMembers, sortByTitle } from '../../util/sortMethods';
import { IdeaEventSelector } from '../IdeaEventSelector';
import { IdeaPreview } from '../IdeaPreview/IdeaPreview';
import { IdeaStatusSelector } from '../IdeaStatusSelector';
import { SortMethodSelector } from '../SortMethodSelector';
import { NonParticipantAlert } from './NonParticipantAlert';

const useStyles = makeStyles(() => ({
  button: { minWidth: '11rem' },
  container: {
    marginTop: '2rem',
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'center',
    margin: '0 auto',
    marginBottom: '1.2rem',
  },
  search: {
    height: '2.5rem',
    width: '30rem',
  },
  pullRight: {
    marginLeft: 'auto',
  },
  eventFilterContainer: {
    display: 'flex',
    width: '100%',
  },
  formLabel: { display: 'flex', alignItems: 'center', marginRight: '5px' },
}));

/** @public */
export type SortViewProps = {
  fullWidth?: boolean;
  fullHeight?: boolean;
};

/** @public */
export const SortView = (props: SortViewProps) => {
  const { fullWidth = true, fullHeight = true } = props;
  const tomtomLabApi = useApi(tomtomLabApiRef);
  const catalogApi = useApi(catalogApiRef);
  const classes = useStyles();
  // defaulting to event 3 which is WhatTheHack 2024
  const [selectedEventId, setSelectedEventId] = useState(3);
  const sortMethods = [sortByDate, sortByTitle, sortByMembers];
  const [sortMethodNbr, setSortMethodNbr] = useState(0);
  const [ideaStatusNumber, setIdeaStatusNumber] = useState(0);
  const [searchValue, setSearchValue] = useState('');

  const statuses = [
    'all',
    'draft',
    'proposed',
    'readyForJury',
    'readyForFinals',
    'doneHacking',
  ];

  const [catalogEntities, fetchCatalogEntities] = useAsyncFn(async () => {
    return await fetchCatalogItems(catalogApi);
  });

  const [ideas, fetchIdeas] = useAsyncFn(async (eventId: number) => {
    const limit = undefined;
    const order = undefined;
    const response = await tomtomLabApi.getIdeas(limit, order, eventId);
    const dbProjects: Idea[] = [];

    response.data.forEach((project: any) => {
      dbProjects.push(parseIdea(project));
    });

    return dbProjects;
  });

  const { isUserParticipant } = useParticipant();

  const { events } = useEvents({ order: 'latest' });

  const catalogEntityRefs = catalogEntities.value?.map((project: Entity) =>
    stringifyEntityRef(project),
  );

  const getSearchResults = () => {
    const searchedIdeas = ideas.value
      ?.filter(idea =>
        idea.title
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase()),
      )
      .sort(sortMethods[sortMethodNbr]);
    if (ideaStatusNumber) {
      return searchedIdeas?.filter(
        idea => idea.status === statuses[ideaStatusNumber],
      );
    }
    return searchedIdeas;
  };

  useEffect(() => {
    const filterBrokenLinks = () => {
      if (catalogEntityRefs) {
        ideas.value?.forEach(async (project: Idea) => {
          if (project.entityRef) {
            if (!catalogEntityRefs?.includes(project.entityRef)) {
              await tomtomLabApi.updateIdea({
                ...project,
                entityRef: null,
              });
            }
          }
        });
      }
    };
    filterBrokenLinks();
  }, [tomtomLabApi, ideas.value, catalogEntityRefs, catalogEntities.value]);

  useEffect(() => {
    fetchCatalogEntities();
    fetchIdeas(selectedEventId);
  }, [fetchIdeas, fetchCatalogEntities, selectedEventId]);

  const handleSortMethodChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    setSortMethodNbr(
      typeof event.target.value === 'number' ? event.target.value : 0,
    );
  };

  const handleSelectedStatusChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    setIdeaStatusNumber(
      typeof event.target.value === 'number' ? event.target.value : 0,
    );
  };

  if (catalogEntities.error)
    return <Alert severity="error">{catalogEntities.error.message}</Alert>;

  if (ideas.error) return <Alert severity="error">{ideas.error.message}</Alert>;

  const exportIdeas = async () => {
    const ideasToExport = getSearchResults();
    const eventTitles = await Promise.all(
      (ideasToExport ?? []).map(idea =>
        tomtomLabApi
          .getEventById(idea.eventId ?? 0)
          .then(event => event?.title),
      ),
    );

    const ideasMembers: any[] = await Promise.all(
      (ideasToExport ?? []).map(idea =>
        fetchIdeaMembers(tomtomLabApi, idea).then(member => member),
      ),
    );

    const data: IdeaExportData[] = (ideasToExport ?? []).map((idea, i) => {
      const eventTitle = eventTitles[i] ?? '';
      const members = ideasMembers[i];

      const cleanUpUserRef = (userRef: string) =>
        userRef
          .replace('user:default/', '')
          .replace('_tomtom.com', '@tomtom.com');

      return {
        title: idea.title,
        teaser: idea.summary,
        event: eventTitle,
        summary: idea.summary,
        status: idea.status,
        date: idea.updatedAt ?? '',
        responsible: cleanUpUserRef(idea.responsible),
        members: members?.map((m: any) => cleanUpUserRef(m.userRef))?.join(' '),
      };
    }) as IdeaExportData[];

    exportIdeasAsCSV(data, `ideas_${Date.now().toString()}`);
  };

  return (
    <Content noPadding>
      {!isUserParticipant && <NonParticipantAlert />}
      <Box className={classes.header} width="100%">
        <Box display="flex" width="100%" justifyContent="space-between">
          <Box
            display="flex"
            flexBasis="80%"
            justifyContent="space-between"
            gridGap="0px"
          >
            <Box display="flex">
              <IdeaEventSelector
                events={events ?? []}
                selectedEventId={selectedEventId}
                onChange={(e: any): void => {
                  setSelectedEventId(parseInt(e.target.value as string, 10));
                  fetchIdeas(e.target.value as number);
                }}
              />
            </Box>

            <Box display="flex">
              <IdeaStatusSelector
                statusNumber={ideaStatusNumber}
                handleSelectedStatusChange={handleSelectedStatusChange}
              />
            </Box>

            <Box display="flex">
              {/* <FormLabel className={classes.formLabel}>Sort by</FormLabel> */}
              <SortMethodSelector
                sortMethodNbr={sortMethodNbr}
                handleSortMethodChange={handleSortMethodChange}
              />
            </Box>

            <SearchBar
              className={classes.search}
              value={searchValue}
              onChange={newSortMethod => {
                setSearchValue(newSortMethod);
              }}
              onCancelSearch={() => {
                setSearchValue('');
              }}
            />
          </Box>

          <Box display="flex">
            <Button
              disabled
              className={classes.button}
              variant="contained"
              color="primary"
              component={Link}
              to="/tomtom-lab/register-idea"
            >
              Register idea
            </Button>
            {ideas.value && ideas.value.length > 0 && (
              <IconButton
                color="primary"
                onClick={async () => await exportIdeas()}
              >
                <GetAppIcon />
              </IconButton>
            )}
            <SupportButton />
          </Box>
        </Box>
      </Box>

      <IdeaPreview
        ideas={getSearchResults() || []}
        fetchTomtomLabProjects={() => fetchIdeas(selectedEventId)}
        gridSize={fullWidth ? 2 : 4}
        height={fullHeight ? 'large' : 'small'}
      />
      <Content noPadding className={classes.container} />
    </Content>
  );
};
