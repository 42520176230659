import React, { useEffect } from 'react';
import { useAsyncFn } from 'react-use';

import { CatalogApi, EntityFilterQuery } from '@backstage/catalog-client';
import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import {
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

const getEntities = async (catalog: CatalogApi, filter: EntityFilterQuery) => {
  const result = await catalog.getEntities({
    filter,
  });

  return result.items.filter((e: Entity) =>
    e.metadata.annotations?.['backstage.io/managed-by-location'].includes(
      'github.com',
    ),
  );
};

export const useFactsByEntities = (
  entityFilter: EntityFilterQuery,
  factsIds: string[],
): [
  facts: { loading: boolean; error?: any; value?: any },
  fetchFacts: () => Promise<
    {
      entity: Entity;
      facts: any;
    }[]
  >,
] => {
  const [idsQuery] = React.useState<string>(
    factsIds.map(id => `ids[]=${id}`).join('&'),
  );

  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);
  const catalogApi = useApi(catalogApiRef);

  const [facts, fetchFacts] = useAsyncFn(async () => {
    const entitiesToQuery = await getEntities(catalogApi, entityFilter);
    const baseUrl = await discoveryApi.getBaseUrl('tech-insights');
    const headers: HeadersInit = new Headers();
    headers.set('content-type', 'application/json');

    const promises = entitiesToQuery.map(async entity => {
      const results: { entity: Entity; facts: any }[] = [];

      const url = `${baseUrl}/facts/latest?entity=${encodeURIComponent(
        `${stringifyEntityRef(entity)}`,
      )}&${idsQuery}`;

      const res = await fetchApi.fetch(url, {
        method: 'GET',
        headers,
      });

      if (!res.ok) {
        results.push({ entity, facts: {} });
      } else {
        const data = await res.json();
        results.push({ entity, facts: data });
      }
      return results;
    });

    const results = await Promise.all(promises);
    return results.flat();
  });

  useEffect(() => {
    fetchFacts();
  }, [fetchFacts, idsQuery]);

  return [facts, fetchFacts];
};
