import { JSON_RULE_ENGINE_CHECK_TYPE } from '@backstage-community/plugin-tech-insights-backend-module-jsonfc';

import { TechInsightsCheckID } from '../../checkIDs';
import { TechInsightsRetrieverIDs } from '../../retrieverIDs';

export const repoUsesConvCommitsCheck = {
  id: TechInsightsCheckID.REPO_USES_CONVENTIONAL_COMMITS_CHECK,
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: 'Has conventional commits',
  description:
    'Service repositories enforce a standardized commit message format for better traceability and organization.',
  factIds: [
    TechInsightsRetrieverIDs.GITHUB_REPO_USES_CONVENTIONAL_COMMITS_FACT_RETRIEVER,
  ],
  rule: {
    conditions: {
      all: [
        {
          fact: 'usesConventionalCommits',
          operator: 'equal',
          value: true,
        },
      ],
    },
  },
};
