import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, ButtonProps } from '@material-ui/core';

import { useBuildTemplateFormData } from './hooks';

export const NavigateToTemplateButton = (
  props: {
    template: string;
    text: string;
  } & ButtonProps,
) => {
  const { template, text } = props;
  const navigate = useNavigate();

  const { formData } = useBuildTemplateFormData(template);

  const handleButtonClick = () => {
    navigate(
      `/create/templates/default/${template}?formData=${encodeURIComponent(
        JSON.stringify(formData),
      )}`,
    );
  };

  return Object.keys(formData ?? {}).length > 0 ? (
    <Button onClick={handleButtonClick} {...props}>
      {text}
    </Button>
  ) : null;
};
