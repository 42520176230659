import React from 'react';

import { InfoCardVariants } from '@backstage/core-components';
import {
  EntityOwnershipCard,
  EntityRelationAggregation,
} from '@backstage/plugin-org';

export const EntityOwnershipCardWithActions = (props: {
  variant?: InfoCardVariants;
  entityFilterKind?: string[];
  hideRelationsToggle?: boolean;
  relationsType?: EntityRelationAggregation;
}) => {
  return (
    <>
      <EntityOwnershipCard {...props} />
    </>
  );
};
