import { FormDataBuilder } from '../types';
import {
  batchUpdateEntityOwnersFormDataBuilder,
  updateEntityOwnerFormDataBuilder,
} from './entity';
import {
  githubBatchRepositoryOwnershipFormDataBuilder,
  githubRepositoryOwnershipFormDataBuilder,
  onboardGithubRepoFormBuilder,
  removeGithubRepositoryOwnershipFormDataBuilder,
} from './github';
import { tenantFormBuilder } from './kaap';
import {
  createServiceFormBuilder,
  updateServiceRepositoriesFormBuilder,
  updateServiceApisFormBuilder,
  updateServiceFormBuilder,
  serviceReadMeFormBuilder,
  serviceConventionalCommitFormBuilder,
  serviceTechDocsFormBuilder,
} from './service';
import {
  createSubUnitFormDataBuilder,
  teamConfigFormDataBuilder,
  onboardByManagerFormBuilder,
  updateGroupParentFormDataBuilder,
  extraTeamMembersFormDataBuilder,
} from './team';

// the key needs to be the same as the template name
const templateBuilderMappers: Record<string, FormDataBuilder> = {
  // github and repository
  'github-repository-ownership': githubRepositoryOwnershipFormDataBuilder,
  'batch-github-repository-ownership':
    githubBatchRepositoryOwnershipFormDataBuilder,
  'onboard-github-repo': onboardGithubRepoFormBuilder,
  'remove-github-repository-ownership':
    removeGithubRepositoryOwnershipFormDataBuilder,

  // kaap
  'dx-cra-kaap-update-tenant': tenantFormBuilder,

  // team
  'team-config': teamConfigFormDataBuilder,
  'onboard-team-by-managers': onboardByManagerFormBuilder,
  'create-sub-unit': createSubUnitFormDataBuilder,
  'update-team-parent': updateGroupParentFormDataBuilder,
  'extra-team-members': extraTeamMembersFormDataBuilder,

  // entity
  'update-entity-owner': updateEntityOwnerFormDataBuilder,
  'batch-update-entity-owners': batchUpdateEntityOwnersFormDataBuilder,

  // service
  'create-service': createServiceFormBuilder,
  'update-service-repositories': updateServiceRepositoriesFormBuilder,
  'update-service-apis': updateServiceApisFormBuilder,
  'update-frontstage-service': updateServiceFormBuilder,
  'service-readme': serviceReadMeFormBuilder,
  'service-repository-conventional-commit':
    serviceConventionalCommitFormBuilder, // use the same builder
  'service-repository-techdocs': serviceTechDocsFormBuilder, // use the same builder
};

export const formDataBuilderFactory = (
  templateName: string | undefined,
): FormDataBuilder | undefined => {
  if (!templateName) return undefined;

  if (Object.keys(templateBuilderMappers).indexOf(templateName) !== -1) {
    return templateBuilderMappers[templateName];
  }

  return undefined;
};
