import { GithubRepositoryWorkflowJob } from '@internal/plugin-catalog-model';

export const getRunners = (
  jobs: Record<string, GithubRepositoryWorkflowJob>,
): string[] => {
  const runners: string[] = [];
  for (const [_, job] of Object.entries(jobs)) {
    if (job['runs-on']) {
      runners.push(job['runs-on']);
    }
  }
  return [...new Set(runners)];
};
