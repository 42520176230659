import { useAzureSubscriptions } from '@internal/plugin-azure-resources-common-react';
import React, { useEffect, useState } from 'react';

import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

import { FormControl, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

export type AzureSubscriptionPickerUiOptions = {
  allowedValues?: string[];
};

export const AzureSubscriptionPickerExtension = (
  props: FieldExtensionComponentProps<string, AzureSubscriptionPickerUiOptions>,
) => {
  const {
    onChange,
    schema: { title = '', description = '' },
    required,
    rawErrors,
    formData,
    idSchema,
  } = props;

  const [isRequired, setIsRequired] = useState<boolean>(required ?? false);

  const onSelect = (_: any, value: string | null) => {
    onChange(value ?? '');
  };

  const { subscriptions } = useAzureSubscriptions();

  useEffect(() => {
    if (Array.isArray(formData) && formData?.length > 0) {
      setIsRequired(false);
    } else {
      setIsRequired(required ?? false);
    }
  }, [required, setIsRequired, formData?.length, formData]);

  const allowedSubscriptions = (subscriptions ?? [])
    .filter(subscription => subscription.subscriptionId !== undefined)
    .map(subscription => subscription.subscriptionId as string);

  return (
    <FormControl
      margin="dense"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <Autocomplete
        id={idSchema?.$id}
        value={
          (subscriptions ?? []).find(
            subscription => subscription.subscriptionId === (formData ?? ''),
          )?.displayName ?? undefined
        }
        onChange={onSelect}
        options={allowedSubscriptions}
        getOptionLabel={option =>
          (subscriptions ?? []).find(
            subscription => subscription.subscriptionId === option,
          )?.displayName ?? option
        }
        renderOption={(option: string) =>
          (subscriptions ?? []).find(
            subscription => subscription.subscriptionId === option,
          )?.displayName ?? option
        }
        renderInput={params => (
          <TextField
            {...params}
            label={title}
            helperText={description}
            required={isRequired}
            InputProps={params.InputProps}
          />
        )}
      />
    </FormControl>
  );
};
