import { FactRetriever } from '@backstage-community/plugin-tech-insights-node';

import { teamComponentOrAPIOnboardingHandler } from '../handlers/teamComponentOrAPIOnboardingHandler';

export const teamComponentOrAPIOnboardingFactRetriever: FactRetriever = {
  id: 'teamComponentOrAPIOnboardingFactRetriever',
  version: '0.0.1',
  title: 'Team Component or API entity onboarding',
  description:
    'Generates facts which indicate the quality of team Component or API entities onboarding',
  schema: {
    isComponentOrAPIOnboard: {
      type: 'boolean',
      description: 'At least one Component or API entity is onboard',
    },
  },
  handler: teamComponentOrAPIOnboardingHandler,
};
