import { UserTommeesList } from '@internal/plugin-recognition';
import React from 'react';

import { MicrosoftCalendarCard } from '@backstage-community/plugin-microsoft-calendar';
import {
  RELATION_CHILD_OF,
  RELATION_HAS_MEMBER,
  RELATION_MEMBER_OF,
  RELATION_PARENT_OF,
} from '@backstage/catalog-model';
import { EntityLayout, EntitySwitch } from '@backstage/plugin-catalog';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import {
  EntityOwnershipCard,
  EntityUserProfileCard,
} from '@backstage/plugin-org';

import { Grid } from '@material-ui/core';

import {
  ActionContent,
  EntityWarningContent,
  OwnUserContent,
} from '../Content';

const MSCalendar = (
  <Grid item xs={12} md={6}>
    <EntitySwitch>
      <EntitySwitch.Case>
        <MicrosoftCalendarCard />
      </EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);

export const UserPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        <EntityWarningContent />
        {ActionContent}
        <Grid item xs={12} md={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>

        <Grid item xs={12} md={6}>
          <EntityCatalogGraphCard
            variant="gridItem"
            height={500}
            maxDepth={2}
            relations={[
              RELATION_CHILD_OF,
              RELATION_MEMBER_OF,
              RELATION_HAS_MEMBER,
            ]}
            relationPairs={[
              [RELATION_CHILD_OF, RELATION_PARENT_OF],
              [RELATION_MEMBER_OF, RELATION_HAS_MEMBER],
            ]}
            direction={Direction.LEFT_RIGHT}
            kinds={['Group', 'User']}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <UserTommeesList />
        </Grid>
        <OwnUserContent children={MSCalendar} />
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);
