import { ManagedClustersGetUpgradeProfileResponse } from '@azure/arm-containerservice';
import useAsync from 'react-use/lib/useAsync';

import { useApi } from '@backstage/core-plugin-api';

import { azureResourcesApiRef } from '../api';

export const useAzureManagedClusterLatestVersion = (
  subscriptionId: string,
  resourceGroupName: string,
  resourceName: string,
  usePreview?: boolean,
) => {
  const api = useApi(azureResourcesApiRef);

  const {
    value: upgradeProfile,
    loading,
    error,
  } = useAsync(async (): Promise<ManagedClustersGetUpgradeProfileResponse> => {
    return await api.getManagedClusterUpgradeProfile(
      subscriptionId,
      resourceGroupName,
      resourceName,
    );
  });

  const stableVersion = upgradeProfile?.controlPlaneProfile?.upgrades
    ?.filter(u => !Object.prototype.hasOwnProperty.call(u, 'isPreview'))
    ?.reverse()?.[0];

  const previewVersion = upgradeProfile?.controlPlaneProfile?.upgrades
    ?.filter(u => Object.prototype.hasOwnProperty.call(u, 'isPreview'))
    ?.reverse()?.[0];

  let latestVersion = '';
  if (usePreview && previewVersion) {
    latestVersion = previewVersion.kubernetesVersion ?? '';
  } else {
    latestVersion = stableVersion?.kubernetesVersion ?? '';
  }

  return { latestVersion, loading, error } as const;
};
