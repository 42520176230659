import axios, { AxiosInstance } from 'axios';
import https from 'https';
import { Logger } from 'winston';

export class WatchtowerReader {
  private readonly endpoint: string;
  axiosInstance: AxiosInstance;
  private readonly logger: Logger;

  constructor(endpoint: string, logger: Logger) {
    this.logger = logger;
    this.endpoint = endpoint;
    // This is a workaround to avoid SSL certificate validation
    // as watchtower endpoint is using self-signed certificate
    this.axiosInstance = axios.create({
      httpsAgent: new https.Agent({ rejectUnauthorized: false }),
    });
  }

  async getRepoAttribute<T>(
    repoName: string,
    attributeName: string,
  ): Promise<T | null> {
    const start = process.hrtime();
    const response = await (this.axiosInstance as AxiosInstance).get(
      `${this.endpoint}/attributes/repo/${repoName}/attribute/${attributeName}`,
    );
    const end = process.hrtime(start);
    this.logger.info(
      `Time taken to fetch attribute ${attributeName} for repo ${repoName} is ${
        end[0]
      }s ${end[1] / 1000000}ms`,
    );
    if (response.status === 404) {
      this.logger.warn(`Couldn't find attribute: ${attributeName}`);
      return null;
    }
    if (response.status !== 200) {
      this.logger.error(
        `Unexpected error fetching attribute: ${attributeName}`,
      );
      throw new Error(
        `Failed to fetch attribute ${attributeName} for repo ${repoName}`,
      );
    }
    return response.data ? (response.data as T) : null;
  }

  async getStats<U, T>(statsName: string, payload: U): Promise<T | null> {
    try {
      const start = process.hrtime();
      const response = await (this.axiosInstance as AxiosInstance).post<T>(
        `${this.endpoint}/analytics/${statsName}`,
        payload,
        {
          timeout: 20000,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      const end = process.hrtime(start);
      this.logger.info(
        `Time taken to fetch stats ${statsName} is ${end[0]}s ${
          end[1] / 1000000
        }ms`,
      );
      if (response.status === 404) {
        return null;
      }
      if (response.status !== 200) {
        throw new Error(
          `Failed to fetch stats ${statsName} for repo ${JSON.stringify(
            payload,
          )}`,
        );
      }
      return response.data ? response.data : null;
    } catch (error) {
      return null;
    }
  }
}
