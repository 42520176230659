import type { FieldValidation } from '@rjsf/utils';

export const validateUrlInput = (
  value: string,
  validation: FieldValidation,
) => {
  try {
    // @ts-ignore
    const _ = new URL(value);
  } catch (e) {
    validation.addError(`Only valid URLs allowed`);
  }
};
