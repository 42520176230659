import { EntityGithubInsightsContent } from '@roadiehq/backstage-plugin-github-insights';
import { EntityGithubPullRequestsContent } from '@roadiehq/backstage-plugin-github-pull-requests';
import React from 'react';

import {
  EntityAdrContent,
  isAdrAvailable,
} from '@backstage-community/plugin-adr';
import {
  EntityAzureGitTagsContent,
  EntityAzurePullRequestsContent,
  isAzureDevOpsAvailable,
} from '@backstage-community/plugin-azure-devops';
import { GithubIssuesPage } from '@backstage-community/plugin-github-issues';
import { EntitySonarQubeContentPage } from '@backstage-community/plugin-sonarqube';
import { isSonarQubeAvailable } from '@backstage-community/plugin-sonarqube-react';
import { EntityTodoContent } from '@backstage-community/plugin-todo';
import {
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityLayout,
} from '@backstage/plugin-catalog';

import { Grid } from '@material-ui/core';

import { CICDContent, OverviewContent, TechDocsContent } from '../Content';
import {
  isGitHubAvailable,
  isGithubPullRequestsAvailable,
} from '../entityUtils';

export const InfrastructureEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {OverviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route
      if={isGitHubAvailable}
      path="/code-insights"
      title="Code Insights"
    >
      <EntityGithubInsightsContent />
    </EntityLayout.Route>

    <EntityLayout.Route
      if={isAzureDevOpsAvailable}
      path="/pull-requests"
      title="Pull Requests"
    >
      <EntityAzurePullRequestsContent defaultLimit={250} />
    </EntityLayout.Route>

    <EntityLayout.Route
      if={isGithubPullRequestsAvailable}
      path="/pull-requests"
      title="Pull Requests"
    >
      <EntityGithubPullRequestsContent />
    </EntityLayout.Route>

    <EntityLayout.Route
      if={isAzureDevOpsAvailable}
      path="/git-tags"
      title="Git Tags"
    >
      <EntityAzureGitTagsContent />
    </EntityLayout.Route>

    <EntityLayout.Route
      if={isGitHubAvailable}
      path="github-issues"
      title="GitHub Issues"
    >
      <GithubIssuesPage />
    </EntityLayout.Route>

    <EntityLayout.Route if={isGitHubAvailable} path="/ci-cd" title="CI/CD">
      {CICDContent}
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/sonarqube"
      title="SonarQube"
      if={isSonarQubeAvailable}
    >
      <EntitySonarQubeContentPage />
    </EntityLayout.Route>

    <EntityLayout.Route path="/todo" title="Todo">
      <EntityTodoContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
      <EntityAdrContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {TechDocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);
