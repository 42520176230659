import {
  EntityBugReportMeta,
  EntityConfluenceMeta,
  EntityManagerMeta,
  EntitySlackMeta,
} from '@internal/plugin-catalog-model';

import { Entity, RELATION_OWNER_OF } from '@backstage/catalog-model';

export const getManagerOnboardingDetails = (entity: Entity) => {
  let hasManagers = false;
  let hasManagerTitles = false;
  let hasManagerEmails = false;
  let hasManagerNames = false;
  if (entity.metadata.managers) {
    const managers: EntityManagerMeta[] = entity.metadata
      .managers as any as EntityManagerMeta[];
    hasManagers = managers.length > 0;
    hasManagerTitles =
      managers.filter((m: EntityManagerMeta) => !m.title).length === 0;
    hasManagerEmails =
      managers.filter((m: EntityManagerMeta) => !m.email).length === 0;
    hasManagerNames =
      managers.filter((m: EntityManagerMeta) => !m.name).length === 0;
  }
  return { hasManagers, hasManagerTitles, hasManagerEmails, hasManagerNames };
};

export const isBugReportSet = (entity: Entity) => {
  let hasBugReport = false;
  if (entity.metadata.bugReport) {
    const bugReport: EntityBugReportMeta = entity.metadata
      .bugReport as unknown as EntityBugReportMeta;

    hasBugReport =
      bugReport && bugReport.title !== undefined && bugReport.url !== undefined;
  }
  return Boolean(hasBugReport);
};

export const isConfluenceSet = (entity: Entity) => {
  let hasConfluence = false;
  if (entity.metadata.confluence) {
    const confluence: EntityConfluenceMeta = entity.metadata
      .confluence as unknown as EntityConfluenceMeta;
    hasConfluence =
      confluence &&
      confluence.title !== undefined &&
      confluence.url !== undefined;
  }
  return hasConfluence;
};

export const isMissionSet = (entity: Entity) => {
  let hasMission = false;
  if (entity.metadata.mission) {
    const mission: string = entity.metadata.mission as unknown as string;
    hasMission = mission.length > 0;
  }
  return hasMission;
};

export const isSlackSet = (entity: Entity) => {
  let hasSlackChannel = false;
  if (entity.metadata.slack) {
    const slack: EntitySlackMeta = entity.metadata
      .slack as unknown as EntitySlackMeta;
    hasSlackChannel =
      slack && slack.title !== undefined && slack.url !== undefined;
  }
  return hasSlackChannel;
};

export const isTeamOwnershipSet = (entity: Entity) => {
  let hasComponentOrApi = false;
  const relations =
    entity.relations?.filter(r => {
      const isRelationComponentOrApi =
        r.targetRef.includes('component:') || r.targetRef.includes('api:');
      if (r.type === RELATION_OWNER_OF && isRelationComponentOrApi) {
        return true;
      }
      return false;
    }) ?? [];
  if (relations?.length > 0) {
    hasComponentOrApi = true;
  }
  return hasComponentOrApi;
};

export const isTeamOnboardingFinished = (entity: Entity) => {
  const managersDetails = getManagerOnboardingDetails(entity);
  const isManagersSet =
    managersDetails.hasManagerEmails &&
    managersDetails.hasManagerNames &&
    managersDetails.hasManagerTitles &&
    managersDetails.hasManagers;

  return (
    isManagersSet &&
    isBugReportSet(entity) &&
    isMissionSet(entity) &&
    isSlackSet(entity) &&
    isTeamOwnershipSet(entity)
  );
};
