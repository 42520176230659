import React from 'react';

import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

import { UrlInput } from './UrlInput';

export const UrlInputExtension = (
  props: FieldExtensionComponentProps<string>,
) => {
  return <UrlInput {...props} />;
};
