import { FactRetrieverContext } from '@backstage-community/plugin-tech-insights-node';
import { Entity } from '@backstage/catalog-model';

import { WatchtowerReader } from '../../WatchtowerReader';
import { HasReadmeFact, TypedTechInsightFact } from '../../types';
import { getEntities } from '../utils/getEntities';
import { getRepoNameFromProjectSlug } from './utils';

export const createRepoHasReadmeFactHandler = (
  watchtowerReader: WatchtowerReader,
) => {
  return async ({
    logger,
    entityFilter,
    tokenManager,
    discovery,
  }: FactRetrieverContext): Promise<TypedTechInsightFact<HasReadmeFact>[]> => {
    const entities = await getEntities({
      logger,
      entityFilter,
      tokenManager,
      discovery,
    });
    const entityFactsPromise = entities.items.map(async (entity: Entity) => {
      const repoName = getRepoNameFromProjectSlug(entity);
      const falsyFact = {
        entity: {
          namespace: entity.metadata.namespace!,
          kind: entity.kind,
          name: entity.metadata.name,
        },
        facts: { hasReadme: false },
      };
      if (!repoName) {
        logger.warn(
          `readmefacthandler Could not find a slug for entity ${entity.metadata.name}`,
        );
        return falsyFact;
      }

      try {
        const readmeResponse = await watchtowerReader.getRepoAttribute<{
          has_readme: true;
        }>(repoName, 'readme');
        if (readmeResponse) {
          return {
            entity: {
              namespace: entity.metadata.namespace!,
              kind: entity.kind,
              name: entity.metadata.name,
            },
            facts: { hasReadme: readmeResponse.has_readme },
          };
        }
        logger.warn(`No readme fact found for ${repoName}`);
        return falsyFact;
      } catch (error) {
        logger.error(
          `readmefacthandler Error while fetching has read me fact for ${repoName}`,
          error as any,
        );
        return falsyFact;
      }
    });
    const facts = await Promise.all(entityFactsPromise);
    return facts.filter(
      fact => !!fact,
    ) as TypedTechInsightFact<HasReadmeFact>[];
  };
};
