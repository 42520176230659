import { useAsync } from 'react-use';

import { CatalogApi, EntityFilterQuery } from '@backstage/catalog-client';
import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import {
  DiscoveryApi,
  FetchApi,
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

export const useGetFactsByFactIDs = <T>(
  entityFilter: EntityFilterQuery,
  factsIds: string[],
) => {
  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);
  const catalogApi = useApi(catalogApiRef);

  const {
    value: facts,
    loading,
    error,
  } = useAsync(async () => {
    return await fetchFacts<T>(
      catalogApi,
      discoveryApi,
      fetchApi,
      factsIds,
      entityFilter,
    );
  });

  return { facts, loading, error } as const;
};
export async function fetchFacts<T>(
  catalogApi: CatalogApi,
  discoveryApi: DiscoveryApi,
  fetchApi: FetchApi,
  factsIds: string[],
  entityFilter: EntityFilterQuery,
  entities?: Entity[],
) {
  const results: { entity: Entity; facts: T }[] = [];

  const entitiesForFacts =
    entities || (await catalogApi.getEntities({ filter: entityFilter })).items;

  const baseUrl = await discoveryApi.getBaseUrl('tech-insights');
  const headers: HeadersInit = new Headers();
  headers.set('content-type', 'application/json');
  const idsQuery = factsIds.map(id => `ids[]=${id}`).join('&');
  const promises = entitiesForFacts.map(async entity => {
    const url = `${baseUrl}/facts/latest?entity=${encodeURIComponent(
      `${stringifyEntityRef(entity)}`,
    )}&${idsQuery}`;

    const res = await fetchApi.fetch(url, {
      method: 'GET',
      headers,
    });

    if (!res.ok) {
      results.push({ entity, facts: {} as T });
    } else {
      results.push({ entity, facts: (await res.json()) as T });
    }
    return results;
  });

  return (await Promise.all(promises)).flat();
}
