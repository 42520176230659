import { SystemEntity } from '@internal/plugin-catalog-model';

import { Entity } from '@backstage/catalog-model';
import { IdentityApi } from '@backstage/core-plugin-api';
import { CatalogApi } from '@backstage/plugin-catalog-react';

import { arrayToObjectReducer } from '../utils';

export const updateServiceRepositoriesFormBuilder = async (
  entity: Entity,
  formFields: string[],
  _identityApi?: IdentityApi,
  _catalogApi?: CatalogApi,
) => {
  const formData = formFields
    .map((field: string) => {
      let data: { [key in string]: string | string[] | undefined };

      switch (field) {
        case 'name':
          data = { [field]: `system:${entity.metadata.name}` };
          break;
        case 'hasRepositories':
          data = {
            [field]: (entity as SystemEntity).spec?.hasRepositories ?? [],
          };
          break;
        default:
          data = {};
          break;
      }

      return data;
    })
    .reduce(arrayToObjectReducer, {});

  return formData;
};
