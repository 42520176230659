import React from 'react';

import {
  Entity,
  RELATION_OWNED_BY,
  RELATION_PART_OF,
} from '@backstage/catalog-model';
import { MarkdownContent } from '@backstage/core-components';
import {
  EntityRefLinks,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';

import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { AboutField } from './AboutField';

const useStyles = makeStyles({
  description: {
    wordBreak: 'break-word',
  },
});

export interface AboutContentProps {
  entity: Entity;
}

const getCountAssociatedRepositories = (entity: Entity): number => {
  const associatedRepos =
    (entity?.spec?.hasRepositories as string[])?.length || 0;
  if (entity.metadata.annotations?.['github.com/project-slug']) {
    return associatedRepos + 1;
  }
  return associatedRepos;
};

export function AboutContent(props: AboutContentProps) {
  const { entity } = props;
  const classes = useStyles();

  const isSystem = entity.kind.toLocaleLowerCase('en-US') === 'system';
  const isResource = entity.kind.toLocaleLowerCase('en-US') === 'resource';
  const isComponent = entity.kind.toLocaleLowerCase('en-US') === 'component';
  const isAPI = entity.kind.toLocaleLowerCase('en-US') === 'api';
  const operationalTier = entity?.spec?.operationalTier;

  const partOfSystemRelations = getEntityRelations(entity, RELATION_PART_OF, {
    kind: 'system',
  });
  const partOfComponentRelations = getEntityRelations(
    entity,
    RELATION_PART_OF,
    {
      kind: 'component',
    },
  );
  const partOfDomainRelations = getEntityRelations(entity, RELATION_PART_OF, {
    kind: 'domain',
  });
  const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);
  return (
    <Grid container>
      <AboutField label="description" gridSizes={{ xs: 12 }}>
        <MarkdownContent
          className={classes.description}
          content={
            entity?.metadata?.description ||
            'No description provided for this entity'
          }
        />
      </AboutField>
      <AboutField
        label="owner"
        value="label"
        gridSizes={{ xs: 12, sm: 6, lg: 4 }}
      >
        {ownedByRelations.length > 0 && (
          <EntityRefLinks entityRefs={ownedByRelations} defaultKind="group" />
        )}
      </AboutField>
      {(isSystem || partOfDomainRelations.length > 0) && (
        <AboutField
          label="Domain"
          value="Domain name"
          gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        >
          {partOfDomainRelations.length > 0 && (
            <EntityRefLinks
              entityRefs={partOfDomainRelations}
              defaultKind="domain"
            />
          )}
        </AboutField>
      )}
      {(isAPI ||
        isComponent ||
        isResource ||
        partOfSystemRelations.length > 0) && (
        <AboutField
          label="System"
          value="System Name"
          gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        >
          {partOfSystemRelations.length > 0 && (
            <EntityRefLinks
              entityRefs={partOfSystemRelations}
              defaultKind="system"
            />
          )}
        </AboutField>
      )}
      {isComponent && partOfComponentRelations.length > 0 && (
        <AboutField
          label="Parent component"
          value="Parent component"
          gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        >
          <EntityRefLinks
            entityRefs={partOfComponentRelations}
            defaultKind="component"
          />
        </AboutField>
      )}
      <AboutField
        label="Lifecycle"
        value={entity?.spec?.lifecycle as string}
        gridSizes={{ xs: 12, sm: 6, lg: 4 }}
      />

      <AboutField
        label="tags"
        value="tags"
        gridSizes={{ xs: 12, sm: 6, lg: 4 }}
      >
        {(entity?.metadata?.tags || []).map(tag => (
          <Chip key={tag} size="small" label={tag} />
        ))}
      </AboutField>

      <AboutField
        label="Criticality"
        value={operationalTier?.toString() || 'unknown'}
        errorHighlight={operationalTier === undefined}
        gridSizes={{ xs: 12, sm: 6, lg: 4 }}
      />

      <AboutField
        label="Associated Repositories"
        value={`${getCountAssociatedRepositories(entity)}`}
      />
    </Grid>
  );
}
