import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { IdentityApi } from '@backstage/core-plugin-api';
import { CatalogApi } from '@backstage/plugin-catalog-react';

import { arrayToObjectReducer } from '../utils';

export const createSubUnitFormDataBuilder = async (
  entity: Entity,
  formFields: string[],
  identityApi?: IdentityApi,
  _catalogApi?: CatalogApi,
) => {
  const currentUser = await identityApi?.getBackstageIdentity();

  const formData = formFields
    .map((field: string) => {
      switch (field) {
        case 'parent':
          return { [field]: stringifyEntityRef(entity) };
        case 'managers':
          if (currentUser?.userEntityRef) {
            return { [field]: [currentUser?.userEntityRef] };
          }
          return { [field]: [] };
        default:
          return {};
      }
    })
    .reduce(arrayToObjectReducer, {});

  return formData;
};
