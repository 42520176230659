import { isTeamOnboardingFinished } from '@internal/plugin-techinsights-module-entities';
import * as GitHubPullRequests from '@roadiehq/backstage-plugin-github-pull-requests';

import * as GitHubActions from '@backstage-community/plugin-github-actions';
import { Entity, RELATION_HAS_PART } from '@backstage/catalog-model';
import { isComponentType, isKind } from '@backstage/plugin-catalog';

const GITHUB_REPO_ANNOTATION = 'github.com/project-slug';
const TOMTOM_COM_LANDING_PAGE_ENABLED = 'tomtom.com/landing-page-enabled';

export const isGroupType = (type: string) => (entity: Entity) =>
  isKind('Group')(entity) && entity.spec?.type === type;

export const isLinksAvailable = (entity: Entity) =>
  Boolean(entity.metadata.links);

export const isLinksNotEmpty = (entity: Entity) =>
  Boolean(
    isLinksAvailable(entity) &&
      entity.metadata.links?.length &&
      entity.metadata.links?.length > 0,
  );

export const isGitHubAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[GITHUB_REPO_ANNOTATION]);

export const isGithubPullRequestsAvailable = (entity: Entity) =>
  Boolean(
    entity.metadata.annotations?.[GITHUB_REPO_ANNOTATION] &&
      GitHubPullRequests.isGithubPullRequestsAvailable(entity),
  );

export const isGithubActionsAvailable = (entity: Entity) =>
  Boolean(
    entity.metadata.annotations?.[GITHUB_REPO_ANNOTATION] &&
      GitHubActions.isGithubActionsAvailable(entity),
  );

export const isLandingPageEnabled = (entity: Entity) => {
  const isGroup = entity.kind.toLowerCase() === 'group';
  let isAnnotationEnabled = false;
  if (entity.metadata.annotations) {
    const value = entity.metadata.annotations[TOMTOM_COM_LANDING_PAGE_ENABLED];
    if (value) {
      isAnnotationEnabled = value.toLowerCase() === 'true';
    }
  }
  return Boolean(isGroup && isAnnotationEnabled);
};

export const isSubcomponentsAvailable = (entity: Entity) => {
  const hasPartRelations = entity.relations?.some(
    r => r.type === RELATION_HAS_PART,
  );
  return Boolean(hasPartRelations);
};

export const isNotInfraComponent = (entity: Entity) => {
  return !isComponentType('infrastructure')(entity);
};

export const isOnboardingInProgress = (entity: Entity) => {
  if (!isKind('component')(entity)) {
    return false;
  }

  const isOwnerSet = Boolean(entity.spec?.owner);
  const isTitleSet = Boolean(entity.metadata.title);
  const isTechdocsSet = Boolean(
    entity.metadata.annotations?.['backstage.io/techdocs-ref'],
  );

  return !isOwnerSet || !isTitleSet || !isTechdocsSet;
};

export const isTeamOnboardingInProgress = (entity: Entity) => {
  return !isTeamOnboardingFinished(entity);
};
