import { CatalogNamePickerState, CatalogNamePickerValidation } from '../types';

export const serializeData = (
  data: CatalogNamePickerState,
): string | undefined => {
  if (data.value && data.value !== '') {
    const isNameTaken = data.validation?.isNameTaken ?? false;
    const value = data.value;

    return `value=${value}:nameTaken=${isNameTaken}`;
  }
  return undefined;
};

/**
 *
 * @param formData
 * @returns CatalogNamePickerState. If formData does not have the structure
 * 'value=<some-value>:nameTake=<true|false>', the return value will be the default state:
 * {
 *   value: undefined,
 *   validation: {
 *     isNameTaken: false
 *   }
 * }
 */
export const parseFormData = (
  formData: string | undefined,
): CatalogNamePickerState => {
  const validation: CatalogNamePickerValidation = {
    isNameTaken: false,
  };

  let value: string | undefined;

  const defaultState = {
    value: undefined,
    validation: {
      isNameTaken: false,
    },
  };

  if (!formData?.startsWith('value=')) {
    return defaultState;
  }

  const valueFieldIndex = 0;
  const validationFieldIndex = 1;
  if (formData && formData.indexOf(':') !== -1) {
    const formDataFields = formData.split(':');
    value = formDataFields[valueFieldIndex].split('=')?.[1];
    if (!value || value.length === 0) {
      return defaultState;
    }
    validation.isNameTaken =
      formDataFields[validationFieldIndex].split('=')?.[1] === 'true';
  }

  return {
    validation,
    value,
  };
};

export const getInitialInputValue = (formData: string | undefined): string =>
  formData
    ? formData.replace(
        /(value\=)([a-z\-0-9_A-Z]+)(\:nameTaken\=(true|false))/gm,
        '$2',
      )
    : '';
