import { TechInsightsCheckID } from '@internal/plugin-techinsights-module-entities';
import React from 'react';

import { EntityTechInsightsScorecardCard } from '@backstage-community/plugin-tech-insights';
import {
  Entity,
  GroupEntity,
  RELATION_PARENT_OF,
} from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';

export const isTeam = (entity: Entity): boolean =>
  Boolean(
    !(entity as GroupEntity).relations?.find(
      item => item.type === RELATION_PARENT_OF,
    ),
  );

export const TeamOnboardingStatusCard = () => {
  const { entity } = useEntity();
  let checksId = [
    TechInsightsCheckID.MANAGER_CHECK,
    TechInsightsCheckID.SLACK_CHANNEL_CHECK,
    TechInsightsCheckID.BUG_REPORT_CHECK,
  ];

  if (isTeam(entity)) {
    checksId = [...checksId, TechInsightsCheckID.COMPONENT_OR_API_CHECK];
  }

  return (
    <EntityTechInsightsScorecardCard
      title="Onboarding Status"
      checksId={checksId}
    />
  );
};
