import {
  FactRetrieverContext,
  TechInsightFact,
} from '@backstage-community/plugin-tech-insights-node';
import { UrlReader } from '@backstage/backend-common';
import { Entity, stringifyEntityRef } from '@backstage/catalog-model';

import { getEntities } from '../utils/getEntities';
import {
  getRepoSourceLocation,
  getWorkflowMetadata,
  mapToJobsWithActions,
  readWorkflowsDirectory,
} from './utils';

const createFactWithNoJobs = (entity: Entity): TechInsightFact => ({
  entity: {
    namespace: entity.metadata.namespace || 'default',
    kind: entity.kind,
    name: entity.metadata.name,
  },
  facts: {
    isGitHubActionsUsed: false,
    jobs: [],
  },
});

type JobWithFacts = {
  workflowFile: string;
  jobId: string;
  actions: string[];
};

const createFactWithJobs = (
  entity: Entity,
  jobsWithActions: Array<JobWithFacts>,
): TechInsightFact => ({
  entity: {
    namespace: entity.metadata.namespace!,
    kind: entity.kind,
    name: entity.metadata.name,
  },
  facts: {
    isGitHubActionsUsed: true,
    jobs: jobsWithActions,
  },
});

export const createGitHubActionsHandler =
  (reader: UrlReader) =>
  async (ctx: FactRetrieverContext): Promise<TechInsightFact[]> => {
    const entitiesResponse = await getEntities({
      logger: ctx.logger,
      tokenManager: ctx.tokenManager,
      discovery: ctx.discovery,
      entityFilter: ctx.entityFilter,
    });

    const entities = entitiesResponse.items;

    const facts: TechInsightFact[] = await Promise.all(
      entities.map(async entity => {
        const sourceLocation = getRepoSourceLocation(entity);

        if (!sourceLocation) {
          ctx.logger.error(
            `No "backstage.io/source-location" annotation on entity ${stringifyEntityRef(
              entity,
            )}`,
          );

          return createFactWithNoJobs(entity);
        }

        try {
          const isGitHub =
            sourceLocation && sourceLocation.includes('github.com');
          if (isGitHub) {
            const workflows = await readWorkflowsDirectory(
              sourceLocation,
              reader,
            );

            if (workflows) {
              const metadata = await getWorkflowMetadata(workflows, ctx);
              const jobs = (await Promise.all(metadata)).flatMap(j => j);

              const jobsWithActions: Array<JobWithFacts> =
                mapToJobsWithActions(jobs);

              return createFactWithJobs(entity, jobsWithActions);
            }
          }
        } catch (error: any) {
          ctx.logger.error(`ERROR ${JSON.stringify(error.message)}`);
        }

        return createFactWithNoJobs(entity);
      }),
    );
    return facts;
  };
