import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import React from 'react';

import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import {
  ReportIssue,
  ExpandableNavigation,
  TextSize,
  LightBox,
} from '@backstage/plugin-techdocs-module-addons-contrib';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';

export const TechDocsContent = (
  <EntityTechdocsContent>
    <TechDocsAddons>
      <ReportIssue />
      <ExpandableNavigation />
      <TextSize />
      <LightBox />
      <Mermaid
        config={{ theme: 'forest', themeVariables: { lineColor: '#000000' } }}
      />
    </TechDocsAddons>
  </EntityTechdocsContent>
);
