import { z } from 'zod';

import { makeFieldSchemaFromZod } from '../utils';

export const DEFAULT_SECRET_KEY = 'AZURE_USER_TOKEN';

const AzureLoginFieldSchema = makeFieldSchemaFromZod(
  z.string(),
  z.object({
    requestUserCredentials: z
      .object({
        secretsKey: z
          .string()
          .describe(
            'key to access the token later. Defaults to AZURE_USER_TOKEN',
          )
          .default(DEFAULT_SECRET_KEY),
        additionalScopes: z
          .string()
          .array()
          .default([])
          .describe(
            'Scopes to be passed when requesting the token. Defaults to an empty array',
          ),
      })
      .optional(),
  }),
);

export const AzureLoginSchema = AzureLoginFieldSchema.schema;

export type AzureLoginProps = typeof AzureLoginFieldSchema.type;
