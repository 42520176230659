import { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';

import { identityApiRef, useApi } from '@backstage/core-plugin-api';

import { tomtomLabApiRef } from '../api';

export const useParticipant = () => {
  const tomtomLabApi = useApi(tomtomLabApiRef);
  const identityApi = useApi(identityApiRef);
  const [isUserParticipant, setIsUserParticipant] = useState<
    boolean | undefined
  >(undefined);

  const [result, fetchParticipant] = useAsyncFn(async () => {
    const userRef = (await identityApi.getBackstageIdentity()).userEntityRef;
    return tomtomLabApi.getActiveEventParticipant(encodeURIComponent(userRef));
  }, []);

  useEffect(() => {
    fetchParticipant();
  }, [fetchParticipant]);

  useEffect(() => {
    if (result.value !== null) {
      setIsUserParticipant(true);
    } else {
      setIsUserParticipant(false);
    }
  }, [result]);

  return {
    participant: result.value,
    loading: result.loading,
    isUserParticipant,
    error: result.error,
  };
};
