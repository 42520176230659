import React, { ChangeEvent, useState } from 'react';

import { Entity, GroupEntity } from '@backstage/catalog-model';
import { Progress } from '@backstage/core-components';
import { humanizeEntityRef } from '@backstage/plugin-catalog-react';

import { Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useCheckResults } from '../../../hooks/useCheckResults';
import { OnBoardingTabOverview } from './onBoardingTabOverview';
import { useEntitiesToQuery } from './useEntitiesToQuery';
import { useProductUnits } from './useProductUnits';

export const TeamOnboardingStatus = () => {
  const { productUnits, loading } = useProductUnits();

  const [selectedProductUnits, setSelectedProductUnits] = useState<Entity[]>(
    [],
  );
  const [entitiesToQuery, setEntitiesToQuery] = useState<Entity[]>([]);

  const { results, loading: loadingResults } = useCheckResults(entitiesToQuery);

  useEntitiesToQuery(selectedProductUnits, setEntitiesToQuery);

  const onChange = (_: ChangeEvent<{}>, newGroupSelection: Entity[]) => {
    setSelectedProductUnits(newGroupSelection);
  };

  return loading || loadingResults ? (
    <Progress />
  ) : (
    <Grid item container data-testid="team-onboarding-status">
      <Grid item xs={3} style={{ marginBottom: '16px' }}>
        <Autocomplete
          data-testid="pu-selector"
          multiple
          loading={loading}
          onChange={onChange}
          value={selectedProductUnits}
          options={productUnits}
          getOptionLabel={option =>
            (option as GroupEntity).spec?.profile?.displayName ??
            humanizeEntityRef(option)
          }
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label="Product Unit"
              placeholder="Select Product Unit"
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <OnBoardingTabOverview results={results} />
      </Grid>
    </Grid>
  );
};
