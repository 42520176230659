import { QetaPage } from '@drodil/backstage-plugin-qeta';
import { GithubAppCreationPage } from '@internal/backstage-plugin-github-app-creation';
import { KaapPage } from '@internal/backstage-plugin-kaap';
import { ApiAuthPage } from '@internal/plugin-api-auth';
import {
  apiDocsPlugin,
  ApiExplorerPage,
} from '@internal/plugin-api-governance';
import { ServiceCatalogExplorerPage } from '@internal/plugin-catalog-explorer';
import { GithubTeamActionsPage } from '@internal/plugin-github-team-actions';
import { GithubTeamDeploymentsPage } from '@internal/plugin-github-team-deployments';
import { GlossaryPage } from '@internal/plugin-glossary';
import { KaapCatalogPage } from '@internal/plugin-kaap-catalog';
import { RecognitionPage } from '@internal/plugin-recognition';
import {
  GenericValuePickerFieldExtension,
  GenericValueInputPickerFieldExtension,
  RepoNamePickerExtension,
  GithubTeamPickerFieldExtension,
  AzSecurityGroupPickerFieldExtension,
  AzureSubscriptionPickerFieldExtension,
  CatalogUserPickerFieldExtension,
  CatalogNamePickerFieldExtension,
  AzureLoginFieldExtension,
  UrlInputExtension,
  GithubLoginFieldExtension,
  MyCostCentersPickerFieldExtension,
} from '@internal/plugin-scaffolder-field-react';
import { SecretsSharingFrontendPage } from '@internal/plugin-secrets-sharing-frontend';
import { TechInsightsPage } from '@internal/plugin-tech-insights-page';
import { TomtomLabPage } from '@internal/plugin-tomtom-lab';
import { tomtomTheme } from '@internal/plugin-tomtom-theme-react';
import { AnnouncementsPage } from '@k-phoen/backstage-plugin-announcements';
import { SelectFieldFromApiExtension } from '@roadiehq/plugin-scaffolder-frontend-module-http-request-field';
import { TimeSaverPage } from '@tduniec/backstage-plugin-time-saver';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import React, { PropsWithChildren } from 'react';
import { Route, useLocation } from 'react-router';

import { BazaarPage } from '@backstage-community/plugin-bazaar';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { FeatureFlagged } from '@backstage/core-app-api';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import {
  microsoftAuthApiRef,
  IdentityApi,
  discoveryApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { CatalogUnprocessedEntitiesPage } from '@backstage/plugin-catalog-unprocessed-entities';
import { DevToolsPage } from '@backstage/plugin-devtools';
import { orgPlugin } from '@backstage/plugin-org';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import { SearchPage } from '@backstage/plugin-search';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import {
  ReportIssue,
  ExpandableNavigation,
  TextSize,
  LightBox,
} from '@backstage/plugin-techdocs-module-addons-contrib';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import {
  UserSettingsPage,
  SettingsLayout,
} from '@backstage/plugin-user-settings';
import { themes, UnifiedThemeProvider } from '@backstage/theme';

import { CssBaseline } from '@material-ui/core';

import { ArgoCDLogo } from './ArgoCDLogo';
import { GrafanaLogo } from './GrafanaLogo';
import { OtelLogo } from './OtelLogo';
import { apis } from './apis';
import { Root } from './components/Root';
import { entityPage } from './components/catalog/EntityPage';
import { customDevToolsPage } from './components/devtools/CustomDevToolsPage';
import { HomePage } from './components/home/HomePage';
import { OrgGraphPage } from './components/org-graph/OrgGraphPage';
import { searchPage } from './components/search/SearchPage';
import { setTokenCookie } from './cookieAuth';
import * as plugins from './plugins';

const app = createApp({
  apis,
  plugins: Object.values(plugins),
  icons: {
    grafana: GrafanaLogo,
    argocd: ArgoCDLogo,
    otel: OtelLogo,
  },
  featureFlags: [
    {
      pluginId: '',
      name: 'show-api-keys-feature',
      description: 'Enables the API Keys tab under settings',
    },
    {
      pluginId: '',
      name: 'show-time-saver-tab',
      description: 'Enables the Time Saver tab',
    },
    {
      pluginId: '',
      name: 'show-tech-insights-sidebar',
      description: 'Enables the Tech Insights sidebar',
    },
  ],
  components: {
    SignInPage: props => {
      const discoveryApi = useApi(discoveryApiRef);
      return (
        <SignInPage
          {...props}
          auto
          provider={{
            id: 'microsoft-auth-provider',
            title: 'Azure AD (TomTom Employees)',
            message: 'Sign in using Azure AD.',
            apiRef: microsoftAuthApiRef,
          }}
          onSignInSuccess={async (identityApi: IdentityApi) => {
            setTokenCookie(
              await discoveryApi.getBaseUrl('cookie'),
              identityApi,
            );

            props.onSignInSuccess(identityApi);
          }}
        />
      );
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  themes: [
    {
      id: 'tomtom-theme',
      title: 'TomTom Theme',
      variant: 'light',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={tomtomTheme}>
          <CssBaseline>{children}</CssBaseline>
        </UnifiedThemeProvider>
      ),
    },
    {
      id: 'community-dark',
      title: 'Community Dark',
      // @ts-ignore
      variant: 'community-dark',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={themes.dark} children={children} />
      ),
    },
  ],
});

const scaffolderPage = (
  <ScaffolderPage
    groups={[
      {
        title: 'Team',
        filter: entity => entity?.metadata?.tags?.includes('team') ?? false,
      },
      {
        title: 'Ownership',
        filter: entity =>
          entity?.metadata?.tags?.includes('ownership') ?? false,
      },
      {
        title: 'Catalog',
        filter: entity => entity?.metadata?.tags?.includes('catalog') ?? false,
      },
      {
        title: 'Code',
        filter: entity =>
          entity?.metadata?.tags?.includes('scaffolding') ?? false,
      },
      {
        title: 'Infra',
        filter: entity =>
          (entity?.metadata?.tags?.includes('infra') &&
            !entity?.metadata?.tags?.includes('no-manual-use')) ??
          false,
      },
      {
        title: 'No manual use',
        filter: entity =>
          entity?.metadata?.tags?.includes('no-manual-use') ?? false,
      },
      {
        title: 'CI',
        filter: entity => entity?.metadata?.tags?.includes('ci') ?? false,
      },
      {
        title: 'CD',
        filter: entity => entity?.metadata?.tags?.includes('cd') ?? false,
      },
      {
        title: 'Incidents',
        filter: entity =>
          entity?.metadata?.tags?.includes('incidents') ?? false,
      },
    ]}
  />
);

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomePage />} />
    <Route path="/catalog-list" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
        <ExpandableNavigation />
        <TextSize />
        <LightBox />
        <Mermaid
          config={{ theme: 'forest', themeVariables: { lineColor: '#000000' } }}
        />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={scaffolderPage}>
      <ScaffolderFieldExtensions>
        <GenericValuePickerFieldExtension />
        <GenericValueInputPickerFieldExtension />
        <RepoNamePickerExtension />
        <GithubTeamPickerFieldExtension />
        <CatalogUserPickerFieldExtension />
        <CatalogNamePickerFieldExtension />
        <AzureLoginFieldExtension />
        <GithubLoginFieldExtension />
        <UrlInputExtension />
        <AzSecurityGroupPickerFieldExtension />
        <AzureSubscriptionPickerFieldExtension />
        <SelectFieldFromApiExtension />
        <MyCostCentersPickerFieldExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route path="/kaap" element={<KaapCatalogPage />} />
    <Route path="/kaap2_0" element={<KaapPage />} /> {/* New */}
    <Route
      path="/services"
      element={
        <ServiceCatalogExplorerPage
          title="Service"
          initialKindFilter="Component"
          initialTypeFilter="service"
        />
      }
    />
    <Route
      path="/tech-radar"
      element={
        <TechRadarPage
          pageTitle="TomTom DevEx TechRadar"
          width={1500}
          height={800}
        />
      }
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />}>
      <FeatureFlagged with="show-api-keys-feature">
        <SettingsLayout.Route path="/api-keys" title="API Keys">
          <ApiAuthPage />
        </SettingsLayout.Route>
      </FeatureFlagged>
    </Route>
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/org-graph" element={<OrgGraphPage />} />
    <Route path="bazaar" element={<BazaarPage />} />
    <Route path="/glossary" element={<GlossaryPage />} />
    <Route path="/github-team-actions" element={<GithubTeamActionsPage />} />
    <Route path="/announcements" element={<AnnouncementsPage />} />
    <Route
      path="/github-team-deployments"
      element={<GithubTeamDeploymentsPage />}
    />
    <Route path="/qeta" element={<QetaPage title="Questions" />} />
    <Route path="/tech-insights" element={<TechInsightsPage />} />
    <Route path="/recognition" element={<RecognitionPage />} />
    <Route path="/tomtom-lab" element={<TomtomLabPage />} />
    <Route path="/devtools" element={<DevToolsPage />}>
      {customDevToolsPage}
    </Route>
    <Route
      path="/catalog-unprocessed-entities"
      element={<CatalogUnprocessedEntitiesPage />}
    />
    <Route path="/secrets-sharing" element={<SecretsSharingFrontendPage />} />
    <Route path="/github-app-creation" element={<GithubAppCreationPage />} />
    <Route path="/kaap-catalog" element={<KaapCatalogPage />} />
    <FeatureFlagged with="show-time-saver-tab">
      <Route path="/time-saver" element={<TimeSaverPage />} />
    </FeatureFlagged>
  </FlatRoutes>
);

export const FrontStageAppWrapper = ({ children }: PropsWithChildren<{}>) => {
  const location = useLocation();
  const CLASS_NAME = 'FrontStageCatalog';
  const pathnames = [
    '/catalog',
    'not:/catalog/default/',
    '/api-docs',
    '/docs',
    'not:/docs/default',
  ];

  const shouldAddFrontStageClassToPage = (pathname: string) => {
    const processedPathnames = pathnames
      .filter(e => pathname.includes(e.replace(/not\:/g, '')))
      .map(e =>
        e.includes('not:')
          ? !pathname.includes(e.replace(/not\:/g, ''))
          : pathname.includes(e),
      );

    if (processedPathnames.length === 0) return false;
    return processedPathnames.reduce((acc, current) => acc && current);
  };

  const pathname = location.pathname;

  return (
    <div className={shouldAddFrontStageClassToPage(pathname) ? CLASS_NAME : ''}>
      {children}
    </div>
  );
};

export default app.createRoot(
  <>
    <AlertDisplay transientTimeoutMs={2500} />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
