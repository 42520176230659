import {
  createApiRef,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';

import { LandingPage } from './types';

export interface LandingPageApi {
  getLandingPageContent: (
    entityRef: string,
  ) => Promise<LandingPage | undefined>;
  saveLandingPageContent: (landingPage: LandingPage) => Promise<LandingPage>;
}

export const landingPageApiRef = createApiRef<LandingPageApi>({
  id: 'plugin.landing-page.service',
});

type ApiOptions = {
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi;
};

export class DefaultLandingPageApi implements LandingPageApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: ApiOptions) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  private async fetch<T = LandingPage>(
    input: string,
    init?: RequestInit,
  ): Promise<T> {
    const baseApiUrl = await this.discoveryApi.getBaseUrl(
      'landing-page/content',
    );
    const { token } = await this.identityApi.getCredentials();

    const headers: HeadersInit = new Headers(init?.headers);
    if (token && !headers.has('authorization')) {
      headers.set('authorization', `Bearer ${token}`);
    }

    const request = new Request(`${baseApiUrl}${input}`, {
      ...init,
      headers,
    });

    return fetch(request).then(async response => {
      if (!response.ok) {
        throw await ResponseError.fromResponse(response);
      }

      return response.json() as Promise<T>;
    });
  }

  async getLandingPageContent(
    entityRef: string,
  ): Promise<LandingPage | undefined> {
    return this.fetch(`/get?entityRef=${entityRef}`);
  }

  async saveLandingPageContent(landingPage: LandingPage): Promise<LandingPage> {
    return this.fetch('/save', {
      body: JSON.stringify(landingPage),
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    });
  }
}
