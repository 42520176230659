import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

import { TextField } from '@material-ui/core';

import { useUpdateValidations } from './hooks/useUpdateValidations';
import { getInitialInputValue } from './utils/formUtils';

export type CatalogNameExtensionUiOptions = {
  validate?: {
    entityKinds: string[];
  };
};

export const CatalogNameExtension = ({
  onChange,
  rawErrors,
  schema: { title = 'Catalog Entity Name', description = 'Name of the entity' },
  required,
  formData,
  uiSchema,
  idSchema,
  placeholder,
}: FieldExtensionComponentProps<string, CatalogNameExtensionUiOptions>) => {
  const { 'ui:autofocus': autoFocus } = uiSchema;

  const validate = useMemo(() => uiSchema['ui:options']?.validate, [uiSchema]);

  const catalogApi = useApi(catalogApiRef);

  const valueRef = useRef<HTMLInputElement>(null);

  const initialInputValue = useMemo(
    () => getInitialInputValue(formData),
    [formData],
  );

  const [catalogInputName, setCatalogInputName] =
    useState<string>(initialInputValue);

  useEffect(() => {
    setCatalogInputName(catalogInputName);
  }, [catalogInputName]);

  const updateValidations = useUpdateValidations(
    validate,
    onChange,
    catalogApi,
  );

  const validateOnBlur = () => {
    if (valueRef.current?.value) {
      updateValidations(valueRef.current?.value);
    }
  };

  return (
    <TextField
      inputRef={valueRef}
      id={idSchema?.$id}
      label={title}
      value={catalogInputName}
      onChange={event => setCatalogInputName(event.target.value)}
      placeholder={placeholder}
      helperText={description}
      required={required}
      onBlur={validateOnBlur}
      margin="normal"
      error={rawErrors?.length > 0 && !formData}
      inputProps={{ autoFocus }}
    />
  );
};
