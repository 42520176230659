import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const githubAppCreationPlugin = createPlugin({
  id: 'github-app-creation',
  routes: {
    root: rootRouteRef,
  },
});

export const GithubAppCreationPage = githubAppCreationPlugin.provide(
  createRoutableExtension({
    name: 'GithubAppCreationPage',
    component: () =>
      import('./components/GithubAppCreationComponent').then(
        m => m.GithubAppCreation,
      ),
    mountPoint: rootRouteRef,
  }),
);
