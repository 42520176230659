import { WatchtowerReader } from '../../WatchtowerReader';
import { createRepoBranchProtectionFactHandler } from '../../handlers/github';
import { TechInsightsRetrieverIDs } from '../../retrieverIDs';
import { BranchProtectionFact, TypedFactRetriever } from '../../types';

export const createRepoBranchProtectionFactRetriever = (
  reader: WatchtowerReader,
) => {
  const RepoBranchProtectionFactRetriever: TypedFactRetriever<BranchProtectionFact> =
    {
      id: TechInsightsRetrieverIDs.GITHUB_REPO_BRANCH_PROTECTION_FACT_RETRIEVER,
      version: '0.0.1',
      title: 'Github Workflow Entity Fact Retriever',
      description: 'Generates facts regarding the github workflow entity',
      entityFilter: [
        {
          kind: 'Component',
          'spec.type': 'service',
        },
      ],
      schema: {
        defaultBranch: {
          type: 'string',
          description: 'The default branch used in the repository',
        },
        branchAllowsForcePushes: {
          type: 'boolean',
          description: 'default branch allows force pushes',
        },
        mergeNeedsConversationResolution: {
          type: 'string',
          description: 'requires conversations to be resolved before merge',
        },
        mergeDismissesStaleReviews: {
          type: 'boolean',
          description: 'dismisses stale reviews',
        },
        mergeApprovalReviewCount: {
          type: 'integer',
          description: 'required approving review count',
        },
        mergeRequiresReviews: {
          type: 'boolean',
          description: 'requires reviews before merging',
        },
        mergeRequiresStatusChecks: {
          type: 'boolean',
          description: 'requires status checks before merging',
        },
      },
      handler: createRepoBranchProtectionFactHandler(reader),
    };
  return RepoBranchProtectionFactRetriever;
};
