import React from 'react';

import { Link } from '@backstage/core-components';

import {
  List,
  ListItem,
  ListItemIcon,
  Typography,
  ListItemText,
  Box,
  makeStyles,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import BeenHereIcon from '@material-ui/icons/Beenhere';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles(() => ({
  missingMessage: {
    marginLeft: '0.6em',
    fontWeight: 'bold',
  },
  heading: {
    marginLeft: '0.6em',
    fontWeight: 'bold',
  },
  statement: {
    marginLeft: '2.4em',
    marginBottom: '0.6em',
  },
  missionVisionPurposeElement: {
    display: 'flex',
  },
}));
export const GroupProfileMissionVisionPurpose = (props: {
  missions: string[];
  mission?: string;
  vision?: string;
  purpose?: string;
}) => {
  let missionVisionPurposeMissingMessage;
  const classes = useStyles();

  if (!(props.mission && props.vision && props.purpose)) {
    missionVisionPurposeMissingMessage = (
      <Box className={classes.missingMessage} data-testid="empty-state">
        <Link to="/create/templates/default/team-config">
          Click here to customize your team.
        </Link>
      </Box>
    );
  } else {
    missionVisionPurposeMissingMessage = null;
  }

  if (props.mission || props.vision || props.purpose) {
    let missionElement;
    if (props.mission) {
      missionElement = (
        <Box data-testid="mission">
          <Box
            className={classes.missionVisionPurposeElement}
            flexDirection="row"
          >
            <Box>
              <BeenHereIcon />
            </Box>
            <Box className={classes.heading}>Mission:</Box>
          </Box>
          <Box className={classes.statement}>{props.mission}</Box>
        </Box>
      );
    } else {
      missionElement = (
        <Box data-testid="missionUnavailable">
          <Box
            className={classes.missionVisionPurposeElement}
            flexDirection="row"
          >
            <Box>
              <WarningIcon />
            </Box>
            <Box className={classes.missingMessage}>
              This team has not set up a mission.
            </Box>
          </Box>
        </Box>
      );
    }

    let visionElement;
    if (props.vision) {
      visionElement = (
        <Box data-testid="vision">
          <Box
            className={classes.missionVisionPurposeElement}
            flexDirection="row"
          >
            <Box>
              <VisibilityIcon />
            </Box>
            <Box className={classes.heading}>Vision:</Box>
          </Box>
          <Box className={classes.statement}>{props.vision}</Box>
        </Box>
      );
    } else {
      visionElement = (
        <Box data-testid="visionUnavailable">
          <Box
            className={classes.missionVisionPurposeElement}
            flexDirection="row"
          >
            <Box>
              <WarningIcon />
            </Box>
            <Box className={classes.missingMessage}>
              This team has not set up a vision.
            </Box>
          </Box>
        </Box>
      );
    }

    let purposeElement;
    if (props.purpose) {
      purposeElement = (
        <Box data-testid="purpose">
          <Box
            className={classes.missionVisionPurposeElement}
            flexDirection="row"
          >
            <Box>
              <TrendingUpIcon />
            </Box>
            <Box className={classes.heading}>Purpose:</Box>
          </Box>
          <Box className={classes.statement}>{props.purpose}</Box>
        </Box>
      );
    } else {
      purposeElement = (
        <Box data-testid="purposeUnavailable">
          <Box
            className={classes.missionVisionPurposeElement}
            flexDirection="row"
          >
            <Box>
              <WarningIcon />
            </Box>
            <Box className={classes.missingMessage}>
              This team has not set up a purpose.
            </Box>
          </Box>
        </Box>
      );
    }

    return (
      <Box data-testid="mission-vision-purpose">
        {missionElement}
        {visionElement}
        {purposeElement}
        {missionVisionPurposeMissingMessage}
      </Box>
    );
  }
  if (props.missions.length !== 0) {
    const items = props.missions?.map((mission: string, index: number) => (
      <ListItem key={index} disableGutters data-testid="legacy-missions">
        <ListItemIcon>
          <ArrowRightIcon />
        </ListItemIcon>
        <ListItemText
          primary={<Typography color="textPrimary">{mission}</Typography>}
        />
      </ListItem>
    ));

    return <List>{items}</List>;
  }

  return missionVisionPurposeMissingMessage;
};
