import {
  EntityGrafanaAlertsCard,
  EntityGrafanaDashboardsCard,
  isAlertSelectorAvailable,
  isDashboardSelectorAvailable,
} from '@k-phoen/backstage-plugin-grafana';
import {
  isPluginApplicableToEntity as isPagerDutyAvailable,
  EntityPagerDutyCard,
} from '@pagerduty/backstage-plugin';
import { EntityGithubPullRequestsOverviewCard } from '@roadiehq/backstage-plugin-github-pull-requests';
import React from 'react';

import { EntityGithubDeploymentsCard } from '@backstage-community/plugin-github-deployments';
import { EntitySonarQubeCard } from '@backstage-community/plugin-sonarqube';
import { isSonarQubeAvailable } from '@backstage-community/plugin-sonarqube-react';
import { Entity } from '@backstage/catalog-model';
import {
  EntityAboutCard,
  EntityHasSubcomponentsCard,
  EntityLinksCard,
  EntitySwitch,
} from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';

import { Grid } from '@material-ui/core';

import {
  isGitHubAvailable,
  isLinksNotEmpty,
  isNotInfraComponent,
  isSubcomponentsAvailable,
} from '../entityUtils';
import { ActionContent } from './ActionContent';
import { EntityWarningContent } from './EntityWarningContent';

const isTagSelectorAvailable = (entity: Entity) =>
  Boolean(isDashboardSelectorAvailable(entity));

export const OverviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    <EntityWarningContent />
    {ActionContent}
    <EntitySwitch>
      <EntitySwitch.Case>
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={isNotInfraComponent}>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={isLinksNotEmpty}>
        <Grid item md={6} xs={12}>
          <EntityLinksCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={isTagSelectorAvailable}>
        <Grid item md={6}>
          <EntityGrafanaDashboardsCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={isAlertSelectorAvailable}>
        <Grid item md={6}>
          <EntityGrafanaAlertsCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={isSubcomponentsAvailable}>
        <Grid item md={6} xs={12}>
          <EntityHasSubcomponentsCard variant="gridItem" />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={isGitHubAvailable}>
        <Grid item md={6} xs={12}>
          <EntityGithubPullRequestsOverviewCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={isGitHubAvailable}>
        <Grid item md={6} sm={6} xs={12}>
          <EntityGithubDeploymentsCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={isSonarQubeAvailable}>
        <Grid item md={6} xs={12}>
          <EntitySonarQubeCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={isPagerDutyAvailable}>
        <Grid item md={6}>
          <EntityPagerDutyCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);
