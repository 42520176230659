import React from 'react';

import { CheckResultRenderer } from '@backstage-community/plugin-tech-insights';
import { CheckResult } from '@backstage-community/plugin-tech-insights-common';
import { StatusError, StatusOK } from '@backstage/core-components';

const CustomBooleanCheck = ({ checkResult }: { checkResult: CheckResult }) => {
  return !!checkResult.result ? <StatusOK /> : <StatusError />;
};

export const customJsonRulesEngineCheckResultRenderer: CheckResultRenderer = {
  type: 'json-rules-engine',
  component: (checkResult: CheckResult) => (
    <CustomBooleanCheck checkResult={checkResult} />
  ),
};
