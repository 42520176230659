import { useAsync } from 'react-use';

import { useApi } from '@backstage/core-plugin-api';

import { tomtomLabApiRef } from '../api';
import { Event, ListEventFilter } from '../types';

export const useEvents = (
  filter: ListEventFilter,
  callback?: (events: Event[]) => Event[] | undefined,
): {
  events: Event[] | undefined;
  loading: boolean;
  error?: any;
} => {
  const tomtomLabApi = useApi(tomtomLabApiRef);
  const { value: events, loading } = useAsync(async () => {
    return tomtomLabApi.getEvents(filter);
  });
  const func = callback ?? ((evts: Event[]) => evts);
  return { events: func(events ?? []), loading };
};
