import { TechInsightsCheckID } from '@internal/plugin-techinsights-module-entities';
import { useCallback, useEffect, useState } from 'react';

import { CheckResult, PercentageData } from './types';

export const useCalculatePercentages = (results: CheckResult[]) => {
  const initialPercentageState = {
    percentage: 0,
    onboarded: 0,
    total: 0,
  };

  // all groups
  const [completionData, setCompletionData] = useState<PercentageData>(
    initialPercentageState,
  );
  const [managerData, setManagerData] = useState<PercentageData>(
    initialPercentageState,
  );
  const [slackData, setSlackData] = useState<PercentageData>(
    initialPercentageState,
  );
  const [confluenceData, setConfluenceData] = useState<PercentageData>(
    initialPercentageState,
  );
  const [bugReportData, setBugReportData] = useState<PercentageData>(
    initialPercentageState,
  );

  // team only
  const [teamCompletionData, setTeamCompletionData] = useState<PercentageData>(
    initialPercentageState,
  );
  const [teamManagerData, setTeamManagerData] = useState<PercentageData>(
    initialPercentageState,
  );
  const [teamSlackData, setTeamSlackData] = useState<PercentageData>(
    initialPercentageState,
  );
  const [teamConfluenceData, setTeamConfluenceData] = useState<PercentageData>(
    initialPercentageState,
  );
  const [teamBugReportData, setTeamBugReportData] = useState<PercentageData>(
    initialPercentageState,
  );
  const [teamComponentOrAPIOnboardData, setTeamComponentOrAPIOnboardData] =
    useState<PercentageData>(initialPercentageState);

  const calculateOnboarded = useCallback(
    (checkId: string, isTeamRestricted?: boolean): number => {
      let onboarded = 0;

      results
        .filter(r => (isTeamRestricted ? r.entity.isTeam : true))
        .map(result => {
          result.results.map(r => {
            if (r.checkId === checkId && r.result) {
              onboarded++;
            }
          });
        });

      return onboarded;
    },
    [results],
  );

  const calculateNotFullyOnboard = useCallback(
    (isTeamRestricted?: boolean): number => {
      let count = 0;
      results
        .filter(r => (isTeamRestricted ? r.entity.isTeam : true))
        .map(result => {
          const checkNotPassed = result.results.find(r => !r.result);
          if (checkNotPassed) count++;
        });

      return count;
    },
    [results],
  );

  const round = (percentage: number): number => {
    return Number((Math.round(percentage * 100) / 100).toFixed(2));
  };

  const calculateTotal = useCallback(
    (isTeamRestricted?: boolean) =>
      isTeamRestricted
        ? results?.filter(r => r.entity.isTeam).length
        : results?.length,
    [results],
  );

  const calculatePercentageData = useCallback(
    (
      checkId: string,
      updateStateCallback: (data: PercentageData) => void,
      isTeamRestricted?: boolean,
    ) => {
      const total = calculateTotal(isTeamRestricted);
      const onboarded = calculateOnboarded(checkId, isTeamRestricted);
      const percentage = (onboarded * 100) / total;

      updateStateCallback({
        total: total,
        onboarded,
        percentage: round(percentage),
      });
    },
    [calculateOnboarded, calculateTotal],
  );

  const calculateCompletionPercentageData = useCallback(
    (
      updateStateCallback: (data: PercentageData) => void,
      isTeamRestricted?: boolean,
    ) => {
      const total = calculateTotal(isTeamRestricted);
      const notFullyOnboarded = calculateNotFullyOnboard(isTeamRestricted);
      const onboarded = total - notFullyOnboarded;
      const percentage = (onboarded * 100) / total;

      updateStateCallback({
        total: total,
        onboarded,
        percentage: round(percentage),
      });
    },
    [calculateNotFullyOnboard, calculateTotal],
  );

  useEffect(() => {
    if (results && results?.length > 0) {
      // all groups
      calculateCompletionPercentageData(setCompletionData);
      calculatePercentageData(
        TechInsightsCheckID.MANAGER_CHECK,
        setManagerData,
      );
      calculatePercentageData(
        TechInsightsCheckID.SLACK_CHANNEL_CHECK,
        setSlackData,
      );
      calculatePercentageData(
        TechInsightsCheckID.CONFLUENCE_CHECK,
        setConfluenceData,
      );
      calculatePercentageData(
        TechInsightsCheckID.BUG_REPORT_CHECK,
        setBugReportData,
      );

      // teams
      calculateCompletionPercentageData(setTeamCompletionData, true);
      calculatePercentageData(
        TechInsightsCheckID.MANAGER_CHECK,
        setTeamManagerData,
        true,
      );
      calculatePercentageData(
        TechInsightsCheckID.SLACK_CHANNEL_CHECK,
        setTeamSlackData,
        true,
      );
      calculatePercentageData(
        TechInsightsCheckID.CONFLUENCE_CHECK,
        setTeamConfluenceData,
        true,
      );
      calculatePercentageData(
        TechInsightsCheckID.BUG_REPORT_CHECK,
        setTeamBugReportData,
        true,
      );
      calculatePercentageData(
        TechInsightsCheckID.COMPONENT_OR_API_CHECK,
        setTeamComponentOrAPIOnboardData,
        true,
      );
    }
  }, [results, calculatePercentageData, calculateCompletionPercentageData]);

  return {
    allGroups: {
      completionData: completionData,
      managerData: managerData,
      slackData: slackData,
      confluenceData: confluenceData,
      bugReportData: bugReportData,
    },
    teams: {
      teamCompletionData: teamCompletionData,
      teamManagerData: teamManagerData,
      teamSlackData: teamSlackData,
      teamConfluenceData: teamConfluenceData,
      teamBugReportData: teamBugReportData,
      teamComponentOrAPIOnboardData: teamComponentOrAPIOnboardData,
    },
  };
};
