import { GroupEntity } from '@internal/plugin-catalog-model';
import React from 'react';

import { EmailIcon, Link } from '@backstage/core-components';

import { Grid, makeStyles } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import BugReportIcon from '@material-ui/icons/BugReport';

import Confluence from '../../images/confluence.svg';
import Slack from '../../images/slack.svg';

const useStyles = makeStyles(
  () => ({
    root: {
      flexGrow: 1,
      marginLeft: '5px',
      '& a': {
        padding: '5px',
      },
    },
    disabled: {
      filter: 'grayscale(1)',
    },
  }),
  { name: 'GroupProfileTitleIcons' },
);

type MetadataProperty = 'title' | 'url';

export const GroupProfileTitleIcons = (props: { group: GroupEntity }) => {
  const classes = useStyles();
  const {
    metadata: { bugReport, confluence, slack },
    spec: { profile },
  } = props.group;

  const getSlack = (property: MetadataProperty) => {
    if (slack && props.group.metadata.slack?.[property]) {
      return props.group.metadata.slack?.[property]?.toString();
    }
    return undefined;
  };

  const getTeamEmail = () => {
    return profile?.email;
  };

  const getConfluence = (property: MetadataProperty) => {
    if (confluence && props.group?.metadata?.confluence?.[property]) {
      return props.group?.metadata?.confluence?.[property]?.toString();
    }
    return undefined;
  };

  const getBugReport = (property: MetadataProperty) => {
    if (bugReport && props.group?.metadata?.bugReport?.[property]) {
      return props.group?.metadata?.bugReport?.[property]?.toString();
    }
    return undefined;
  };

  return (
    <Grid spacing={2} container className={classes.root}>
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={0}>
          <Grid item>
            <IconButton
              data-testid="slack"
              aria-label="slack"
              disabled={!slack}
              title={getSlack('title') ?? 'slack'}
              component={Link}
              to={getSlack('url') ?? '#'}
            >
              <img
                className={!slack ? classes.disabled : ''}
                src={Slack}
                alt="slack"
                width="18"
                height="18"
              />
            </IconButton>
          </Grid>

          <Grid item>
            <IconButton
              data-testid="team-email"
              disabled={!profile?.email}
              aria-label="team-email"
              title="team-email"
              component={Link}
              to={getTeamEmail() ? `mailto:${getTeamEmail()}` : '#'}
            >
              <EmailIcon />
            </IconButton>
          </Grid>

          <Grid item>
            <IconButton
              data-testid="confluence"
              disabled={!confluence}
              aria-label="confluence"
              title={getConfluence('title') ?? 'confluence'}
              component={Link}
              to={getConfluence('url') ?? '#'}
            >
              <img
                className={!confluence ? classes.disabled : ''}
                src={Confluence}
                alt="confluence"
                width="18"
                height="18"
              />
            </IconButton>
          </Grid>

          <Grid item>
            <IconButton
              data-testid="bug-report"
              disabled={!bugReport}
              aria-label="bug-report"
              title={getBugReport('title') ?? 'bug report'}
              component={Link}
              to={getBugReport('url') ?? '#'}
            >
              <BugReportIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
