import useAsync from 'react-use/lib/useAsync';

import { useApi } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';

import { azureResourcesApiRef } from '../api';
import {
  AzureResourcePolicyCompliancy,
  AZURE_ANNOTATION_COMPLIANCY_RESOURCE_NAME_FILTERS,
} from '../types';
import { parseAzureResourceNameFilters } from '../utils';

export const useAzureResourcePolicyCompliancy = () => {
  const { entity } = useEntity();
  const api = useApi(azureResourcesApiRef);

  const resourceNameFilters: string[] =
    parseAzureResourceNameFilters(
      entity,
      AZURE_ANNOTATION_COMPLIANCY_RESOURCE_NAME_FILTERS,
    ) ?? [];

  const {
    value: compliancies,
    loading,
    error,
  } = useAsync(async (): Promise<AzureResourcePolicyCompliancy[]> => {
    const resourcesResponse = await api.getAzurePolicyCompliancy(
      resourceNameFilters,
    );

    return (resourcesResponse?.data as AzureResourcePolicyCompliancy[]) ?? [];
  }, []);

  return { compliancies, loading, error } as const;
};
