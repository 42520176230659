import { KubernetesValidatorFunctions } from '@backstage/catalog-model';
import { CatalogApi } from '@backstage/plugin-catalog-react';

export const isValidName = (value: string) => {
  return KubernetesValidatorFunctions.isValidObjectName(value);
};

export const isEntityExist = async (
  entityName: string,
  kinds: string[],
  catalogApi: CatalogApi,
): Promise<boolean> => {
  if (kinds.length === 0 || entityName.length === 0) {
    return false;
  }
  const response = await catalogApi.getEntitiesByRefs({
    entityRefs: kinds.map(
      k => `${k.toLocaleLowerCase()}:default/${entityName}`,
    ),
  });

  return response.items.some(e => e !== null && e !== undefined);
};
