import React, { useCallback, useEffect, useState } from 'react';
import useDebounce from 'react-use/lib/useDebounce';

import { useApi } from '@backstage/core-plugin-api';
import { scmIntegrationsApiRef } from '@backstage/integration-react';

import { EntityGroupPicker } from './EntityGroupPicker';
import { GithubRepoOwnerPicker } from './GithubRepoOwnerPicker';
import { RepoHostPicker } from './RepoHostPicker';
import { RepoNamePickerRepoName } from './RepoNamePickerRepoName';
import { useSetSecret } from './hooks/useSetSecret';
import { useUiSchemas } from './hooks/useUiSchemas';
import { RepoNamePickerProps } from './schema';
import { RepoNamePickerState } from './types';
import {
  parseRepoNamePickerFormData,
  serializeRepoNamePickerData,
} from './utils';

export const RepoNamePicker = (props: RepoNamePickerProps) => {
  const { uiSchema, onChange, rawErrors, formData } = props;

  const integrationApi = useApi(scmIntegrationsApiRef);

  const [state, setState] = useState<RepoNamePickerState>(
    parseRepoNamePickerFormData(formData),
  );

  const { owner, organization, project } = state;

  const { allowedHosts, allowedOrganizations, allowedOwners, allowedProjects } =
    useUiSchemas(props);

  const updateLocalState = useCallback(
    (newState: RepoNamePickerState) => {
      setState(prevState => ({ ...prevState, ...newState }));
    },
    [setState],
  );

  useEffect(() => {
    onChange(serializeRepoNamePickerData(state));
  }, [state, onChange]);

  /* we deal with calling the repo setting here instead of in each components for ease */
  useEffect(() => {
    if (allowedOrganizations.length > 0 && !organization) {
      setState(prevState => ({
        ...prevState,
        organization: allowedOrganizations[0],
      }));
    }
  }, [setState, allowedOrganizations, organization]);

  useEffect(() => {
    if (allowedOwners.length > 0 && !owner) {
      setState(prevState => ({
        ...prevState,
        owner: allowedOwners[0],
      }));
    }
  }, [setState, allowedOwners, owner]);

  useEffect(() => {
    if (allowedProjects.length > 0 && !project) {
      setState(prevState => ({
        ...prevState,
        project: allowedProjects[0],
      }));
    }
  }, [setState, allowedProjects, project]);

  useDebounce(useSetSecret(props, state, setState), 500, [
    state,
    uiSchema,
    setState,
  ]);

  const hostType =
    (state.host && integrationApi.byHost(state.host)?.type) ?? null;

  return (
    <>
      <RepoHostPicker
        host={state.host}
        hosts={allowedHosts}
        onChange={host =>
          setState(prevState => ({
            ...prevState,
            host,
            entityRef: '',
            repoName: '',
          }))
        }
        rawErrors={rawErrors}
      />

      {hostType === 'github' && (
        <GithubRepoOwnerPicker
          allowedOwners={allowedOwners}
          onChange={updateLocalState}
          rawErrors={rawErrors}
          state={state}
        />
      )}

      {state.owner && (
        <EntityGroupPicker
          onChange={updateLocalState}
          rawErrors={rawErrors}
          state={state}
        />
      )}

      {state.entityRef && (
        <RepoNamePickerRepoName
          onChange={updateLocalState}
          rawErrors={rawErrors}
          state={state}
        />
      )}
    </>
  );
};
