import { useRequestGitHubUserCredentials } from '../../hooks';
import {
  GithubLoginProps,
  DEFAULT_OWNER,
  DEFAULT_SECRET_KEY,
} from './GithubLoginSchema';

export const GithubLogin = ({ uiSchema }: GithubLoginProps) => {
  const { requestUserCredentials, githubOwner, githubRepo } =
    uiSchema?.['ui:options'] ?? {};

  useRequestGitHubUserCredentials({
    host: 'github.com',
    owner: `${githubOwner ?? DEFAULT_OWNER}`,
    repoName: `${githubRepo}`,
    additionalScopes: requestUserCredentials?.additionalScopes ?? {
      github: [],
    },
    secretsKey: requestUserCredentials?.secretsKey ?? DEFAULT_SECRET_KEY,
  });

  return null;
};
