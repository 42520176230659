import {
  FactRetrieverContext,
  TechInsightFact,
} from '@backstage-community/plugin-tech-insights-node';
import { Entity } from '@backstage/catalog-model';

import { getEntities } from './utils/getEntities';
import { getManagerOnboardingDetails } from './utils/onboardingDetails';

export const teamManagerHandler = async ({
  discovery,
  entityFilter,
  tokenManager,
}: FactRetrieverContext): Promise<TechInsightFact[]> => {
  const entities = await getEntities({ entityFilter, tokenManager, discovery });

  return entities.items.map((entity: Entity) => {
    const { hasManagers, hasManagerTitles, hasManagerEmails, hasManagerNames } =
      getManagerOnboardingDetails(entity);

    return {
      entity: {
        namespace: entity.metadata.namespace!,
        kind: entity.kind,
        name: entity.metadata.name,
      },
      facts: {
        hasManagers: Boolean(hasManagers),
        hasManagerTitles: Boolean(hasManagerTitles),
        hasManagerEmails: Boolean(hasManagerEmails),
        hasManagerNames: Boolean(hasManagerNames),
      },
    };
  });
};
