import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const githubTeamDeploymentsPlugin = createPlugin({
  id: 'github-team-deployments',
  routes: {
    root: rootRouteRef,
  },
});

export const GithubTeamDeploymentsPage = githubTeamDeploymentsPlugin.provide(
  createRoutableExtension({
    name: 'GithubTeamDeploymentsPage',
    component: () =>
      import('./components/DeploymentGroupTable').then(
        m => m.DeploymentGroupTable,
      ),
    mountPoint: rootRouteRef,
  }),
);
