import { FactRetrieverContext } from '@backstage-community/plugin-tech-insights-node';

import { WatchtowerReader } from '../../WatchtowerReader';
import { BranchProtectionFact, TypedTechInsightFact } from '../../types';
import { getEntities } from '../utils/getEntities';

export const createRepoBranchProtectionFactHandler = (
  watchtowerReader: WatchtowerReader,
) => {
  return async ({
    logger,
    entityFilter,
    tokenManager,
    discovery,
  }: FactRetrieverContext) => {
    const entities = await getEntities({
      logger,
      entityFilter,
      tokenManager,
      discovery,
    });

    const entityFactsPromise = entities.items.map(async (entity: any) => {
      const projectSlug =
        entity.metadata.annotations?.['github.com/project-slug'];
      if (!projectSlug || !projectSlug.includes('/')) {
        return null;
      }
      const repoName = projectSlug.split('/')[1];
      const entityRef = {
        namespace: entity.metadata.namespace!,
        kind: entity.kind,
        name: entity.metadata.name,
      };
      try {
        const branchProtectionFact =
          await watchtowerReader.getRepoAttribute<BranchProtectionFact>(
            repoName,
            'branch-protection-rules',
          );
        if (branchProtectionFact) {
          return {
            entity: entityRef,
            facts: branchProtectionFact,
          };
        }
        return {
          entity: entityRef,
          facts: {
            defaultBranch: '',
            branchAllowsForcePushes: false,
            mergeRequiresReviews: false,
            mergeRequiresStatusChecks: false,
            mergeApprovalReviewCount: 0,
            mergeDismissesStaleReviews: false,
            mergeNeedsConversationResolution: false,
          },
        };
      } catch (error) {
        logger.error(
          `Error while fetching branch protection rules for ${repoName}`,
          error as any,
        );
        return null;
      }
    });
    const facts = await Promise.all(entityFactsPromise);
    return facts.filter(
      fact => !!fact,
    ) as TypedTechInsightFact<BranchProtectionFact>[];
  };
};
