import { AzureAPIs, AzureProviders } from '../types';

export const getURLForAzureAPI = (
  subscriptionId: string,
  provider: AzureProviders,
  api: AzureAPIs,
  apiVersion: string,
) => {
  return `https://management.azure.com/subscriptions/${subscriptionId}/\
providers/${provider}/${api}?api-version=${apiVersion}&$top=5000`;
};
