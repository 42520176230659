import { useCommonStyles } from '@internal/plugin-tomtom-theme-react';
import { NewAnnouncementBanner } from '@k-phoen/backstage-plugin-announcements';
import React from 'react';

import { Content, Header, Page } from '@backstage/core-components';

import { Grid } from '@material-ui/core';

import { FeedbackBanner } from './FeedbackBanner';
import { GHWidgets } from './GHWidgets';
import { HomeToolKit } from './HomeToolKit';
import { SearchAndLogo } from './SearchAndLogo';
import { useFeedbackBanner } from './hooks/useFeedbackBanner';
import { useGHWidgets } from './hooks/useGHWidgets';
import { useHomePageStyles } from './hooks/useHomePageStyles';

export const HomePage = () => {
  const classes = useHomePageStyles();
  const commonStyles = useCommonStyles();
  const hasGithubComponentAPIEntities = useGHWidgets();
  const [isFeedbackBannerClosed, setFeedbackBannerClosed] = useFeedbackBanner();

  const HeaderContent = () => (
    <Grid container>
      <Grid item md={12} className={classes.newAnnouncementBanner}>
        <NewAnnouncementBanner />
      </Grid>
      {!isFeedbackBannerClosed && (
        <Grid item md={12}>
          <FeedbackBanner onDismiss={setFeedbackBannerClosed} />
        </Grid>
      )}
    </Grid>
  );

  return (
    <Page themeId="home">
      <Header
        style={{
          boxShadow: 'none',
          display: 'inherit',
          backgroundImage: 'none',
        }}
        title=""
      >
        <HeaderContent />
      </Header>
      <Content className={classes.content}>
        <Grid
          className={
            !hasGithubComponentAPIEntities ? commonStyles.minHeight55vh : ''
          }
        >
          <Grid className={commonStyles.marginTop10vh}>
            <SearchAndLogo />
            {hasGithubComponentAPIEntities && <GHWidgets />}
          </Grid>
        </Grid>
        <HomeToolKit />
      </Content>
    </Page>
  );
};
