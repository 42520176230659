import { JSON_RULE_ENGINE_CHECK_TYPE } from '@backstage-community/plugin-tech-insights-backend-module-jsonfc';

import { TechInsightsCheckID } from '../../checkIDs';
import { TechInsightsRetrieverIDs } from '../../retrieverIDs';

export const serviceQualityChecks = [
  {
    id: TechInsightsCheckID.SONARQUBE_QUALITY_GATE_CHECK,
    type: JSON_RULE_ENGINE_CHECK_TYPE,
    name: 'SonarQube Quality Gate check',
    description:
      'The service passed the quality gates in SonarQube, meeting the required standard over the last 7 days',
    factIds: [TechInsightsRetrieverIDs.SONARQUBE_FACTS_RETRIEVER],
    rule: {
      conditions: {
        all: [
          {
            fact: 'qualityGatePassed',
            operator: 'equal',
            value: true,
          },
        ],
      },
    },
  },
  {
    id: TechInsightsCheckID.SONARQUBE_CODE_COVERAGE_CHECK,
    type: JSON_RULE_ENGINE_CHECK_TYPE,
    name: 'Service SonarQube Code Coverage check',
    description:
      'Verifies that a Service has sufficient code coverage in tests as configured in the Sonarqube Project',
    factIds: [TechInsightsRetrieverIDs.SONARQUBE_FACTS_RETRIEVER],
    rule: {
      conditions: {
        all: [
          {
            fact: 'hasSufficientCodeCoverage',
            operator: 'equal',
            value: true,
          },
        ],
      },
    },
  },
  {
    id: TechInsightsCheckID.SONARQUBE_SECURITY_REVIEW_CHECK,
    type: JSON_RULE_ENGINE_CHECK_TYPE,
    name: 'Security Review >= B',
    description:
      'The service’s security review rating in SonarQube needs to be equal to or greater than B over the last 7 days, ensuring compliance with security standards and minimizing potential vulnerabilities',
    factIds: [TechInsightsRetrieverIDs.SONARQUBE_FACTS_RETRIEVER],
    rule: {
      conditions: {
        all: [
          {
            fact: 'passesSecurityReview',
            operator: 'equal',
            value: true,
          },
        ],
      },
    },
  },
  {
    id: TechInsightsCheckID.SONARQUBE_VULNERABILITIES_CHECK,
    type: JSON_RULE_ENGINE_CHECK_TYPE,
    name: 'Vulnerabilities == 0',
    description:
      'The service has no reported vulnerabilities in SonarQube, ensuring the service remains secure and free from critical security issues.',
    factIds: [TechInsightsRetrieverIDs.SONARQUBE_FACTS_RETRIEVER],
    rule: {
      conditions: {
        all: [
          {
            fact: 'hasVulnerabilities',
            operator: 'equal',
            value: false,
          },
        ],
      },
    },
  },
  {
    id: TechInsightsCheckID.SONARQUBE_TOTAL_ISSUES_CHECK,
    type: JSON_RULE_ENGINE_CHECK_TYPE,
    name: 'Sonar Issues < 5',
    description:
      'The service has fewer than 5 open issues in SonarQube, ensuring the code quality remains high and free from critical defects',
    factIds: [TechInsightsRetrieverIDs.SONARQUBE_FACTS_RETRIEVER],
    rule: {
      conditions: {
        all: [
          {
            fact: 'totalIssues',
            operator: 'lessThanInclusive',
            value: 5,
          },
        ],
      },
    },
  },
  {
    id: TechInsightsCheckID.SONARQUBE_SECURITY_HOTSPOTS_CHECK,
    type: JSON_RULE_ENGINE_CHECK_TYPE,
    name: 'Security Hotspots == 0',
    description:
      'The service has no security hotspots in SonarQube, ensuring the code is secure and free from potential security risks.',
    factIds: [TechInsightsRetrieverIDs.SONARQUBE_FACTS_RETRIEVER],
    rule: {
      conditions: {
        all: [
          {
            fact: 'hasSecurityHotspots',
            operator: 'equal',
            value: false,
          },
        ],
      },
    },
  },
];
