import { JSON_RULE_ENGINE_CHECK_TYPE } from '@backstage-community/plugin-tech-insights-backend-module-jsonfc';

import { TechInsightsCheckID } from '../../checkIDs';
import { TechInsightsRetrieverIDs } from '../../retrieverIDs';

export const repoHasReadmeCheck = {
  id: TechInsightsCheckID.REPO_HAS_README_CHECK,
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: 'Has a README',
  description:
    'Service associated repositories include a README.md file to setup instructions, usage guidelines, and other key information.',
  factIds: [TechInsightsRetrieverIDs.GITHUB_REPO_HAS_README_FACT_RETRIEVER],
  rule: {
    conditions: {
      all: [
        {
          fact: 'hasReadme',
          operator: 'equal',
          value: true,
        },
      ],
    },
  },
};
