import { JSON_RULE_ENGINE_CHECK_TYPE } from '@backstage-community/plugin-tech-insights-backend-module-jsonfc';

import { TechInsightsCheckID } from '../../checkIDs';
import { TechInsightsRetrieverIDs } from '../../retrieverIDs';

const CI_SUCCESS_RATE = 90;

export const componentCIHealthCheck = {
  id: TechInsightsCheckID.COMPONENT_CI_SUCCESS_CHECK,
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: `CI Success Rate >= ${CI_SUCCESS_RATE}%`,
  description: `At least ${CI_SUCCESS_RATE}% or more of CI pipeline runs are successful without errors over the last 7 days.`,
  factIds: [TechInsightsRetrieverIDs.GITHUB_REPO_CI_SUCCESS_FACT_RETRIEVER],
  rule: {
    conditions: {
      all: [
        {
          fact: 'successPercent',
          operator: 'greaterThanInclusive',
          value: CI_SUCCESS_RATE,
        },
      ],
    },
  },
};
