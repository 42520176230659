import { FactRetriever } from '@backstage-community/plugin-tech-insights-node';

import { teamBugReportHandler } from '../handlers/teamBugReportHandler';

export const teamBugReportFactRetriever: FactRetriever = {
  id: 'teamBugReportFactRetriever',
  version: '0.0.1',
  title: 'Team BugReport',
  description:
    'Generates facts which indicate the quality of data in metadata.bugReport field',
  entityFilter: [{ kind: ['group'] }],
  schema: {
    hasBugReport: {
      type: 'boolean',
      description: 'The metadata.bugReport field is set',
    },
  },
  handler: teamBugReportHandler,
};
