import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'github-team-actions',
});

export const buildRouteRef = createSubRouteRef({
  id: 'github-team-actions/build',
  path: '/:repo/:id',
  parent: rootRouteRef,
});
