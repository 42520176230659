import { colors } from './colors';
import { trailingArrow } from './commons';
import { fonts } from './fonts';

export const MuiThemeOverrides: any = {
  MuiLink: {
    styleOverrides: {
      root: {
        fontFamily: fonts.proximaNovaBold,
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        backgroundColor: colors.primary.main,
      },
      label: {
        color: colors.primary.white,
      },
      outlined: {
        '& span': {
          color: colors.primary.black,
        },
      },
      deleteIcon: {
        color: colors.primary.black,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontFamily: fonts.gilroyBold,
        fontWeight: 700,
        borderRadius: '100px',
        textTransform: 'none',
        '&.Mui-disabled': {
          backgroundColor: '#bdbdbd',
        },
      },
      containedPrimary: {
        '&::after': trailingArrow,
        textTransform: 'initial',
      },
      textPrimary: {
        textTransform: 'initial',
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      label: {
        fontFamily: fonts.proximaNovaBold,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '&.Mui-focused fieldset[class*=notchedOutline]': {
          borderColor: colors.primary.black,
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        '&.Mui-focused': {
          color: colors.primary.black,
        },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      h1: {
        fontFamily: fonts.gilroyBold,
        fontWeight: 400,
      },
      h2: {
        fontFamily: fonts.gilroyBold,
        fontWeight: 400,
      },
      h3: {
        fontFamily: fonts.gilroyBold,
        fontWeight: 400,
      },
      h4: {
        fontFamily: fonts.gilroyBold,
        fontWeight: 400,
      },
      h5: {
        fontFamily: fonts.gilroyBold,
        fontWeight: 400,
      },
      h6: {
        fontFamily: fonts.gilroyBold,
        fontWeight: 400,
      },
      paragraph: {
        fontFamily: fonts.proximaNova,
        fontSize: '14px',
      },
      body2: {
        fontFamily: fonts.proximaNovaBold,
        fontSize: '16px',
      },
      body1: {
        fontFamily: fonts.proximaNova,
      },
      colorPrimary: {
        color: colors.primary.black,
        '&[class*="cardHeader"]': {
          color: colors.primary.white,
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      rounded: {
        borderRadius: '20px',
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        paddingLeft: 22,
        paddingBottom: 19,
        '&:last-child': {
          paddingBottom: 19,
        },
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        fontFamily: fonts.proximaNova,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        lineHeight: '21px',
      },
      head: {
        '& .Mui-disabled': {
          backgroundColor: colors.primary.white,
        },
      },
    },
  },
  MuiStepConnector: {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          backgroundColor: colors.primary.white,
        },
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        borderRadius: '5px',
      },
    },
  },
};
