import { useCallback } from 'react';

import { CatalogApi } from '@backstage/catalog-client';

import { OnChangeFunc } from '../types';
import { serializeData } from '../utils/formUtils';
import { isEntityExist } from '../utils/validationUtils';

export const useUpdateValidations = (
  validate: { entityKinds: string[] } | undefined,
  onChange: OnChangeFunc,
  catalogApi: CatalogApi,
) => {
  return useCallback(
    (value: string) => {
      (async () => {
        if (validate) {
          const isNameTaken = await isEntityExist(
            value,
            validate.entityKinds,
            catalogApi,
          );
          onChange(
            serializeData({
              value,
              validation: {
                isNameTaken,
              },
            }),
          );
        }
      })();
    },
    [catalogApi, onChange, validate],
  );
};
