import { serviceBasicFactsHandler } from '../../handlers/service/serviceBasicFactsHandler';
import { TechInsightsRetrieverIDs } from '../../retrieverIDs';
import { ServiceAnnotationFact, TypedFactRetriever } from '../../types';

export const serviceBasicFactsRetriever: TypedFactRetriever<ServiceAnnotationFact> =
  {
    id: TechInsightsRetrieverIDs.SERVICE_BASIC_FACT_RETRIEVER,
    version: '0.0.1',
    title: 'Service Slack Channel',
    description:
      'Generates facts which if a slack channel is set for a service',
    entityFilter: [{ 'spec.type': ['service'] }],
    schema: {
      hasSlackChannel: {
        type: 'boolean',
        description: 'The metadata annotation slack.com/channel field is set',
      },
      hasSonarQubeProject: {
        type: 'boolean',
        description: 'Metadata annotation sonarqube.org/project-key is set',
      },
      hasPagerDutyIntegration: {
        type: 'boolean',
        description: 'Metadata annotation pagerduty.com/integration-key is set',
      },
      hasMonitoringDashboard: {
        type: 'boolean',
        description: 'Metadata annotation monitoring/dashboard is set',
      },
      hasOperationalStatusDefined: {
        type: 'boolean',
        description: 'The operational status of the service is defined',
      },
      hasOperationalTierDefined: {
        type: 'boolean',
        description: 'The operational tier of the service is defined',
      },
    },
    handler: serviceBasicFactsHandler,
  };
