import { WorkflowEdge, WorkflowNode } from '../types';

const getWorkflowNameFromEntity = (
  workflowNodes: WorkflowNode[],
  entityRef: string,
) => workflowNodes.find(node => node.entityRef === entityRef)?.id ?? entityRef;

export const generateEdges = (
  workflows: Array<{ workflowRef: string; dependsOn?: string[] }> | undefined,
  workflowNodes: WorkflowNode[],
): WorkflowEdge[] => {
  const edges: Set<{ from: string; to: string }> = new Set();

  workflows?.forEach(w => {
    w.dependsOn?.forEach(dep => {
      edges.add({
        from: getWorkflowNameFromEntity(workflowNodes, dep),
        to: getWorkflowNameFromEntity(workflowNodes, w.workflowRef),
      });
    });
  });

  return [...edges];
};
