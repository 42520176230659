import { CatalogApi } from '@backstage/catalog-client';
import { UserEntity } from '@backstage/catalog-model';

export const fetchUserName = async (
  userRef: string | undefined,
  catalogApi: CatalogApi,
) => {
  if (!userRef) return '';
  const entity = await catalogApi.getEntityByRef(userRef);
  if (entity) {
    return (
      (entity as UserEntity).spec?.profile?.displayName ??
      (entity as UserEntity).metadata.title
    );
  }
  return userRef;
};
