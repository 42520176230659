import React, { useCallback, useEffect } from 'react';
import useAsync from 'react-use/lib/useAsync';

import { EntityFilterQuery } from '@backstage/catalog-client';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

import { TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { RepoNamePickerState } from './types';
import { serializeGroupEntityName } from './utils';

export const EntityGroupPicker = (props: {
  rawErrors: string[];
  state: RepoNamePickerState;
  onChange: (state: RepoNamePickerState) => void;
}) => {
  const { rawErrors, state, onChange } = props;

  const { entityRef } = state;

  const catalogApi = useApi(catalogApiRef);

  const catalogFilter: EntityFilterQuery = {
    kind: 'Group',
  };
  const { value: entities, loading } = useAsync(() =>
    catalogApi.getEntities({ filter: catalogFilter }),
  );

  const entityRefs = entities?.items.map(e => serializeGroupEntityName(e));
  const onSelect = useCallback(
    (_: any, value: string | null) => {
      onChange({ entityRef: value ?? undefined, repoName: '' });
    },
    [onChange],
  );

  useEffect(() => {
    if (entityRefs?.length === 1) {
      onChange({ entityRef: entityRefs[0] ?? undefined });
    }
  }, [entityRefs, onChange]);

  return (
    <FormControl margin="normal" required error={rawErrors?.length > 0}>
      <Autocomplete
        disabled={entityRefs?.length === 1}
        value={entityRef || ''}
        loading={loading}
        onChange={onSelect}
        options={entityRefs || []}
        autoSelect
        renderInput={params => (
          <TextField
            {...params}
            label="Team"
            margin="dense"
            helperText="Please choose your team this repo will belong to"
            FormHelperTextProps={{ margin: 'dense', style: { marginLeft: 0 } }}
            variant="outlined"
            required
            InputProps={params.InputProps}
          />
        )}
      />
    </FormControl>
  );
};
