import { HomePagePipelineStatus } from '@internal/plugin-github-team-actions';
import { useCommonStyles } from '@internal/plugin-tomtom-theme-react';
import {
  HomePageRequestedReviewsCard,
  HomePageYourOpenPullRequestsCard,
} from '@roadiehq/backstage-plugin-github-pull-requests';
import React from 'react';

import { Grid } from '@material-ui/core';

import { useHomePageStyles } from './hooks/useHomePageStyles';

export const GHWidgets = () => {
  const classes = useHomePageStyles();
  const commonStyles = useCommonStyles();

  return (
    <Grid
      container
      className={classes.infoCardsContainer}
      justifyContent="center"
      spacing={4}
    >
      <Grid item xs={12} md={6}>
        <Grid
          item
          xs={12}
          className={`${commonStyles.tomtomButton} ${classes.prInfo} ${classes.prOpenMaxHeight}`}
        >
          <HomePageYourOpenPullRequestsCard />
        </Grid>
        <Grid
          item
          xs={12}
          className={`${commonStyles.paddingTop25} ${classes.pipelineStatusMaxHeight}`}
        >
          <HomePagePipelineStatus />
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        className={`${commonStyles.tomtomButton} ${classes.prInfo} ${classes.prRequestedMaxHeight}`}
      >
        <HomePageRequestedReviewsCard />
      </Grid>
    </Grid>
  );
};
