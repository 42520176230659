import {
  KaaPAccountEntity,
  KaaPTenantEntity,
  Tier,
} from '@internal/plugin-catalog-model';

import {
  PluginEndpointDiscovery,
  TokenManager,
} from '@backstage/backend-common';
import { CatalogClient } from '@backstage/catalog-client';
import {
  Entity,
  getCompoundEntityRef,
  parseEntityRef,
} from '@backstage/catalog-model';
import { LocationSpec } from '@backstage/plugin-catalog-common';
import {
  CatalogProcessorEmit,
  CatalogProcessorErrorResult,
  processingResult,
} from '@backstage/plugin-catalog-node';
import { JsonObject } from '@backstage/types';

import {
  CreateTenantRequest,
  KaapAPIClient,
} from '../../KaaPClient/KaaPAPIClient';
import {
  MessageBody,
  NotificationClient,
} from '../../clients/NotificationClient';
import { RELATION_HAS_TENANT, RELATION_IS_TENANT_OF } from '../../relations';
import { EntityStatus, STATUS_COMPLETED, STATUS_FAILED } from './types';

export function emitError(
  location: LocationSpec,
  message: string,
  emit: CatalogProcessorEmit,
) {
  const processingError: CatalogProcessorErrorResult = {
    type: 'error',
    error: {
      name: 'Error',
      message,
    },
    location,
  };
  emit(processingError);
}

export function utcDateTagTemplate(
  strings: TemplateStringsArray,
  ...params: string[]
) {
  const utcDateString = params[0];
  const dateAndTimeMatcher =
    /(?<date>^\d{4}-\d{2}-\d{2})T(?<time>\d{2}:\d{2}:\d{2})/;
  const { date, time } = utcDateString.match(dateAndTimeMatcher)?.groups ?? {
    date: '',
    time: '',
  };
  return `${date} - ${time}${strings.join('')}`;
}

export function emitRelationships(entity: Entity, emit: CatalogProcessorEmit) {
  const target = entity.spec?.account as string;
  const targetRef = parseEntityRef(target);
  const selfRef = getCompoundEntityRef(entity);

  emit(
    processingResult.relation({
      source: selfRef,
      type: RELATION_IS_TENANT_OF,
      target: {
        kind: targetRef.kind,
        namespace: targetRef.namespace,
        name: targetRef.name,
      },
    }),
  );
  emit(
    processingResult.relation({
      source: {
        kind: targetRef.kind,
        namespace: targetRef.namespace,
        name: targetRef.name,
      },
      type: RELATION_HAS_TENANT,
      target: selfRef,
    }),
  );
}

export async function attemptSendNotification(
  kaapAccount: KaaPAccountEntity,
  token: string,
  entityProcessingStatus: EntityStatus,
  discovery: PluginEndpointDiscovery,
  appBaseUrl: string,
) {
  if (kaapAccount?.spec.slackChannel) {
    sendNotification(
      token,
      discovery,
      kaapAccount?.spec.slackChannel,
      entityProcessingStatus.notificationBody as MessageBody,
      appBaseUrl,
    );
  }
}

export function isKaaPTenantEntity(entity: Entity): boolean {
  return (
    entity.kind === 'KaaPTenant' && entity.apiVersion === 'tomtom.com/v1alpha1'
  );
}

export function getMemberFromPath(membershipPath: string) {
  return membershipPath.split('/').slice(-1).pop() ?? 'no membership';
}

export async function getToken(tokenManager?: TokenManager) {
  return (await tokenManager?.getToken())?.token ?? '';
}

export async function createTenant(
  account: KaaPAccountEntity,
  entity: KaaPTenantEntity,
  orchAPIClient: KaapAPIClient,
  member: string,
  token: string,
) {
  const parsedCostCenter = Number(account.spec.costCenter);
  const costCenterCode = !isNaN(parsedCostCenter) ? parsedCostCenter : 100010;
  // this value does not exist in workday. Later when we have the new API, we can fix all tenants have the correct value https://wd3.myworkday.com/tomtom/d/search.htmld?q=100010&state=searchCategory-all%3Adefault
  const tenantValues: CreateTenantRequest = {
    name: entity.metadata.name,
    tier: (entity.spec?.tier as Tier) ?? '',
    region: entity.spec?.region,
    stage: getStageFromTier(entity.spec?.tier as Tier),
    subscriptionId: entity.spec?.azureSubscriptionId,
    argoAppOfApps: {
      repository: entity.spec?.argoAppOfApps?.repository ?? '',
      path: entity.spec?.argoAppOfApps?.path ?? '',
      targetRevision: '',
    },
    groupObjectId: account.spec?.securityGroupId,
    groupFriendlyName: `Cost Center ${account.spec?.owner}`, // Temp, type in Typescript is string but oAPI gets int. In the new API this value is not collected anymore
    slackChannel: account.spec?.slackChannel ?? 'NO_SLACK_CHANNEL',
    costCenterCode,
    tenantConfiguration: {
      namespaceQuota: entity.spec?.namespaceQuota,
    },
    tenantResourceQuota: {
      cpuLimit: (entity.spec?.resourceQuota as JsonObject)?.cpuLimit as number,
      cpuRequest: (entity.spec?.resourceQuota as JsonObject)
        ?.cpuRequest as number,
      memoryLimit: (entity.spec?.resourceQuota as JsonObject)
        ?.memoryLimit as number,
      memoryRequest: (entity.spec?.resourceQuota as JsonObject)
        ?.memoryRequest as number,
    },
    ...(member && { member }),
  };

  return orchAPIClient.createTenant(tenantValues, entity.spec.isEdit, token);
}

export function getStageFromTier(tier: Tier) {
  switch (tier) {
    case 'playground':
      return 'dev';
    case 'internal':
      return 'dev';
    case 'external':
      return 'prod';
    default:
      return 'dev';
  }
}

export async function getEntityFromEntityRef(
  entityRef: string,
  discovery: PluginEndpointDiscovery,
  token: string,
): Promise<Entity> {
  const catalog = new CatalogClient({ discoveryApi: discovery });

  const entity = await catalog.getEntityByRef(entityRef, { token });

  return Promise.resolve(entity as Entity);
}

export function isCompleted(status: string) {
  return status === STATUS_COMPLETED;
}

export function getFleetMemberFromTier(tier: Tier) {
  return tier === 'playground'
    ? 'kaap-westeurope-shared-03'
    : 'kaap-westeurope-shared-01';
}

export function processRequestStatus(requestStatus: any, logger?: any) {
  const CREATED_STATUS_CODE = 201;
  const UPDATED_STATUS_CODE = 200;
  let success = false;
  let content = '';

  if (logger) {
    logger.info(
      `Request status output: ${JSON.stringify(requestStatus.output)}`,
    );
  }
  if (Array.isArray(requestStatus.output)) {
    success =
      requestStatus.output[0].statusCode === CREATED_STATUS_CODE ||
      requestStatus.output[0].statusCode === UPDATED_STATUS_CODE;
    content = JSON.parse(requestStatus.output[0].content);
  } else {
    success = isCompleted(requestStatus.runtimeStatus.toLocaleUpperCase());
    content = requestStatus.output;
  }
  return {
    status:
      isCompleted(requestStatus.runtimeStatus.toLocaleUpperCase()) ||
      requestStatus.runtimeStatus.toLocaleUpperCase() === STATUS_FAILED,
    success,
    content,
  };
}

export async function sendNotification(
  token: string,
  discoveryApi: PluginEndpointDiscovery,
  channel: string,
  message: MessageBody,
  appBaseUrl: string,
) {
  const notificationClient = new NotificationClient(token, discoveryApi);
  notificationClient.sendNotification({
    message: toBlockMessage(message, appBaseUrl),
    channel,
    reactions:
      message.statusCode === 201 || message.statusCode === 200
        ? ['rocket']
        : ['alert'],
  });
}

export function toBlockMessage(messageBody: MessageBody, appBaseUrl: string) {
  let contextText = '';
  if (messageBody.action === 'create') {
    contextText = `The tenant *${messageBody.tenantName}* has been created successfully.`;
  } else {
    contextText = `The tenant *${messageBody.tenantName}* has been updated successfully.`;
  }
  if (!(messageBody.statusCode === 201 || messageBody.statusCode === 200)) {
    contextText = `The tenant *${messageBody.tenantName}* has failed to be ${messageBody.action}d.`;
  }
  const statusText =
    messageBody.statusCode === 201 || messageBody.statusCode === 200
      ? `:large_green_circle: Completed`
      : `:red_circle: Failed`;
  return [
    {
      type: 'header',
      text: {
        type: 'plain_text',
        text: `:rocket: ${
          messageBody.action === 'create'
            ? 'Create new Tenant'
            : 'Update existing Tenant'
        }`,
        emoji: true,
      },
    },
    {
      type: 'context',
      elements: [
        {
          type: 'mrkdwn',
          text: contextText,
        },
      ],
    },
    {
      type: 'divider',
    },
    {
      type: 'section',
      fields: [
        {
          type: 'mrkdwn',
          text: `*Tenant name:*\n${messageBody.tenantName}`,
        },
        {
          type: 'plain_text',
          text: `Status:\n ${statusText}`,
          emoji: true,
        },
      ],
    },
    {
      type: 'section',
      fields: [
        {
          type: 'mrkdwn',
          text: `*Creation request time:*\n${messageBody.createdTime}`,
        },
        ...(messageBody.statusCode === 201 || messageBody.statusCode === 200
          ? [
              {
                type: 'mrkdwn',
                text: `*URL*\n<${appBaseUrl}/catalog/default/kaaptenant/${messageBody.tenantName}|Entity URL>`,
              },
            ]
          : []),
      ],
    },
    ...(messageBody.statusCode === 201 || messageBody.statusCode === 200
      ? [
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: `*Kubeconfig:*\n \`\`\`\n${messageBody.kubeconfig}\n\`\`\``,
            },
          },
        ]
      : [
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: `*Error:*\n \`\`\`\n${messageBody.errorDetails}\n\`\`\``,
            },
          },
        ]),
  ];
}
