import { ClientSecretCredential } from '@azure/identity';
import {
  KaaPAccountEntity,
  KaaPTenantEntity,
} from '@internal/plugin-catalog-model';
import objectHash from 'object-hash';
import { Logger } from 'winston';

import {
  PluginEndpointDiscovery,
  TokenManager,
} from '@backstage/backend-common';
import { CacheService } from '@backstage/backend-plugin-api';
import { Entity } from '@backstage/catalog-model';
import { LocationSpec } from '@backstage/plugin-catalog-common';
import {
  CatalogProcessor,
  CatalogProcessorEmit,
  CatalogProcessorErrorResult,
} from '@backstage/plugin-catalog-node';

import {
  KaapAPIClient,
  OrchestrationAPIResponse,
  TenantInformation,
} from '../../KaaPClient/KaaPAPIClient';
import {
  EntityStatus,
  OptionsType,
  PROCESSOR_NAME,
  RequestStatus,
  STATUS_COMPLETED,
  STATUS_CREATING,
  STATUS_FAILED,
  STATUS_FETCHING,
} from './types';
import {
  attemptSendNotification,
  createTenant,
  emitRelationships,
  getEntityFromEntityRef,
  getFleetMemberFromTier,
  getToken,
  isKaaPTenantEntity,
  processRequestStatus,
  utcDateTagTemplate,
} from './utils';

enum AzureRegion {
  'WestEurope' = 'westeurope',
  'NorthEurope' = 'northeurope',
  'Southeast Asia' = 'southeastasia',
  'Korea' = 'korea',
  'Korea Central' = 'koreacentral',
  'Korea South' = 'koreasouth',
  'East Asia' = 'eastasia',
  'South India' = 'southindia',
  'West India' = 'westindia',
  'UK South' = 'uksouth',
  'UK West' = 'ukwest',
  'East US' = 'eastus',
  'West US' = 'westus',
  'South Central US' = 'southcentralus',
  'North Central US' = 'northcentralus',
  'West Central US' = 'westcentralus',
}

const validateEntitySpec = (entity: KaaPTenantEntity): boolean => {
  let isValidSpec = true;

  const {
    spec: {
      tier,
      azureSubscriptionId,
      region,
      namespaceQuota,
      resourceQuota,
      type,
    },
  } = entity;
  if (!tier || !['playground', 'internal', 'external'].includes(tier)) {
    isValidSpec = false;
  }

  if (!azureSubscriptionId) {
    isValidSpec = false;
  }

  if (!region || !Object.values(AzureRegion).includes(region as AzureRegion)) {
    isValidSpec = false;
  }

  if (isNaN(namespaceQuota) || namespaceQuota <= 0) {
    isValidSpec = false;
  }

  if (
    !resourceQuota ||
    isNaN(resourceQuota.cpuLimit) ||
    resourceQuota.cpuLimit <= 0 ||
    isNaN(resourceQuota.cpuRequest) ||
    resourceQuota.cpuRequest <= 0 ||
    isNaN(resourceQuota.memoryLimit) ||
    resourceQuota.memoryLimit <= 0 ||
    isNaN(resourceQuota.memoryRequest) ||
    resourceQuota.memoryRequest <= 0
  ) {
    isValidSpec = false;
  }

  if (!type || !['tenant'].includes(type)) {
    isValidSpec = false;
  }

  return isValidSpec;
};

export class KaaPTenantProcessor implements CatalogProcessor {
  private readonly logger?: Logger;
  private readonly config?: { [key: string]: any };
  private readonly appBaseUrl?: string;
  private readonly discovery?: PluginEndpointDiscovery;
  private readonly kaapUrl?: string;
  private readonly tokenManager?: TokenManager;
  private readonly cacheClient: CacheService;
  private orchAPIClient: KaapAPIClient = {} as KaapAPIClient;

  static buildKaaPTenantProcessor(options: OptionsType): KaaPTenantProcessor {
    return new KaaPTenantProcessor({
      ...options,
    });
  }

  constructor(options: OptionsType) {
    this.config = options.msProviderConfig;
    this.logger = options.logger;
    this.discovery = options.discovery;
    this.kaapUrl = options.kaapURL;
    this.tokenManager = options.tokenManager;
    this.appBaseUrl = options.appBaseUrl;
    this.cacheClient = options.cacheClient;
    this.orchAPIClient = new KaapAPIClient(
      this.kaapUrl!,
      this.logger as Logger,
    );
  }

  getProcessorName = (): string => PROCESSOR_NAME;

  async validateEntityKind(entity: Entity): Promise<boolean> {
    if (!isKaaPTenantEntity(entity)) {
      return false;
    }
    const kaaPTenantEntity = entity as KaaPTenantEntity;

    if (!kaaPTenantEntity.spec) {
      return false;
    }

    if (!validateEntitySpec(kaaPTenantEntity)) {
      return false;
    }

    return true;
  }

  async postProcessEntity(
    entity: KaaPTenantEntity,
    location: LocationSpec,
    emit: CatalogProcessorEmit,
  ): Promise<Entity> {
    if (isKaaPTenantEntity(entity)) {
      this.logger?.info(`Processing KaaPTenant entity ${entity.metadata.name}`);
      try {
        this.setupAnnotations(entity);

        const kaapAccount = await this.getAccount(entity);
        if (!kaapAccount) {
          this.logger?.warn(
            `Failed to find the related KaaP account for tenant ${entity.metadata.name}`,
          );
          const processingError = this.createAccountNotFoundError(
            entity,
            location,
          );
          emit(processingError);
          return entity;
        }

        emitRelationships(entity, emit);

        const cacheKey = this.getEntityCacheKey(entity);
        const cacheInfo = await this.getInfoFromCache(cacheKey);

        if (cacheInfo?.status === STATUS_COMPLETED) {
          if (this.isEntityEdited(entity, cacheInfo)) {
            this.logger?.info(
              `Tenant ${entity.metadata.name} has been edited by user. Proceeding to call oAPI`,
            );
            entity.spec.isEdit = true;
            return this.doBeginProcessing(cacheKey, entity, kaapAccount);
          }

          if (cacheInfo.createdAt) {
            const lastRefreshedAt = cacheInfo.createdAt;
            const currentTime = new Date().getTime();
            const timeElapsed = currentTime - lastRefreshedAt!;
            // 15 minutes, in case there are manual changes in the tenant
            const cacheTTL = 900000;
            if (timeElapsed >= cacheTTL) {
              this.logger?.info(`Refreshing tenant ${entity.metadata.name}...`);

              return this.doFetch(cacheKey, entity, cacheInfo);
            }
          }

          return this.doComplete(cacheKey, entity, cacheInfo);
        }

        if (!cacheInfo) {
          return this.doBeginProcessing(cacheKey, entity, kaapAccount);
        }

        this.logger?.info(
          `Processing tenant ${entity.metadata.name} with status ${cacheInfo.status}`,
        );

        if (cacheInfo.status === STATUS_CREATING) {
          if (cacheInfo.creationStatusURI) {
            return this.doCreate(cacheKey, entity, kaapAccount, cacheInfo);
          }
          return this.doBeginProcessing(cacheKey, entity, kaapAccount);
        }

        if (cacheInfo.status === STATUS_FETCHING) {
          if (cacheInfo.creationStatusURI) {
            return this.processFetchTenant(
              cacheKey,
              entity,
              kaapAccount,
              cacheInfo,
            );
          }
          return this.doBeginProcessing(cacheKey, entity, kaapAccount);
        }

        this.logger?.error(
          `Unable to process tenant ${entity.metadata.name}. Retrying...`,
        );
        this.storeInfoInCache(cacheKey, {});
        return entity;
      } catch (e) {
        this.logger?.error(
          `Error processing the KaaPTenant entity for tenant ${entity.metadata.name}. ${e}`,
        );
        throw new Error(`Error processing this tenant, ${e}`);
      }
    }
    return entity;
  }

  private setupAnnotations(entity: KaaPTenantEntity): void {
    entity.metadata.annotations = {
      ...entity.metadata.annotations,
      'tomtom.com/member': getFleetMemberFromTier(entity.spec.tier),
      'backstage.io/edit-url': '',
      'tomtom.com/kubeconfig': 'UNSET',
    };
  }

  private isEntityEdited(
    entity: KaaPTenantEntity,
    cacheInfo: EntityStatus,
  ): boolean {
    const {
      namespaceQuota,
      resourceQuota,
      argoAppOfApps,
      azureSubscriptionId,
    } = cacheInfo;

    if (namespaceQuota === undefined && !resourceQuota && !azureSubscriptionId)
      return false;

    if (namespaceQuota !== entity.spec.namespaceQuota) return true;

    if (azureSubscriptionId !== entity.spec.azureSubscriptionId) return true;

    if (
      resourceQuota?.cpuLimit !== entity.spec.resourceQuota.cpuLimit ||
      resourceQuota?.cpuRequest !== entity.spec.resourceQuota.cpuRequest ||
      resourceQuota?.memoryLimit !== entity.spec.resourceQuota.memoryLimit ||
      resourceQuota?.memoryRequest !== entity.spec.resourceQuota.memoryRequest
    )
      return true;

    if (argoAppOfApps?.repository !== entity.spec.argoAppOfApps?.repository)
      return true;

    if (argoAppOfApps?.path !== entity.spec.argoAppOfApps?.path) return true;

    if (
      argoAppOfApps?.targetRevision !==
      entity.spec.argoAppOfApps?.targetRevision
    )
      return true;

    return false;
  }

  private async processFetchTenant(
    cacheKey: string,
    entity: KaaPTenantEntity,
    kaapAccount: KaaPAccountEntity,
    cacheInfo: EntityStatus,
  ): Promise<KaaPTenantEntity> {
    const { orchStatus, creationStatus } = await this.checkRequestStatus(
      cacheInfo.creationStatusURI!,
    );

    // https://learn.microsoft.com/en-us/azure/azure-functions/durable/durable-functions-http-features?tabs=python#async-operation-tracking
    if (creationStatus.requestStatus === 202) {
      // Attempt to keep the kubeconfig from the previous status in case this is coming from edit flow
      this.setKubeconfig(entity, cacheInfo?.kubeconfig ?? 'UNSET');
      return entity;
    }

    if (orchStatus.success === true) {
      this.logger?.info(
        `Fetching tenant ${entity.metadata.name} details was successful. Proceeding to update entity in Frontstage`,
      );

      const kubeconfig = orchStatus.content.tenantMetadata.kubeconfig;
      this.setKubeconfig(entity, kubeconfig ?? 'UNSET');
      this.storeInfoInCache(cacheKey, {
        ...cacheInfo,
        status: STATUS_COMPLETED,
        createdAt: new Date().getTime(),
        kubeconfig: kubeconfig ?? 'UNSET',
        azureSubscriptionId: entity.spec.azureSubscriptionId,
        namespaceQuota: entity.spec.namespaceQuota,
        resourceQuota: entity.spec.resourceQuota,
        argoAppOfApps: entity.spec.argoAppOfApps,
      });
      return entity;
    }

    this.logger?.error(
      `Failed to fetch details for tenant ${entity.metadata.name} from oAPI`,
    );
    const entityStatus = { status: STATUS_FAILED };
    this.storeInfoInCache(cacheKey, entityStatus);

    this.notifyUser(kaapAccount, entityStatus);
    return entity;
  }

  private async doFetch(
    cacheKey: string,
    entity: KaaPTenantEntity,
    cacheInfo: EntityStatus,
  ): Promise<KaaPTenantEntity> {
    const res = await this.fetchTenant(entity);

    if (!res.statusQueryGetUri) {
      this.logger?.error(
        `No status URI for tenant ${
          entity.metadata.name
        } was received from oAPI. Details: ${JSON.stringify(res)}`,
      );

      this.storeInfoInCache(cacheKey, {
        status: STATUS_CREATING,
        kubeconfig: cacheInfo?.kubeconfig ?? 'UNSET',
      });
      return entity;
    }

    this.storeInfoInCache(cacheKey, {
      creationStatusURI: res.statusQueryGetUri,
      kubeconfig: cacheInfo?.kubeconfig ?? 'UNSET',
      status: STATUS_FETCHING,
    });
    return entity;
  }

  private async doCreate(
    cacheKey: string,
    entity: KaaPTenantEntity,
    kaapAccount: KaaPAccountEntity,
    cacheInfo: EntityStatus,
  ): Promise<KaaPTenantEntity> {
    const { orchStatus, creationStatus } = await this.checkRequestStatus(
      cacheInfo.creationStatusURI!,
    );

    // https://learn.microsoft.com/en-us/azure/azure-functions/durable/durable-functions-http-features?tabs=python#async-operation-tracking
    if (creationStatus.requestStatus === 202) {
      // Attempt to keep the kubeconfig from the previous status in case this is coming from edit flow
      this.setKubeconfig(entity, cacheInfo?.kubeconfig ?? 'UNSET');
      return entity;
    }

    if (orchStatus.success === true) {
      this.logger?.info(
        `Tenant ${entity.metadata.name}: creation/update was successful. Proceeding to update entity in Frontstage`,
      );
      this.setKubeconfig(entity, orchStatus.content.kubeconfig ?? 'UNSET');

      this.storeInfoInCache(cacheKey, {
        status: STATUS_COMPLETED,
        createdAt: new Date().getTime(),
        azureSubscriptionId: entity.spec.azureSubscriptionId,
        namespaceQuota: entity.spec.namespaceQuota,
        resourceQuota: entity.spec.resourceQuota,
        argoAppOfApps: entity.spec.argoAppOfApps,
      });
      const kubeconfig = orchStatus.content.kubeconfig;
      this.notifyUser(kaapAccount, {
        ...cacheInfo,
        status: STATUS_COMPLETED,
        kubeconfig: atob(kubeconfig),
        notificationBody: {
          action: entity.spec.isEdit ? 'Updated' : 'Created',
          tenantName: entity.metadata.name,
          statusCode: 200,
          kubeconfig: atob(kubeconfig),
          createdTime: utcDateTagTemplate`${
            creationStatus.createdTime ?? ''
          } *UTC Time`,
        },
      });

      return entity;
    }

    if (this.isTenantProvisioned(orchStatus)) {
      this.logger?.info(
        `Tenant ${entity.metadata.name} already exists. Proceeding to fetch its details`,
      );
      return this.doFetch(cacheKey, entity, cacheInfo);
    }

    this.logger?.error(
      `Failed to create/update tenant ${
        entity.metadata.name
      }. Details: ${JSON.stringify(orchStatus)}`,
    );
    // To schedule it for refresh in 15 minutes
    cacheInfo.createdAt = new Date().getTime();
    return this.doComplete(cacheKey, entity, cacheInfo);
  }

  private async doBeginProcessing(
    cacheKey: string,
    entity: KaaPTenantEntity,
    kaapAccount: KaaPAccountEntity,
  ): Promise<KaaPTenantEntity> {
    const res = await this.createTenant(entity, kaapAccount);

    if (!res.statusQueryGetUri) {
      this.logger?.error(
        `No status URI for tenant ${
          entity.metadata.name
        } was received from oAPI. Details: ${JSON.stringify(res)}`,
      );

      this.storeInfoInCache(cacheKey, {
        status: STATUS_CREATING,
        kubeconfig: 'UNSET',
      });
      return entity;
    }

    this.storeInfoInCache(cacheKey, {
      creationStatusURI: res.statusQueryGetUri,
      status: STATUS_CREATING,
    });

    return entity;
  }

  private doComplete(
    cacheKey: string,
    entity: KaaPTenantEntity,
    cacheInfo: EntityStatus,
  ): KaaPTenantEntity {
    this.logger?.info(
      `Tenant ${entity.metadata.name} has already been processed. Skipping...`,
    );

    this.setKubeconfig(entity, cacheInfo.kubeconfig ?? 'UNSET');
    entity.spec.isEdit = false;
    this.storeInfoInCache(cacheKey, {
      ...cacheInfo,
      status: STATUS_COMPLETED,
    });
    return entity;
  }

  private getAzureToken = async (): Promise<string> => {
    const azureTenantId = this.config?.tenantId;
    const clientId = this.config?.clientId;
    const clientSecret = this.config?.clientSecret;

    const client = new ClientSecretCredential(
      azureTenantId,
      clientId,
      clientSecret,
    );
    const { token } = await client.getToken(`${this.kaapUrl}/.default`);
    return token;
  };

  private getAccount = async (
    entity: KaaPTenantEntity,
  ): Promise<KaaPAccountEntity> => {
    const token = await getToken(this.tokenManager);
    return (await getEntityFromEntityRef(
      entity.spec.account,
      this.discovery as PluginEndpointDiscovery,
      token,
    )) as KaaPAccountEntity;
  };

  private createAccountNotFoundError = (
    entity: KaaPTenantEntity,
    location: LocationSpec,
  ): CatalogProcessorErrorResult => {
    this.logger?.error(
      `Failed to create/update tenant ${entity.metadata.name}. Can not find the related KaaP account.`,
    );
    return {
      type: 'error',
      error: {
        name: 'Error',
        message:
          "Failed to create/update tenant. Can't find the related KaaP account.",
      },
      location: location,
    };
  };

  private getEntityCacheKey = (entity: KaaPTenantEntity): string => {
    return `kaaptenant_new:${entity.metadata.namespace}/${
      entity.metadata.name
    }:processing-status:${objectHash(entity)}`;
  };

  private getInfoFromCache = async (
    entityKey: string,
  ): Promise<EntityStatus | undefined> => {
    return this.cacheClient.get<EntityStatus>(entityKey);
  };

  private storeInfoInCache = async (
    entityKey: string,
    info: EntityStatus,
  ): Promise<void> => {
    await this.cacheClient.set(entityKey, info);
  };

  private createTenant = async (
    entity: KaaPTenantEntity,
    kaapAccount: KaaPAccountEntity,
  ): Promise<OrchestrationAPIResponse> => {
    const entityMember =
      entity.spec?.kaapMember ?? getFleetMemberFromTier(entity.spec.tier);

    const token = await this.getAzureToken();

    return createTenant(
      kaapAccount,
      entity,
      this.orchAPIClient,
      entityMember,
      token,
    );
  };

  private fetchTenant = async (
    entity: KaaPTenantEntity,
  ): Promise<OrchestrationAPIResponse> => {
    const tier = entity.spec.tier;
    const member = getFleetMemberFromTier(tier);
    const name = entity.metadata.name;

    const token = await this.getAzureToken();
    return this.orchAPIClient.getTenant(tier, member, name, token);
  };

  private checkRequestStatus = async (
    statusUri: string,
  ): Promise<{
    orchStatus: RequestStatus;
    creationStatus: TenantInformation;
  }> => {
    const token = await this.getAzureToken();
    const info = await this.orchAPIClient.getTenantInformation(
      statusUri,
      token,
    );
    const orchStatus = processRequestStatus(info, this.logger);
    return { orchStatus, creationStatus: info };
  };

  private setKubeconfig = (
    entity: KaaPTenantEntity,
    kubeconfig: string,
  ): void => {
    entity.metadata.annotations = {
      ...entity.metadata.annotations,
      'tomtom.com/kubeconfig': kubeconfig,
    };
  };

  private notifyUser = async (
    kaapAccount: KaaPAccountEntity,
    entityProcessingStatus: EntityStatus,
  ) => {
    const token = await getToken(this.tokenManager);
    attemptSendNotification(
      kaapAccount,
      token,
      entityProcessingStatus,
      this.discovery as PluginEndpointDiscovery,
      this.appBaseUrl as string,
    );
  };

  private isTenantProvisioned = (completionStatus: RequestStatus): boolean => {
    const errorDetails = completionStatus.content
      ? completionStatus.content.detail.message ?? ''
      : 'Not specified';

    if (errorDetails.includes('already exists')) return true;
    return false;
  };
}
