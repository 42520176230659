import { GroupEntity } from '@internal/plugin-catalog-model';
import React from 'react';

import { Avatar } from '@backstage/core-components';
import { useRouteRef, useRouteRefParams } from '@backstage/core-plugin-api';
import { entityRouteRef } from '@backstage/plugin-catalog-react';

import { Box, Link, Typography } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';

import { GroupProfileTitleIcons } from './GroupProfileTitleIcons';

export const GroupProfileTitle = (props: {
  title: string;
  picture: string | undefined;
  group: GroupEntity;
}) => {
  const currentRouteParams = useRouteRefParams(entityRouteRef);

  const entityRoute = useRouteRef(entityRouteRef);
  const routeParams = {
    kind: props.group.kind,
    namespace: props.group.metadata.namespace ?? 'default',
    name: props.group.metadata.name,
  };

  return (
    <Box display="flex" alignItems="center">
      {props.picture ? (
        <Avatar displayName={props.title} picture={props.picture} />
      ) : (
        <GroupIcon data-testid="profile-group-icon" fontSize="inherit" />
      )}
      <Box ml={1} data-testid="profile-title">
        {routeParams.name !== currentRouteParams?.name ? (
          <Typography variant="h5">
            <Link
              data-testid="profile-title-link"
              href={entityRoute(routeParams)}
            >
              {props.title}
            </Link>
          </Typography>
        ) : (
          <Typography variant="h5" data-testid="profile-title-non-link">
            {props.title}
          </Typography>
        )}
      </Box>
      <GroupProfileTitleIcons group={props.group} />
    </Box>
  );
};
