import React, { PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';

import { FeatureFlagged } from '@backstage/core-app-api';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  useSidebarOpenState,
} from '@backstage/core-components';
import { MyGroupsSidebarItem } from '@backstage/plugin-org';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';

import { Link, makeStyles } from '@material-ui/core';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import EmojiObjects from '@material-ui/icons/EmojiObjects';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ExtensionIcon from '@material-ui/icons/Extension';
import HomeIcon from '@material-ui/icons/Home';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import ListIcon from '@material-ui/icons/List';
import MenuIcon from '@material-ui/icons/Menu';
import GroupIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import PieChart from '@material-ui/icons/PieChart';
import SearchIcon from '@material-ui/icons/Search';
import Timelapse from '@material-ui/icons/Timelapse';

import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import { UserSidebarItem } from './UserSidebarItem';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
  svg: {
    width: '125px',
    height: '24px',
  },
});

const SidebarLogo = () => {
  const { root, link, svg } = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={root}>
      <Link
        component={NavLink}
        to="/"
        underline="none"
        className={link}
        aria-label="Home"
      >
        {isOpen ? <LogoFull cssClasses={{ svg }} /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => {
  return (
    <SidebarPage>
      <Sidebar>
        <SidebarLogo />
        <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
          <SidebarSearchModal />
        </SidebarGroup>

        <SidebarDivider />
        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          <SidebarItem icon={HomeIcon} to="/" text="Home" />
          <SidebarItem icon={ExtensionIcon} to="services" text="Services" />
          <MyGroupsSidebarItem
            singularTitle="My Team"
            pluralTitle="My Teams"
            icon={GroupIcon}
          />
          <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
          <SidebarItem icon={ExtensionIcon} to="kaap" text="KaaP" />
          <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
          <SidebarItem
            icon={CreateComponentIcon}
            to="create?filters[kind]=template&filters[user]=all"
            title="Create..."
            text="Create"
          />
        </SidebarGroup>

        <SidebarSpace />
        <SidebarDivider />
        <SidebarGroup label="Others" icon={<PieChart />}>
          <FeatureFlagged with="show-tech-insights-sidebar">
            <SidebarItem
              icon={EqualizerIcon}
              to="tech-insights"
              text="Tech Insights"
            />
          </FeatureFlagged>
          <FeatureFlagged with="show-time-saver-tab">
            <SidebarItem icon={Timelapse} to="time-saver" text="Time Saver" />
          </FeatureFlagged>
          <SidebarItem
            icon={EmojiObjects}
            to="tomtom-lab"
            text="What The Hack"
          />
          <SidebarItem icon={ListIcon} to="catalog-list" text="Catalog" />
        </SidebarGroup>

        <SidebarDivider />
        <SidebarGroup
          label="Settings"
          icon={<UserSettingsSignInAvatar />}
          to="/settings"
        >
          <UserSidebarItem icon={PersonIcon} />
          <SidebarSettings />
        </SidebarGroup>
      </Sidebar>
      {children}
    </SidebarPage>
  );
};
