import { useDebounce } from 'react-use';

import { useApi } from '@backstage/core-plugin-api';
import { scmAuthApiRef } from '@backstage/integration-react';
import { useTemplateSecrets } from '@backstage/plugin-scaffolder-react';

type UseRequestGitHubUserCredentialsOptions = {
  host: UseRequestGitHubUserCredentialsParam;
  owner: UseRequestGitHubUserCredentialsParam;
  repoName: UseRequestGitHubUserCredentialsParam;
  secretsKey: UseRequestGitHubUserCredentialsParam;
  additionalScopes: { github: string[] };
} | null;

type UseRequestGitHubUserCredentialsParam = string | null;

export const useRequestGitHubUserCredentials = (
  options: UseRequestGitHubUserCredentialsOptions,
) => {
  const scmAuthApi = useApi(scmAuthApiRef);
  const { setSecrets } = useTemplateSecrets();
  const { host, owner, repoName, secretsKey, additionalScopes } = options ?? {};

  useDebounce(
    async () => {
      if (!(host && owner && repoName) || !secretsKey || !setSecrets) {
        return;
      }

      const [encodedHost, encodedWorkspace, encodedRepoName] = [
        host,
        owner,
        repoName,
      ].map(encodeURIComponent);

      // user has requested that we use the users credentials
      // so lets grab them using the scmAuthApi and pass through
      // any additional scopes from the ui:options
      const { token } = await scmAuthApi.getCredentials({
        url: `https://${encodedHost}/${encodedWorkspace}/${encodedRepoName}`,
        additionalScope: {
          repoWrite: true,
          customScopes: additionalScopes,
        },
      });

      // set the secret using the key provided in the ui:options for use
      // in the templating the manifest with ${{ secrets[secretsKey] }}
      setSecrets({ [secretsKey]: token });
    },
    500,
    [host, owner, repoName],
  );
};
