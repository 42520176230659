import { WatchtowerReader } from '../../WatchtowerReader';
import { createReposHasdependabotPRsFactHandler } from '../../handlers/github';
import { TechInsightsRetrieverIDs } from '../../retrieverIDs';
import { DependabotPRsFact, TypedFactRetriever } from '../../types';

export const createRepoHasDependabotPRsFactRetriever = (
  reader: WatchtowerReader,
): TypedFactRetriever<DependabotPRsFact> => ({
  id: TechInsightsRetrieverIDs.GITHUB_REPOSITORY_HAS_DEPENDABOT_PRS_FACT_RETRIEVER,
  version: '0.0.1',
  title: 'Service Open dependabot PRs',
  description:
    'Get number of PRs opened by dependabot in a Repo that are still open',
  entityFilter: [
    {
      kind: 'Component',
      'spec.type': 'service',
    },
  ],
  schema: {
    open_dependabot_prs: {
      type: 'integer',
      description:
        'Number of PRs opened by dependabot in the repo associated with this entity',
    },
  },
  handler: createReposHasdependabotPRsFactHandler(reader),
});
