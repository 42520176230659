import {
  Entity,
  RELATION_HAS_PART,
  RELATION_OWNED_BY,
  RELATION_OWNER_OF,
  RELATION_PART_OF,
  getCompoundEntityRef,
  parseEntityRef,
} from '@backstage/catalog-model';
import {
  CatalogProcessorEmit,
  processingResult,
} from '@backstage/plugin-catalog-node';

export function emitOwnerRelations(emit: CatalogProcessorEmit, entity: Entity) {
  const selfRef = getCompoundEntityRef(entity);
  const targetRef = (entity.spec?.owner as string)
    .toLowerCase()
    .includes('group:')
    ? parseEntityRef(entity.spec?.owner as string)
    : parseEntityRef(
        {
          namespace: selfRef.namespace,
          name: entity.spec?.owner as string,
        },
        {
          defaultKind: 'Group',
          defaultNamespace: selfRef.namespace,
        },
      );
  emit(
    processingResult.relation({
      source: selfRef,
      type: RELATION_OWNED_BY,
      target: {
        kind: targetRef.kind,
        namespace: targetRef.namespace,
        name: targetRef.name,
      },
    }),
  );

  emit(
    processingResult.relation({
      source: targetRef,
      type: RELATION_OWNER_OF,
      target: {
        kind: selfRef.kind,
        namespace: selfRef.namespace,
        name: selfRef.name,
      },
    }),
  );
}

export function emitSystemRelations(
  emit: CatalogProcessorEmit,
  entity: Entity,
) {
  const selfRef = getCompoundEntityRef(entity);
  const targetRef = (entity.spec?.system as string)
    .toLowerCase()
    .includes('system:')
    ? parseEntityRef(entity.spec?.system as string)
    : parseEntityRef(
        {
          namespace: selfRef.namespace,
          name: entity.spec?.system as string,
        },
        {
          defaultKind: 'System',
          defaultNamespace: selfRef.namespace,
        },
      );

  emit(
    processingResult.relation({
      source: selfRef,
      type: RELATION_PART_OF,
      target: {
        kind: targetRef.kind,
        namespace: targetRef.namespace,
        name: targetRef.name,
      },
    }),
  );

  emit(
    processingResult.relation({
      source: targetRef,
      type: RELATION_HAS_PART,
      target: {
        kind: selfRef.kind,
        namespace: selfRef.namespace,
        name: selfRef.name,
      },
    }),
  );
}
