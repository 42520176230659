import React from 'react';

import { FormControl, MenuItem, Select } from '@material-ui/core';

import { useStyles } from '../../hooks/useStyles';
import { Event } from '../../types';

type Props = {
  events: Event[];
  selectedEventId: number;
  onChange: (e: any) => void;
};

export const IdeaEventSelector = ({
  events,
  selectedEventId,
  onChange,
}: Props) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl}>
      <Select
        className={classes.select}
        onChange={onChange}
        value={selectedEventId}
      >
        <MenuItem key="all" value={0}>
          All events
        </MenuItem>
        {events?.map(event => (
          <MenuItem key={event.id} value={event.id}>
            {event.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
