import { JSON_RULE_ENGINE_CHECK_TYPE } from '@backstage-community/plugin-tech-insights-backend-module-jsonfc';

import { TechInsightsCheckID } from '../../checkIDs';
import { TechInsightsRetrieverIDs } from '../../retrieverIDs';

export const repoUsesGitHubActionsCheck = {
  id: TechInsightsCheckID.REPO_USES_GITHUB_ACTIONS_CHECK,
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: 'Repo Uses GitHub Actions Check',
  description: 'Verifies that the repo uses GitHub Actions',
  factIds: [TechInsightsRetrieverIDs.GITHUB_ACTIONS_FACT_RETRIEVER],
  rule: {
    conditions: {
      all: [
        {
          fact: 'isGitHubActionsUsed',
          operator: 'equal',
          value: true,
        },
      ],
    },
  },
};
