import React from 'react';

import { Box, LinearProgress, makeStyles, Typography } from '@material-ui/core';

const commonProgressStyle = {
  backgroundColor: '#BFBFBF',
  borderRadius: 4,
  height: 6,
};
const useStyles = makeStyles({
  high: {
    ...commonProgressStyle,
    '& div': {
      backgroundColor: '#39B398',
    },
  },
  medium: {
    ...commonProgressStyle,
    '& div': {
      backgroundColor: '#FDA16B',
    },
  },
  low: {
    ...commonProgressStyle,
    '& div': {
      backgroundColor: '#FF6262',
    },
  },
});

export const ScoreCell = ({
  renderedCellValue,
}: {
  renderedCellValue: any;
}) => {
  const classes = useStyles();
  const getClassForValue = (value: number) => {
    if (value >= 90) {
      return classes.high;
    } else if (value >= 75) {
      return classes.medium;
    }
    return classes.low;
  };
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant="determinate"
          value={renderedCellValue}
          className={getClassForValue(renderedCellValue)}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body1">{`${Math.round(
          renderedCellValue,
        )}%`}</Typography>
      </Box>
    </Box>
  );
};
