/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React, { ChangeEvent, useState } from 'react';

import {
  Box,
  makeStyles,
  TablePagination,
  GridSize,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  Paper,
  Theme,
  Dialog,
} from '@material-ui/core';

import { Idea } from '../../types';
import {
  getSharedIdeaId,
  isSharedIdeaLink,
  redirectToIdeasUrl,
} from '../../util/parseMethods';
import { IdeaDetailsDialog } from '../HomePageBazaarInfoCard';
import { IdeaRowCard } from '../IdeaRowCard/IdeaRowCard';

type Props = {
  ideas: Idea[];
  fetchTomtomLabProjects: () => Promise<Idea[]>;
  useTablePagination?: boolean;
  gridSize?: GridSize;
  height: 'large' | 'small';
};

const useStyles = makeStyles<Theme>(theme => ({
  content: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  empty: {
    height: '10rem',
    textAlign: 'center',
    verticalAlign: 'middle',
    lineHeight: '10rem',
  },
  pagination: {
    marginTop: '1rem',
    marginLeft: 'auto',
    marginRight: '0',
  },
  root: {
    width: '100%',
    margin: theme.spacing(2),
  },
  table: {
    padding: theme.spacing(1),
  },
  tableRow: {
    padding: '10px',
  },
}));

const getCurrentPageIdeas = (
  totalIdeas: Array<Idea>,
  page: number,
  rows: number,
) => totalIdeas.slice((page - 1) * rows, rows * page);

const sharedIdea = (ideas: Array<Idea>) =>
  ideas.find(i => i.id === Number(getSharedIdeaId()));

export const IdeaPreview = ({
  ideas,
  fetchTomtomLabProjects,
  useTablePagination = true,
  height = 'large',
}: Props) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(12);
  const [openSharedIdeaDialog, setOpenSharedIdeaDialog] = useState(
    isSharedIdeaLink(),
  );

  const handlePageChange = (_: any, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleRowChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRows(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleClose = () => {
    setOpenSharedIdeaDialog(!openSharedIdeaDialog);
    fetchTomtomLabProjects();
    redirectToIdeasUrl();
  };

  if (!ideas.length) {
    return <div className={classes.empty}>Register your first idea</div>;
  }

  return (
    <Box className={classes.content}>
      <TableContainer component={Paper} className={classes.table}>
        <Table>
          <TableBody>
            {getCurrentPageIdeas(ideas, page, rows).map((idea: Idea) => {
              const { id } = idea;
              return (
                <TableRow
                  className={classes.tableRow}
                  key={id}
                  component={() => (
                    <IdeaRowCard
                      project={idea}
                      fetchTomtomLabProjects={fetchTomtomLabProjects}
                      height={height}
                    />
                  )}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {useTablePagination && (
        <TablePagination
          component="div"
          className={classes.pagination}
          rowsPerPageOptions={[12, 24, 48, 96]}
          count={ideas?.length}
          page={page - 1}
          onPageChange={handlePageChange}
          rowsPerPage={rows}
          onRowsPerPageChange={handleRowChange}
          backIconButtonProps={{ disabled: page === 1 }}
          nextIconButtonProps={{
            disabled: rows * page >= ideas.length,
          }}
        />
      )}

      {sharedIdea(ideas) && (
        <Dialog
          fullWidth
          maxWidth="lg"
          onClose={handleClose}
          open={openSharedIdeaDialog}
        >
          <IdeaDetailsDialog
            initIdea={sharedIdea(ideas)!}
            handleClose={handleClose}
          />
        </Dialog>
      )}
    </Box>
  );
};
