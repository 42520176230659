import { InteractiveBrowserCredential } from '@azure/identity';

import { configApiRef, useApi } from '@backstage/core-plugin-api';

const TENANT_ID_CONFIG_KEY = 'app.azureResources.tenantId';
const CLIENT_ID_CONFIG_KEY = 'app.azureResources.clientId';

export const useInteractiveBrowserCredential =
  (): InteractiveBrowserCredential => {
    const config = useApi(configApiRef);
    const tenantId = config.getString(TENANT_ID_CONFIG_KEY);
    const clientId = config.getString(CLIENT_ID_CONFIG_KEY);

    return new InteractiveBrowserCredential({
      tenantId,
      clientId,
      loginStyle: 'popup',
    });
  };
