import { useInteractiveBrowserCredential } from '@internal/plugin-azure-resources-common-react';
import { useDebounce } from 'react-use';

import { useTemplateSecrets } from '@backstage/plugin-scaffolder-react';

import { AzureLoginProps, DEFAULT_SECRET_KEY } from './AzureLoginSchema';

export const AzureLogin = ({ uiSchema }: AzureLoginProps) => {
  const { setSecrets } = useTemplateSecrets();
  const credentials = useInteractiveBrowserCredential();

  const { requestUserCredentials } = uiSchema?.['ui:options'] ?? {};
  useDebounce(async () => {
    const secretsKey = requestUserCredentials?.secretsKey ?? DEFAULT_SECRET_KEY;
    const additionalScopes = requestUserCredentials?.additionalScopes ?? [];

    const accessToken = await credentials.getToken(additionalScopes);
    setSecrets({ [secretsKey]: accessToken.token });
  });

  return null;
};
