import {
  RadarRing,
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage-community/plugin-tech-radar';

import { getData } from './companyData';

export class TechRadarClient implements TechRadarApi {
  private readonly rings: RadarRing[] = [
    { id: 'adopt', name: 'ADOPT', color: '#93c47d' },
    { id: 'trial', name: 'TRIAL', color: '#93d2c2' },
    { id: 'assess', name: 'ASSESS', color: '#fbdb84' },
    { id: 'hold', name: 'HOLD', color: '#efafa9' },
  ];

  async load(): Promise<TechRadarLoaderResponse> {
    return getData(this.rings);
  }
}
