import {
  AzureManagedClusterDaemonSetResult,
  AzureManagedClusterDeploymentResult,
  AzureManagedClusterIngressResult,
  AzureManagedClusterNamespaceResult,
  AzureManagedClusterPodResult,
  AzureManagedClusterReplicaSetResult,
  AzureManagedClusterSecretResult,
  AzureManagedClusterServiceResult,
  ResourceType,
} from '../types';

const parseNamespaceCommandResultLogs = (logs: string | undefined) => {
  const results: AzureManagedClusterNamespaceResult[] = [];
  const numberCol = 3;
  if (logs) {
    const namespaces: string[] = logs.split('\n').splice(1);
    namespaces.forEach((n: string, i: number) => {
      const log: string[] = n.replace(/\s\s+/g, ' ').split(' '); // replace multiple spaces with one space
      if (log.length === numberCol) {
        results.push({
          id: i,
          name: log[0],
          status: log[1],
          age: log[2],
        });
      }
    });
  }

  return results;
};

const parseServiceCommandResultLogs = (logs: string | undefined) => {
  const results: AzureManagedClusterServiceResult[] = [];
  const numberCol = 7;

  if (logs) {
    const array: string[] = logs.split('\n').splice(1); // remove header
    array.forEach((n: string, i: number) => {
      const log: string[] = n
        .replace(/\s\s+/g, ' ')
        .replace(', ', ',') // port has ', ' and should be kept as one field
        .split(' ');
      if (log.length === numberCol) {
        results.push({
          id: i,
          namespace: log[0],
          name: log[1],
          type: log[2],
          clusterIP: log[3],
          externalIP: log[4],
          port: log[5],
          age: log[6],
        });
      }
    });
  }
  return results;
};

const parseIngressCommandResultLogs = (logs: string | undefined) => {
  const results: AzureManagedClusterIngressResult[] = [];
  const numberCol = 7;

  if (logs) {
    const array: string[] = logs.split('\n').splice(1); // remove header
    array.forEach((n: string, i: number) => {
      const log: string[] = n
        .replace(/\s\s+/g, ' ')
        .replace(', ', ',') // port has ', ' and should be kept as one field
        .split(' ');
      if (log.length === numberCol) {
        results.push({
          id: i,
          namespace: log[0],
          name: log[1],
          class: log[2],
          hosts: log[3],
          address: log[4],
          port: log[5],
          age: log[6],
        });
      }
    });
  }

  return results;
};

const parseDeploymentCommandResultLogs = (logs: string | undefined) => {
  const results: AzureManagedClusterDeploymentResult[] = [];
  const numberCol = 6;
  if (logs) {
    const array: string[] = logs.split('\n').splice(1); // remove header
    array.forEach((n: string, i: number) => {
      const log: string[] = n.replace(/\s\s+/g, ' ').split(' '); // replace multiple spaces with one space
      if (log.length === numberCol) {
        results.push({
          id: i,
          namespace: log[0],
          name: log[1],
          ready: log[2],
          upToDate: log[3],
          available: log[4],
          age: log[5],
        });
      }
    });
  }
  return results;
};

const parsePodCommandResultLogs = (logs: string | undefined) => {
  const results: AzureManagedClusterPodResult[] = [];
  const numberCol = 6;

  if (logs) {
    const array: string[] = logs.split('\n').splice(1); // remove header
    array.forEach((n: string, i: number) => {
      const log: string[] = n.replace(/\s\s+/g, ' ').split(' '); // replace multiple spaces with one space
      if (log.length === numberCol) {
        results.push({
          id: i,
          namespace: log[0],
          name: log[1],
          ready: log[2],
          status: log[3],
          restarts: log[4],
          age: log[5],
        });
      }
    });
  }
  return results;
};

const parseReplicaSetCommandResultLogs = (logs: string | undefined) => {
  const results: AzureManagedClusterReplicaSetResult[] = [];
  const numberCol = 6;

  if (logs) {
    const array: string[] = logs.split('\n').splice(1); // remove header
    array.forEach((n: string, i: number) => {
      const log: string[] = n.replace(/\s\s+/g, ' ').split(' '); // replace multiple spaces with one space
      if (log.length === numberCol) {
        results.push({
          id: i,
          namespace: log[0],
          name: log[1],
          desired: log[2],
          current: log[3],
          ready: log[4],
          age: log[5],
        });
      }
    });
  }
  return results;
};

const parseDaemonSetCommandResultLogs = (logs: string | undefined) => {
  const results: AzureManagedClusterDaemonSetResult[] = [];
  const numberCol = 9;

  if (logs) {
    const array: string[] = logs.split('\n').splice(1); // remove header
    array.forEach((n: string, i: number) => {
      const log: string[] = n.replace(/\s\s+/g, ' ').split(' '); // replace multiple spaces with one space
      if (log.length === numberCol) {
        results.push({
          id: i,
          namespace: log[0],
          name: log[1],
          desired: log[2],
          current: log[3],
          ready: log[4],
          upToDate: log[5],
          available: log[6],
          nodeSelector: log[7],
          age: log[8],
        });
      }
    });
  }
  return results;
};

const parseSecretCommandResultLogs = (logs: string | undefined) => {
  const results: AzureManagedClusterSecretResult[] = [];
  const numberCol = 5;

  if (logs) {
    const array: string[] = logs.split('\n').splice(1); // remove header
    array.forEach((n: string, i: number) => {
      const log: string[] = n.replace(/\s\s+/g, ' ').split(' '); // replace multiple spaces with one space
      if (log.length === numberCol) {
        results.push({
          id: i,
          namespace: log[0],
          name: log[1],
          type: log[2],
          data: log[3],
          age: log[4],
        });
      }
    });
  }
  return results;
};

export const parseCommandResultLogs = (
  resourceType: ResourceType,
  result: string | undefined,
): Array<Record<string, any>> => {
  if (!result) {
    return [];
  }

  let data: Array<Record<string, any>> = [];

  switch (ResourceType[resourceType as unknown as keyof typeof ResourceType]) {
    case ResourceType.NAMESPACES:
      data = parseNamespaceCommandResultLogs(result);
      break;

    case ResourceType.SERVICES:
      data = parseServiceCommandResultLogs(result);
      break;

    case ResourceType.INGRESSES:
      data = parseIngressCommandResultLogs(result);
      break;

    case ResourceType.DEPLOYMENTS:
      data = parseDeploymentCommandResultLogs(result);
      break;

    case ResourceType.PODS:
      data = parsePodCommandResultLogs(result);
      break;

    case ResourceType.REPLICASETS:
      data = parseReplicaSetCommandResultLogs(result);
      break;

    case ResourceType.DAEMONSETS:
      data = parseDaemonSetCommandResultLogs(result);
      break;

    case ResourceType.SECRETS:
      data = parseSecretCommandResultLogs(result);
      break;

    default:
      break;
  }

  return data;
};
