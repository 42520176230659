import { useAzureOwnGroups } from '@internal/plugin-azure-resources-common-react';
import React, { useCallback, useEffect, useState } from 'react';

import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

import { FormControl, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { wellKnownGroups } from './wellKnownGroups';

export type AzSecurityGroupPickerUiOptions = {
  allowedValues?: string[];
};

export const AzSecurityGroupPickerExtension = (
  props: FieldExtensionComponentProps<string, AzSecurityGroupPickerUiOptions>,
) => {
  const {
    onChange,
    schema: { title = '', description = '' },
    required,
    rawErrors,
    formData,
    idSchema,
    uiSchema,
  } = props;
  const [isRequired, setSelectRequired] = useState<boolean>(required ?? false);

  const { excludeWellKnownGroups } = uiSchema?.['ui:options'] ?? {};

  const onSelect = (_: any, value: string | null) => {
    onChange(value || '');
  };

  const { value: groups } = useAzureOwnGroups();

  useEffect(() => {
    if (Array.isArray(formData) && formData?.length > 0) {
      setSelectRequired(false);
    } else {
      setSelectRequired(required ?? false);
    }
  }, [required, setSelectRequired, formData?.length, formData]);

  const allowedGroups = useCallback(() => {
    return (groups ?? [])
      .filter(group =>
        excludeWellKnownGroups
          ? !wellKnownGroups.includes(group.id) &&
            !group.displayName?.startsWith('RG_AZ_PIM_') &&
            !group.displayName?.toUpperCase().startsWith('RG_AZ_APP_') &&
            !group.displayName?.toUpperCase().startsWith('RG_APP_') &&
            !group.displayName?.toUpperCase().startsWith('RG_AZ_TT_') &&
            !group.displayName?.toUpperCase().startsWith('RG_AZ_LOC_') &&
            !group.displayName?.toUpperCase().startsWith('SPO_LOCATION_') &&
            !group.displayName?.toUpperCase().startsWith('RG_VPN_')
          : true,
      )
      .map(group => group.id);
  }, [groups, excludeWellKnownGroups]);

  return (
    <FormControl
      margin="dense"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <Autocomplete
        id={idSchema?.$id}
        value={
          groups.find(group => group.id === (formData as string))
            ?.displayName || undefined
        }
        onChange={onSelect}
        options={allowedGroups()}
        getOptionLabel={option =>
          groups.find(group => group.id === option)?.displayName || option
        }
        renderOption={(option: string) =>
          groups.find(group => group.id === option)?.displayName || option
        }
        renderInput={params => (
          <TextField
            {...params}
            label={title}
            helperText={description}
            required={isRequired}
            InputProps={params.InputProps}
          />
        )}
      />
    </FormControl>
  );
};
