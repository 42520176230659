import { FactRetriever } from '@backstage-community/plugin-tech-insights-node';

import { teamMissionsHandler } from '../handlers/teamMissionsHandler';

export const teamMissionsFactRetriever: FactRetriever = {
  id: 'teamMissionsFactRetriever',
  version: '0.0.1',
  title: 'Team Missions',
  description:
    'Generates facts which indicate the quality of data in metadata.missions field',
  entityFilter: [{ kind: ['group'] }],
  schema: {
    hasMissions: {
      type: 'boolean',
      description: 'The metadata.missions field is set',
    },
  },
  handler: teamMissionsHandler,
};
