import React from 'react';

const LogoFull = (props: {
  cssClasses?: {
    svg: string;
  };
}) => {
  return (
    <svg
      className={props.cssClasses?.svg}
      viewBox="0 0 125 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.444 22.1291H124.999L125 13.0888C125 12.5001 124.925 12.0677 124.859 11.7568C124.836 11.6515 124.816 11.5679 124.794 11.4864C124.092 8.90791 121.578 7.12306 118.811 7.23661C117.528 7.29135 116.322 7.76667 115.225 8.64921L115.085 8.76175L114.945 8.64921C113.849 7.76667 112.642 7.29135 111.359 7.23661C108.59 7.1212 106.078 8.90791 105.376 11.4864C105.354 11.568 105.334 11.652 105.311 11.7578C105.242 12.0876 105.17 12.505 105.17 13.0888L105.171 22.1291H108.726V13.242C108.726 11.9834 109.753 10.9594 111.015 10.9594C112.266 10.9594 113.292 11.9744 113.303 13.2221C113.304 13.2265 113.304 13.2343 113.304 13.242V22.1291H116.866L116.867 13.2213C116.878 11.9744 117.904 10.9594 119.155 10.9594C120.417 10.9594 121.444 11.9834 121.444 13.242V22.1291Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.1744 18.5808C93.0256 18.5808 91.2773 16.8374 91.2773 14.6945C91.2773 12.5516 93.0256 10.8081 95.1744 10.8081C97.3234 10.8081 99.0716 12.5516 99.0716 14.6945C99.0716 16.8374 97.3234 18.5808 95.1744 18.5808M95.1741 7.25977C91.0633 7.25977 87.7188 10.5949 87.7188 14.6944C87.7188 18.794 91.0633 22.1292 95.1741 22.1292C99.285 22.1292 102.629 18.794 102.629 14.6944C102.629 10.5949 99.285 7.25977 95.1741 7.25977"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.9062 22.1322H87.5178L85.4546 18.5802C85.0074 18.5797 83.1692 18.5774 83.026 18.5774C81.8791 18.5774 80.8802 17.7509 80.7994 16.7351C80.7887 16.6021 80.7791 16.4373 80.7716 16.2564V10.9011H85.4537L87.2833 7.69065H80.7716V1.86719L77.2102 4.32421V7.51998H74.9629V11.0718H77.21L77.2134 15.9213C77.2134 15.9233 77.211 16.1477 77.2144 16.2123L77.228 16.4824C77.3498 18.8374 78.5126 20.5712 80.684 21.6352C81.2652 21.92 81.908 22.0769 82.7073 22.1292L82.9062 22.1288V22.1322Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.864 22.1293H72.4192L72.4198 13.0889C72.4198 12.5002 72.3444 12.0678 72.2785 11.7569C72.2556 11.6516 72.2355 11.568 72.2135 11.4865C71.512 8.90803 68.9973 7.12318 66.2307 7.23673C64.9479 7.29148 63.7413 7.76679 62.6447 8.64934L62.5049 8.76187L62.3651 8.64934C61.2685 7.76679 60.0619 7.29148 58.7788 7.23673C56.01 7.12132 53.4979 8.90803 52.7962 11.4865C52.774 11.5682 52.754 11.6521 52.731 11.7579C52.6613 12.0878 52.5898 12.5051 52.5898 13.0889L52.5905 22.1293H56.1459V13.2422C56.1459 11.9835 57.1727 10.9595 58.4348 10.9595C59.6856 10.9595 60.7119 11.9745 60.7229 13.2222C60.7233 13.2266 60.7238 13.2344 60.7238 13.2422V22.1293H64.2861L64.2869 13.2214C64.2978 11.9745 65.3242 10.9595 66.5749 10.9595C67.8372 10.9595 68.864 11.9835 68.864 13.2422V22.1293Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.5944 18.5808C40.4454 18.5808 38.6973 16.8374 38.6973 14.6945C38.6973 12.5516 40.4454 10.8081 42.5944 10.8081C44.7434 10.8081 46.4915 12.5516 46.4915 14.6945C46.4915 16.8374 44.7434 18.5808 42.5944 18.5808M42.594 7.25977C38.4831 7.25977 35.1387 10.5949 35.1387 14.6944C35.1387 18.794 38.4831 22.1292 42.594 22.1292C46.7049 22.1292 50.0493 18.794 50.0493 14.6944C50.0493 10.5949 46.7049 7.25977 42.594 7.25977"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.3261 22.1324H34.9378L32.8745 18.5805C32.4273 18.58 30.5891 18.5776 30.4459 18.5776C29.299 18.5776 28.3001 17.7512 28.2193 16.7353C28.2086 16.6023 28.199 16.4376 28.1915 16.2566V10.9014H32.8736L34.7033 7.69089H28.1915V1.86743L24.6301 4.32446V7.52023H22.3828V11.072H24.6299L24.6333 15.9215C24.6333 15.9236 24.6309 16.1479 24.6343 16.2125L24.6479 16.4827C24.7697 18.8376 25.9326 20.5715 28.1039 21.6355C28.6851 21.9202 29.328 22.0772 30.1272 22.1294L30.3261 22.1291V22.1324Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.28764 23.9999L11.4639 18.5137H5.11133L8.28764 23.9999Z"
        fill="#df1b12"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.28694 12.5839C5.89835 12.5839 3.95522 10.6459 3.95522 8.26409C3.95522 5.88209 5.89835 3.94415 8.28694 3.94415C10.6755 3.94415 12.6187 5.88209 12.6187 8.26409C12.6187 10.6459 10.6755 12.5839 8.28694 12.5839M8.28694 0C3.7175 0 0 3.70725 0 8.26409C0 12.8208 3.7175 16.528 8.28694 16.528C12.8564 16.528 16.5739 12.8208 16.5739 8.26409C16.5739 3.70725 12.8564 0 8.28694 0"
        fill="#df1b12"
      />
    </svg>
  );
};

export default LogoFull;
