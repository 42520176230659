export class ResourceQuota {
  cpuLimit: number;
  cpuRequest: number;
  memoryLimit: number;
  memoryRequest: number;

  constructor() {
    this.cpuLimit = 4000;
    this.cpuRequest = 4000;
    this.memoryLimit = 8192;
    this.memoryRequest = 8192;
  }
}

export class ArgoProject {
  repositories: string[] | null;
  rootPath: string;

  constructor() {
    this.repositories = [];
    this.rootPath = '';
  }
}
export class Tenant {
  name: string;
  tier: string;
  ownerObjectId: string;
  clusterId?: string;
  costCenterCode: number;
  resourceQuota: ResourceQuota;
  namespaceQuota: number;
  slackChannel: string;
  kubeConfig?: string;
  customerSubscriptionId?: string;
  nodeGroup?: string | null;
  argoUrl?: string;
  argoProject?: ArgoProject | null;

  constructor() {
    this.name = '';
    this.tier = '';
    this.ownerObjectId = '';
    this.costCenterCode = 0;
    this.resourceQuota = new ResourceQuota();
    this.namespaceQuota = 3;
    this.slackChannel = '';
    this.argoProject = new ArgoProject();
  }

  getRegion(): string | undefined {
    return this.clusterId?.split('/')[1].split('-')[1];
  }

  getContext(): string | undefined {
    return this.clusterId?.split('/')[1].split('-')[2];
  }

  getCpuRequest(): number {
    return this.resourceQuota.cpuRequest / 1000;
  }

  getCpuLimit(): number {
    return this.resourceQuota.cpuLimit / 1000;
  }

  getMemoryRequest(): number {
    return this.resourceQuota.memoryRequest / 1024;
  }

  getMemoryLimit(): number {
    return this.resourceQuota.memoryLimit / 1024;
  }

  getClusterName(): string {
    return this.clusterId?.split('/')[1] ?? '';
  }

  getKubeconfig() {
    return atob(this.kubeConfig ?? '');
  }
}

export type Cluster = { id: string; name: string; tier: string; stage: string };

export type ValidationError = { valid: boolean; error: string | undefined };
