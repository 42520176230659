import { makeStyles, Theme } from '@material-ui/core';

import { colors } from '../../theme/colors';

export const useCommonStyles = makeStyles<Theme>(
  theme => ({
    paddingTop25: {
      paddingTop: theme.spacing(3.125),
    },
    marginTop5vh: {
      marginTop: '5vh',
    },
    marginTop10vh: {
      marginTop: '10vh',
    },
    minHeight55vh: {
      minHeight: '55vh',
    },
    tomtomButton: {
      '& button': {
        padding: 0,
        borderRadius: 100,
        width: 110,
        height: 40,
        marginTop: 14,
        textTransform: 'capitalize',
        backgroundColor: theme.palette.primary.main,
        color: colors.primary.white,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
        '& span:first-child': {
          justifyContent: 'space-around',
          padding: 10,
          '&::after': {
            content: JSON.stringify('\u2192'),
          },
        },
      },
    },
  }),
  { name: 'FrontstageCommon' },
);
