import { Entity, getEntitySourceLocation } from '@backstage/catalog-model';
import { IdentityApi } from '@backstage/core-plugin-api';
import { CatalogApi } from '@backstage/plugin-catalog-react';

import { arrayToObjectReducer } from '../utils';

const getAnnotationValue = (entity: Entity, annotation: string) => {
  return entity.metadata.annotations?.[annotation];
};

const gitUrlParse = (url: string) => {
  const parsedUrl = new URL(url);
  const [_, repoOwner, repo] = parsedUrl.pathname.split('/');
  return { repoOwner, repo };
};

const metadataAnnotations: { [key in string]: string } = {
  sonarQubeProjectKey: 'sonarqube.org/project-key',
  veracodeAppName: 'ssu.tomtom.com/veracode-application-name',
  blackDuckProjectName: 'ssu.tomtom.com/blackduck-project-name',
  coverityStream: 'ssu.tomtom.com/coverity-stream',
};

export const onboardGithubRepoFormBuilder = async (
  entity: Entity,
  formFields: string[],
  _identityApi?: IdentityApi,
  _catalogApi?: CatalogApi,
) => {
  const location = getEntitySourceLocation(entity);
  const { repoOwner, repo } = gitUrlParse(location.target);

  const formData = formFields
    .map((field: string) => {
      let data: { [key in string]: string | undefined };
      switch (field) {
        case 'repoUrl':
          data = { [field]: `github.com?owner=${repoOwner}&repo=${repo}` };
          break;
        case 'description':
          data = { [field]: entity.metadata[field] ?? '' };
          break;
        case 'name':
          data = { [field]: `value=${entity.metadata[field]}:nameTaken=false` };
          break;
        case 'owner':
        case 'type':
        case 'lifecycle':
          data = { [field]: entity.spec?.[field]?.toString() ?? '' };
          break;
        case 'sonarQubeProjectKey':
        case 'veracodeAppName':
        case 'blackDuckProjectName':
        case 'coverityStream':
          data = {
            [field]: getAnnotationValue(entity, metadataAnnotations[field]),
          };
          break;
        default:
          data = {};
          break;
      }
      return data;
    })
    .reduce(arrayToObjectReducer, {});

  return formData;
};
