import { RulesetDefinition, Spectral } from '@stoplight/spectral-core';
import { oas } from '@stoplight/spectral-rulesets';
import { Logger } from 'winston';

import { ApiEntity, Entity } from '@backstage/catalog-model';
import { LocationSpec } from '@backstage/plugin-catalog-common';
import {
  CatalogProcessor,
  CatalogProcessorEmit,
  CatalogProcessorCache,
} from '@backstage/plugin-catalog-node';

import { ANNOTATION_OPENAPI_LINTER_PROCESSOR } from './annotations';

// A processor that runs linters on openapi specs
export class OpenapiLinterProcessor implements CatalogProcessor {
  private readonly spectral: Spectral;

  constructor(private readonly logger: Logger) {
    this.spectral = new Spectral();
    this.spectral.setRuleset({
      extends: [[oas as RulesetDefinition, 'recommended']],
      rules: { 'oas3-api-servers': 'off' },
    });
  }

  getProcessorName(): string {
    return 'OpenapiLinterProcessor';
  }

  /**
   * Post-processes an emitted entity, after it has been validated.
   *
   * @param entity - The entity to process
   * @param location - The location that the entity came from
   * @param emit - A sink for auxiliary items resulting from the processing
   * @param cache - A cache for storing values local to this processor and the current entity.
   * @returns The same entity or a modified version of it
   */
  async validateEntityKind(entity: Entity): Promise<boolean> {
    return entity.kind.toLowerCase() === 'api';
  }

  async postProcessEntity(
    entity: Entity,
    _location: LocationSpec,
    _emit: CatalogProcessorEmit,
    cache: CatalogProcessorCache,
  ): Promise<Entity> {
    if (entity.kind.toLowerCase() !== 'api') {
      return entity;
    }

    this.logger.info(
      `running ${this.getProcessorName()} at ${entity.metadata.name}`,
    );
    const apiEntity = entity as ApiEntity;

    const cachedResult = await cache.get<string>(apiEntity.spec.definition);
    if (cachedResult) {
      if (!entity.metadata.annotations) {
        entity.metadata.annotations = {};
      }
      entity.metadata.annotations[ANNOTATION_OPENAPI_LINTER_PROCESSOR] =
        cachedResult;
      this.logger.info(
        `${this.getProcessorName()} added annotations from cache for ${
          entity.metadata.name
        }`,
      );
      return entity;
    }

    return this.spectral.run(apiEntity.spec.definition).then(
      value => {
        const linterResult = JSON.stringify(value, null, 0);
        if (!entity.metadata.annotations) {
          entity.metadata.annotations = {};
        }
        entity.metadata.annotations[ANNOTATION_OPENAPI_LINTER_PROCESSOR] =
          linterResult;

        this.logger.info(
          `${this.getProcessorName()} added annotations for ${
            entity.metadata.name
          }`,
        );
        cache.set(apiEntity.spec.definition, linterResult);
        return entity;
      },
      reason => {
        this.logger.error(
          `error occurred while running spectral on ${entity.metadata.name} ${reason}`,
        );
        return entity;
      },
    );
  }
}
