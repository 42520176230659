import React from 'react';
import useAsync from 'react-use/lib/useAsync';

import { stringifyEntityRef } from '@backstage/catalog-model';
import { Progress } from '@backstage/core-components';
import { InfoCard } from '@backstage/core-components';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';

import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
  Link,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { recognitionApiRef } from '../../api';
import { UserTommee } from '../../api/RecognitionApi';
import * as Images from '../../images';

export const UserTommeesList = () => {
  const { entity } = useEntity();
  const currentUser = stringifyEntityRef(entity);
  const identityApi = useApi(identityApiRef);
  const recognitionAPI = useApi(recognitionApiRef);
  const { value, loading, error } = useAsync(async (): Promise<{
    tommees: UserTommee[];
    pendingRequests: number;
    isOnOwnUserPage: boolean;
  }> => {
    const { userEntityRef } = await identityApi.getBackstageIdentity();
    const isOnOwnUserPage = userEntityRef === stringifyEntityRef(entity);
    const userTommees = await recognitionAPI.getUserTommees(currentUser);
    const pendingRequests = isOnOwnUserPage
      ? await recognitionAPI.getPendingRequests()
      : [];
    return {
      tommees: userTommees,
      pendingRequests: pendingRequests.length,
      isOnOwnUserPage,
    };
  }, []);

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  const { tommees, pendingRequests, isOnOwnUserPage } = value ?? {
    tommees: [],
    pendingRequests: 0,
    isOnOwnUserPage: false,
  };

  const title = isOnOwnUserPage ? 'Your current Tommees' : 'Current Tommees';
  return (
    <InfoCard title={title} variant="flex">
      {pendingRequests > 0 && (
        <Typography variant="subtitle1">
          You have {pendingRequests} pending Tommee requests.
          <Link href="/recognition">Click here</Link> to review them.
        </Typography>
      )}
      {tommees && tommees.length > 0 ? (
        <ImageList cols={4} rowHeight={164}>
          {tommees?.map(tommee => (
            <ImageListItem key={tommee.id}>
              <img
                src={tommee.icon || Images.ImageNotDisplayed}
                alt={tommee.name}
                width="100%"
              />
              <ImageListItemBar
                title={tommee.name}
                subtitle={<span>{tommee.description}</span>}
                position="bottom"
              />
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <Typography variant="subtitle1">
          {isOnOwnUserPage
            ? 'You do not have Tommees yet, '
            : 'This user does not have Tommees yet, '}
          <Link href="/recognition">click here</Link> to request them.
        </Typography>
      )}
    </InfoCard>
  );
};
