import { FactRetrieverContext } from '@backstage-community/plugin-tech-insights-node';
import { stringifyEntityRef } from '@backstage/catalog-model';

import { WatchtowerReader } from '../../WatchtowerReader';
import { TypedTechInsightFact, CISucessPercentFact } from '../../types';
import { getEntities } from '../utils/getEntities';
import { getRepoNameFromProjectSlug, weekRange } from './utils';

type PeriodValue = {
  start: string;
  end: string;
  value: number;
};

type CISuccessRateOutput = {
  start_date: string;
  end_date: string;
  unit: string;
  periods: PeriodValue[];
};
type CISuccessRateInput = {
  start_date: string;
  end_date: string;
  repository_names: string[];
};

export const createCISuccessFactsHandler = (
  watchtowerReader: WatchtowerReader,
) => {
  return async ({
    logger,
    entityFilter,
    tokenManager,
    discovery,
  }: FactRetrieverContext): Promise<
    TypedTechInsightFact<CISucessPercentFact>[]
  > => {
    const entities = await getEntities({
      logger,
      entityFilter,
      tokenManager,
      discovery,
    });

    const ciFacts: TypedTechInsightFact<CISucessPercentFact>[] = [];

    for (const entity of entities.items) {
      const repoName = getRepoNameFromProjectSlug(entity);

      if (!repoName) {
        logger.warn(`No slug found for entity ${stringifyEntityRef(entity)}`);
        continue;
      }

      try {
        const { start, end } = weekRange();
        const payload = {
          repository_names: [repoName],
          start_date: start,
          end_date: end,
        };
        const ciSuccessRateFact = await watchtowerReader.getStats<
          CISuccessRateInput,
          CISuccessRateOutput
        >('ci/success-rate', payload);
        logger.debug(
          `ciSuccessRateFact for repo ${repoName} :: ${JSON.stringify(
            ciSuccessRateFact,
          )}`,
        );
        const totalSuccessRate = parseSuccessRate(ciSuccessRateFact?.periods);
        if (totalSuccessRate) {
          ciFacts.push({
            entity: {
              namespace: entity.metadata.namespace!,
              kind: entity.kind,
              name: entity.metadata.name,
            },
            facts: { successPercent: totalSuccessRate! },
          });
        } else {
          logger.info(
            `No ciSuccessRateFact for repo ${repoName} :: ${JSON.stringify(
              ciSuccessRateFact,
            )}`,
          );
        }
      } catch (error) {
        logger.error(
          `Error while fetching CI success percent for ${repoName}`,
          error as any,
        );
      }
    }

    return ciFacts;
  };
};

export function parseSuccessRate(periodValues?: PeriodValue[]): number {
  return periodValues?.[0]?.value ?? 0;
}
