import { NavigateToTemplateButton } from '@internal/plugin-catalog-page-addons-react';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

import { CompoundEntityRef } from '@backstage/catalog-model';

import { Box, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import HubIcon from '@material-ui/icons/DeviceHub';

import { useGetScorecardData } from '../../hooks';
import { Scorecard, ScorePerTier } from '../../types';
import { summariseScores } from '../../utils';
import { ScoreCharts } from '../ScoreCharts';
import { TiersBox } from '../TiersBox/TiersBox';

const useStyles = () =>
  makeStyles(theme => {
    return {
      loadingPanel: {
        borderRadius: 20,
        padding: 16,
        height: 418,
        alignContent: 'center',
        textAlign: 'center',
        '& svg': {
          color: theme.palette.grey[400],
        },
      },
      paperStyle: {
        borderRadius: 20,
        padding: 16,
      },
    };
  })();

export interface TeamScorecardAboutCardProps {
  entityRef: CompoundEntityRef;
}

const emptyData: ScorePerTier = {
  Gold: 0,
  Silver: 0,
  Bronze: 0,
  Black: 0,
};

const getScoresPerTier = (data: Scorecard) => {
  if (!data) {
    return emptyData;
  }
  return summariseScores(data.items);
};

export const TeamScorecardAboutCard = (props: TeamScorecardAboutCardProps) => {
  const { scorecard, loading, error } = useGetScorecardData(props.entityRef);
  const classes = useStyles();

  const scoresPerTier: ScorePerTier = getScoresPerTier(scorecard as Scorecard);

  if (loading) {
    return (
      <Paper className={classes.loadingPanel}>
        <CircularProgress size={150} />
      </Paper>
    );
  }

  if (error) {
    return (
      <Paper className={classes.paperStyle}>
        <Typography variant="h5">
          Error retrieving scorecard information
        </Typography>
      </Paper>
    );
  }

  return (
    <Paper className={classes.paperStyle}>
      <Grid container style={{ display: 'flex' }}>
        <Grid item container md={8}>
          <Grid item md={12}>
            <Typography variant="h5">Scorecard</Typography>
          </Grid>
          <Grid item md={12} style={{ padding: 40 }}>
            {!scorecard || (scorecard as Scorecard).items.length < 1 ? (
              <Box>
                <Typography variant="h5">
                  This team have no associated services
                </Typography>
                <Typography>
                  Add a service to your team by clicking the button below
                </Typography>
                <NavigateToTemplateButton
                  startIcon={<HubIcon />}
                  variant="outlined"
                  template="create-service"
                  text="Add Service"
                />
              </Box>
            ) : (
              <ScoreCharts data={scorecard as Scorecard} />
            )}
          </Grid>
        </Grid>
        <Grid item md={4}>
          <TiersBox scoresPerTier={scoresPerTier} />
        </Grid>
      </Grid>
    </Paper>
  );
};
