import React from 'react';

import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

import { FormControl, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { useGetGithubTeams } from './useGetGithubTeams';

export type GithubTeamPickerUiOptions = {
  allowedValues?: string[];
};

export const GithubTeamPickerExtension = (
  props: FieldExtensionComponentProps<string, GithubTeamPickerUiOptions>,
) => {
  const {
    onChange,
    schema: { title = '', description = '' },
    required,
    uiSchema,
    rawErrors,
    formData,
    idSchema,
  } = props;

  const onSelect = (_: any, value: string | null) => {
    onChange(value || '');
  };

  const allowedValues: string[] = uiSchema['ui:options']?.allowedValues || [];
  const { value: teams } = useGetGithubTeams();

  const allowedTeams =
    allowedValues.length > 0
      ? (teams ?? []).filter(team => allowedValues.includes(team))
      : teams ?? [];

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <Autocomplete
        id={idSchema?.$id}
        value={(formData as string) || undefined}
        onChange={onSelect}
        options={allowedTeams}
        renderInput={params => (
          <TextField
            {...params}
            label={title}
            margin="normal"
            helperText={description}
            variant="outlined"
            required={required}
            InputProps={params.InputProps}
          />
        )}
      />
    </FormControl>
  );
};
