import { useAsync } from 'react-use';

import { identityApiRef, useApi } from '@backstage/core-plugin-api';

const juryMembers = [
  'user:default/dusan.milenkovic_tomtom.com',
  'user:default/ferenc.szeli_tomtom.com',
  'user:default/nikhil.kattani_tomtom.com',
  'user:default/joost.voogt_tomtom.com',
  'user:default/alexey.samuleenkov_tomtom.com',
  'user:default/armando.collazo_tomtom.com',
  'user:default/dragan.kostic_tomtom.com',
  'user:default/elias.rosenberg_tomtom.com',
  'user:default/silvia.longo_tomtom.com',
  'user:default/chris.poppe_tomtom.com',
  'user:default/martijn.siereveld_tomtom.com',
  'user:default/renato.martins_tomtom.com',
  'user:default/leo.sei_tomtom.com',
  'user:default/tomas.cafferata_tomtom.com',
  'user:default/bram.vandervlist_tomtom.com',
  'user:default/federico.evers_tomtom.com',
  'user:default/david.leane_tomtom.com',
  'user:default/eszter.pattantyus_tomtom.com',
  'user:default/peter.clarys_tomtom.com',
  'user:default/herve.clauss_tomtom.com',
  'user:default/veerle.hostyn_tomtom.com',
  'user:default/eric.agterberg_tomtom.com',
  'user:default/anna.bednarczyk_tomtom.com',
  'user:default/maren.wilger_tomtom.com',
  'user:default/nicolas.gerber_tomtom.com',
  'user:default/rob.vandermost_tomtom.com',
  'user:default/joao.laranjeira_tomtom.com',
  'user:default/shubham.bhusari_tomtom.com',
  'user:default/galina.kuklenko_tomtom.com',
  'user:default/serj.lotutovici_tomtom.com',
  'user:default/aleksa.novakovic_tomtom.com',
  'user:default/frederic.depuydt_tomtom.com',
  'user:default/barbara.lambrecht_tomtom.com',
  'user:default/guillermo.ruiz_tomtom.com',
  'user:default/marcin.bialas_tomtom.com',
  'user:default/marius.swanepoel_tomtom.com',
  'user:default/timothy.grymonpon_tomtom.com',
  'user:default/illia.matviienko_tomtom.com',
  'user:default/mike.gilbert_tomtom.com',
  'user:default/nathan.gile_tomtom.com',
  'user:default/karen.vanaudenhaege_tomtom.com',
  'user:default/ana.lira_tomtom.com',
  'user:default/dominik.plichta_tomtom.com',
  'user:default/emre.toparlar_tomtom.com',
  'user:default/daria.turobos_tomtom.com',
  'user:default/pascal.clarysse_tomtom.com',
  'user:default/ralf-peter.schaefer_tomtom.com',
  'user:default/kia.eisinga_tomtom.com',
  'user:default/hubert.jeziorski_tomtom.com',
  'user:default/philippe.dirkse_tomtom.com',
  'user:default/juniper.gujral_tomtom.com',
  'user:default/hannes.scharmann_tomtom.com',
  'user:default/hans.vanleijen_tomtom.com',
  'user:default/ricardo.rosa_tomtom.com',
  'user:default/craig.knox_tomtom.com',
  'user:default/emil.dziadczyk@tomtom.com',
];

export const useIsJuryMember = () => {
  const identityApi = useApi(identityApiRef);
  const { value: userSession } = useAsync(async () =>
    identityApi.getBackstageIdentity(),
  );

  const userRef = userSession?.userEntityRef ?? '';

  return juryMembers.includes(userRef);
};
