import { CheckId } from '../../../tier';
import { scorecardTemplateMapperGenerator } from '../scorecardTemplateMapperGenerator';

const isValidCheckId = (checkId: string) =>
  (Object.values(CheckId) as string[]).includes(checkId);

export const useCheckTemplateAndFormData = (checkId: string) => {
  if (!isValidCheckId(checkId)) {
    return {
      template: undefined,
      formData: {},
    };
  }

  const templateWithFormData = scorecardTemplateMapperGenerator().get(
    checkId as CheckId,
  );

  return {
    template: templateWithFormData?.template,
    formData: templateWithFormData?.formData ?? {},
  };
};
