import useAsync from 'react-use/lib/useAsync';

import { useApi } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';

import { azureResourcesApiRef } from '../api';
import {
  AzureSecurityRecommendation,
  AzureSecurityRecommendationSeverity,
} from '../types';
import {
  parseAzureResourceTagAnnotation,
  prepareSecurityRecommendations,
} from '../utils';

export const useAzureSecurityRecommendations = () => {
  const { entity } = useEntity();
  const api = useApi(azureResourcesApiRef);

  const [tagKey, tagValue] = parseAzureResourceTagAnnotation(entity);

  const {
    value: securityRecommendations,
    loading,
    error,
  } = useAsync(async (): Promise<AzureSecurityRecommendationSeverity[]> => {
    const resourcesResponse = await api.getAzureResourceRecommendations({
      tagKey,
      tagValue,
    });

    const recommendations =
      (resourcesResponse?.data as AzureSecurityRecommendation[]) ?? [];

    return prepareSecurityRecommendations(recommendations);
  }, []);

  return { securityRecommendations, loading, error } as const;
};
