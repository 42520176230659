import React from 'react';

import {
  RELATION_CHILD_OF,
  RELATION_PARENT_OF,
} from '@backstage/catalog-model';
import { CatalogGraphPage, Direction } from '@backstage/plugin-catalog-graph';

export const OrgGraphPage = () => (
  <CatalogGraphPage
    initialState={{
      showFilters: true,
      maxDepth: 1,
      direction: Direction.TOP_BOTTOM,
      selectedKinds: ['group'],
      selectedRelations: [RELATION_PARENT_OF, RELATION_CHILD_OF],
      rootEntityRefs: ['group:default/tomtom'],
    }}
  />
);
