import { Octokit } from '@octokit/rest';

import { ConfigApi } from '@backstage/core-plugin-api';
import { readGithubIntegrationConfigs } from '@backstage/integration';
import { ScmAuthApi } from '@backstage/integration-react';

import { WorkflowRunRequest } from './types';

export class GithubWorkflowClient {
  private readonly configApi: ConfigApi;
  private readonly scmAuthApi: ScmAuthApi;

  constructor(options: { configApi: ConfigApi; scmAuthApi: ScmAuthApi }) {
    this.configApi = options.configApi;
    this.scmAuthApi = options.scmAuthApi;
  }

  private async getOctokit(hostname: string = 'github.com'): Promise<Octokit> {
    const { token } = await this.scmAuthApi.getCredentials({
      url: `https://${hostname}/`,
      additionalScope: {
        customScopes: {
          github: ['repo'],
        },
      },
    });
    const configs = readGithubIntegrationConfigs(
      this.configApi.getOptionalConfigArray('integrations.github') ?? [],
    );
    const githubIntegrationConfig = configs.find(v => v.host === hostname);
    const baseUrl = githubIntegrationConfig?.apiBaseUrl;
    return new Octokit({ auth: token, baseUrl });
  }

  async listWorkflowRuns(workflowRunRequest: WorkflowRunRequest) {
    const {
      owner,
      repo,
      workflow_id,
      pageSize = 10,
      page = 0,
      branch,
    } = workflowRunRequest;
    const octokit = await this.getOctokit();
    const workflowRuns = await octokit.actions.listWorkflowRuns({
      owner,
      repo,
      workflow_id,
      page_size: pageSize,
      page,
      ...(branch ? { branch } : {}),
    });

    return workflowRuns.data;
  }

  async listBatchWorkflowRuns(workflowRunRequests: WorkflowRunRequest[]) {
    const allPromises = workflowRunRequests.map(workflowRunRequest =>
      this.listWorkflowRuns(workflowRunRequest),
    );

    return Promise.all(allPromises);
  }
}
