import {
  hasRepositoryConfirmedOwner,
  hasRepositoryProposedOwner,
  NavigateToTemplateButton,
} from '@internal/plugin-catalog-page-addons-react';
import React from 'react';

import { Entity } from '@backstage/catalog-model';
import { EntitySwitch, isKind } from '@backstage/plugin-catalog';

import { Grid } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';

import { ComponentActionContent } from './ComponentActionContent';
import { GroupActionContent } from './GroupActionContent';

const isEntityOwnerEditable = (entity: Entity): boolean =>
  (entity.spec?.owner !== undefined &&
    entity.metadata.annotations?.[
      'backstage.io/managed-by-location'
    ].startsWith('url:')) ??
  false;

export const ActionContent = (
  <Grid item md={12}>
    <EntitySwitch>
      <EntitySwitch.Case if={isEntityOwnerEditable}>
        <NavigateToTemplateButton
          startIcon={<LinkIcon />}
          variant="outlined"
          template="update-entity-owner"
          text="Update Entity Owner"
        />
      </EntitySwitch.Case>
    </EntitySwitch>
    <EntitySwitch>
      <EntitySwitch.Case if={hasRepositoryProposedOwner}>
        <NavigateToTemplateButton
          color="primary"
          variant="contained"
          template="github-repository-ownership"
          text="Confirm Repository Ownership"
        />
      </EntitySwitch.Case>
      <EntitySwitch.Case if={hasRepositoryConfirmedOwner}>
        <NavigateToTemplateButton
          variant="outlined"
          template="github-repository-ownership"
          text="Re-Confirm Repository Ownership"
        />

        <NavigateToTemplateButton
          variant="outlined"
          template="remove-github-repository-ownership"
          text="Remove Repository Ownership Confirmation"
        />
      </EntitySwitch.Case>
    </EntitySwitch>
    <EntitySwitch>
      <EntitySwitch.Case if={isKind('Group')}>
        <GroupActionContent />
      </EntitySwitch.Case>
    </EntitySwitch>
    <EntitySwitch>
      <EntitySwitch.Case if={isKind('Component')}>
        <ComponentActionContent />
      </EntitySwitch.Case>
    </EntitySwitch>
    {/* <EntitySwitch>
      <EntitySwitch.Case if={isComponentEntity}>
        <NavigateToTemplateButton
          startIcon={<EditOutlinedIcon />}
          variant="outlined"
          template="onboard-github-repo"
          text="Mixer - update annotations"
        />
      </EntitySwitch.Case>
    </EntitySwitch> */}
  </Grid>
);
