import React, { ReactElement } from 'react';

import { Box, IconButton, Menu } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

export const EntityMoreActionsMenu = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const arrayOfChildren = React.Children.toArray(children);

  return (
    // FIXME: This is a hack to get the button to align with the other buttons. It starts breaking at 959px width.
    <Box
      display="flex"
      justifyContent="right"
      position="relative"
      bottom="511px"
      right="90px"
      paddingRight="10px"
    >
      <IconButton
        aria-label="more entity actions"
        aria-haspopup="true"
        ref={anchorEl}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
        {arrayOfChildren.map(child =>
          React.cloneElement(child as ReactElement, {
            ...(child as ReactElement).props,
            onClick: () => {
              handleClose();
            },
          }),
        )}
      </Menu>
    </Box>
  );
};
