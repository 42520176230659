import { JSON_RULE_ENGINE_CHECK_TYPE } from '@backstage-community/plugin-tech-insights-backend-module-jsonfc';

import { TechInsightsCheckID } from '../../checkIDs';
import { TechInsightsRetrieverIDs } from '../../retrieverIDs';

export const componentCIMedianBuildDurationCheck = {
  id: TechInsightsCheckID.REPO_CI_MEDIAN_BUILD_DURATION_CHECK,
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: 'Median CI Duration between 5 and 10 mins',
  description:
    'The median(p50) duration of CI workflow runs for the associated repositories over the last 7 days is between 5 and 10 minutes.',
  factIds: [
    TechInsightsRetrieverIDs.GITHUB_REPO_CI_MEDIAN_BUILD_DURATION_FACT_RETRIEVER,
  ],
  rule: {
    conditions: {
      all: [
        {
          fact: 'medianBuildDurationSecs',
          operator: 'greaterThanInclusive',
          value: 300,
        },
        {
          fact: 'medianBuildDurationSecs',
          operator: 'lessThanInclusive',
          value: 600,
        },
      ],
    },
  },
};
