import { Entity, RELATION_PARENT_OF } from '@backstage/catalog-model';

const isCostCenterTeam = (entity: Entity) =>
  entity.kind.toLocaleLowerCase() === 'group' &&
  entity.spec?.type === 'cost-center' &&
  entity.metadata.annotations?.['graph.microsoft.com/group-type'] ===
    'workday_cost_center' &&
  entity.metadata.annotations?.['workday.com/cost-center'] !== undefined;

const isSupervisoryOrgTeam = (entity: Entity) =>
  entity.kind.toLocaleLowerCase() === 'group' &&
  entity.spec?.type === 'supervisory-org' &&
  entity.metadata.annotations?.['graph.microsoft.com/group-type'] ===
    'workday_supervisory_org' &&
  entity.metadata.annotations?.['workday.com/supervisory-org-id'] !== undefined;

const isTeam = (entity: Entity) =>
  entity.kind.toLocaleLowerCase() === 'group' &&
  !entity?.relations?.find(item => item.type === RELATION_PARENT_OF);

const isSelfManagedTeam = (entity: Entity) =>
  isTeam(entity) && !isCostCenterTeam(entity) && !isSupervisoryOrgTeam(entity);

const isSelfManagedWarningDisabled = (entity: Entity) =>
  entity.metadata.annotations?.['tomtom.com/show-self-managed-warning'] ===
  'false';

export const hasGroupSelfManagedWarning = (entity: Entity) =>
  isSelfManagedTeam(entity) && !isSelfManagedWarningDisabled(entity);
