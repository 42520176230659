import {
  createPlugin,
  createComponentExtension,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

const githubPullRequestsBoardPlugin = createPlugin({
  id: 'github-pull-requests-board',
  routes: {
    root: rootRouteRef,
  },
});

/** @public */
export const EntityTeamPullRequestsCard = githubPullRequestsBoardPlugin.provide(
  createComponentExtension({
    name: 'EntityTeamPullRequestsCard',
    component: {
      lazy: () =>
        import('./components/EntityTeamPullRequestsCard').then(
          m => m.EntityTeamPullRequestsCard,
        ),
    },
  }),
);

/** @public */
export const EntityTeamPullRequestsContent =
  githubPullRequestsBoardPlugin.provide(
    createRoutableExtension({
      name: 'PullRequestPage',
      component: () =>
        import('./components/EntityTeamPullRequestsContent').then(
          m => m.EntityTeamPullRequestsContent,
        ),
      mountPoint: rootRouteRef,
    }),
  );
