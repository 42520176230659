import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  fetchApiRef,
  configApiRef,
} from '@backstage/core-plugin-api';

import { KaapApiClient, kaapApiRef } from './api';
import { ArmApiClient, armApiRef } from './azureapi';
import { rootRouteRef } from './routes';

export const kaapPlugin = createPlugin({
  id: 'kaap',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: kaapApiRef,
      deps: {
        fetchApi: fetchApiRef,
        configApi: configApiRef,
      },
      factory: ({ fetchApi, configApi }) =>
        new KaapApiClient(fetchApi, configApi),
    }),
    createApiFactory({
      api: armApiRef,
      deps: {},
      factory: () => new ArmApiClient(),
    }),
  ],
});

export const KaapPage = kaapPlugin.provide(
  createRoutableExtension({
    name: 'KaapPage',
    component: () => import('./components/Tenants').then(m => m.Tenants),
    mountPoint: rootRouteRef,
  }),
);
