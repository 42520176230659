import { githubWorkflowEntityFactHandler } from '../../handlers/entities';
import { TechInsightsRetrieverIDs } from '../../retrieverIDs';
import { GithubWorkflowEntityFact, TypedFactRetriever } from '../../types';

export const githubWorkflowEntityFactRetriever: TypedFactRetriever<GithubWorkflowEntityFact> =
  {
    id: TechInsightsRetrieverIDs.GITHUB_WORKFLOW_ENTITY_FACT_RETRIEVER,
    version: '0.0.1',
    title: 'Github Workflow Entity Fact Retriever',
    description: 'Generates facts regarding the github workflow entity',
    entityFilter: [{ kind: ['GitHubWorkflow'] }],
    schema: {
      runners: {
        type: 'object',
        description: 'The runners used in the repository',
      },
      actions: {
        type: 'object',
        description: 'The actions used in the repository',
      },
      groupRef: {
        type: 'string',
        description: 'The groupRef of the repository',
      },
    },
    handler: githubWorkflowEntityFactHandler,
  };
