import React from 'react';

import { Select, SelectItem } from '@backstage/core-components';

import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
} from '@material-ui/core';

export const GenericValuePicker = (props: {
  allowedValues?: string[];
  rawErrors: string[];
  pickedValue: string;
  onChange: (pickedValue: string) => void;
  schema: { title: string; description: string };
}) => {
  const {
    allowedValues = [],
    rawErrors,
    pickedValue,
    onChange,
    schema: { title, description },
  } = props;

  const items: SelectItem[] = allowedValues
    ? allowedValues.map(i => ({ label: i, value: i }))
    : [{ label: 'Loading...', value: 'loading' }];

  return (
    <>
      <FormControl
        margin="normal"
        required
        error={rawErrors?.length > 0 && !pickedValue}
      >
        {allowedValues?.length ? (
          <Select
            native
            label={title}
            onChange={s => onChange(String(Array.isArray(s) ? s[0] : s))}
            disabled={allowedValues.length === 1}
            selected={pickedValue}
            items={items}
          />
        ) : (
          <>
            <InputLabel htmlFor="valueInput">{title}</InputLabel>
            <Input
              id="valueInput"
              onChange={e => onChange(e.target.value)}
              value={pickedValue}
            />
          </>
        )}
        <FormHelperText>{description}</FormHelperText>
      </FormControl>
    </>
  );
};
