import React from 'react';

import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

import { FormControl, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

export type GenericValueInputPickerUiOptions = {
  allowedValues?: string[];
};

export const GenericValueInputPicker = (
  props: FieldExtensionComponentProps<string, GenericValueInputPickerUiOptions>,
) => {
  const {
    onChange,
    schema: { title = '', description = '' },
    required,
    uiSchema,
    rawErrors,
    formData,
    idSchema,
  } = props;

  const onSelect = (_: any, value: string | null) => {
    onChange(value || '');
  };

  const allowedValues: string[] = uiSchema['ui:options']?.allowedValues || [];

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <Autocomplete
        id={idSchema?.$id}
        value={(formData as string) || ''}
        onChange={onSelect}
        options={allowedValues}
        renderInput={params => (
          <TextField
            {...params}
            label={title}
            margin="normal"
            helperText={description}
            variant="outlined"
            required={required}
            InputProps={params.InputProps}
          />
        )}
      />
    </FormControl>
  );
};
