import { WatchtowerReader } from '../../WatchtowerReader';
import { createRepoHasReadmeFactHandler } from '../../handlers/github';
import { TechInsightsRetrieverIDs } from '../../retrieverIDs';
import { HasReadmeFact, TypedFactRetriever } from '../../types';

export const createRepoHasReadMeFactRetriever = (
  reader: WatchtowerReader,
): TypedFactRetriever<HasReadmeFact> => ({
  id: TechInsightsRetrieverIDs.GITHUB_REPO_HAS_README_FACT_RETRIEVER,
  version: '0.0.1',
  title: 'Readme Fact Retriever',
  description: 'Get README info from Github',
  entityFilter: [
    {
      kind: 'Component',
      'spec.type': 'service',
    },
  ],
  schema: {
    hasReadme: {
      type: 'boolean',
      description:
        'verify if the repo associated with this entity has a README file',
    },
  },
  handler: createRepoHasReadmeFactHandler(reader),
});
