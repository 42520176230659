import { SystemEntity } from '@internal/plugin-catalog-model';
import { useAsync } from 'react-use';

import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { scmAuthApiRef } from '@backstage/integration-react';
import { catalogApiRef, useEntity } from '@backstage/plugin-catalog-react';

import { generateEdges, generateNodes, getWorkflows } from '../utils';

export const useWorkflowGraphData = () => {
  const { entity } = useEntity();
  const catalogApi = useApi(catalogApiRef);
  const configApi = useApi(configApiRef);
  const scmAuthApi = useApi(scmAuthApiRef);

  const systemEntity = entity as SystemEntity;

  const { loading, error, value } = useAsync(async () => {
    const workflows = await getWorkflows(
      systemEntity.metadata.workflows,
      catalogApi,
      configApi,
      scmAuthApi,
    );

    const workflowNodes = generateNodes(workflows);

    const workflowEdges = generateEdges(
      systemEntity.metadata.workflows,
      workflowNodes,
    );

    return { nodes: workflowNodes, edges: workflowEdges };
  });

  return {
    nodes: value?.nodes ?? [],
    edges: value?.edges ?? [],
    loading,
    error,
  };
};
