import React from 'react';

import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

import { InputLabel, FormHelperText, Input } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';

export const UrlInput = (props: FieldExtensionComponentProps<string>) => {
  const { schema } = props;
  const { title, description } = schema;
  return (
    <FormControl margin="normal" required error={props.rawErrors?.length > 0}>
      <InputLabel htmlFor="validateUrl">{title}</InputLabel>
      <Input
        id="validateUrl"
        aria-describedby="urlInput"
        onChange={e => props.onChange(e.target?.value)}
      />
      <FormHelperText id="urlInput">{description}</FormHelperText>
    </FormControl>
  );
};
