import { FactRetriever } from '@backstage-community/plugin-tech-insights-node';
import { UrlReader } from '@backstage/backend-common';

import { createGitHubActionsHandler } from '../../handlers/github';
import { TechInsightsRetrieverIDs } from '../../retrieverIDs';

export const createGitHubActionsFactRetriever = (
  reader: UrlReader,
): FactRetriever => ({
  id: TechInsightsRetrieverIDs.GITHUB_ACTIONS_FACT_RETRIEVER,
  version: '0.0.1',
  title: 'Team Component or API entity onboarding',
  description: 'Get GitHub Actions facts for entities',
  entityFilter: {
    kind: 'Component',
  },
  schema: {
    isGitHubActionsUsed: {
      type: 'boolean',
      description: '',
    },
    jobs: {
      type: 'set',
      description: '',
      metadata: {
        required: false,
      },
    },
  },
  handler: createGitHubActionsHandler(reader),
});
