import { Entity } from '@backstage/catalog-model';

export const parseAzureResourceNameFilters = (
  entity: Entity,
  filterAnnotation: string,
): string[] => {
  const sep = ',';

  if (entity?.metadata.annotations?.[filterAnnotation]) {
    let annotation = entity.metadata.annotations[filterAnnotation];

    if (annotation.startsWith(sep)) {
      annotation = annotation.slice(1);
    }

    if (annotation.endsWith(sep)) {
      annotation = annotation.slice(0, annotation.length - 1);
    }

    return annotation.indexOf(sep) ? annotation.split(sep) : [annotation];
  }

  return [];
};
