import { useEffect } from 'react';

import { Entity } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { CatalogApi, catalogApiRef } from '@backstage/plugin-catalog-react';

const getChildEntities = async (current: Entity, catalogApi: CatalogApi) => {
  return await catalogApi.getEntities({
    filter: {
      kind: ['group'],
      'relations.childOf': `group:${current.metadata.namespace}/${current.metadata.name}`,
    },
  });
};

const addNew = (entities: Entity[], newEntities: Entity[]) => {
  entities.push(...newEntities.filter(ne => !entities.includes(ne)));
};

const queueEntitiesWithChildren = (
  newEntities: Entity[],
  entities: Entity[],
  queue: Entity[],
) => {
  if (newEntities.length > 0) {
    addNew(entities, newEntities);
    const children = newEntities.filter(ne => {
      const childOfRelations = ne.relations?.filter(r => r.type === 'parentOf');
      if (childOfRelations && childOfRelations.length > 0) {
        return true;
      }
      return false;
    });
    queue.push(...children);
  }
};

const getEntities = async (
  entities: Entity[],
  selectedProductUnits: Entity[],
  catalogApi: CatalogApi,
) => {
  const queue: Entity[] = [...selectedProductUnits];
  while (queue.length) {
    const current: Entity = queue.shift()!;
    if (!entities.includes(current)) {
      entities.push(current);
    }
    const response = await getChildEntities(current, catalogApi);
    queueEntitiesWithChildren(response.items, entities, queue);
  }
};

export const useEntitiesToQuery = (
  selectedProductUnits: Entity[],
  updateEntitiesCallback: (updatedEntities: Entity[]) => void,
) => {
  const catalogApi = useApi(catalogApiRef);

  useEffect(() => {
    const entities: Entity[] = [];
    if (selectedProductUnits.length > 0) {
      getEntities(entities, selectedProductUnits, catalogApi).then(() => {
        updateEntitiesCallback(entities);
      });
    } else {
      updateEntitiesCallback([]);
    }
  }, [selectedProductUnits, catalogApi, updateEntitiesCallback]);
};
