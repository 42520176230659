import React, { PropsWithChildren } from 'react';
import { useAsync } from 'react-use';

import { stringifyEntityRef } from '@backstage/catalog-model';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';

export const OwnUserContent = ({
  children,
}: PropsWithChildren<{}>): JSX.Element | null => {
  const { entity } = useEntity();

  const identityApi = useApi(identityApiRef);

  const { value: isOnOwnUserPage } = useAsync(async () => {
    const { userEntityRef } = await identityApi.getBackstageIdentity();
    return userEntityRef === stringifyEntityRef(entity);
  });

  return isOnOwnUserPage ? <>{children}</> : null;
};
