import { TeamScoreCard } from '@internal/backstage-plugin-scorecard';
import { AzureContent } from '@internal/plugin-azure-resources';
import { EntityTeamPullRequestsContent } from '@internal/plugin-github-pull-requests-board';
import {
  GithubTeamActionsPage,
  isGithubTeamActionsDisabled,
} from '@internal/plugin-github-team-actions';
import {
  GithubTeamDeploymentsPage,
  isGithubTeamDeploymentDisabled,
} from '@internal/plugin-github-team-deployments';
import { LandingPageContent } from '@internal/plugin-landing-page';
import {
  EntityOwnershipCardWithActions,
  GroupProfileCard,
  GroupProfileChildrenCards,
} from '@internal/plugin-team-space';
import { TechInsightsCheckID } from '@internal/plugin-techinsights-module-entities';
import { WatchtowerDashboardPage } from '@internal/plugin-watchtower-dashboard';
import {
  EntityGrafanaAlertsCard,
  EntityGrafanaDashboardsCard,
  isAlertSelectorAvailable,
  isDashboardSelectorAvailable,
} from '@k-phoen/backstage-plugin-grafana';
import React from 'react';

import { GithubIssuesPage } from '@backstage-community/plugin-github-issues';
import { EntityTechInsightsScorecardCard } from '@backstage-community/plugin-tech-insights';
import { Entity, RELATION_PARENT_OF } from '@backstage/catalog-model';
import {
  EntityLayout,
  EntityLinksCard,
  EntitySwitch,
} from '@backstage/plugin-catalog';
import { EntityMembersListCard } from '@backstage/plugin-org';

import { Grid } from '@material-ui/core';

import { ActionContent, EntityWarningContent } from '../Content';
import {
  isLinksAvailable,
  isLandingPageEnabled,
  isTeamOnboardingInProgress,
} from '../entityUtils';

const isTeam = (entity: Entity) =>
  !entity?.relations?.find(item => item.type === RELATION_PARENT_OF);

const isEnabledForTeam = (entity: Entity) =>
  !isLandingPageEnabled(entity) && isTeam(entity);

const isTagSelectorAvailable = (entity: Entity) =>
  Boolean(isDashboardSelectorAvailable(entity));

const isTeamOnboardingInCompleted = (entity: Entity) =>
  isTeamOnboardingInProgress(entity) && isTeam(entity);

export const GroupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Landing Page" if={isLandingPageEnabled}>
      <LandingPageContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/overview" title="Overview">
      <Grid container spacing={3}>
        <EntityWarningContent />
        {ActionContent}
        <Grid item xs={12} md={6}>
          <GroupProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCardWithActions
            variant="gridItem"
            relationsType="aggregated"
            entityFilterKind={[
              'KaaPAccount',
              'Component',
              'Resource',
              'Template',
              'API',
              'System',
              'Repository',
            ]}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <GroupProfileChildrenCards variant="gridItem" />
        </Grid>

        <Grid item xs={12} md={6}>
          <EntitySwitch>
            <EntitySwitch.Case if={isLinksAvailable}>
              <Grid item xs={12}>
                <EntityLinksCard />
              </Grid>
            </EntitySwitch.Case>
          </EntitySwitch>
        </Grid>

        <EntitySwitch>
          <EntitySwitch.Case if={isTeamOnboardingInCompleted}>
            <Grid item xs={12} md={6}>
              <EntityTechInsightsScorecardCard
                title="Team onboarding statuts"
                checksId={[
                  TechInsightsCheckID.MANAGER_CHECK,
                  TechInsightsCheckID.SLACK_CHANNEL_CHECK,
                  TechInsightsCheckID.BUG_REPORT_CHECK,
                  TechInsightsCheckID.COMPONENT_OR_API_CHECK,
                ]}
              />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>

        <EntitySwitch>
          <EntitySwitch.Case if={isTeam}>
            <Grid item xs={12}>
              <EntityMembersListCard />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/azure" title="Azure" if={isEnabledForTeam}>
      <AzureContent />
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/dashboard"
      title="Grafana Dashboard"
      if={isTagSelectorAvailable || isAlertSelectorAvailable}
    >
      <Grid container spacing={3}>
        <EntitySwitch>
          <EntitySwitch.Case if={isTagSelectorAvailable}>
            <Grid item sm={6}>
              <EntityGrafanaDashboardsCard />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>

        <EntitySwitch>
          <EntitySwitch.Case if={isAlertSelectorAvailable}>
            <Grid item sm={6}>
              <EntityGrafanaAlertsCard />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/pull-requests"
      title="Pull Requests"
      if={isEnabledForTeam}
    >
      <EntityTeamPullRequestsContent />
    </EntityLayout.Route>

    <EntityLayout.Route
      path="github-issues"
      title="GitHub Issues"
      if={isEnabledForTeam}
    >
      <GithubIssuesPage />
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/github-actions"
      title="CI/CD"
      if={isEnabledForTeam}
    >
      <EntitySwitch>
        <EntitySwitch.Case if={isGithubTeamActionsDisabled}>
          <Grid item sm={12}>
            <GithubTeamActionsPage />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/github-deployments"
      title="Github Deployments"
      if={isEnabledForTeam}
    >
      <EntitySwitch>
        <EntitySwitch.Case if={isGithubTeamDeploymentDisabled}>
          <Grid item sm={12}>
            <GithubTeamDeploymentsPage />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/team-watchtower-dashboard"
      title="Metrics Dashboard"
    >
      <Grid item sm={12}>
        <WatchtowerDashboardPage />
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/scorecard" title="Scorecard">
      <Grid item sm={12}>
        <TeamScoreCard />
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);
