import {
  DiscoveryApi,
  FetchApi,
  createApiRef,
} from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';

import {
  RECOGNITION_EFFECTIVE_NAME,
  Tommee,
  TommeeDetails,
  TommeeRequest,
  TommeeRequestInfo,
  TommeeOwner,
  TommeeUsers,
  RecognitionApi,
  UserTommee,
  TommeeStatus,
  CreateTommeeData,
  PendingTomeeRequest,
} from './RecognitionApi';

export class RecognitionApiClient implements RecognitionApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }
  async requestTommee(
    request: TommeeRequest,
  ): Promise<{ tommee_id: number; user_ref: string }> {
    const baseUrl = await this.discoveryApi.getBaseUrl('recognition');

    const resp = await this.fetchApi.fetch(
      `${baseUrl}/${request.tommeeId}/request`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
      },
    );
    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }
    return await resp.json();
  }
  async requestTommees(
    tommeeId: number,
    requests: TommeeRequestInfo[],
  ): Promise<{ tommee_id: number; user_ref: string }[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('recognition');

    const resp = await this.fetchApi.fetch(`${baseUrl}/${tommeeId}/requests`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requests),
    });
    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }
    return await resp.json();
  }

  async getPendingRequests(): Promise<PendingTomeeRequest[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('recognition');

    const resp = await this.fetchApi.fetch(`${baseUrl}/user/requests`, {
      method: 'GET',
    });

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    return await resp.json();
  }

  async approveRequest(tommeeId: number, userRef: string): Promise<void> {
    const baseUrl = await this.discoveryApi.getBaseUrl('recognition');
    const resp = await this.fetchApi.fetch(
      `${baseUrl}/${tommeeId}/request/${encodeURIComponent(userRef)}/approve`,
      {
        method: 'POST',
      },
    );

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }
  }

  async rejectRequest(tommeeId: number, userRef: string): Promise<void> {
    const baseUrl = await this.discoveryApi.getBaseUrl('recognition');
    const resp = await this.fetchApi.fetch(
      `${baseUrl}/${tommeeId}/request/${encodeURIComponent(userRef)}/reject`,
      {
        method: 'POST',
      },
    );

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }
  }

  async getCurrentUserTommees(): Promise<UserTommee[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('recognition');

    const resp = await this.fetchApi.fetch(`${baseUrl}/user`, {
      method: 'GET',
    });

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    return await resp.json();
  }

  async getUserTommees(userId: string): Promise<UserTommee[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('recognition');

    const resp = await this.fetchApi.fetch(
      `${baseUrl}/${encodeURIComponent(userId)}/tommees`,
      {
        method: 'GET',
      },
    );

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    return await resp.json();
  }

  async getTommeeUsers(tommeeId: number): Promise<TommeeUsers> {
    const baseUrl = await this.discoveryApi.getBaseUrl('recognition');

    const resp = await this.fetchApi.fetch(`${baseUrl}/${tommeeId}`, {
      method: 'GET',
    });

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    return await resp.json();
  }

  async getUsersByTommees(
    status?: TommeeStatus,
  ): Promise<{ [key in number]: string[] }> {
    const baseUrl = await this.discoveryApi.getBaseUrl('recognition');

    const statusQuery = status ? `status=${status}` : '';
    const resp = await this.fetchApi.fetch(`${baseUrl}/users?${statusQuery}`, {
      method: 'GET',
    });

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    const usersByTommee = (await resp.json()) as {
      tommeeId: number;
      user: string;
    }[];
    const result: { [key in number]: string[] } = {};
    for (const user of usersByTommee) {
      if (result[user.tommeeId]) {
        result[user.tommeeId].push(user.user);
      } else {
        result[user.tommeeId] = [user.user];
      }
    }
    return result;
  }

  async getTommees(): Promise<Tommee[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('recognition');

    const resp = await this.fetchApi.fetch(`${baseUrl}`, {
      method: 'GET',
    });

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    const result: Tommee[] = await resp.json();
    return result.sort(tommee => (tommee.owned ? -1 : 1));
  }

  async getTommee(tommeeId: number): Promise<TommeeDetails> {
    const baseUrl = await this.discoveryApi.getBaseUrl('recognition');

    const resp = await this.fetchApi.fetch(`${baseUrl}/${tommeeId}`, {
      method: 'GET',
    });
    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }
    return await resp.json();
  }

  async getOwnersByTommees(): Promise<TommeeOwner[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('recognition');

    const resp = await this.fetchApi.fetch(`${baseUrl}/owners`, {
      method: 'GET',
    });
    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }
    return await resp.json();
  }

  async createTommee(
    tommeeData: CreateTommeeData,
    ownerRefs: string[],
  ): Promise<string> {
    const baseUrl = await this.discoveryApi.getBaseUrl('recognition');

    const resp = await this.fetchApi.fetch(`${baseUrl}/tommee`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ tommeeData, ownerRefs }),
    });
    if (!resp.ok) {
      const errorMessage = await this.getErrorMessageFromResponse(resp);
      throw new Error(errorMessage);
    }
    return resp.json();
  }

  async editTommee(
    tommeeId: number,
    tommeeData: CreateTommeeData,
    ownerRefs: string[],
  ): Promise<string> {
    const baseUrl = await this.discoveryApi.getBaseUrl('recognition');

    const resp = await this.fetchApi.fetch(`${baseUrl}/${tommeeId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ tommeeData, ownerRefs }),
    });
    if (!resp.ok) {
      const errorMessage = await this.getErrorMessageFromResponse(resp);
      throw new Error(errorMessage);
    }
    return resp.json();
  }

  async deleteTommee(tommeeId: number): Promise<string> {
    const baseUrl = await this.discoveryApi.getBaseUrl('recognition');

    const resp = await this.fetchApi.fetch(`${baseUrl}/${tommeeId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (!resp.ok) {
      throw new Error('Could not delete');
    }
    return resp.json();
  }

  private getErrorMessageFromResponse = async (
    response: Response,
  ): Promise<string> => {
    const errorResponse = await ResponseError.fromResponse(response);
    let errorMessage = 'root:Error when posting data';

    if (
      errorResponse.cause.message.includes(
        'duplicate key value violates unique constraint "tommees_name_unique"',
      )
    ) {
      errorMessage = `name:A ${RECOGNITION_EFFECTIVE_NAME} with this name already exists`;
    } else if (errorResponse.cause.name === 'PayloadTooLargeError') {
      errorMessage = 'icon:Image too large';
    }

    return errorMessage;
  };
}

export const recognitionApiRef = createApiRef<RecognitionApi>({
  id: 'plugin.recognition.service',
});
