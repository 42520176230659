import React, { Suspense } from 'react';

import { Progress } from '@backstage/core-components';

const LazyOpenApiGovernance = React.lazy(() =>
  import('./OpenApiGovernance').then(m => ({
    default: m.OpenApiGovernance,
  })),
);

/** @public */
export type OpenApiGovernanceWidgetProps = {
  lint: string;
};

/** @public */
export const OpenApiGovernanceWidget = (
  props: OpenApiGovernanceWidgetProps,
) => {
  return (
    <Suspense fallback={<Progress />}>
      <LazyOpenApiGovernance {...props} />
    </Suspense>
  );
};
