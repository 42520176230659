import {
  PluginEndpointDiscovery,
  TokenManager,
} from '@backstage/backend-common';
import { CatalogClient } from '@backstage/catalog-client';
import { stringifyEntityRef } from '@backstage/catalog-model';

export const getTeamComponentOrAPIEntities = async (options: {
  tokenManager: TokenManager;
  discovery: PluginEndpointDiscovery;
}) => {
  const { tokenManager, discovery } = options;
  const { token } = await tokenManager.getToken();
  const catalogClient = new CatalogClient({
    discoveryApi: discovery,
  });
  const groupEntityResponse = await catalogClient.getEntities(
    {
      filter: {
        kind: ['Group'],
      },
    },
    { token },
  );

  return Promise.allSettled(
    groupEntityResponse?.items.map(async entity => {
      const ownedGroupEntities = await catalogClient.getEntities(
        {
          filter: {
            kind: ['Group'],
            'relations.ownedBy': stringifyEntityRef(entity),
          },
        },
        { token },
      );
      const ownedComponentOrAPIEntities = await catalogClient.getEntities(
        {
          filter: {
            kind: ['Component', 'API'],
            'relations.ownedBy': stringifyEntityRef(entity),
          },
        },
        { token },
      );

      return {
        namespace: entity.metadata.namespace ?? 'default',
        kind: entity.kind,
        name: entity.metadata.name,
        ownedComponentOrAPICount:
          ownedGroupEntities?.items.length > 0
            ? 1 // if the the current group entity owns another group, already return a, so that the checker always returns true
            : ownedComponentOrAPIEntities?.items?.length ?? 0,
      };
    }),
  );
};
