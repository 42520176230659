/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { Entity, UserEntity } from '@backstage/catalog-model';
import { CatalogApi } from '@backstage/plugin-catalog-react';

import { TomtomLabApi } from '../api';
import { Assessment, Idea, Member } from '../types';
import { parseMember } from './parseMethods';
import { sortMembers } from './sortMethods';

export const fetchIdeaMembers = async (
  client: TomtomLabApi,
  idea: Idea,
): Promise<Member[]> => {
  if (idea.id) {
    const response = await client.getMembers(idea.id);

    if (response.data.length > 0) {
      const dbMembers = response.data.map((member: any) => {
        return parseMember(member);
      });

      dbMembers.sort(sortMembers);
      return dbMembers;
    }
  }
  return [];
};

export const fetchSelfAssessments = async (
  client: TomtomLabApi,
  idea: Idea,
): Promise<Assessment | null | undefined> => {
  if (idea.id) {
    const response = await client.getSelfAssessment(idea.id);

    if (response) {
      return response as Assessment;
    }
  }
  return null;
};

export const fetchCatalogItems = async (
  catalogApi: CatalogApi,
): Promise<Entity[]> => {
  const entities = await catalogApi.getEntities({
    filter: {
      kind: ['Component', 'Resource'],
    },
    fields: ['kind', 'metadata.name', 'metadata.namespace'],
  });

  return entities.items;
};

export const fetchUser = async (
  catalogApi: CatalogApi,
  entityRef: string,
): Promise<UserEntity | undefined> => {
  const user = await catalogApi.getEntityByRef(entityRef);
  return user as UserEntity;
};
