import { Entity } from '@backstage/catalog-model';

export const GITHUB_TEAM_ACTIONS = 'enable-github-team-cicd';

/** @public */
export const isGithubTeamActionsDisabled = (entity: Entity) =>
  (entity?.metadata.annotations?.[GITHUB_TEAM_ACTIONS] ?? 'yes') === 'yes';

export {
  githubTeamActionsPlugin,
  GithubTeamActionsPage,
  HomePagePipelineStatus,
} from './plugin';
