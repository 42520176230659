import React from 'react';

import { Link } from '@backstage/core-components';

import { Box, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export const NonParticipantAlert = () => (
  <Box marginBottom="10px">
    <Alert severity="warning">
      It seems that you are not registered yet. Before you can join a team to
      work on an idea, you must{' '}
      <Typography variant="body2" component="strong">
        <Link
          to="/tomtom-lab/register-participant"
          variant="body2"
          color="secondary"
        >
          register
        </Link>
      </Typography>{' '}
      yourself as a participant.
    </Alert>
  </Box>
);
