/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { Idea, IdeaSolution, Member, Event } from '../types';

export const parseIdea = (metadata: any): Idea => {
  let parsedSolutions;

  if (typeof metadata.solutions === 'string') {
    parsedSolutions = JSON.parse(metadata.solutions);
  } else {
    parsedSolutions = metadata.solutions;
  }

  const solutions: IdeaSolution[] = parsedSolutions.filter(
    (s: IdeaSolution) => s.eventId === metadata.event_id,
  );

  return {
    id: metadata.id,
    entityRef: metadata.entity_ref,
    title: metadata.title,
    description: metadata.description,
    status: metadata.status,
    updatedAt: metadata.updated_at,
    membersCount: metadata.members_count,
    size: metadata.size,
    startDate: metadata.start_date,
    endDate: metadata.end_date,
    responsible: metadata.responsible,
    docs: metadata.docs,
    summary: metadata.summary,
    solution: solutions.length > 0 ? solutions[0].details : '',
    eventId: metadata.event_id,
  } as Idea;
};

export const parseMember = (member: any): Member => {
  return {
    itemId: member.item_id,
    userId: member.user_id,
    userRef: member.user_ref,
    joinDate: member.join_date,
    picture: member.picture,
  } as Member;
};

export const parseEvent = (event: any): Event => {
  return {
    id: event.id,
    title: event.title,
    description: event.description,
    startDate: event.startDate,
    endDate: event.endDate,
    isActive: event.isActive,
    subEventOf: event.subEventOf,
    organizerRef: event.organizerRef,
  } as Event;
};

export const parseTomtomLabResponse = async (response: any) => {
  if (response) {
    const metadata = await response.json().then((resp: any) => resp.data[0]);
    if (metadata) {
      return parseIdea(metadata);
    }
  }
  return null;
};

export const isSharedIdeaLink = () => {
  const path = window.location.href.split('/');
  return path.at(-1) === 'ideas' ? false : true;
};

export const isIdeaLink = (ideaId: number) => {
  const path = window.location.href.split('/');
  return path.at(path.length - 1) === String(ideaId) ? true : false;
};

export const getSharedIdeaId = () => {
  const path = window.location.href.split('/');
  return path.at(-1);
};

export const redirectToIdeasUrl = () => {
  const path = window.location.href.split('ideas');
  window.history.replaceState(null, 'Ideas', `${path[0]}ideas`);
};
