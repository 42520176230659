import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  formControl: {
    width: '10rem',
    flexDirection: 'row',
    display: 'inline-flex',
    justifyContent: 'space-between',
  },
  select: {
    fontSize: 'medium',
    fontWeight: 'bold',
    width: '16rem',
  },
});
