import { GithubRepositoryWorkflowJob } from '@internal/plugin-catalog-model';

// normal action should be included but re-usable workflow should be excluded,
// check the regex match here: https://regex101.com/r/5vfrES/1
const isUseAction = (uses: string): boolean => {
  return (
    uses.match(
      /^[a-zA-Z]+[a-zA-Z0-9_-]*\/[a-zA-Z]+[a-zA-Z0-9_-]*\@[a-zA-Z]+[a-zA-Z0-9_-]*/g,
    ) !== null
  );
};

export const getActions = (
  jobs: Record<string, GithubRepositoryWorkflowJob>,
): string[] => {
  const actions: string[] = [];
  for (const [_, job] of Object.entries(jobs)) {
    job.steps?.map(step => {
      if (step.uses && isUseAction(step.uses)) {
        actions.push(step.uses);
      }
    });
  }
  return [...new Set(actions)];
};
