import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { IdentityApi } from '@backstage/core-plugin-api';
import { CatalogApi } from '@backstage/plugin-catalog-react';

import { arrayToObjectReducer } from '../utils';

const formateSupOrgGroupName = (groupName: string | undefined) => {
  const formattedGroupName = groupName
    ?.toLocaleLowerCase()
    .trim()
    .split(' (')[0]
    .replace(/[^a-zA-Z0-9_\-\.]/g, ' ')
    .replaceAll(/\s+/g, ' ')
    .replaceAll(' ', '_');
  return `rg_az_suporg_${formattedGroupName}`;
};

const getUserSupOrgGroup = async (
  identityApi?: IdentityApi,
  catalogApi?: CatalogApi,
) => {
  const backstageIdentity = await identityApi?.getBackstageIdentity();

  if (backstageIdentity) {
    const userEntity = await catalogApi?.getEntityByRef(
      backstageIdentity.userEntityRef,
    );
    return formateSupOrgGroupName(
      userEntity?.metadata.annotations?.[
        'graph.microsoft.com/supervisory-org-name'
      ],
    );
  }

  return undefined;
};

const getTeamRepositories = async (
  entity: Entity,
  catalogApi?: CatalogApi,
): Promise<string[]> => {
  if (!catalogApi) {
    return [];
  }
  const repoEntities = await catalogApi.getEntities({
    filter: {
      kind: ['Repository'],
      'relations.ownedBy': stringifyEntityRef(entity),
    },
  });

  return repoEntities?.items.map(repoEntity => {
    return `repository:default/${repoEntity.metadata.name}`;
  });
};

export const githubRepositoryOwnershipFormDataBuilder = async (
  entity: Entity,
  formFields: string[],
  identityApi?: IdentityApi,
  catalogApi?: CatalogApi,
) => {
  const team = await getUserSupOrgGroup(identityApi, catalogApi);

  const formData = formFields
    .map((field: string) => {
      let data: { [key in string]: string | undefined };

      switch (field) {
        case 'repository':
          data = { [field]: `repository:default/${entity.metadata.name}` };
          break;
        case 'team':
          data = { [field]: team ? `group:default/${team}` : '' };
          break;
        default:
          data = {};
          break;
      }

      return data;
    })
    .reduce(arrayToObjectReducer, {});

  return formData;
};

export const githubBatchRepositoryOwnershipFormDataBuilder = async (
  entity: Entity,
  formFields: string[],
  identityApi?: IdentityApi,
  catalogApi?: CatalogApi,
) => {
  const team = await getUserSupOrgGroup(identityApi, catalogApi);

  const repositories = await getTeamRepositories(entity, catalogApi);

  const formData = formFields
    .map((field: string) => {
      let data: { [key in string]: string | string[] | undefined };

      switch (field) {
        case 'repositories':
          data = {
            [field]: repositories,
          };
          break;
        case 'team':
          data = { [field]: team ? `group:default/${team}` : '' };
          break;
        default:
          data = {};
          break;
      }

      return data;
    })
    .reduce(arrayToObjectReducer, {});

  return formData;
};
