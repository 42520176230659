import React, { useCallback, useEffect, useMemo } from 'react';
import { useAsync } from 'react-use';

import {
  CATALOG_FILTER_EXISTS,
  EntityFilterQuery,
} from '@backstage/catalog-client';
import {
  Entity,
  parseEntityRef,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import {
  errorApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { NotFoundError } from '@backstage/errors';
import {
  catalogApiRef,
  humanizeEntityRef,
} from '@backstage/plugin-catalog-react';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  AutocompleteChangeReason,
} from '@material-ui/lab/Autocomplete';

import { MyCostCentersPickerProps, MyCostCentersPickerSchema } from './schema';

export { MyCostCentersPickerSchema };

export const MyCostCentersPicker = (props: MyCostCentersPickerProps) => {
  const identityApi = useApi(identityApiRef);
  const catalogApi = useApi(catalogApiRef);
  const errorApi = useApi(errorApiRef);

  const {
    schema: { title = 'Owner', description = 'The owner of the component' },
    uiSchema,
    idSchema,
    onChange,
    formData,
    required,
    rawErrors,
  } = props;

  const { value: entities, loading } = useAsync(async () => {
    const { userEntityRef } = await identityApi.getBackstageIdentity();

    if (!userEntityRef) {
      errorApi.post(new NotFoundError('No user entity ref found'));
      return undefined;
    }

    const catalogFilter: EntityFilterQuery = [
      {
        kind: 'Group',
        'relations.hasMember': userEntityRef,
        'metadata.annotations.workday.com/cost-center': CATALOG_FILTER_EXISTS,
      },
    ];
    const fields = ['metadata.name', 'metadata.namespace', 'kind'];

    const { items } = await catalogApi.getEntities({
      filter: catalogFilter,
      fields,
    });

    return items;
  });

  const entityRefDefaults = useMemo(
    () => ({
      defaultKind: 'Group',
      defaultNamespace: 'default',
    }),
    [],
  );

  const getLabel = useCallback(
    (ref: string) => {
      try {
        return humanizeEntityRef(
          parseEntityRef(ref, entityRefDefaults),
          entityRefDefaults,
        );
      } catch (err) {
        return ref;
      }
    },
    [entityRefDefaults],
  );

  const allowArbitraryValues =
    uiSchema['ui:options']?.allowArbitraryValues ?? true;

  const onSelect = useCallback(
    (_: any, ref: string | Entity | null, reason: AutocompleteChangeReason) => {
      // ref can either be a string from free solo entry or
      if (typeof ref !== 'string') {
        // if ref does not exist: pass 'undefined' to trigger validation for required value
        onChange(ref ? stringifyEntityRef(ref as Entity) : undefined);
      } else {
        if (reason === 'blur' || reason === 'create-option') {
          // Add in default namespace, etc.
          let entityRef = ref;
          try {
            // Attempt to parse the entity ref into it's full form.
            entityRef = stringifyEntityRef(
              parseEntityRef(ref as string, entityRefDefaults),
            );
          } catch (err) {
            // If the passed in value isn't an entity ref, do nothing.
          }
          // We need to check against formData here as that's the previous value for this field.
          if (formData !== ref || allowArbitraryValues) {
            onChange(entityRef);
          }
        }
      }
    },
    [onChange, formData, allowArbitraryValues, entityRefDefaults],
  );

  // Since free solo can be enabled, attempt to parse as a full entity ref first, then fall
  // back to the given value.
  const selectedEntity =
    entities?.find(e => stringifyEntityRef(e) === formData) ??
    (allowArbitraryValues && formData ? getLabel(formData) : '');

  useEffect(() => {
    if (entities?.length === 1 && selectedEntity === '') {
      onChange(stringifyEntityRef(entities[0]));
    }
  }, [entities, onChange, selectedEntity]);

  return (
    <FormControl
      margin="dense"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <Autocomplete
        disabled={entities?.length === 1}
        id={idSchema?.$id}
        value={selectedEntity}
        loading={loading}
        onChange={onSelect}
        options={entities || []}
        getOptionLabel={option =>
          // option can be a string due to freeSolo.
          typeof option === 'string'
            ? option
            : humanizeEntityRef(option, entityRefDefaults)!
        }
        autoSelect
        freeSolo={!!allowArbitraryValues}
        renderInput={params => (
          <TextField
            {...params}
            label={title}
            helperText={description}
            FormHelperTextProps={{ margin: 'dense', style: { marginLeft: 0 } }}
            required={required}
            InputProps={params.InputProps}
          />
        )}
      />
    </FormControl>
  );
};
