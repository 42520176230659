import axios from 'axios';
import https from 'https';

import { FactRetrieverContext } from '@backstage-community/plugin-tech-insights-node';
import { Entity } from '@backstage/catalog-model';

import {
  ServiceAnnotationFact,
  SonarqubeQualityGateFact,
  TypedTechInsightFact,
} from '../../types';
import { ServiceOperationalTier } from '../../types';
import { ServiceOperationalStatus } from '../../types';
import { getEntities } from '../utils/getEntities';
import { metricKeys, parseSonarqubeStats } from './sonarqubeUtils';

function generateFact(entity: Entity, facts: Record<string, any>) {
  return {
    entity: {
      namespace: entity.metadata.namespace!,
      kind: entity.kind,
      name: entity.metadata.name,
    },
    facts: facts,
  };
}

function isSlackChannelSet(entity: Entity): boolean {
  return Boolean(entity.metadata.annotations?.['slack.com/channel']);
}
function isSonarQubeProjectSet(entity: Entity): boolean {
  return Boolean(entity.metadata.annotations?.['sonarqube.org/project-key']);
}
function isMonitoringDashboardSet(entity: Entity): boolean {
  return Boolean(entity.metadata.annotations?.['monitoring/dashboard']);
}
function isPagerDutyIntegrationSet(entity: Entity): boolean {
  return Boolean(
    entity.metadata.annotations?.['pagerduty.com/integration-key'],
  );
}

type OperationalStatus = {
  hasOperationalStatusDefined: boolean;
  hasOperationalTierDefined: boolean;
};

const validOperationalStatusValues = new Set(
  Object.values(ServiceOperationalStatus),
);
const validOperationalTierValues = new Set(
  Object.values(ServiceOperationalTier),
);

function getOperationalStatus(entity: Entity): OperationalStatus {
  const { lifecycle, operationalTier } = entity?.spec || {};
  const status =
    lifecycle &&
    validOperationalStatusValues.has(
      (lifecycle as string).toLowerCase() as ServiceOperationalStatus,
    )
      ? true
      : false;
  const tier =
    operationalTier &&
    validOperationalTierValues.has(
      (operationalTier as string).toLowerCase() as ServiceOperationalTier,
    )
      ? true
      : false;

  return {
    hasOperationalStatusDefined: status,
    hasOperationalTierDefined: tier,
  };
}

export const serviceBasicFactsHandler = async ({
  logger,
  discovery,
  entityFilter,
  tokenManager,
}: FactRetrieverContext): Promise<
  TypedTechInsightFact<ServiceAnnotationFact>[]
> => {
  const entities = await getEntities({
    logger,
    entityFilter,
    tokenManager,
    discovery,
  });

  return entities.items.map((entity: Entity) => {
    const hasSlackChannel = isSlackChannelSet(entity);
    const hasSonarQubeProject = isSonarQubeProjectSet(entity);
    const hasMonitoringDashboard = isMonitoringDashboardSet(entity);
    const hasPagerDutyIntegration = isPagerDutyIntegrationSet(entity);
    const { hasOperationalStatusDefined, hasOperationalTierDefined } =
      getOperationalStatus(entity);
    return generateFact(entity, {
      hasSlackChannel,
      hasSonarQubeProject,
      hasMonitoringDashboard,
      hasPagerDutyIntegration,
      hasOperationalStatusDefined,
      hasOperationalTierDefined,
    });
  });
};

export const createSonarqubeQualityGateFactsHandler =
  (sonarEndpoint: string, token: string) =>
  async ({
    logger,
    discovery,
    entityFilter,
    tokenManager,
  }: FactRetrieverContext): Promise<
    TypedTechInsightFact<SonarqubeQualityGateFact>[]
  > => {
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({ rejectUnauthorized: false }),
    });

    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
    const entities = await getEntities({
      logger,
      entityFilter,
      tokenManager,
      discovery,
    });
    const defaultStatusCheckResults = {
      passesSecurityReview: false,
      hasVulnerabilities: false,
      hasSecurityHotspots: false,
      totalIssues: 0,
      qualityGatePassed: false,
      hasSufficientCodeCoverage: false,
    };

    const entitiesPromises = entities.items.map(async (entity: Entity) => {
      const sonarqubeProjectKey =
        entity.metadata.annotations?.['sonarqube.org/project-key'];
      if (sonarqubeProjectKey) {
        const response = await axiosInstance.get(
          `${sonarEndpoint}/api/measures/component`,
          {
            params: {
              component: sonarqubeProjectKey,
              metricKeys: metricKeys.join(','),
            },
          },
        );
        const { status, data } = response;
        if (status !== 200) {
          logger.error(
            `sonarqubeQualityGateFactsHandler: Failed to fetch quality gate status for ${sonarqubeProjectKey}, ${status}, ${JSON.stringify(
              data,
            )}`,
          );
        }
        const sonarqubeStatusCheckResults = parseSonarqubeStats(data);
        return generateFact(entity, sonarqubeStatusCheckResults);
      }
      return generateFact(entity, defaultStatusCheckResults);
    });
    const results = await Promise.allSettled(entitiesPromises);
    // @ts-ignore
    return results.filter(r => r.status === 'fulfilled').map(r => r.value);
  };
