export enum TechInsightsRetrieverIDs {
  GITHUB_REPO_BRANCH_PROTECTION_FACT_RETRIEVER = 'githubRepoBranchProtectionFactRetriever',
  GITHUB_REPO_CI_MEDIAN_BUILD_DURATION_FACT_RETRIEVER = 'githubRepoCIMedianBuildDurationFactRetriever',
  GITHUB_REPO_CI_PR_MEDIAN_TIME_TO_MERGE_FACT_RETRIEVER = 'githubRepoCIPRMedianTimeToMergeFactRetriever',
  GITHUB_REPO_CI_SUCCESS_FACT_RETRIEVER = 'githubRepoCISuccessFactRetriever',
  GITHUB_REPO_HAS_README_FACT_RETRIEVER = 'githubRepoHasReadmeFactRetriever',
  GITHUB_REPO_USES_CONVENTIONAL_COMMITS_FACT_RETRIEVER = 'githubRepoUsesConventionalCommitsFactRetriever',
  GITHUB_ACTIONS_FACT_RETRIEVER = 'githubActionsFactRetriever',
  GROUP_REPOSITORY_ENTITY_FACT_RETRIEVER = 'groupRepositoryEntityFactRetriever',
  GITHUB_WORKFLOW_ENTITY_FACT_RETRIEVER = 'githubWorkflowEntityFactRetriever',
  GITHUB_REPOSITORY_HAS_DEPENDABOT_PRS_FACT_RETRIEVER = 'githubRepositoryHasDependabotPRsFactRetriever',
  SERVICE_BASIC_FACT_RETRIEVER = 'serviceBasicFactsRetriever',
  SONARQUBE_FACTS_RETRIEVER = 'sonarqubeQualityGateRetriever',
}
