import React from 'react';

import { TableColumn } from '@backstage/core-components';

import { Button } from '@material-ui/core';

import { ResourceType } from '../types';

const namespaceCols: TableColumn[] = [
  {
    title: 'name',
    field: 'name',
    highlight: true,
  },
  {
    title: 'status',
    field: 'status',
  },
  {
    title: 'age',
    field: 'age',
  },
];

const serviceCols: TableColumn[] = [
  {
    title: 'namespace',
    field: 'namespace',
  },
  {
    title: 'name',
    field: 'name',
    highlight: true,
  },
  {
    title: 'type',
    field: 'type',
  },
  {
    title: 'clusterIP',
    field: 'clusterIP',
  },
  {
    title: 'externalIP',
    field: 'externalIP',
  },
  {
    title: 'port(s)',
    field: 'port',
  },
  {
    title: 'age',
    field: 'age',
  },
];

const ingressCols: TableColumn[] = [
  {
    title: 'namespace',
    field: 'namespace',
  },
  {
    title: 'name',
    field: 'name',
    highlight: true,
  },
  {
    title: 'class',
    field: 'class',
  },
  {
    title: 'hosts',
    field: 'hosts',
  },
  {
    title: 'address',
    field: 'address',
  },
  {
    title: 'port(s)',
    field: 'port',
  },
  {
    title: 'age',
    field: 'age',
  },
];

const deploymentCols: TableColumn[] = [
  {
    title: 'namespace',
    field: 'namespace',
  },
  {
    title: 'name',
    field: 'name',
    highlight: true,
  },
  {
    title: 'ready',
    field: 'ready',
  },
  {
    title: 'up-to-date',
    field: 'upToDate',
  },
  {
    title: 'available',
    field: 'available',
  },
  {
    title: 'age',
    field: 'age',
  },
];

const podCols: TableColumn[] = [
  {
    title: 'namespace',
    field: 'namespace',
  },
  {
    title: 'name',
    field: 'name',
    highlight: true,
  },
  {
    title: 'ready',
    field: 'ready',
  },
  {
    title: 'status',
    field: 'status',
  },
  {
    title: 'restarts',
    field: 'restarts',
  },
  {
    title: 'age',
    field: 'age',
  },
];

const replicaSetCols: TableColumn[] = [
  {
    title: 'namespace',
    field: 'namespace',
  },
  {
    title: 'name',
    field: 'name',
    highlight: true,
  },
  {
    title: 'desired',
    field: 'desired',
  },
  {
    title: 'current',
    field: 'current',
  },
  {
    title: 'ready',
    field: 'ready',
  },
  {
    title: 'age',
    field: 'age',
  },
];

const daemonSetCols: TableColumn[] = [
  {
    title: 'namespace',
    field: 'namespace',
  },
  {
    title: 'name',
    field: 'name',
    highlight: true,
  },
  {
    title: 'desired',
    field: 'desired',
  },
  {
    title: 'current',
    field: 'current',
  },
  {
    title: 'ready',
    field: 'ready',
  },
  {
    title: 'up-to-date',
    field: 'upToDate',
  },
  {
    title: 'available',
    field: 'available',
  },
  {
    title: 'NodeSelector',
    field: 'nodeSelector',
  },
  {
    title: 'age',
    field: 'age',
  },
];

const secretCols: TableColumn[] = [
  {
    title: 'namespace',
    field: 'namespace',
  },
  {
    title: 'name',
    field: 'name',
    highlight: true,
  },
  {
    title: 'type',
    field: 'type',
  },
  {
    title: 'data',
    field: 'data',
  },
  {
    title: 'age',
    field: 'age',
  },
];

const idColumn: TableColumn = {
  title: 'id',
  field: 'id',
  hidden: true,
};

const createDebugColumn = (
  resourceType: ResourceType,
  onClick: (type: ResourceType, name: string, namespace: string) => void,
): TableColumn => ({
  title: 'action',
  render: (row: any): React.ReactNode => (
    <Button
      size="small"
      variant="text"
      color="secondary"
      onClick={() =>
        onClick(resourceType, row?.name, row?.namespace ?? 'default')
      }
    >
      Debug
    </Button>
  ),
});

const createResourceTypeColumns = (
  resourceType: ResourceType,
  onClick: (type: ResourceType, name: string, namespace: string) => void,
): TableColumn[] => {
  const debugColumn = createDebugColumn(resourceType, onClick);

  switch (ResourceType[resourceType as unknown as keyof typeof ResourceType]) {
    case ResourceType.DAEMONSETS:
      return [debugColumn, idColumn, ...daemonSetCols];
    case ResourceType.DEPLOYMENTS:
      return [debugColumn, idColumn, ...deploymentCols];
    case ResourceType.INGRESSES:
      return [debugColumn, idColumn, ...ingressCols];
    case ResourceType.PODS:
      return [debugColumn, idColumn, ...podCols];
    case ResourceType.REPLICASETS:
      return [debugColumn, idColumn, ...replicaSetCols];
    case ResourceType.NAMESPACES:
      return [debugColumn, idColumn, ...namespaceCols];
    case ResourceType.SECRETS:
      return [debugColumn, idColumn, ...secretCols];
    case ResourceType.SERVICES:
      return [debugColumn, idColumn, ...serviceCols];

    default:
      return [debugColumn, idColumn];
  }
};

export const resourceColumnFactory = (
  onClick: (type: ResourceType, name: string, namespace: string) => void,
): Partial<Record<ResourceType, TableColumn[]>> => {
  const factories: Partial<Record<ResourceType, TableColumn[]>> = {};

  Object.keys(ResourceType).forEach(key => {
    factories[key as ResourceType] = createResourceTypeColumns(
      key as ResourceType,
      onClick,
    );
  });

  return factories;
};
