import React from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';

import StarImage from '../../../images/star.svg';
import { Tier } from '../../types';

const commonStyle = {
  border: '0.5px solid #7E7E7E',
  borderRadius: 10,
  borderHeight: 0.5,
  height: 22,
  width: '120px',
  display: 'flex',
  justifyContent: 'space-between',
};

const useStyles = makeStyles({
  Bronze: {
    ...commonStyle,
    backgroundColor: '#CD7F32',
  },
  Silver: {
    ...commonStyle,
    backgroundColor: '#C0C0C0',
  },
  Gold: {
    ...commonStyle,
    backgroundColor: '#FFD700',
  },
  Black: {
    ...commonStyle,
    backgroundColor: '#544573',
  },
  star: {
    width: 18,
    height: 18,
    marginLeft: 1,
  },
  label: {
    color: '#F3F9FD',
    textShadow: '0px 0px 2px black',
    marginLeft: 10,
  },
  stars: {
    marginRight: 10,
  },
  starsCompact: {
    marginRight: 5,
    marginLeft: 5,
  },
  compact: {
    width: 80,
    justifyContent: 'center',
    allignItems: 'center',
  },
});

export const TierBadge = ({
  tier,
  compactModeEnabled = false,
}: {
  tier: Tier;
  compactModeEnabled?: boolean;
}) => {
  const classes = useStyles();
  const getStarsByTier = (currentTier: Tier) => {
    const starsByTier = {
      Bronze: 1,
      Silver: 2,
      Gold: 3,
      Black: 0,
    };
    return Array.from({ length: starsByTier[currentTier] }).map((_, index) => (
      <img key={index} src={StarImage} alt="star" className={classes.star} />
    ));
  };

  const getClassForTier = (currentTier: string) => {
    return classes[currentTier as 'Bronze' | 'Silver' | 'Gold' | 'Black'];
  };
  return (
    <div
      className={`${getClassForTier(tier)} ${
        compactModeEnabled && classes.compact
      }`}
    >
      {!compactModeEnabled && (
        <Box className={classes.label}>
          <Typography variant="subtitle2" component="span">
            {!compactModeEnabled ? tier.toLocaleUpperCase() : ''}
          </Typography>
        </Box>
      )}
      <Box className={commonStyle ? classes.starsCompact : classes.stars}>
        {getStarsByTier(tier)}
      </Box>
    </div>
  );
};
