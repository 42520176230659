import { useAsync } from 'react-use';

import { identityApiRef, useApi } from '@backstage/core-plugin-api';

const administrators = [
  'user:default/dusan.milenkovic_tomtom.com',
  'user:default/armando.collazo_tomtom.com',
  'user:default/elias.rosenberg_tomtom.com',
  'user:default/silvia.longo_tomtom.com',
  'user:default/chris.poppe_tomtom.com',
  'user:default/maren.wilger_tomtom.com',
];

export const useIsAdministrator = () => {
  const identityApi = useApi(identityApiRef);
  const { value: userSession } = useAsync(async () =>
    identityApi.getBackstageIdentity(),
  );

  const userRef = userSession?.userEntityRef ?? '';

  return administrators.includes(userRef);
};
