export enum TechInsightsCheckID {
  MANAGER_CHECK = 'managerCheck',
  SLACK_CHANNEL_CHECK = 'slackChannelCheck',
  CONFLUENCE_CHECK = 'confluenceCheck',
  BUG_REPORT_CHECK = 'bugReportCheck',
  COMPONENT_CI_SUCCESS_CHECK = 'componentCISuccessCheck',
  COMPONENT_OR_API_CHECK = 'componentOrAPICheck',
  REPO_HAS_BRANCH_PROTECTION_CHECK = 'repoHashBranchProtectionCheck',
  REPO_HAS_README_CHECK = 'repoHasReadmeCheck',
  REPO_HAS_ZERO_OPEN_DEPENDABOT_PR_CHECK = 'repoHasZeroOpenDependabotPRCheck',
  REPO_USES_GITHUB_ACTIONS_CHECK = 'repoUsesGitHubActionsCheck',
  REPO_USES_OLD_CHECKOUT_ACTION_VERSION_CHECK = 'repoUsesOldCheckoutActionVersionCheck',
  // REPO_USES_LATEST_CHECKOUT_ACTION_VERSION_CHECK = 'repoUsesLatestCheckoutActionVersionCheck',
  REPO_USES_CONVENTIONAL_COMMITS_CHECK = 'repoUsesConventionalCommitsCheck',
  REPO_CI_MEDIAN_BUILD_DURATION_CHECK = 'repoCIMedianBuildDurationCheck',
  REPO_CI_MEDIAN_PR_TIME_TO_MERGE_CHECK = 'repoCIMedianPRTimeToMergeCheck',
  SERVICE_SLACK_CHANNEL_CHECK = 'serviceSlackChannelCheck',
  SERVICE_PAGERDUTY_INTEGRATION_CHECK = 'servicePagerDutyIntegrationCheck',
  SERVICE_SONARQUBE_INTEGRATION_CHECK = 'serviceSonarQubeIntegrationCheck',
  SERVICE_MONITORING_DASHBOARD_CHECK = 'serviceMonitoringDashboardCheck',
  SERVICE_OPERATIONAL_STATUS_CHECK = 'serviceOperationalStatusCheck',
  SERVICE_OPERATIONAL_TIER_CHECK = 'serviceOperationalTierCheck',
  SONARQUBE_QUALITY_GATE_CHECK = 'sonarqubeQualityGateCheck',
  SONARQUBE_CODE_COVERAGE_CHECK = 'sonarqubeCodeCoverageCheck',
  SONARQUBE_VULNERABILITIES_CHECK = 'sonarqubeVulnerabilitiesCheck',
  SONARQUBE_SECURITY_REVIEW_CHECK = 'sonarqubeSecurityReviewCheck',
  SONARQUBE_TOTAL_ISSUES_CHECK = 'sonarqubeTotalIssuesCheck',
  SONARQUBE_SECURITY_HOTSPOTS_CHECK = 'sonarqubeSecurityHotspotsCheck',
}
