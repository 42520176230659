import { JSON_RULE_ENGINE_CHECK_TYPE } from '@backstage-community/plugin-tech-insights-backend-module-jsonfc';

import { TechInsightsCheckID } from '../../checkIDs';
import { TechInsightsRetrieverIDs } from '../../retrieverIDs';

export const repoUsesOldCheckoutActionVersionCheck = {
  id: TechInsightsCheckID.REPO_USES_OLD_CHECKOUT_ACTION_VERSION_CHECK,
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: 'Repo uses old Checkout actions version',
  description: 'Verifies that the repo does not uses Checkout Actions v1 or v2',
  factIds: [TechInsightsRetrieverIDs.GITHUB_ACTIONS_FACT_RETRIEVER],
  rule: {
    conditions: {
      all: [
        {
          fact: 'jobs',
          path: '$.actions',
          operator: 'doesNotContain',
          value: 'actions/checkout@v4',
        },
        {
          fact: 'jobs',
          path: '$.actions',
          operator: 'doesNotContain',
          value: 'actions/checkout@v4',
        },
      ],
    },
  },
};
