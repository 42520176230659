import React from 'react';

import { OpenApiGovernanceWidget } from '../OpenApiGovernanceWidget';

/** @public */
export type ApiGovernanceWidget = {
  type: string;
  title: string;
  component: (lint: string) => React.ReactElement;
};

/** @public */
export function defaultGovernanceWidgets(): ApiGovernanceWidget[] {
  return [
    {
      type: 'openapi',
      title: 'OpenAPI',
      component: lint => <OpenApiGovernanceWidget lint={lint} />,
    },
  ];
}
