import { createApiRef } from '@backstage/core-plugin-api';

import { AzureResourcesApi } from './AzureResourcesApi';
import { AzureResourcesClient } from './AzureResourcesClient';

export const azureResourcesApiRef = createApiRef<AzureResourcesApi>({
  id: 'azure-resources',
});

export { type AzureResourcesApi, AzureResourcesClient };
