import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { IdentityApi } from '@backstage/core-plugin-api';
import { CatalogApi } from '@backstage/plugin-catalog-react';

import { arrayToObjectReducer } from '../utils';

export const batchUpdateEntityOwnersFormDataBuilder = async (
  entity: Entity,
  formFields: string[],
  _identityApi?: IdentityApi,
  catalogApi?: CatalogApi,
) => {
  // all the entities owned by the group
  const entityRefs =
    entity.relations?.filter(r => r.type === 'ownerOf').map(r => r.targetRef) ??
    [];

  const entities =
    (
      await catalogApi?.getEntitiesByRefs({
        entityRefs,
      })
    )?.items ?? [];

  // entities managed via urls
  const entitiesManagedByUrl = entities
    .filter(
      e =>
        e !== undefined &&
        e.metadata.annotations?.[
          'backstage.io/managed-by-location'
        ]?.startsWith('url:'),
    )
    .map(e => stringifyEntityRef(e!));

  const formData = formFields
    .map((field: string) => {
      let data: { [key in string]: string | string[] | undefined };

      switch (field) {
        case 'entities':
          data = {
            [field]: entitiesManagedByUrl,
          };
          break;
        default:
          data = {};
          break;
      }

      return data;
    })
    .reduce(arrayToObjectReducer, {});

  return formData;
};
