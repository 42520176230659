import { DateTime } from 'luxon';
import yaml from 'yaml';

import { FactRetrieverContext } from '@backstage-community/plugin-tech-insights-node';
import { ReadTreeResponse, UrlReader } from '@backstage/backend-common';
import { Entity, getEntitySourceLocation } from '@backstage/catalog-model';

export const getRepoSourceLocation = (entity: Entity) => {
  let sourceLocation;
  try {
    const sl = getEntitySourceLocation(entity);
    sourceLocation = sl.target;
  } catch (e: unknown) {
    // TODO: logging
  }
  return sourceLocation;
};

export const readWorkflowsDirectory = async (
  sourceLocation: string,
  reader: UrlReader,
) => {
  const url = `${sourceLocation}.github/workflows/`;
  const workflows = await reader.readTree(url);
  return workflows;
};

export const getWorkflowMetadata = async (
  workflows: ReadTreeResponse,
  ctx: FactRetrieverContext,
) => {
  const files = await workflows.files();
  const metadata = files.map(async file => {
    ctx.logger.info(`Reading ${file.path}`);

    const workflowContent = yaml.parse(
      (await file.content()).toString('utf-8'),
    );

    return Object.keys(workflowContent.jobs)
      .map(jobName => ({
        ...workflowContent.jobs[jobName],
        id: jobName,
      }))
      .map(job => ({
        workflowFile: file.path,
        jobId: job.id,
        steps: job.steps.filter((step: any) => step.uses),
      }));
  });
  return metadata;
};

export const mapToJobsWithActions = (
  jobs: { workflowFile: string; jobId: any; steps: any }[],
): Array<{ workflowFile: string; jobId: string; actions: string[] }> => {
  return jobs
    .map(
      (job: {
        workflowFile: string;
        jobId: string;
        steps: Array<{ uses: string; name: string }>;
      }) => ({
        workflowFile: job.workflowFile,
        jobId: job.jobId,
        actions: job.steps.map(step => step.uses),
      }),
    )
    .filter(
      (action: { workflowFile: string; jobId: string; actions: string[] }) =>
        action !== undefined && action.actions.length > 0,
    );
};

export function getRepoNameFromProjectSlug(entity: Entity): string | null {
  const projectSlug = entity.metadata.annotations?.['github.com/project-slug'];
  if (!projectSlug || !projectSlug.includes('/')) {
    return null;
  }
  const repoName = projectSlug.split('/')[1];
  return repoName;
}
export function weekRange(): { start: string; end: string } {
  // range is inclusive, so yesterday : yesterday-6 is 7 days
  const yesterday = DateTime.now().minus({ days: 1 });
  const lastWeek = yesterday.minus({ days: 6 });
  return {
    start: lastWeek.toFormat('yyyy-MM-dd'),
    end: yesterday.toFormat('yyyy-MM-dd'),
  };
}
