export enum CheckId {
  GroupOwnerCheck = 'groupOwnerCheck',
  TitleCheck = 'titleCheck',
  DescriptionCheck = 'descriptionCheck',
  TechDocsCheck = 'techDocsCheck',
  ServiceSlackChannelCheck = 'serviceSlackChannelCheck',
  RepoHasReadmeCheck = 'repoHasReadmeCheck',
  RepoHashBranchProtectionCheck = 'repoHashBranchProtectionCheck',
  RepoUsesConventionalCommitsCheck = 'repoUsesConventionalCommitsCheck',
  ComponentCISuccessCheck = 'componentCISuccessCheck',
  RepoHasZeroOpenDependabotPRCheck = 'repoHasZeroOpenDependabotPRCheck',
  ServicePagerDutyIntegrationCheck = 'servicePagerDutyIntegrationCheck',
  ServiceSonarQubeIntegrationCheck = 'serviceSonarQubeIntegrationCheck',
  ServiceMonitoringDashboardCheck = 'serviceMonitoringDashboardCheck',
  RepoCIMedianBuildDurationCheck = 'repoCIMedianBuildDurationCheck',
  ServiceOperationalStatusCheck = 'serviceOperationalStatusCheck',
  ServiceOperationalTierCheck = 'serviceOperationalTierCheck',
}
