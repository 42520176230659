import { createSonarqubeQualityGateFactsHandler } from '../../handlers/service/serviceBasicFactsHandler';
import { TechInsightsRetrieverIDs } from '../../retrieverIDs';
import { SonarqubeStatuschecksFact, TypedFactRetriever } from '../../types';

export const createSonarqubeQualityGateFactRetriever = (
  endpoint: string,
  token: string,
): TypedFactRetriever<SonarqubeStatuschecksFact> => ({
  id: TechInsightsRetrieverIDs.SONARQUBE_FACTS_RETRIEVER,
  version: '0.0.1',
  title: 'Sonarqube Quality Gate',
  description: 'Generates facts about the Sonarqube Quality Gate of a service',
  entityFilter: [{ 'spec.type': ['service'] }],
  schema: {
    totalIssues: {
      type: 'integer',
      description:
        'Total number of issues found in the sonarqube project for this service',
    },
    hasSufficientCodeCoverage: {
      type: 'boolean',
      description:
        'Fact that indicates if service has sufficient code coverage',
    },
    passesSecurityReview: {
      type: 'boolean',
      description: 'Fact that indicates if service passes the security review',
    },
    hasVulnerabilities: {
      type: 'boolean',
      description: 'Fact that indicates if service has vulnerabilities',
    },
    hasSecurityHotspots: {
      type: 'boolean',
      description: 'Fact that indicates if service has security hotspots',
    },
    qualityGatePassed: {
      type: 'boolean',
      description:
        'Fact that indicates if service passes the quality gate set in Sonarqube',
    },
  },
  handler: createSonarqubeQualityGateFactsHandler(endpoint, token),
});
