import useAsync from 'react-use/lib/useAsync';

import { useApi } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';

import { azureResourcesApiRef } from '../api';
import {
  AzureManagedCluster,
  AZURE_ANNOTATION_AKS_RESOURCE_NAME_FILTERS,
} from '../types';
import { parseAzureResourceNameFilters } from '../utils';

export const useAzureManagedClusters = () => {
  const { entity } = useEntity();
  const api = useApi(azureResourcesApiRef);

  const resourceNameFilters: string[] =
    parseAzureResourceNameFilters(
      entity,
      AZURE_ANNOTATION_AKS_RESOURCE_NAME_FILTERS,
    ) ?? [];

  const {
    value: clusters,
    loading,
    error,
  } = useAsync(async (): Promise<AzureManagedCluster[]> => {
    const resourcesResponse = await api.getAzureManagedClusters(
      resourceNameFilters,
    );

    return (resourcesResponse?.data as AzureManagedCluster[]) ?? [];
  }, []);

  return { clusters, loading, error } as const;
};
