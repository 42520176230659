import React from 'react';

import { Alert } from '@material-ui/lab';

export const EntityGroupSelfManagedWarning = () => {
  return (
    <Alert severity="warning">
      This team is self-managed. You have the risk that your team information
      and members are out of the sync. <br /> Please consider using the default
      team derived from Workday. <br />
      <sub>
        This warning can be disabled by adding the annotation:{' '}
        <code>tomtom.com/show-self-managed-warning: 'false'</code>.
      </sub>
    </Alert>
  );
};
