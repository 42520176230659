import { Octokit } from '@octokit/rest';
import useAsync from 'react-use/lib/useAsyncRetry';

import { useApi } from '@backstage/core-plugin-api';
import { scmAuthApiRef } from '@backstage/integration-react';

export const useGetGithubTeams = () => {
  const scmAuthApi = useApi(scmAuthApiRef);

  const [encodedHost, encodedWorkspace] = ['github.com', 'tomtom-internal'].map(
    encodeURIComponent,
  );

  return useAsync(async () => {
    const { token } = await scmAuthApi.getCredentials({
      url: `https://${encodedHost}/${encodedWorkspace}`,
      additionalScope: {
        repoWrite: true,
        customScopes: {
          github: [],
        },
      },
    });

    const octokit = new Octokit({
      auth: token,
      apiBaseUrl: 'https://api.github.com',
    });

    // page starts with 1
    const pages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const pageSize = 100;

    const promises = pages.map(page =>
      octokit.request('GET /orgs/{org}/teams', {
        org: 'tomtom-internal',
        page,
        per_page: pageSize,
      }),
    );

    const allTeams = await Promise.all(promises);

    return allTeams?.flatMap(teams => teams?.data.map(item => item.slug)) ?? [];
  });
};
