import { useAsync } from 'react-use';

import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

import { fetchUserName } from '../util/fetchUserName';

export const useFetchUserName = (userRef: string | undefined) => {
  const catalogApi = useApi(catalogApiRef);
  return useAsync(async () => {
    return fetchUserName(userRef, catalogApi);
  }, [userRef]);
};
