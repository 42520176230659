import { ANNOTATION_OPENAPI_LINTER_PROCESSOR } from '@internal/plugin-catalog-backend-module-api-governance';
import React from 'react';

import { ApiEntity } from '@backstage/catalog-model';
import { InfoCard } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';

import { Alert } from '@material-ui/lab';

import { apiDocsConfigRef } from '../../config';

const getLinterResult = (entity: ApiEntity): string => {
  return entity.metadata.annotations
    ? entity.metadata.annotations[ANNOTATION_OPENAPI_LINTER_PROCESSOR] || ''
    : '';
};

/** @public */
export const ApiGovernanceCard = () => {
  const { entity } = useEntity<ApiEntity>();
  const config = useApi(apiDocsConfigRef);
  const { getApiGovernanceWidget } = config;

  if (!entity) {
    return <Alert severity="error">Could not fetch the API</Alert>;
  }

  const governanceWidget = getApiGovernanceWidget(entity);
  const lint = getLinterResult(entity);

  if (governanceWidget) {
    return governanceWidget.component(lint);
  }

  return (
    <InfoCard>Governance does not support "{entity.spec.type}" type</InfoCard>
  );
};
