import { JSON_RULE_ENGINE_CHECK_TYPE } from '@backstage-community/plugin-tech-insights-backend-module-jsonfc';

export const basicChecks = [
  {
    id: 'groupOwnerCheck',
    type: JSON_RULE_ENGINE_CHECK_TYPE,
    name: 'Has responsible team',
    description:
      'The service has a designated team responsible for maintaining, monitoring, and improving the service.',
    factIds: ['entityOwnershipFactRetriever'],
    rule: {
      conditions: {
        all: [
          {
            fact: 'hasGroupOwner',
            operator: 'equal',
            value: true,
          },
        ],
      },
    },
  },
  {
    id: 'titleCheck',
    type: JSON_RULE_ENGINE_CHECK_TYPE,
    name: 'Has title',
    description:
      'The service has a title set to improve readability and discoverability, making it easier to understand and find.',
    factIds: ['entityMetadataFactRetriever'],
    rule: {
      conditions: {
        all: [
          {
            fact: 'hasTitle',
            operator: 'equal',
            value: true,
          },
        ],
      },
    },
  },
  {
    id: 'descriptionCheck',
    type: JSON_RULE_ENGINE_CHECK_TYPE,
    name: 'Has a description',
    description:
      'Service includes a clear description outlining its purpose and functionality.',
    factIds: ['entityMetadataFactRetriever'],
    rule: {
      conditions: {
        all: [
          {
            fact: 'hasDescription',
            operator: 'equal',
            value: true,
          },
        ],
      },
    },
  },
  {
    id: 'techDocsCheck',
    type: JSON_RULE_ENGINE_CHECK_TYPE,
    name: 'Has TechDocs documentation',
    description:
      'The service has technical documentation that describes its architecture, features, and operational details.',
    factIds: ['techdocsFactRetriever'],
    rule: {
      conditions: {
        all: [
          {
            fact: 'hasAnnotationBackstageIoTechdocsRef',
            operator: 'equal',
            value: true,
          },
        ],
      },
    },
  },
];
