import { KaaPTenantEntity } from '@internal/plugin-catalog-model';
import { NavigateToTemplateButton } from '@internal/plugin-catalog-page-addons-react';
import { tomtomTheme } from '@internal/plugin-tomtom-theme-react';
import React, { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ANNOTATION_ORIGIN_LOCATION } from '@backstage/catalog-model';
import { CodeSnippet, InfoCard, Link } from '@backstage/core-components';
import {
  IconComponent,
  alertApiRef,
  discoveryApiRef,
  identityApiRef,
  useApi,
  useApp,
} from '@backstage/core-plugin-api';
import { EntityAboutCard, EntityLayout } from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { EntityRefLink, useEntity } from '@backstage/plugin-catalog-react';

import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  ImageList,
  ImageListItem,
  Input,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LanguageIcon from '@material-ui/icons/Language';

import { ActionContent, EntityWarningContent } from '../Content';

const useStyles = makeStyles({
  svgIcon: {
    display: 'inline-block',
    '& svg': {
      display: 'inline-block',
      fontSize: 'inherit',
      verticalAlign: 'baseline',
    },
  },
  buttonProgress: {
    color: tomtomTheme.getTheme('v4')?.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

const CLUSTER_NAME_FOR_DATASOURCE_MAP: any = {
  'kaap-external-01-kaap-westeurope-shared-01': {
    datasource: '2c119bf1-84c8-16a6-fd3a-91bd28250676',
    redMetricsLink:
      'https://grafana.tomtomgroup.com/d/cdnr1f1aps35se/red-metrics-external?var-cluster={CLUSTER}&var-tenant={TENANT_NAME}',
  },
  'kaap-internal-01-kaap-westeurope-shared-01': {
    datasource: '88e5c6b2-32e8-a063-efce-e6d98ddfbfb3',
    redMetricsLink:
      'https://grafana.tomtomgroup.com/d/fdnr1dhylh4w0c/red-metrics-internal?var-cluster={CLUSTER}&var-tenant={TENANT_NAME}',
  },
  'kaap-playground-01-kaap-westeurope-shared-03': {
    datasource: 'e34e8e2f-9fa7-071c-13ba-1bb330195859',
    redMetricsLink:
      'https://grafana.tomtomgroup.com/d/b3f0ee44-113e-4cc7-9104-0f15a20ef/red-metrics-playground?var-cluster={CLUSTER}&var-tenant={TENANT_NAME}',
  },
};

function buildGrafanaURL(tenantName: string, datasource: string) {
  if (!tenantName || !datasource) return '';
  return `https://grafana.tomtomgroup.com/d/k8s_views_pods/kaap-short-term-metrics?var-tenant=${encodeURIComponent(
    tenantName,
  )}&var-datasource=${encodeURIComponent(datasource)}`;
}

function buildGrafanaREDMetricsURL(
  tenantName: string,
  { cluster, redMetricsLink }: { cluster: string; redMetricsLink: string },
) {
  if (!tenantName || !cluster || !redMetricsLink) return '';
  return redMetricsLink
    .replace('{CLUSTER}', encodeURIComponent(cluster))
    .replace('{TENANT_NAME}', encodeURIComponent(tenantName));
}

function buildArgoCDURL(kubeconfig: string) {
  if (!kubeconfig) return '';
  const domain = kubeconfig.match(
    /https:\/\/capsule-proxy.(?<domain>[a-zA-Z0-9-_\.]+)/,
  )?.groups?.domain;
  return `https://argocd.${domain}/`;
}

function buildOtelURL(clusterName: string, tenantName: string) {
  if (!clusterName || !tenantName) return '';
  return `https://grafana.tomtomgroup.com/d/ddve8ix5h9szke/kaap-logs?var-cluster=${encodeURIComponent(
    clusterName,
  )}&var-tenant=${encodeURIComponent(tenantName)}`;
}

function getTrivyMetricsURL(dataSource: string, tenantName: string) {
  if (!dataSource || !tenantName) return '';
  return `https://grafana.tomtomgroup.com/d/ycwPj724k/kaap-security-scanning?orgId=1&var-datasource=${dataSource}&var-tenant=${tenantName}&var-namespace=All`;
}

function getCostsURL(dataSource: string, tenantName: string) {
  if (!dataSource || !tenantName) return '';
  return `https://grafana.tomtomgroup.com/d/fdpqztyccc1s0a/tenant-cost-report?orgId=1&var-datasource=${dataSource}&var-tenant=${tenantName}`;
}

export const KaaPTenantPage = () => {
  const app = useApp();
  const classes = useStyles();
  const discoveryApi = useApi(discoveryApiRef);
  const identityApi = useApi(identityApiRef);
  const alertApi = useApi(alertApiRef);
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);
  const [open, setOpen] = useState(false);
  const { entity } = useEntity();
  const confirmationNameRef = useRef<HTMLInputElement>(null);

  const iconResolver = (key?: string): IconComponent =>
    key ? app.getSystemIcon(key) ?? LanguageIcon : LanguageIcon;
  const tenantName = entity?.metadata.name;
  const clusterName = entity?.metadata.annotations?.['tomtom.com/member'] ?? '';
  const tier = entity?.spec?.tier as string;

  const deleteTenant = useCallback(async () => {
    setIsDeleting(true);
    try {
      const { token } = await identityApi.getCredentials();
      const endpointBase = await discoveryApi.getBaseUrl('kaap-backend');

      const locationRef =
        entity.metadata.annotations?.[ANNOTATION_ORIGIN_LOCATION];
      const response = await fetch(`${endpointBase}/delete-tenant`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          name: tenantName,
          member: clusterName,
          tier,
          locationRef,
        }),
      });
      const { status, error } = await response.json();
      if (status === 'ok') {
        setTimeout(() => {
          const parentAccountPath = `/catalog/${entity.metadata.namespace}/kaapaccount/${entity.metadata.accountName}`;
          navigate(parentAccountPath, { replace: true });
        }, 3000);
      } else {
        const errorMessage =
          error && error === 'NOT OWN TENANT'
            ? `You do not own ${tenantName} tenant`
            : 'Error deleting tenant';
        alertApi.post({
          message: errorMessage,
          severity: 'error',
        });
      }
    } catch (error) {
      alertApi.post({
        message: 'Error deleting tenant',
        severity: 'error',
      });
    } finally {
      setIsDeleting(false);
    }
  }, [
    tenantName,
    clusterName,
    tier,
    identityApi,
    discoveryApi,
    alertApi,
    navigate,
    entity.metadata.accountName,
    entity.metadata.namespace,
    entity.metadata.annotations,
  ]);

  const kubeconfig =
    entity.metadata.annotations?.['tomtom.com/kubeconfig'] !== 'UNSET'
      ? atob(entity.metadata.annotations?.['tomtom.com/kubeconfig'] ?? '')
      : 'Kubeconfig unavailable';

  const handleEntering = useCallback(() => {
    if (confirmationNameRef.current !== null) {
      confirmationNameRef.current.focus();
    }
  }, []);

  const handleCancel = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleOk = () => {
    if (confirmationNameRef.current?.value !== tenantName) {
      alertApi.post({
        message: 'Tenant name does not match',
        severity: 'warning',
      });
      return;
    }
    deleteTenant();
    setOpen(false);
  };

  const linkMetadata =
    CLUSTER_NAME_FOR_DATASOURCE_MAP[`kaap-${tier}-01-${clusterName}`];

  const links = [
    {
      text: 'Grafana',
      href: buildGrafanaURL(tenantName, linkMetadata?.datasource ?? ''),
      Icon: iconResolver('grafana'),
    },
    {
      text: 'Grafana - RED metrics',
      href: buildGrafanaREDMetricsURL(tenantName, {
        cluster: clusterName,
        redMetricsLink: linkMetadata?.redMetricsLink ?? '',
      }),
      Icon: iconResolver('grafana'),
    },
    {
      text: 'ArgoCD',
      href: buildArgoCDURL(kubeconfig),
      Icon: iconResolver('argocd'),
    },
    {
      text: 'Trivy Dashboard',
      href: getTrivyMetricsURL(linkMetadata?.datasource ?? '', tenantName),
      Icon: iconResolver('grafana'),
    },
    {
      text: 'Costs',
      href: getCostsURL(linkMetadata?.datasource ?? '', tenantName),
      Icon: AttachMoneyIcon,
    },
    {
      text: 'Otel',
      href: buildOtelURL(clusterName, tenantName),
      Icon: iconResolver('otel'),
    },
  ];

  return (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3} alignItems="stretch">
          <EntityWarningContent />
          {ActionContent}
          <Grid item container md={12}>
            <Grid item md={6}>
              <ButtonGroup>
                <NavigateToTemplateButton
                  color="primary"
                  variant="contained"
                  template="dx-cra-kaap-update-tenant"
                  text="Update Tenant"
                />
                <Dialog
                  maxWidth="xs"
                  onEntering={handleEntering}
                  aria-labelledby="confirmation-dialog-title"
                  open={open}
                >
                  <DialogTitle id="confirmation-dialog-title">
                    Please type the tenant name to confirm deletion
                  </DialogTitle>
                  <DialogContent dividers>
                    <Input inputRef={confirmationNameRef} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCancel} color="secondary">
                      Cancel
                    </Button>
                    <Button onClick={handleOk} color="primary">
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
                <Button
                  color="primary"
                  onClick={() => setOpen(true)}
                  disabled={isDeleting || !clusterName}
                >
                  Delete Tenant
                  {isDeleting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <EntityAboutCard variant="gridItem" />
          </Grid>
          <Grid item md={6} xs={12}>
            <EntityCatalogGraphCard variant="gridItem" height={400} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid container spacing={4}>
              <Grid item md={12}>
                <InfoCard title="Links">
                  {!links || links.length === 0 ? (
                    <Typography variant="body1">
                      No links defined for this tenant.
                    </Typography>
                  ) : (
                    <ImageList rowHeight="auto" cols={3}>
                      {links.map(({ text, href, Icon }, i) => {
                        if (href === '' || href === undefined) return null;
                        return (
                          <ImageListItem key={i}>
                            <Box display="flex">
                              <Box mr={1} className={classes.svgIcon}>
                                <Typography component="div">
                                  <Icon />
                                </Typography>
                              </Box>
                              <Box flexGrow="1">
                                <Link to={href} target="_blank" rel="noopener">
                                  {text || href}
                                </Link>
                              </Box>
                            </Box>
                          </ImageListItem>
                        );
                      })}
                    </ImageList>
                  )}
                </InfoCard>
              </Grid>
              <Grid item md={12}>
                <InfoCard title="Details and Specs">
                  <Grid container>
                    <Grid container item md={6}>
                      <Grid item md={6}>
                        <Typography variant="body2">Account</Typography>
                      </Grid>
                      <Grid item md={6}>
                        <EntityRefLink
                          entityRef={(entity as KaaPTenantEntity).spec.account}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <Typography variant="body2">Tier</Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography variant="body1">
                          {(entity as KaaPTenantEntity).spec.tier}
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography variant="body2">
                          Azure Subscription Id
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography variant="body1">
                          {
                            (entity as KaaPTenantEntity).spec
                              .azureSubscriptionId
                          }
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography variant="body2">Region</Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography variant="body1">
                          {(entity as KaaPTenantEntity).spec.region}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item md={6}>
                      <Grid item md={6}>
                        <Typography variant="body2">Namespace Quota</Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography variant="body1">
                          {(entity as KaaPTenantEntity).spec.namespaceQuota}
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography variant="body2">CPU Request</Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography variant="body1">
                          {
                            (entity as KaaPTenantEntity).spec.resourceQuota
                              .cpuRequest
                          }
                          m
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography variant="body2">CPU Limit</Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography variant="body1">
                          {
                            (entity as KaaPTenantEntity).spec.resourceQuota
                              .cpuLimit
                          }
                          m
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography variant="body2">Memory Request</Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography variant="body1">
                          {
                            (entity as KaaPTenantEntity).spec.resourceQuota
                              .memoryRequest
                          }
                          Mi
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography variant="body2">Memory Limit</Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography variant="body1">
                          {
                            (entity as KaaPTenantEntity).spec.resourceQuota
                              .memoryLimit
                          }
                          Mi
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </InfoCard>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <InfoCard title="Kubeconfig">
              {!kubeconfig ? (
                <Typography variant="body1">
                  No Kubeconfig defined for this tenant.
                </Typography>
              ) : (
                <Grid item md={12}>
                  <CodeSnippet
                    text={kubeconfig}
                    language="yaml"
                    showCopyCodeButton
                  />
                  <Grid item md={12}>
                    <Divider />
                    <Typography variant="h6">
                      How to use the Kubeconfig:
                    </Typography>
                    <Typography variant="body1">
                      <ol>
                        <li>Copy the Kubeconfig above</li>
                        <li>
                          Save it to a file, e.g. <code>~/.kube/config</code>
                        </li>
                        <li>
                          Use the kubeconfig file with kubectl: You can specify
                          the kubeconfig file directly when running{' '}
                          <code>kubectl</code> commands with the{' '}
                          <code>--kubeconfig</code> flag. For example:{' '}
                          <code>
                            kubectl get pods --kubeconfig ~/.kube/config
                          </code>
                        </li>
                      </ol>
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </InfoCard>
          </Grid>
        </Grid>
      </EntityLayout.Route>
    </EntityLayout>
  );
};
