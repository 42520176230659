import React, { useCallback, useEffect } from 'react';

import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

import { TextField } from '@material-ui/core';

import { RepoNamePickerState } from './types';
import { isGithubRepoExists, isFollowingTeamNamingConvention } from './utils';

export const RepoNamePickerRepoName = (props: {
  rawErrors: string[];
  state: RepoNamePickerState;
  onChange: (state: RepoNamePickerState) => void;
}) => {
  const { rawErrors, state, onChange } = props;

  const catalogApi = useApi(catalogApiRef);

  const updateValidations = useCallback(
    async (
      entityRef: string,
      token: string,
      owner: string,
      repoName: string,
    ) => {
      onChange({
        validation: {
          isFollowingTeamNameConvention: await isFollowingTeamNamingConvention(
            catalogApi,
            entityRef ?? '',
            repoName ?? '',
          ),
          isRepoExists:
            state.host?.indexOf('github') !== -1
              ? await isGithubRepoExists(token, owner, repoName)
              : false,
        },
      });
    },
    [onChange, state.host, catalogApi],
  );

  useEffect(() => {
    if (state.entityRef && state.token && state.owner && state.repoName) {
      updateValidations(
        state.entityRef,
        state.token,
        state.owner,
        state.repoName,
      );
    }
  }, [
    state.repoName,
    state.entityRef,
    state.token,
    state.owner,
    updateValidations,
  ]);

  return (
    <TextField
      label="Repository"
      placeholder="the name of the repository"
      helperText="Please choose a valid name for your repository"
      required
      value={state.repoName ?? ''}
      onChange={({ target: { value } }) =>
        onChange({
          repoName: value ?? '',
        })
      }
      margin="normal"
      error={rawErrors?.length > 0 && !state.repoName}
      inputProps={{ autoFocus: true }}
    />
  );
};
