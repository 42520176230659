/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useApi } from '@backstage/core-plugin-api';
import { BackstageTheme } from '@backstage/theme';

import {
  Button,
  Dialog,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';

import { tomtomLabApiRef } from '../../api';
import { Idea } from '../../types';
import { Event, ListEventFilter } from '../../types';
import { useIsJuryMember } from '../../util/juryAssessmentMethods';
import { isSharedIdeaLink, redirectToIdeasUrl } from '../../util/parseMethods';
import { IdeaDetailsDialog } from '../HomePageBazaarInfoCard';
import { StatusTag } from '../StatusTag/StatusTag';

type Props = {
  project: Idea;
  fetchTomtomLabProjects: () => Promise<Idea[]>;
  height: 'large' | 'small';
};

const useStyles = makeStyles((theme: BackstageTheme) => ({
  statusTag: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    marginBottom: '0.8rem',
  },
  row: {
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.divider,
    padding: '2px',
    cursor: 'pointer',
  },
  summary: {
    overflow: 'auto',
  },
  button: { minWidth: '11rem' },
  container: {
    marginTop: '2rem',
  },
  pullRight: {
    marginLeft: 'auto',
  },
  scoreButton: {
    marginTop: '12px',
  },
}));

export const IdeaRowCard = ({
  project,
  fetchTomtomLabProjects,
  height,
}: Props) => {
  const classes = useStyles({ height });
  const { id, title, status, updatedAt, summary, membersCount, eventId } =
    project;
  const [eventTitle, setEventTitle] = useState('');

  const tomtomLabApi = useApi(tomtomLabApiRef);

  useEffect(() => {
    const eventFilter: ListEventFilter = {
      order: 'latest',
    };

    const fetchEventTitle = async () => {
      const event = await tomtomLabApi.getEvents(eventFilter);
      const currentEventTitle = event.find(
        (e: Event) => e.id === eventId,
      )?.title;
      if (!currentEventTitle) return 'No event';
      return currentEventTitle;
    };

    const fetchTitle = async () => {
      setEventTitle(await fetchEventTitle());
    };
    fetchTitle();
  }, [eventId, tomtomLabApi]);

  const [openCard, setOpenCard] = useState(false);

  const handleClose = () => {
    setOpenCard(false);
    fetchTomtomLabProjects();
    if (isSharedIdeaLink()) {
      redirectToIdeasUrl();
    }
  };

  return (
    <>
      <Dialog fullWidth maxWidth="lg" onClose={handleClose} open={openCard}>
        <IdeaDetailsDialog initIdea={project} handleClose={handleClose} />
      </Dialog>
      <Grid
        container
        spacing={3}
        onClick={() => setOpenCard(true)}
        key={id}
        className={classes.row}
        component="tr"
      >
        <Grid item md={10} component="td">
          <Typography variant="h5">{title}</Typography>
        </Grid>
        {useIsJuryMember() && status === 'readyForJury' && (
          <Grid item md={2} component="td" spacing={10}>
            <Button
              variant="contained"
              className={classes.scoreButton}
              color="primary"
              component={Link}
              to={`/tomtom-lab/jury-assessment/${id}`}
            >
              SCORE
            </Button>
          </Grid>
        )}
        <Grid item md={9} component="td">
          <Typography variant="body1" className={classes.summary}>
            {summary}
          </Typography>
        </Grid>
        <Grid item md={4} component="td">
          <StatusTag styles={classes.statusTag} status={status} />
        </Grid>
        <Grid item md={3} component="td">
          <Typography variant="body2">
            {
              // eslint-disable-next-line
              Intl.DateTimeFormat('en-us', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              }).format(new Date(updatedAt || ''))
            }
          </Typography>
        </Grid>
        <Grid item md={3} component="td">
          <Typography variant="body1">{`Members: ${membersCount}`}</Typography>
        </Grid>
        <Grid item md={2} component="td">
          <Typography variant="body1">{`Event: ${eventTitle}`}</Typography>
        </Grid>
      </Grid>
    </>
  );
};
