import React from 'react';

import { Alert } from '@material-ui/lab';

export const EntityRepositoryProposedOwnerWarning = () => {
  return (
    <Alert severity="warning">
      Please confirm the owner of the repository by clicking on the "Confirm
      Owner" button below.
    </Alert>
  );
};
