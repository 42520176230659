import { ServiceScorecard } from '@internal/backstage-plugin-scorecard-common-react';
import React from 'react';

import { EntityLinksCard, EntitySwitch } from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';

import { Grid } from '@material-ui/core';

import { isLinksNotEmpty } from '../entityUtils';
import { ActionContent } from './ActionContent';
import { EntityWarningContent } from './EntityWarningContent';
import { ServiceAboutCard } from './ServiceAboutCard';

const isScorecardsAvailable = () => true;

export const ServiceEntityOverviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    <EntityWarningContent />
    {ActionContent}
    <EntitySwitch>
      <EntitySwitch.Case>
        <Grid item md={6}>
          <ServiceAboutCard variant="gridItem" />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={isScorecardsAvailable}>
        <Grid item md={12} xs={12}>
          <ServiceScorecard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={isLinksNotEmpty}>
        <Grid item md={6} xs={12}>
          <EntityLinksCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);
