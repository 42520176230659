import { RELATION_HAS_TENANT } from '@internal/plugin-kaap-backend-module-entity-ingestion';
import React from 'react';

import { Entity, SystemEntity } from '@backstage/catalog-model';
import {
  EntityAboutCard,
  EntityLayout,
  RelatedEntitiesCard,
} from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { EntityTable } from '@backstage/plugin-catalog-react';

import { Grid } from '@material-ui/core';

import { ActionContent, EntityWarningContent } from '../Content';

export const KaapAccountPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        <EntityWarningContent />
        {ActionContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/tenants" title="Tenants">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={12}>
          <RelatedEntitiesCard
            variant="fullHeight"
            title="Tenants"
            entityKind="KaaPTenant"
            relationType={RELATION_HAS_TENANT}
            columns={[
              EntityTable.columns.createEntityRefColumn({
                defaultKind: 'KaaPTenant',
              }),
              EntityTable.columns.createOwnerColumn(),
              EntityTable.columns.createMetadataDescriptionColumn(),
            ]}
            asRenderableEntities={(entities: Entity[]): SystemEntity[] =>
              entities as SystemEntity[]
            }
            emptyMessage="No tenant is part of this account"
            emptyHelpLink="blank"
          />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);
