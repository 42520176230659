import { GroupEntity } from '@internal/plugin-catalog-model';
import React, { useCallback } from 'react';

import {
  CompoundEntityRef,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { InfoCard, InfoCardVariants } from '@backstage/core-components';
import { alertApiRef, useApi, useRouteRef } from '@backstage/core-plugin-api';
import { catalogApiRef, entityRouteRef } from '@backstage/plugin-catalog-react';

import { Divider, Grid, List } from '@material-ui/core';

import { MemberAvatarList } from '../../GroupMember/MemberAvatarList';
import {
  GroupProfileManagersListItem,
  GroupProfileParentsListItem,
  GroupProfileTitle,
  GroupProfileCardAction,
  GroupProfileMissionVisionPurpose,
} from '../../GroupProfile';
import { useGetEntityAndRelations } from '../../GroupProfile/hooks/useGetEntityAndRelations';

export const GroupProfileChildCard = (props: {
  childEntityRef: CompoundEntityRef;
  variant?: InfoCardVariants;
}) => {
  const catalogApi = useApi(catalogApiRef);
  const alertApi = useApi(alertApiRef);
  const entityRoute = useRouteRef(entityRouteRef);

  const { group, parents } = useGetEntityAndRelations(props.childEntityRef);

  const refreshEntity = useCallback(async () => {
    await catalogApi.refreshEntity(stringifyEntityRef(group as GroupEntity));
    alertApi.post({ message: 'Refresh scheduled', severity: 'info' });
  }, [catalogApi, alertApi, group]);

  if (!group) {
    return <></>;
  }
  const {
    metadata: { name, managers, missions, mission, vision, purpose },
    spec: { profile },
  } = group;

  const routeParams = {
    kind: props.childEntityRef.kind,
    namespace: props.childEntityRef.namespace,
    name: props.childEntityRef.name,
  };

  return (
    <InfoCard
      title={
        <GroupProfileTitle
          title={profile?.displayName ?? name}
          picture={profile?.picture}
          group={group}
        />
      }
      variant={props.variant}
      action={
        <GroupProfileCardAction group={group} refreshEntity={refreshEntity} />
      }
      deepLink={{
        link: entityRoute(routeParams),
        title: `explore ${profile?.displayName ?? name}`,
      }}
    >
      <Grid container spacing={3}>
        <Grid item md={6} xl={6}>
          <List>
            <GroupProfileManagersListItem managers={managers ?? []} />
          </List>
        </Grid>
        <Grid item md={6} xl={6}>
          <List>
            <GroupProfileParentsListItem parents={parents} />
          </List>
        </Grid>
        <Grid item md={12} xl={12}>
          <Divider />
        </Grid>
        <Grid item md={12} xl={12}>
          <GroupProfileMissionVisionPurpose
            missions={missions ?? []}
            mission={mission}
            vision={vision}
            purpose={purpose}
          />
        </Grid>

        <Grid item md={12} xl={12}>
          <MemberAvatarList group={group} />
        </Grid>
      </Grid>
    </InfoCard>
  );
};
