import React from 'react';

import { Entity } from '@backstage/catalog-model';
import { CodeSnippet, EmptyState } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

/** @public */
export type MissingWorkflowDependencyEmptyStateClassKey = 'code';

const useStyles = makeStyles(
  theme => ({
    code: {
      borderRadius: 6,
      margin: theme.spacing(2, 0),
      background:
        theme.palette.type === 'dark' ? '#444' : theme.palette.common.white,
    },
  }),
  { name: 'MissingWorkflowDependencyEmptyState' },
);

function generateYamlExample(entity?: Entity): {
  yamlText: string;
  lineNumbers: number[];
} {
  const kind = entity?.kind || 'System';
  const name = entity?.metadata.name || 'example';
  const type = entity?.spec?.type || 'website';
  const owner = entity?.spec?.owner || 'user:default/guest';

  const yamlText = `apiVersion: backstage.io/v1alpha1
kind: ${kind}
metadata:
  name: ${name}
  workflows:
    - workflowRef: githubworkflow:default/wk-30663478
    - workflowRef: githubworkflow:default/wk-32534945
    - workflowRef: githubworkflow:default/wk-57406921
      dependsOn:
        - githubworkflow:default/wk-30663478
        - githubworkflow:default/wk-32534945
spec:
  type: ${type}
  owner: ${owner}`;

  const lineNumbers: number[] = [5, 6, 7, 8, 9, 10, 11];

  return {
    yamlText,
    lineNumbers,
  };
}

function generateDescription(entityKind = 'System') {
  return (
    <>
      The <code>workflows</code> missing. You need to add the workflows to your{' '}
      {entityKind} if you want to enable the workflow dependency graph.
    </>
  );
}

export function MissingWorkflowDependencyEmptyState() {
  let entity: Entity | undefined;
  try {
    const entityContext = useEntity();
    entity = entityContext.entity;
  } catch (err) {
    // ignore when entity context doesn't exist
  }

  const classes = useStyles();

  const entityKind = 'System';
  const { yamlText, lineNumbers } = generateYamlExample(entity);
  return (
    <EmptyState
      missing="field"
      title="Missing Workflows"
      description={generateDescription(entityKind)}
      action={
        <>
          <Typography variant="body1">
            Add the workflow dependency to your {entityKind} YAML as shown in
            the highlighted example below:
          </Typography>
          <Box className={classes.code}>
            <CodeSnippet
              text={yamlText}
              language="yaml"
              showLineNumbers
              highlightedNumbers={lineNumbers}
              customStyle={{ background: 'inherit', fontSize: '115%' }}
            />
          </Box>
        </>
      }
    />
  );
}
