import React, { useEffect } from 'react';
import useAsync from 'react-use/lib/useAsync';

import { Progress, Select, SelectItem } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { scaffolderApiRef } from '@backstage/plugin-scaffolder-react';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

export const RepoHostPicker = (props: {
  host?: string;
  hosts?: string[];
  onChange: (host: string) => void;
  rawErrors: string[];
}) => {
  const { host, hosts, onChange, rawErrors } = props;
  const scaffolderApi = useApi(scaffolderApiRef);

  const { value: { integrations } = { integrations: [] }, loading } = useAsync(
    async () => {
      return await scaffolderApi.getIntegrationsList({
        allowedHosts: hosts ?? [],
      });
    },
  );

  useEffect(() => {
    // If there is no host chosen currently
    if (!host) {
      // Set the first of the allowedHosts option if that available
      if (hosts?.length) {
        onChange(hosts[0]);
        // if there's no hosts provided, fallback to using the first integration
      } else if (integrations?.length) {
        onChange(integrations[0].host);
      }
    }
  }, [hosts, host, onChange, integrations]);

  // If there are no allowedHosts provided, then show all integrations. Otherwise, only show integrations
  // that are provided in the dropdown for the user to choose from.
  const hostsOptions: SelectItem[] = integrations
    ? integrations
        .filter(i => (hosts?.length ? hosts?.includes(i.host) : true))
        .map(i => ({ label: i.title, value: i.host }))
    : [{ label: 'Loading...', value: 'loading' }];

  if (loading) {
    return <Progress />;
  }

  return (
    <FormControl
      margin="normal"
      required
      error={rawErrors?.length > 0 && !host}
    >
      <Select
        native
        disabled={hosts?.length === 1}
        label="Host"
        onChange={s => onChange(String(Array.isArray(s) ? s[0] : s))}
        selected={host}
        items={hostsOptions}
        data-testid="host-select"
      />

      <FormHelperText>
        The host where the repository will be created
      </FormHelperText>
    </FormControl>
  );
};
