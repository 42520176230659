import { Entity, parseEntityRef } from '@backstage/catalog-model';

export const getEntityRelations = (
  entity: Entity | undefined,
  relationType: string,
  filter?: { kind: string },
) => {
  let entityRefs =
    entity?.relations
      ?.filter(r => r.type === relationType)
      .map(r => parseEntityRef(r.targetRef)) || [];

  if (filter?.kind) {
    entityRefs = entityRefs.filter(
      e =>
        e.kind.toLocaleLowerCase('en-US') ===
        filter.kind.toLocaleLowerCase('en-US'),
    );
  }

  return entityRefs;
};
