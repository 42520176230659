import React from 'react';

import { useElementFilter } from '@backstage/core-plugin-api';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  value: {
    fontWeight: 'bold',
    overflow: 'hidden',
    lineHeight: '24px',
    wordBreak: 'break-word',
  },
  errorValue: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
    overflow: 'hidden',
    lineHeight: '24px',
    wordBreak: 'break-word',
  },
  label: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: 0.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  errorLabel: {
    color: theme.palette.error.main,
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: 0.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

/**
 * Props for {@link AboutField}.
 *
 * @public
 */
export interface AboutFieldProps {
  errorHighlight?: boolean;
  label: string;
  value?: string;
  gridSizes?: Record<string, number>;
  children?: React.ReactNode;
}

/** @public */
export function AboutField(props: AboutFieldProps) {
  const { errorHighlight, label, value, gridSizes, children } = props;
  const classes = useStyles();

  const childElements = useElementFilter(children, c => c.getElements());

  // Content is either children or a string prop `value`
  const content =
    childElements.length > 0 ? (
      childElements
    ) : (
      <Typography
        variant="body2"
        className={errorHighlight ? classes.errorValue : classes.value}
      >
        {value || `unknown`}
      </Typography>
    );
  return (
    <Grid item {...gridSizes}>
      <Typography
        variant="h2"
        className={errorHighlight ? classes.errorLabel : classes.label}
      >
        {label}
      </Typography>
      {content}
    </Grid>
  );
}
