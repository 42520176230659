export const wellKnownGroups = [
  'dcedc4ca-ef7d-4e38-8f98-713505aa017b', // TomTom Users
  'b2f412ba-da2f-4adf-a171-8439a18822e7', // RG_AZ_GTMHUB_Access_Employees
  '5543ef50-dfb8-4209-9675-30633be0e91c', // RG_MS_LIC_M365_E5_Suite_End_User_Global_Standard
  '62376fb8-8ecd-4b5d-9283-f8c90855ce12', // RG_App_Library
  '97ed1212-89a3-45b8-8ab0-795197fb3bb2', // RG_AZ_OPEN_MAPS_Viewer_All_Employees_Contractors_Dev
  'cf7e0dca-ea6e-4851-a081-a0c9a4e82b2e', // RG_AZ_Allow_O365_Group_Creation
  'd450de83-6dc6-4a1c-ad48-da045bddc267', // RG_AZ_Slack_User_Group_BU_Platform_Products_Employees_and_Contractors
  'f5e93fdd-1c6f-475d-bea2-2c24ed90a8a1', // test_dynamic_allusercontractor
];
