import { colors } from './colors';
import { trailingArrow } from './commons';
import { fonts } from './fonts';

const backstagePickerDefaultStyles = {
  '& div div button:last-child span:first-child': {
    filter: colors.primary.mainFilter,
  },
  borderRadius: '10px',
};

const pickerWithBaseStyles = (pickerStyles: any) => {
  return {
    ...pickerStyles,
    '& div:first-child': {
      borderRadius: '10px',
    },
  };
};

const labelText = {
  fontSize: '14px',
  fontFamily: fonts.proximaNovaBold,
};

export const backstageThemeOverrides = {
  BackstageHeader: {
    styleOverrides: {
      title: {
        fontFamily: fonts.gilroyBold,
      },
      subtitle: {
        fontFamily: fonts.gilroyBold,
      },
      type: {
        fontFamily: fonts.proximaNova,
      },
    },
  },
  BackstageContent: {
    styleOverrides: {
      root: {
        '& label': labelText,
        '& span[class*="MuiTypography-button"]': labelText,
        '& div[role="combobox"]': pickerWithBaseStyles(
          backstagePickerDefaultStyles,
        ),
      },
    },
  },
  BackstageHeaderLabel: {
    styleOverrides: {
      label: {
        fontFamily: fonts.proximaNovaBold,
      },
      value: {
        fontFamily: fonts.proximaNova,
      },
    },
  },
  BackstageSidebarItem: {
    styleOverrides: {
      root: {
        fontFamily: fonts.gilroyBold,
        '&:hover': {
          background: colors.primary.main,
          color: colors.primary.white,
        },
      },
      selected: {
        background: colors.primary.main,
        color: colors.primary.white,
      },
      buttonItem: {
        '& + div': {
          backgroundColor: colors.primary.white,
        },
      },
    },
  },
  BackstageSidebarSubmenuItem: {
    styleOverrides: {
      backgroundColor: colors.primary.white,
      item: {
        fontFamily: fonts.gilroyBold,
        '&:hover': {
          background: colors.primary.main,
          color: colors.primary.white,
        },
      },
    },
  },
  BackstageInfoCard: {
    styleOverrides: {
      header: {
        paddingBottom: 5,
        paddingLeft: 22,
      },
      headerTitle: {
        fontWeight: 400,
      },
      headerSubheader: {
        fontFamily: fonts.gilroy,
        '&:empty': {
          display: 'none',
          visibility: 'hidden',
        },
      },
    },
  },
  BackstageIconLinkVertical: {
    styleOverrides: {
      label: {
        fontFamily: fonts.gilroy,
      },
    },
  },
  BackstageMarkdownContent: {
    styleOverrides: {
      markdown: {
        fontFamily: fonts.proximaNova,
      },
    },
  },
  BackstageSelectInputBase: {
    styleOverrides: {
      input: { ...pickerWithBaseStyles(backstagePickerDefaultStyles) },
    },
  },
  BackstageBottomLink: {
    styleOverrides: {
      boxTitle: {
        '& p': {
          color: colors.primary.main,
          fontFamily: fonts.gilroyBold,
          fontSize: 12,
          '&::after': trailingArrow,
        },
      },
      arrow: {
        display: 'none',
      },
    },
  },
  BackstageSelect: {
    styleOverrides: {
      formLabel: labelText,
    },
  },
  CatalogReactEntityOwnerPicker: {
    styleOverrides: {
      input: {
        ...pickerWithBaseStyles(backstagePickerDefaultStyles),
      },
      title: labelText,
    },
  },
  CatalogReactEntityLifecyclePicker: {
    styleOverrides: {
      input: {
        ...pickerWithBaseStyles(backstagePickerDefaultStyles),
      },
      title: labelText,
    },
  },
  CatalogReactEntityTagPicker: {
    styleOverrides: {
      input: {
        ...pickerWithBaseStyles(backstagePickerDefaultStyles),
      },
      title: labelText,
    },
  },
  CatalogReactEntityProcessingStatusPickerPicker: {
    styleOverrides: {
      input: {
        ...pickerWithBaseStyles(backstagePickerDefaultStyles),
      },
      title: labelText,
    },
  },
  CatalogReactUserListPicker: {
    styleOverrides: {
      root: {
        borderRadius: '10px',
        backgroundColor: colors.primary.white,
      },
      title: labelText,
      groupWrapper: {
        boxShadow: 'none',
      },
      menuItem: {
        border: `1px solid ${colors.primary.black}`,
        marginBottom: '15px',
        borderRadius: '100px',
        '&.Mui-disabled, &.Mui-selected': {
          borderColor: colors.gray.main,
          backgroundColor: colors.primary.white,
        },
        '&.Mui-disabled + div': {
          fontFamily: fonts.proximaNova,
          opacity: '0.5',
        },
        '&:hover, &.Mui-selected:hover': {
          backgroundColor: `${colors.primary.main}`,
          color: colors.primary.white,
          '& div:first-child svg': {
            color: colors.primary.white,
          },
        },
        '&:hover + div:last-child': {
          color: colors.primary.white,
        },
      },
      listIcon: {
        '&:hover svg': {
          color: colors.primary.white,
        },
      },
    },
  },
  BackstageClosedDropdown: {
    styleOverrides: {
      icon: {
        filter: colors.primary.mainFilter,
      },
    },
  },
  BackstageItemCardHeader: {
    styleOverrides: {
      root: {
        backgroundColor: colors.primary.main,
        backgroundImage: 'none !important',
        minHeight: '128px',
        color: colors.primary.white,
        '& > h3 a[class*="colorPrimary"]': {
          color: colors.primary.white,
        },
      },
    },
  },
  PluginCatalogGraphCatalogGraphPage: {
    styleOverrides: {
      legend: {
        top: 0,
        bottom: '100vh',
        right: '5px',
        color: colors.secondary.bolt,
      },
    },
  },
  BackstageLoginRequestListItem: {
    styleOverrides: {
      root: {
        '& div:nth-child(2)': {
          flex: '1 1 250px',
        },
      },
    },
  },
};
