import { Entity } from '@backstage/catalog-model';
import { IdentityApi } from '@backstage/core-plugin-api';
import { CatalogApi } from '@backstage/plugin-catalog-react';

import { arrayToObjectReducer } from '../utils';

export const removeGithubRepositoryOwnershipFormDataBuilder = async (
  entity: Entity,
  formFields: string[],
  _identityApi?: IdentityApi,
  _catalogApi?: CatalogApi,
) => {
  const formData = formFields
    .map((field: string) => {
      if (field === 'repository') {
        return { [field]: `repository:${entity.metadata.name}` };
      }
      return {};
    })
    .reduce(arrayToObjectReducer, {});

  return formData;
};
