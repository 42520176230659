import { GithubRepositoryWorkflowEntity } from '@internal/plugin-catalog-model';

import { FactRetrieverContext } from '@backstage-community/plugin-tech-insights-node';
import { Entity, stringifyEntityRef } from '@backstage/catalog-model';

import { GithubWorkflowEntityFact, TypedTechInsightFact } from '../../types';
import { getEntities } from '../utils/getEntities';
import { getActions } from './utils/githubWorkflow/getActions';
import { getRunners } from './utils/githubWorkflow/getRunners';

const getRepositoryRef = (entity: Entity): string =>
  entity.relations?.find(relation => relation.type === 'workflowOf')
    ?.targetRef ?? 'repository:default/unknown';

const getOwnerRef = (entity: Entity): string =>
  entity.relations?.find(relation => relation.type === 'ownedBy')?.targetRef ??
  'group:default/unknown';

const groupWorkflowsBy = (
  entities: Entity[],
  func: (entity: Entity) => string,
): Record<string, Entity[]> => {
  return entities.reduce((acc: Record<string, Entity[]>, entity) => {
    (acc[func(entity)] = acc[func(entity)] || []).push(entity);
    return acc;
  }, Object.create(null));
};

export const githubWorkflowEntityFactHandler = async ({
  discovery,
  entityFilter,
  tokenManager,
}: FactRetrieverContext): Promise<
  TypedTechInsightFact<GithubWorkflowEntityFact>[]
> => {
  const workflowEntities = await getEntities({
    entityFilter,
    tokenManager,
    discovery,
  });

  // group repositories
  const groupedWorkflows = groupWorkflowsBy(
    workflowEntities.items,
    getOwnerRef,
  );

  return Object.keys(groupedWorkflows).map(entityRef => {
    const workflows = groupedWorkflows[entityRef];
    const groupRef = getOwnerRef(workflows[0]);
    const runners: Record<string, Record<string, string[] | string>> = {};
    const actions: Record<string, Record<string, string[] | string>> = {};

    workflows.map(workflow => {
      const jobs = (workflow as GithubRepositoryWorkflowEntity).spec.jobs ?? {};

      runners[stringifyEntityRef(workflow)] = {
        runners: getRunners(jobs),
        repositoryEntityRef: getRepositoryRef(workflow),
      };

      actions[stringifyEntityRef(workflow)] = {
        actions: getActions(jobs),
        repositoryEntityRef: getRepositoryRef(workflow),
      };
    });

    return {
      entity: {
        namespace: 'default',
        kind: entityRef.split(':')[0],
        name: entityRef.split('/')[1],
      },
      facts: {
        runners,
        actions,
        groupRef,
      },
    };
  });
};
