import {
  PluginEndpointDiscovery,
  TokenManager,
} from '@backstage/backend-common';
import { Entity, stringifyEntityRef } from '@backstage/catalog-model';

import { getChildGroupEntityRefs } from './getChildGroupEntityRefs';

const hasChildGroup = (entity: Entity): boolean =>
  entity.relations?.some(relation => relation.type === 'parentOf') ?? false;

// retrieve all the children groups of each group entity
export const getChildGroups = async (
  groupEntities: Entity[],
  tokenManager: TokenManager,
  discovery: PluginEndpointDiscovery,
) => {
  const promises = groupEntities.map(async (group: Entity) => {
    const children = hasChildGroup(group)
      ? await getChildGroupEntityRefs({
          entity: group,
          tokenManager,
          discovery,
          alreadyRetrievedParentRefs: [],
        })
      : [stringifyEntityRef(group)];
    const item: {
      entity: Entity;
      children: string[];
    } = {
      entity: group,
      children,
    };
    return item;
  });
  return (await Promise.all(promises)).flat();
};
