import { AzureCostManagementResponse } from '../types/azureResponseTypes';

export const parseCostManagementResponse = (
  responseData: AzureCostManagementResponse,
) => {
  const rows = responseData.properties?.rows;
  return rows?.map((row: [string, number]) => ({
    name: row[1]?.toString() ?? '',
    cost: row[0],
  }));
};
