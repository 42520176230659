import { JsonObject } from '@backstage/types';

export type FieldType =
  | string
  | number
  | boolean
  | string[]
  | JsonObject
  | JsonObject[]
  | undefined;

export const arrayToObjectReducer = (
  fields: Record<string, FieldType>,
  field: Record<string, FieldType>,
): Record<string, FieldType> => ({ ...fields, ...field });
