import { TechInsightsCheckID } from '@internal/plugin-techinsights-module-entities';
import useAsync from 'react-use/lib/useAsync';

import {
  Entity,
  getCompoundEntityRef,
  GroupEntity,
  RELATION_PARENT_OF,
} from '@backstage/catalog-model';
import {
  discoveryApiRef,
  fetchApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  humanizeEntityRef,
} from '@backstage/plugin-catalog-react';

import { CheckResult } from '../components/TeamOnboarding/TeamOnboardingStatus/types';

export const useCheckResults = (
  entitiesToQuery: Entity[] | undefined = [],
  checksId: string[] = [
    TechInsightsCheckID.MANAGER_CHECK,
    TechInsightsCheckID.SLACK_CHANNEL_CHECK,
    TechInsightsCheckID.CONFLUENCE_CHECK,
    TechInsightsCheckID.BUG_REPORT_CHECK,
    TechInsightsCheckID.COMPONENT_OR_API_CHECK,
  ],
) => {
  const catalogApi = useApi(catalogApiRef);
  const fetchApi = useApi(fetchApiRef);
  const discoveryApi = useApi(discoveryApiRef);
  const identityApi = useApi(identityApiRef);

  const {
    value: checkResults,
    loading,
    error,
  } = useAsync(async () => {
    let entities = entitiesToQuery;
    if (entities.length === 0) {
      const response = await catalogApi.getEntities({
        filter: [{ kind: ['group'] }],
      });
      entities = response.items;
    }

    const baseUrl = await discoveryApi.getBaseUrl('tech-insights');
    const { token } = await identityApi.getCredentials();

    const postUrl = `${baseUrl}/checks/run`;
    const headers: HeadersInit = new Headers();
    headers.set('content-type', 'application/json');
    headers.set('Authorization', `Bearer ${token}`);

    const res = await fetchApi.fetch(postUrl, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        entities: entities.map(i => getCompoundEntityRef(i)),
        checks: checksId,
      }),
    });
    const results = await res.json();
    return Promise.allSettled(
      results.map(async (result: any) => {
        const entity = await catalogApi.getEntityByRef(result.entity);
        return {
          entity: {
            entityRef: result.entity as string,
            displayName:
              (entity as GroupEntity)?.spec?.profile?.displayName ??
              (entity ? humanizeEntityRef(entity) : ''),
            type: (entity as GroupEntity)?.spec?.type ?? '',
            isTeam: Boolean(
              !(entity as GroupEntity)?.relations?.find(
                item => item.type === RELATION_PARENT_OF,
              ),
            ),
          },
          results: result.results.map((r: any) => ({
            checkId: r.check.id,
            result: r.result,
            description: r.check?.description,
          })),
        };
      }),
    );
  }, [entitiesToQuery]);

  const data = checkResults
    ?.filter(res => res.status === 'fulfilled')
    .flatMap(res => (res as PromiseFulfilledResult<CheckResult[]>).value);

  return {
    results: data ?? [],
    loading,
    error,
  };
};
