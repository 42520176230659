import {
  RadarEntry,
  RadarQuadrant,
  RadarRing,
  TechRadarLoaderResponse,
} from '@backstage-community/plugin-tech-radar';

const collaborateEntries: RadarEntry[] = [
  {
    timeline: [
      {
        moved: 0,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'advanced-roadmaps',
    id: 'advanced-roadmaps',
    title: 'Advanced Roadmaps',
    quadrant: 'collaborate',
    description: '',
  },
  {
    timeline: [
      {
        moved: 0,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'confluence',
    id: 'confluence',
    title: 'Confluence',
    quadrant: 'collaborate',
    description:
      '**Confluence** is a web-based corporate wiki developed by Australian software company **Atlassian**. [Wikipedia](https://en.wikipedia.org/wiki/Confluence_(software))\n\n[TomTom Confluence](https://confluence.tomtomgroup.com)',
  },
  {
    timeline: [
      {
        moved: 0,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'draw.io',
    id: 'draw.io',
    title: 'Draw.io',
    quadrant: 'collaborate',
    description: '',
  },
  {
    timeline: [
      {
        moved: 0,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'jira',
    id: 'jira',
    title: 'Jira',
    quadrant: 'collaborate',
    description:
      'Jira is a proprietary issue tracking product developed by Atlassian that allows bug tracking and agile project management.[Wikipedia](https://en.wikipedia.org/wiki/Jira_(software))\n\n[TomTom Jira](https://jira.tomtomgroup.com)',
  },
  {
    timeline: [
      {
        moved: 0,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'jira-service-mngmt',
    id: 'jira-service-mngmt',
    title: 'Jira Service Mngmt.',
    quadrant: 'collaborate',
    description: '',
  },
  {
    timeline: [
      {
        moved: 0,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'roadmunk',
    id: 'roadmunk',
    title: 'Roadmunk',
    quadrant: 'collaborate',
    description: '',
  },
  {
    timeline: [
      {
        moved: 0,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'xray-test-mngmt',
    id: 'xray-test-mngmt',
    title: 'Xray Test Mngmt.',
    quadrant: 'collaborate',
    description: '',
  },
  {
    timeline: [
      {
        moved: 1,
        ringId: 'assess',
        date: new Date('2022-08-01'),
        description: '',
      },
      {
        moved: 0,
        ringId: 'trial',
        date: new Date('2020-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'jupyterhub',
    id: 'jupyterhub',
    title: 'JupyterHub',
    quadrant: 'collaborate',
    description:
      '[JupyterHub](https://github.com/jupyterhub/jupyterhub) is the best way to serve Jupyter notebook for multiple users.',
  },
  {
    timeline: [
      {
        moved: 0,
        ringId: 'hold',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'big-picture',
    id: 'big-picture',
    title: 'Big Picture',
    quadrant: 'collaborate',
    description: '',
  },
  {
    timeline: [
      {
        moved: -1,
        ringId: 'hold',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'maven-sites',
    id: 'maven-sites',
    title: 'Maven Sites',
    quadrant: 'collaborate',
    description:
      "[The Site Plugin](https://maven.apache.org/plugins/maven-site-plugin/) is used to generate a site for the project. The generated site also includes the project's reports that were configured in the POM.",
  },
  {
    timeline: [
      {
        moved: -1,
        ringId: 'hold',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'opshub',
    id: 'opshub',
    title: 'OpsHub',
    quadrant: 'collaborate',
    description: '',
  },
  {
    timeline: [
      {
        moved: -1,
        ringId: 'hold',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'rally',
    id: 'rally',
    title: 'Rally',
    quadrant: 'collaborate',
    description: '',
  },
  {
    timeline: [
      {
        moved: -1,
        ringId: 'hold',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'tempo-timesheet',
    id: 'tempo-timesheet',
    title: 'Tempo Timesheet',
    quadrant: 'collaborate',
    description: '',
  },
];

const runEntries: RadarEntry[] = [
  {
    timeline: [
      {
        moved: 0,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'alertsite',
    id: 'alertsite',
    title: 'Alert Sites',
    quadrant: 'run',
    description: '',
  },
  {
    timeline: [
      {
        moved: 1,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'cloud-refarch-v3',
    id: 'cloud-refarch-v3',
    title: 'Cloud RefArch v3',
    quadrant: 'run',
    description:
      'GitHub Repository: [https://github.com/tomtom-internal/cloudplatform-kubernetes-solution](https://github.com/tomtom-internal/cloudplatform-kubernetes-solution)',
  },
  {
    timeline: [
      {
        moved: 1,
        ringId: 'hold',
        date: new Date('2023-02-14'),
        description: '',
      },
    ],
    url: '#',
    key: 'docker-desktop',
    id: 'docker-desktop',
    title: 'Docker Desktop',
    quadrant: 'run',
    description:
      '[Phasing out Docker Desktop in 2023](https://confluence.tomtomgroup.com/display/DEVEX/Docker+Desktop+Alternatives#DockerDesktopAlternatives-About)',
  },
  {
    timeline: [
      {
        moved: 1,
        ringId: 'trial',
        date: new Date('2023-02-14'),
        description: '',
      },
    ],
    url: '#',
    key: 'rancher-desktop',
    id: 'rancher-desktop',
    title: 'Rancher Desktop',
    quadrant: 'run',
    description:
      '[Rancher Desktop as a Docker Desktop alternative](https://confluence.tomtomgroup.com/display/DEVEX/Docker+Desktop+Alternatives)',
  },
  {
    timeline: [
      {
        moved: 1,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'grafana-cloud',
    id: 'grafana-cloud',
    title: 'Grafana Cloud',
    quadrant: 'run',
    description:
      '[Grafana Cloud](https://grafana.com/products/cloud/) is a composable observability platform, integrating metrics, traces and logs with Grafana. Leverage the best open source observability software without the overhead of installing, maintaining, and scaling your observability stack.\n\n[TomTom Grafana](https://grafana.tomtomgroup.com)',
  },
  {
    timeline: [
      {
        moved: 1,
        ringId: 'assess',
        date: new Date('2023-01-30'),
        description: '',
      },
    ],
    url: '#',
    key: 'grafana-oncall',
    id: 'grafana-oncall',
    title: 'Grafana OnCall',
    quadrant: 'run',
    description:
      '[Grafana OnCall](https://confluence.tomtomgroup.com/display/DEVEX/Grafana+OnCall) is a potential alternative to PagerDuty',
  },
  {
    timeline: [
      {
        moved: 0,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'pager-duty',
    id: 'pager-duty',
    title: 'Pager Duty',
    quadrant: 'run',
    description: '',
  },

  {
    timeline: [
      {
        moved: 0,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'prometheus',
    id: 'prometheus',
    title: 'Prometheus',
    quadrant: 'run',
    description: '',
  },
  {
    timeline: [
      {
        moved: 1,
        ringId: 'trial',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'distributed-tracing',
    id: 'distributed-tracing',
    title: 'Distributed tracing',
    quadrant: 'run',
    description: '',
  },
  {
    timeline: [
      {
        moved: 0,
        ringId: 'hold',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'cloud-refarch-v2',
    id: 'cloud-refarch-v2',
    title: 'Cloud RefArch v2',
    quadrant: 'run',
    description: '',
  },
  {
    timeline: [
      {
        moved: 0,
        ringId: 'hold',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'umi-statuscake',
    id: 'umi-statuscake',
    title: 'UMI / StatusCake',
    quadrant: 'run',
    description: '',
  },
];

const codeEntries: RadarEntry[] = [
  {
    timeline: [
      {
        moved: 0,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'black-duck-hub',
    id: 'black-duck-hub',
    title: 'Black Duck Hub',
    quadrant: 'code',
    description: '',
  },
  {
    timeline: [
      {
        moved: 0,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'coverity',
    id: 'coveruty',
    title: 'Coverity',
    quadrant: 'code',
    description: '',
  },
  {
    timeline: [
      {
        moved: 1,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'github',
    id: 'github',
    title: 'GitHub',
    quadrant: 'code',
    description:
      'Go to [TomTom Internal repositories](https://github.com/tomtom-internal/)',
  },
  {
    timeline: [
      {
        moved: 0,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'opengrok',
    id: 'opengrok',
    title: 'OpenGrok',
    quadrant: 'code',
    description: '',
  },
  {
    timeline: [
      {
        moved: 1,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'sonarqube-ent',
    id: 'sonarqube-ent',
    title: 'SonarQube Ent.',
    quadrant: 'code',
    description: '',
  },
  {
    timeline: [
      {
        moved: 1,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'veradode',
    id: 'veradoce',
    title: 'Veracode',
    quadrant: 'code',
    description: '',
  },
  {
    timeline: [
      {
        moved: -1,
        ringId: 'hold',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'aws-codecommit',
    id: 'aws-codecommit',
    title: 'AWS CodeCommit',
    quadrant: 'code',
    description: '',
  },
  {
    timeline: [
      {
        moved: -1,
        ringId: 'hold',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'azure-repos',
    id: 'azure-repos',
    title: 'Azure Repos',
    quadrant: 'code',
    description: '',
  },
  {
    timeline: [
      {
        moved: -1,
        ringId: 'hold',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'bitbucket',
    id: 'bitcucket',
    title: 'Bitbucket',
    quadrant: 'code',
    description: '',
  },
  {
    timeline: [
      {
        moved: 0,
        ringId: 'hold',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'crucible',
    id: 'crucible',
    title: 'Crucible',
    quadrant: 'code',
    description: '',
  },
  {
    timeline: [
      {
        moved: 0,
        ringId: 'hold',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'ctc++',
    id: 'ctc++',
    title: 'CTC++',
    quadrant: 'code',
    description: '',
  },
  {
    timeline: [
      {
        moved: 0,
        ringId: 'hold',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'fisheye',
    id: 'fisheye',
    title: 'Fisheye',
    quadrant: 'code',
    description: '',
  },
  {
    timeline: [
      {
        moved: -1,
        ringId: 'hold',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'perforce',
    id: 'perforce',
    title: 'Perforce',
    quadrant: 'code',
    description: '',
  },
  {
    timeline: [
      {
        moved: -1,
        ringId: 'hold',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'sonarqube-community',
    id: 'sonarqube-community',
    title: 'SonarQube Community',
    quadrant: 'code',
    description: '',
  },
  {
    timeline: [
      {
        moved: -1,
        ringId: 'hold',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'subversion',
    id: 'subversion',
    title: 'Subversion',
    quadrant: 'code',
    description: '',
  },
  {
    timeline: [
      {
        moved: -1,
        ringId: 'hold',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'tiobe-tics',
    id: 'tiobe-tics',
    title: 'Tiobe TICS',
    quadrant: 'code',
    description: '',
  },
];

const buildEntries: RadarEntry[] = [
  {
    timeline: [
      {
        moved: 1,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'accelerate-kpis',
    id: 'accelerate-kpis',
    title: 'Accelerate KPIs',
    quadrant: 'build',
    description: '',
  },
  {
    timeline: [
      {
        moved: 0,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'artifactory',
    id: 'artifactory',
    title: 'Artifactory',
    quadrant: 'build',
    description: '',
  },
  {
    timeline: [
      {
        moved: 1,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'azure-devops',
    id: 'azure-devops',
    title: 'Azure DevOps',
    quadrant: 'build',
    description: '',
  },
  {
    timeline: [
      {
        moved: 1,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'focus-on-flow',
    id: 'focus-on-flow',
    title: 'Focus on Flow',
    quadrant: 'build',
    description: '',
  },
  {
    timeline: [
      {
        moved: 1,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'github-actions',
    id: 'github-actions',
    title: 'GitHub Actions',
    quadrant: 'build',
    description: '',
  },
  {
    timeline: [
      {
        moved: 1,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'hashicorp-vault',
    id: 'hashicorp-vault',
    title: 'Hashicorp Vault',
    quadrant: 'build',
    description: '',
  },
  {
    timeline: [
      {
        moved: 0,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'macos-farm',
    id: 'macos-farm',
    title: 'MacOS Farm',
    quadrant: 'build',
    description: '',
  },
  {
    timeline: [
      {
        moved: 0,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'testdroid',
    id: 'testdroid',
    title: 'Testdroid',
    quadrant: 'build',
    description: '',
  },
  {
    timeline: [
      {
        moved: 0,
        ringId: 'assess',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'argocd',
    id: 'argocd',
    title: 'ArgoCD',
    quadrant: 'build',
    description: '',
  },
  {
    timeline: [
      {
        moved: 0,
        ringId: 'assess',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'cicd-templates',
    id: 'cicd-templates',
    title: 'CI/CD Templates',
    quadrant: 'build',
    description: '',
  },
  {
    timeline: [
      {
        moved: 0,
        ringId: 'assess',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'gradle-enterprise',
    id: 'gradle-enterprise',
    title: 'Gradle Enterprise',
    quadrant: 'build',
    description: '',
  },
  {
    timeline: [
      {
        moved: 0,
        ringId: 'assess',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'refarch-aas',
    id: 'refarch-aas',
    title: 'RefArch-aaS (KaaS)',
    quadrant: 'build',
    description: '',
  },
  {
    timeline: [
      {
        moved: -1,
        ringId: 'hold',
        date: new Date('2022-08-01'),
        description: '',
      },
      {
        moved: 0,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'ansible-awx',
    id: 'ansible-awx',
    title: 'Ansible AWX',
    quadrant: 'build',
    description: '',
  },
  {
    timeline: [
      {
        moved: -1,
        ringId: 'hold',
        date: new Date('2022-08-01'),
        description: '',
      },
      {
        moved: 0,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'incredibuild',
    id: 'incredibuild',
    title: 'Incredibuild',
    quadrant: 'build',
    description: '',
  },
  {
    timeline: [
      {
        moved: -1,
        ringId: 'hold',
        date: new Date('2022-08-01'),
        description: '',
      },
      {
        moved: 0,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'jenkins-cloudbees',
    id: 'jenkins-cloudbees',
    title: 'Jenkins Clondbees',
    quadrant: 'build',
    description: '',
  },
  {
    timeline: [
      {
        moved: 0,
        ringId: 'hold',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'nexus',
    id: 'nexus',
    title: 'Nexus',
    quadrant: 'build',
    description: '',
  },
  {
    timeline: [
      {
        moved: 0,
        ringId: 'hold',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'nexus-cns.tomtomgroup.com',
    id: 'nexus-cns.tomtomgroup.com',
    title: 'nexus-cns.tomtomgroup.com',
    quadrant: 'build',
    description: 'nexus-cns.tomtomgroup.com',
  },
  {
    timeline: [
      {
        moved: -1,
        ringId: 'hold',
        date: new Date('2022-08-01'),
        description: '',
      },
      {
        moved: 0,
        ringId: 'adopt',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'quickbuild',
    id: 'quickbuild',
    title: 'QuickBuild',
    quadrant: 'build',
    description: '',
  },
  {
    timeline: [
      {
        moved: 0,
        ringId: 'hold',
        date: new Date('2022-08-01'),
        description: '',
      },
    ],
    url: '#',
    key: 'yum.tomtomgroup.com',
    id: 'yum.tomtomgroup.com',
    title: 'yum.tomtomgroup.com',
    quadrant: 'build',
    description: 'yum.tomtomgroup.com',
  },
];

const quadrants = new Array<RadarQuadrant>();
quadrants.push({ id: 'build', name: 'Build' });
quadrants.push({ id: 'code', name: 'Code' });
quadrants.push({ id: 'collaborate', name: 'Collaborate' });
quadrants.push({ id: 'run', name: 'Run' });

const entries: RadarEntry[] = [
  ...collaborateEntries,
  ...runEntries,
  ...codeEntries,
  ...buildEntries,
];

export const getData = (rings: RadarRing[]): TechRadarLoaderResponse => ({
  entries,
  quadrants,
  rings,
});
