import React from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';

import { Tier } from '../../types';

const colorByTier = {
  Gold: '#FFD700',
  Silver: '#C0C0C0',
  Bronze: '#CD7F32',
  Black: '#717185',
};

const useStyles = (props?: { tier: Tier }) =>
  makeStyles(theme => {
    return {
      scoreBox: {
        width: '120px',
        height: '120px',
        backgroundColor: props?.tier
          ? colorByTier[props.tier]
          : theme.palette.grey[400],
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        paddingTop: '10px',
      },
    };
  })();

export const ScoreBox = ({
  tier,
  label,
  score,
}: {
  tier: Tier;
  label: string;
  score: number;
}) => {
  const classes = useStyles({ tier });

  return (
    <Box className={classes.scoreBox}>
      <Typography variant="subtitle1">{label}</Typography>
      <Typography variant="h1">{score}</Typography>
    </Box>
  );
};
