import React from 'react';

import { Table, TableColumn } from '@backstage/core-components';

import { makeStyles, Typography } from '@material-ui/core';
import SyncIcon from '@material-ui/icons/Sync';

import { GithubDeployment } from '../../api';

const useStyles = makeStyles(theme => ({
  empty: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const DeploymentTable = (props: {
  deployments: GithubDeployment[];
  isLoading: boolean;
  page: number;
  pageSize: number;
  total: number;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  reload: () => void;
  columns: TableColumn<GithubDeployment>[];
}) => {
  const {
    deployments,
    page,
    pageSize,
    total,
    onChangePage,
    onChangePageSize,
    isLoading,
    reload,
    columns,
  } = props;
  const classes = useStyles();

  return (
    <Table
      columns={columns}
      options={{ padding: 'dense', paging: true, search: false, pageSize }}
      title="GitHub Deployments"
      totalCount={total}
      page={page}
      data={deployments}
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangePageSize}
      isLoading={isLoading}
      actions={[
        {
          icon: () => <SyncIcon />,
          tooltip: 'Reload',
          isFreeAction: true,
          onClick: () => reload(),
        },
      ]}
      emptyContent={
        <div className={classes.empty}>
          <Typography variant="body1">
            No deployments found for this entity.
          </Typography>
        </div>
      }
    />
  );
};
