import { Entity } from '@backstage/catalog-model';
import { IdentityApi } from '@backstage/core-plugin-api';
import { CatalogApi } from '@backstage/plugin-catalog-react';
import { JsonObject } from '@backstage/types';

import { arrayToObjectReducer } from '../utils';

export const tenantFormBuilder = async (
  entity: Entity,
  formFields: string[],
  _identityApi?: IdentityApi,
  _catalogApi?: CatalogApi,
) => {
  type ArgoConfig = { repository: string; path: string };
  const formData = formFields
    .map((field: string) => {
      let data: {
        [key in string]: string | boolean | undefined | number | JsonObject;
      };
      switch (field) {
        case 'isEdit':
          data = { [field]: true };
          break;
        case 'kaapAccount':
          data = { [field]: entity.spec?.account?.toString() ?? '' };
          break;
        case 'kaapMember':
          data = {
            [field]: entity.metadata.annotations?.['tomtom.com/member'],
          };
          break;
        case 'argoCDRepo':
          data = {
            [field]:
              (entity.spec?.argoAppOfApps as ArgoConfig).repository ?? '',
          };
          break;
        case 'argoCDPath':
          data = {
            [field]: (entity.spec?.argoAppOfApps as ArgoConfig).path ?? '',
          };
          break;
        case 'name':
          data = { [field]: entity.metadata.name };
          break;
        case 'tier':
        case 'stage':
        case 'azureSubscriptionId':
        case 'region':
          data = { [field]: entity.spec?.[field]?.toString() ?? '' };
          break;
        case 'namespaceQuota':
          data = {
            [field]:
              Number.parseInt(entity.spec?.[field]?.toString() ?? '3', 10) ?? 3,
          };
          break;
        case 'resourceQuota':
          data = { [field]: (entity.spec?.[field] as JsonObject) ?? undefined };
          break;
        default:
          data = {};
          break;
      }
      return data;
    })
    .reduce(arrayToObjectReducer, {});

  return formData;
};
