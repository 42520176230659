import {
  AzureResourcesClient,
  azureResourcesApiRef,
} from '@internal/plugin-azure-resources-common-react';

import {
  configApiRef,
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

/** @public */
export const azureResourcesPlugin = createPlugin({
  id: 'azure-resources',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: azureResourcesApiRef,
      deps: {
        configApi: configApiRef,
      },
      factory: ({ configApi }) => new AzureResourcesClient({ configApi }),
    }),
  ],
});

/** @public */
export const AzureResourcesContent = azureResourcesPlugin.provide(
  createRoutableExtension({
    name: 'AzureResourcesContent',
    component: () =>
      import('./components/Content').then(m => m.AzureResourcesContent),
    mountPoint: rootRouteRef,
  }),
);

/** @public */
export const AzureResourceOverviewCard = azureResourcesPlugin.provide(
  createComponentExtension({
    name: 'EntityAzureReadmeCard',
    component: {
      lazy: () =>
        import('./components/Cards').then(m => m.AzureResourceOverviewCard),
    },
  }),
);

/** @public */
export const AzureResourceSecurityCard = azureResourcesPlugin.provide(
  createComponentExtension({
    name: 'AzureResourceSecurityCard',
    component: {
      lazy: () =>
        import('./components/Cards').then(m => m.AzureResourceSecurityCard),
    },
  }),
);

/** @public */
export const AzureResourceCostAdviceCard = azureResourcesPlugin.provide(
  createComponentExtension({
    name: 'AzureResourceSecurityCard',
    component: {
      lazy: () =>
        import('./components/Cards').then(m => m.AzureResourceCostAdviceCard),
    },
  }),
);

/** @public */
export const AzureResourceCompliancyContent = azureResourcesPlugin.provide(
  createRoutableExtension({
    name: 'AzureResourceCompliancyContent',
    component: () =>
      import('./components/Content').then(
        m => m.AzureResourceCompliancyContent,
      ),
    mountPoint: rootRouteRef,
  }),
);

/** @public */
export const AzureResourceTaggingCompliancyCard = azureResourcesPlugin.provide(
  createComponentExtension({
    name: 'AzureResourceTaggingCompliancyCard',
    component: {
      lazy: () =>
        import('./components/Cards').then(
          m => m.AzureResourceTaggingCompliancyCard,
        ),
    },
  }),
);

/** @public */
export const AzureResourcePolicyCompliancyCard = azureResourcesPlugin.provide(
  createComponentExtension({
    name: 'AzureResourcePolicyCompliancyCard',
    component: {
      lazy: () =>
        import('./components/Cards').then(
          m => m.AzureResourcePolicyCompliancyCard,
        ),
    },
  }),
);

/** @public */
export const AzureManagedClustersContent = azureResourcesPlugin.provide(
  createRoutableExtension({
    name: 'AzureManagedClustersContent',
    component: () =>
      import('./components/Content').then(m => m.AzureManagedClustersContent),
    mountPoint: rootRouteRef,
  }),
);

/** @public */
export const AzureManagedClusterCard = azureResourcesPlugin.provide(
  createComponentExtension({
    name: 'AzureManagedClusterCard',
    component: {
      lazy: () =>
        import('./components/Cards').then(m => m.AzureManagedClusterCards),
    },
  }),
);

/** @public */
export const AzureManagedClusterCards = azureResourcesPlugin.provide(
  createComponentExtension({
    name: 'AzureManagedClusterCards',
    component: {
      lazy: () =>
        import('./components/Cards').then(m => m.AzureManagedClusterCards),
    },
  }),
);

/** @public */
export const AzureContent = azureResourcesPlugin.provide(
  createComponentExtension({
    name: 'AzureContent',
    component: {
      lazy: () => import('./components/AzureContent').then(m => m.AzureContent),
    },
  }),
);
