import useAsync from 'react-use/lib/useAsync';

import { useApi } from '@backstage/core-plugin-api';

import { azureResourcesApiRef } from '../api';
import {
  AzureSecurityRecommendation,
  AzureSecurityRecommendationSeverity,
} from '../types';
import { prepareSecurityRecommendations } from '../utils';

export const useAzureManagedClusterSecurityRecommendations = (
  clusterResourceName: string,
) => {
  const api = useApi(azureResourcesApiRef);

  const {
    value: securityRecommendations,
    loading,
    error,
  } = useAsync(async (): Promise<AzureSecurityRecommendationSeverity[]> => {
    const resourcesResponse = await api.getAzureManagedClusterSecurities(
      clusterResourceName,
    );

    return prepareSecurityRecommendations(
      (resourcesResponse?.data as AzureSecurityRecommendation[]) ?? [],
    );
  }, []);

  return { securityRecommendations, loading, error } as const;
};
