import { FactRetriever } from '@backstage-community/plugin-tech-insights-node';

import { WatchtowerReader } from '../../WatchtowerReader';
import {
  createCIPRMedianMergeDuration,
  createCIBuildDurationHandler,
  createCISuccessFactsHandler,
} from '../../handlers/github';
import { TechInsightsRetrieverIDs } from '../../retrieverIDs';

export const createGithubCISuccessFactRetriever = (
  reader: WatchtowerReader,
): FactRetriever => ({
  id: TechInsightsRetrieverIDs.GITHUB_REPO_CI_SUCCESS_FACT_RETRIEVER,
  version: '0.0.1',
  title: 'Component or Repository entity scoring',
  description: 'Get GitHub CI success facts for entities',
  entityFilter: [
    {
      kind: 'Component',
      'spec.type': 'service',
    },
  ],
  schema: {
    successPercent: {
      type: 'float',
      description:
        'percentage of Workflow runs that were successful in the associate repo',
    },
  },
  handler: createCISuccessFactsHandler(reader),
});

export const createGithubCIMedianBuildDurationFactRetriever = (
  reader: WatchtowerReader,
): FactRetriever => ({
  id: TechInsightsRetrieverIDs.GITHUB_REPO_CI_MEDIAN_BUILD_DURATION_FACT_RETRIEVER,
  version: '0.0.1',
  title: 'Component or Repository entity scoring',
  description: 'Get GitHub CI Median Build duration for Service',
  entityFilter: [
    {
      kind: 'Component',
      'spec.type': 'service',
    },
  ],
  schema: {
    medianBuildDurationSecs: {
      type: 'float',
      description:
        'median build duration of the Workflow runs in the associated repo',
    },
  },
  handler: createCIBuildDurationHandler(reader),
});

export const createGithubCIPRMedianTimeToMergeFactRetriever = (
  reader: WatchtowerReader,
): FactRetriever => ({
  id: TechInsightsRetrieverIDs.GITHUB_REPO_CI_PR_MEDIAN_TIME_TO_MERGE_FACT_RETRIEVER,
  version: '0.0.1',
  title: 'Component or Repository entity scoring',
  description: 'Get GitHub CI Median PR time to merge for Service',
  entityFilter: [
    {
      kind: 'Component',
      'spec.type': 'service',
    },
  ],
  schema: {
    medianPRTimeToMergeHours: {
      type: 'float',
      description: 'median time to merge for the PRs in the associated repo',
    },
  },
  handler: createCIPRMedianMergeDuration(reader),
});
