import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const catalogExplorerPlugin = createPlugin({
  id: 'catalog-explorer',
  routes: {
    root: rootRouteRef,
  },
});

export const ServiceCatalogExplorerPage = catalogExplorerPlugin.provide(
  createRoutableExtension({
    name: 'ServiceCatalogExplorerPage',
    component: () =>
      import(
        './components/CatalogExplorerPage/ServiceCatalogExplorerPage'
      ).then(m => m.ServiceCatalogExplorerPage),
    mountPoint: rootRouteRef,
  }),
);

export const ProductCatalogExplorerPage = catalogExplorerPlugin.provide(
  createRoutableExtension({
    name: 'ProductCatalogExplorerPage',
    component: () =>
      import(
        './components/CatalogExplorerPage/ProductCatalogExplorerPage'
      ).then(m => m.ProductCatalogExplorerPage),
    mountPoint: rootRouteRef,
  }),
);
