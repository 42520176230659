import {
  createApiRef,
  FetchApi,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';

import { NewSecretKey, SecretKey } from './types';

export const authApiRef = createApiRef<AuthApi>({
  id: 'api-auth',
});

export interface AuthApi {
  getAllKeys(): Promise<any>;
  createKey(secretKey: NewSecretKey): Promise<any>;
  removeKey(keyId: string): Promise<any>;
}

export class APIAuthClient implements AuthApi {
  private readonly fetchApi: FetchApi;
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: {
    fetchApi: FetchApi;
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) {
    this.fetchApi = options.fetchApi;
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  private async getBaseUrl() {
    return await this.discoveryApi.getBaseUrl('api-auth');
  }

  async createKey(secretKey: SecretKey): Promise<any> {
    const baseUrl = await this.getBaseUrl();
    const { token } = await this.identityApi.getCredentials();
    return await this.fetchApi.fetch(`${baseUrl}/create`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(secretKey),
    });
  }

  async removeKey(keyId: string): Promise<any> {
    const baseUrl = await this.getBaseUrl();
    const { token } = await this.identityApi.getCredentials();
    return await this.fetchApi.fetch(`${baseUrl}/api-credentials/${keyId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async getAllKeys(): Promise<any> {
    const baseUrl = await this.getBaseUrl();
    return this.fetchApi.fetch(`${baseUrl}/api-credentials`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }
}
