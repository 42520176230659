import { GroupEntity } from '@internal/plugin-catalog-model';
import React from 'react';

import {
  CompoundEntityRef,
  RELATION_PARENT_OF,
} from '@backstage/catalog-model';
import { InfoCardVariants } from '@backstage/core-components';
import { getEntityRelations, useEntity } from '@backstage/plugin-catalog-react';

import { Box, Divider, Grid, Typography } from '@material-ui/core';

import { GroupProfileChildCard } from './GroupProfileChildCard';

export const GroupProfileChildrenCards = (props: {
  variant?: InfoCardVariants;
}) => {
  const { entity: group } = useEntity<GroupEntity>();

  const childRelations = getEntityRelations(group, RELATION_PARENT_OF, {
    kind: 'Group',
  });

  if (childRelations.length === 0) {
    return <></>;
  }
  const children = childRelations.map(
    (childEntityRef: CompoundEntityRef, index: number) => {
      return (
        <Grid item md={6} xl={4} sm={6} xs={12} key={index}>
          <GroupProfileChildCard
            childEntityRef={childEntityRef}
            variant={props.variant}
          />
        </Grid>
      );
    },
  );

  return (
    <Box>
      <Box>
        <Typography variant="h5" color="primary">
          Teams
        </Typography>
        <Typography variant="caption" color="primary">
          Please find an overview of the various teams in{' '}
          {group.spec?.profile?.displayName ?? group.metadata?.name} below.
        </Typography>
      </Box>
      <Divider />
      <Grid container spacing={5}>
        {children}
      </Grid>
    </Box>
  );
};
