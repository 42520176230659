import { ExportData, ExportRowData } from '../types';

export const getDataForExport = (
  data: ExportData[],
  columns: ExportRowData[],
): ExportRowData[] => {
  return data.map((record: any) =>
    columns.reduce(
      (recordToDownload: ExportRowData, column) => ({
        ...recordToDownload,
        [column.header as string]: record[column.accessor as string],
      }),
      {},
    ),
  );
};

export const makeCsv = async (rows: any[], filename: string) => {
  const separator: string = ';';
  const keys: string[] = Object.keys(rows[0]);

  const csvContent = `${keys.join(separator)}\n${rows
    .map(row =>
      keys
        .map(function formatCells(key) {
          let cell =
            row[key] === null || row[key] === undefined ? '' : row[key];

          cell = cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        })
        .join(separator),
    )
    .join('\n')}`;

  const downloadBlob = new Blob([csvContent], {
    type: 'text/csv;charset=utf-8;',
  });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(downloadBlob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
