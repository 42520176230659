import { useAsync } from 'react-use';

import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { IdentityApi } from '@backstage/core-plugin-api';

const isUserMemberOfGroup = (entity: Entity, userEntityRef: string) =>
  entity.relations
    ?.filter(relation => relation.type === 'hasMember')
    .map(relation => relation.targetRef)
    .includes(userEntityRef);

export const useIsOnOwnGroupEntityPage = (
  identityApi: IdentityApi,
  entity: Entity,
) => {
  const { value: isOnOwnGroupEntityPage } = useAsync(async () => {
    const { ownershipEntityRefs: userGroups, userEntityRef } =
      await identityApi.getBackstageIdentity();

    return (
      userGroups
        .filter(ug => ug.startsWith('group'))
        .includes(stringifyEntityRef(entity)) ||
      isUserMemberOfGroup(entity, userEntityRef)
    );
  });

  return isOnOwnGroupEntityPage;
};
