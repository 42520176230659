import React from 'react';

import { EntitySwitch, isKind } from '@backstage/plugin-catalog';

import {
  ApiPage,
  ComponentPage,
  DomainPage,
  GroupPage,
  SystemPage,
  UserPage,
  GithubActionAllowListPage,
  KaapAccountPage,
  KaaPTenantPage,
} from './Pages';
import { DefaultEntityPage } from './Pages/DefaultEntityPage';

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={ComponentPage} />
    <EntitySwitch.Case if={isKind('api')} children={ApiPage} />
    <EntitySwitch.Case if={isKind('group')} children={GroupPage} />
    <EntitySwitch.Case if={isKind('user')} children={UserPage} />
    <EntitySwitch.Case if={isKind('system')} children={SystemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={DomainPage} />
    <EntitySwitch.Case if={isKind('KaaPAccount')} children={KaapAccountPage} />
    <EntitySwitch.Case
      if={isKind('KaaPTenant')}
      children={<KaaPTenantPage />}
    />

    <EntitySwitch.Case
      if={isKind('githubaction')}
      children={GithubActionAllowListPage}
    />
    <EntitySwitch.Case>{DefaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
