import { useApi } from '@backstage/core-plugin-api';
import { scmAuthApiRef } from '@backstage/integration-react';
import { useTemplateSecrets } from '@backstage/plugin-scaffolder-react';

import { RepoNamePickerProps } from '../schema';
import { RepoNamePickerState } from '../types';

export const useSetSecret = (
  props: RepoNamePickerProps,
  state: RepoNamePickerState,
  setState: React.Dispatch<React.SetStateAction<RepoNamePickerState>>,
) => {
  const { uiSchema } = props;
  const scmAuthApi = useApi(scmAuthApiRef);
  const { setSecrets } = useTemplateSecrets();

  return async () => {
    const { requestUserCredentials } = uiSchema?.['ui:options'] ?? {};

    const workspace = state.owner ? state.owner : state.project;
    if (
      !requestUserCredentials ||
      !(state.host && workspace && state.repoName)
    ) {
      return;
    }

    const [encodedHost, encodedWorkspace, encodedRepoName] = [
      state.host,
      workspace,
      state.repoName,
    ].map(encodeURIComponent);

    // user has requested that we use the users credentials
    // so lets grab them using the scmAuthApi and pass through
    // any additional scopes from the ui:options
    const { token } = await scmAuthApi.getCredentials({
      url: `https://${encodedHost}/${encodedWorkspace}/${encodedRepoName}`,
      additionalScope: {
        repoWrite: true,
        customScopes: requestUserCredentials.additionalScopes,
      },
    });

    setState(prevState => ({
      ...prevState,
      token,
    }));

    // set the secret using the key provided in the ui:options for use
    // in the templating the manifest with ${{ secrets[secretsKey] }}
    setSecrets({ [requestUserCredentials.secretsKey]: token });
  };
};
