import { Operator } from 'json-rules-engine';
import semver from 'semver';

export const SEMVER_SATISFIES_OPERATOR = 'semverSatisfies';

export const semverSatisfies = new Operator(
  SEMVER_SATISFIES_OPERATOR,
  (actions: string[], value: string) => {
    const [actionToCheck, versionToCheck] = value.split('@');
    const satisfies: boolean[] = [];

    if (actions) {
      actions.flat().forEach(action => {
        const [actionName] = action.split('@');
        if (actionName === actionToCheck) {
          const version = semver.coerce(action);
          if (version) {
            satisfies.push(semver.satisfies(version, versionToCheck));
          }
        }
      });
    }

    return satisfies.length === 0 || satisfies.every(result => result === true);
  },
);
