import {
  AzureSecurityRecommendation,
  AzureSecurityRecommendationSeverity,
} from '../types';

export const prepareSecurityRecommendations = (
  recommendations: AzureSecurityRecommendation[],
): AzureSecurityRecommendationSeverity[] => {
  const cachedRecommendations: {
    [key: string]: Array<{
      link: string;
      label: string;
    }>;
  } = {};
  const SeverityToNumber = {
    Low: 1,
    Medium: 2,
    High: 3,
  };
  const convertSeverityToNumber = (severity: string) =>
    SeverityToNumber[severity as keyof typeof SeverityToNumber];
  const results: AzureSecurityRecommendationSeverity[] = [];
  recommendations.forEach(
    (item: AzureSecurityRecommendation, index: number) => {
      if (!cachedRecommendations[item.displayName]) {
        cachedRecommendations[item.displayName] = [];
        results.push({
          id: index,
          recommendation: item.displayName,
          resources: cachedRecommendations[item.displayName],
          severity: convertSeverityToNumber(item.severity),
        });
      }
      cachedRecommendations[item.displayName].push({
        link: item.link,
        label: item.resourceName,
      });
    },
  );
  return results;
};
