import { useAsync } from 'react-use';

import { configApiRef, useApi } from '@backstage/core-plugin-api';

import { azureResourcesApiRef } from '../api';

export type AzureOwnGroup = {
  id: string;
  displayName?: string;
};

export const useAzureOwnGroups = (): {
  loading: boolean;
  error: Error | undefined;
  value: AzureOwnGroup[];
} => {
  const api = useApi(azureResourcesApiRef);
  const config = useApi(configApiRef);
  const tenantId = config.getOptionalString('app.azureResources.tenantId');
  const clientId = config.getOptionalString('app.azureResources.clientId');
  const {
    value: groupsValue,
    loading,
    error,
  } = useAsync(async () => {
    const groups = await api.getAzureOwnGroups(tenantId || '', clientId || '');
    return groups.value;
  }, []);

  return {
    value: groupsValue ?? [],
    loading,
    error,
  } as const;
};
