import React from 'react';

import { GaugeCard } from '@backstage/core-components';

import { Grid, Typography } from '@material-ui/core';

import { CheckResult } from './types';
import { useCalculatePercentages } from './useCalculatePercentages';

export const OnboardingStats = (props: { results: CheckResult[] }) => {
  const { results } = props;

  const {
    allGroups: {
      completionData,
      managerData,
      slackData,
      confluenceData,
      bugReportData,
    },
    teams: {
      teamCompletionData,
      teamManagerData,
      teamSlackData,
      teamConfluenceData,
      teamBugReportData,
      teamComponentOrAPIOnboardData,
    },
  } = useCalculatePercentages(results);

  return (
    <>
      <Grid container item md={12} spacing={3}>
        <Grid item xl={12}>
          <Typography color="error" variant="h4">
            Total {completionData.total} Groups
          </Typography>
        </Grid>
        <Grid container item md={12}>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <GaugeCard
              progress={completionData.percentage / 100}
              title="Total Completion"
              subheader={`${completionData.onboarded}/${completionData.total}`}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2}>
            <GaugeCard
              progress={managerData.percentage / 100}
              title="Manager"
              subheader={`${managerData.onboarded}/${managerData.total}`}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2}>
            <GaugeCard
              progress={slackData.percentage / 100}
              title="Slack Channel"
              subheader={`${slackData.onboarded}/${slackData.total}`}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2}>
            <GaugeCard
              progress={confluenceData.percentage / 100}
              title="Confluence"
              subheader={`${confluenceData.onboarded}/${confluenceData.total}`}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2}>
            <GaugeCard
              progress={bugReportData.percentage / 100}
              title="Bug Report Link"
              subheader={`${bugReportData.onboarded}/${bugReportData.total}`}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container item md={12} spacing={3} style={{ paddingTop: '30px' }}>
        <Grid item xl={12}>
          <Typography color="error" variant="h4">
            Total {teamCompletionData.total} Teams
          </Typography>
        </Grid>
        <Grid container item md={12}>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <GaugeCard
              progress={teamCompletionData.percentage / 100}
              title="Total Completion"
              subheader={`${teamCompletionData.onboarded}/${teamCompletionData.total}`}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2}>
            <GaugeCard
              progress={teamManagerData.percentage / 100}
              title="Manager"
              subheader={`${teamManagerData.onboarded}/${teamManagerData.total}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <GaugeCard
              progress={teamSlackData.percentage / 100}
              title="Slack Channel"
              subheader={`${teamSlackData.onboarded}/${teamSlackData.total}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <GaugeCard
              progress={teamConfluenceData.percentage / 100}
              title="Confluence"
              subheader={`${teamConfluenceData.onboarded}/${teamConfluenceData.total}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <GaugeCard
              progress={teamBugReportData.percentage / 100}
              title="Bug Report Link"
              subheader={`${teamBugReportData.onboarded}/${teamBugReportData.total}`}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2}>
            <GaugeCard
              progress={teamComponentOrAPIOnboardData.percentage / 100}
              title="Component/API"
              subheader={`${teamComponentOrAPIOnboardData.onboarded}/${teamComponentOrAPIOnboardData.total}`}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
