import { FactRetriever } from '@backstage-community/plugin-tech-insights-node';

import { teamSlackChannelHandler } from '../handlers/teamSlackChannelHandler';

export const teamSlackChannelFactRetriever: FactRetriever = {
  id: 'teamSlackChannelFactRetriever',
  version: '0.0.1',
  title: 'Team Slack Channel',
  description:
    'Generates facts which indicate the quality of data in metadata.slack field',
  entityFilter: [{ kind: ['group'] }],
  schema: {
    hasSlackChannel: {
      type: 'boolean',
      description: 'The metadata.slack field is set',
    },
  },
  handler: teamSlackChannelHandler,
};
