import { ConfigEntity } from '@internal/plugin-catalog-model';

import { Entity } from '@backstage/catalog-model';
import { IdentityApi } from '@backstage/core-plugin-api';
import { CatalogApi } from '@backstage/plugin-catalog-react';

import { arrayToObjectReducer } from '../utils';

const getExtraTeamMembers = (
  configEntity: ConfigEntity,
  teamEntityName: string,
): string[] => {
  // for now assume the config is only for Group
  const config = configEntity.spec?.configs
    ?.reverse()
    .find(c => c.groups.includes(teamEntityName));

  return config?.members ?? [];
};

export const extraTeamMembersFormDataBuilder = async (
  entity: Entity,
  formFields: string[],
  _identityApi?: IdentityApi,
  catalogApi?: CatalogApi,
) => {
  const team_config_entity_name = `${entity.metadata.name}_config`;

  const configEntity = await catalogApi?.getEntityByRef({
    kind: 'Config',
    namespace: 'default',
    name: team_config_entity_name,
  });

  const extraTeamMembers = configEntity
    ? getExtraTeamMembers(configEntity as ConfigEntity, entity.metadata.name)
    : [];

  const formData = formFields
    .map((field: string) => {
      switch (field) {
        case 'team':
          return { [field]: `group:${entity.metadata.name}` };
        case 'members':
          return { [field]: extraTeamMembers };
        default:
          return {};
      }
    })
    .reduce(arrayToObjectReducer, {});

  return formData;
};
