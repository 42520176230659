import React from 'react';

import { Box, Tab, Tabs } from '@material-ui/core';
import DataUsageIcon from '@material-ui/icons/DataUsageOutlined';
import ListIcon from '@material-ui/icons/ListAltOutlined';

import { OnboardingStats } from './OnboardingStats';
import { OnboardingTable } from './OnboardingTable';
import { CheckResult } from './types';

const TabPanel = (props: {
  children?: React.ReactNode;
  index: number;
  value: number;
}) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pl={3} pr={3}>
          {children}
        </Box>
      )}
    </div>
  );
};

export const OnBoardingTabOverview = (props: { results: CheckResult[] }) => {
  const [activeTab, setActiveTab] = React.useState(0);

  return (
    <>
      <Box>
        <Tabs
          value={activeTab}
          indicatorColor="secondary"
          variant="fullWidth"
          onChange={(_, newActiveTab) => setActiveTab(newActiveTab)}
        >
          <Tab
            icon={<DataUsageIcon />}
            label="Chart Overview"
            id="tab-0"
            aria-controls="tabpanel-0"
          />
          <Tab
            icon={<ListIcon />}
            label="Tabular Overview"
            id="tab-1"
            aria-controls="tabpanel-1"
          />
        </Tabs>
      </Box>
      <Box pt={5}>
        <TabPanel value={activeTab} index={0}>
          <OnboardingStats
            results={props.results}
            data-testid="onboarding-cards"
          />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <OnboardingTable
            data-testid="onboarding-table"
            data={props.results}
          />
        </TabPanel>
      </Box>
    </>
  );
};
