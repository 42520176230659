import { DateTime } from 'luxon';
import React from 'react';

import {
  StatusPending,
  StatusRunning,
  StatusOK,
  TableColumn,
  StatusAborted,
  StatusError,
  Link,
} from '@backstage/core-components';

import { Box, Typography } from '@material-ui/core';

import { GithubDeployment } from '../../api';

export const GithubStateIndicator = (props: { state: string }) => {
  switch (props.state) {
    case 'PENDING':
      return <StatusPending />;
    case 'IN_PROGRESS':
      return <StatusRunning />;
    case 'ACTIVE':
      return <StatusOK />;
    case 'ERROR':
    case 'FAILURE':
      return <StatusError />;
    default:
      return <StatusAborted />;
  }
};

export const columnFactories = Object.freeze({
  createEnvironmentColumn(): TableColumn<GithubDeployment> {
    return {
      title: 'Environment',
      field: 'environment',
      highlight: true,
    };
  },

  createStatusColumn(): TableColumn<GithubDeployment> {
    return {
      title: 'Status',
      render: (row: GithubDeployment): JSX.Element => (
        <Box display="flex" alignItems="center">
          <GithubStateIndicator state={row.state} />
          <Typography variant="caption">{row.state}</Typography>
        </Box>
      ),
    };
  },
  createCommitColumn(): TableColumn<GithubDeployment> {
    return {
      title: 'Commit',
      render: (row: GithubDeployment) =>
        row.commit && (
          <Link to={row.commit.commitUrl} target="_blank" rel="noopener">
            {row.commit.abbreviatedOid}
          </Link>
        ),
    };
  },

  createCreatorColumn(): TableColumn<GithubDeployment> {
    return {
      title: 'Creator',
      field: 'creator.login',
    };
  },

  createRepositoryColumn(): TableColumn<GithubDeployment> {
    return {
      title: 'Repository',
      field: 'repository.nameWithOwner',
    };
  },

  createLastUpdatedColumn(): TableColumn<GithubDeployment> {
    return {
      title: 'Last Updated',
      render: (row: GithubDeployment): JSX.Element => (
        <Box>
          {DateTime.fromISO(row.updatedAt).toRelative({ locale: 'en' })}
        </Box>
      ),
    };
  },
});

export const defaultDeploymentColumns: TableColumn<GithubDeployment>[] = [
  columnFactories.createRepositoryColumn(),
  columnFactories.createEnvironmentColumn(),
  columnFactories.createStatusColumn(),
  columnFactories.createCommitColumn(),
  columnFactories.createCreatorColumn(),
  columnFactories.createLastUpdatedColumn(),
];
