import { uniq } from 'lodash';

import {
  PluginEndpointDiscovery,
  TokenManager,
} from '@backstage/backend-common';
import { CatalogClient } from '@backstage/catalog-client';
import {
  Entity,
  RELATION_PARENT_OF,
  stringifyEntityRef,
} from '@backstage/catalog-model';

import { getEntityRelations } from '../../../utils/getEntityRelations';

const isEntity = (entity: Entity | undefined): entity is Entity =>
  entity !== undefined;

export const getChildGroupEntityRefs = async (options: {
  entity: Entity;
  tokenManager: TokenManager;
  discovery: PluginEndpointDiscovery;
  alreadyRetrievedParentRefs: string[];
}): Promise<string[]> => {
  const { entity, tokenManager, alreadyRetrievedParentRefs, discovery } =
    options;
  const { token } = await tokenManager.getToken();
  const catalogClient = new CatalogClient({
    discoveryApi: discovery,
  });
  const entityRef = stringifyEntityRef(entity);
  const childGroups = getEntityRelations(entity, RELATION_PARENT_OF, {
    kind: 'Group',
  });

  const hasChildGroups = childGroups.length > 0;
  if (hasChildGroups) {
    const entityRefs = childGroups.map(r => stringifyEntityRef(r));
    const childGroupResponse = await catalogClient.getEntitiesByRefs(
      {
        fields: ['kind', 'metadata.namespace', 'metadata.name', 'relations'],
        entityRefs,
      },
      { token },
    );
    const childGroupEntities = childGroupResponse.items.filter(isEntity);
    const unknownChildren = childGroupEntities.filter(
      childGroupEntity =>
        !alreadyRetrievedParentRefs.includes(
          stringifyEntityRef(childGroupEntity),
        ),
    );
    const childrenRefs = (
      await Promise.all(
        unknownChildren.map(childGroupEntity =>
          getChildGroupEntityRefs({
            entity: childGroupEntity,
            tokenManager,
            discovery,
            alreadyRetrievedParentRefs: [
              ...alreadyRetrievedParentRefs,
              entityRef,
            ],
          }),
        ),
      )
    ).flatMap(aggregated => aggregated);
    return uniq([...childrenRefs, entityRef]);
  }
  return [entityRef];
};
