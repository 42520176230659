import { useAsync } from 'react-use';

import { useApi } from '@backstage/core-plugin-api';

import { azureResourcesApiRef } from '../api';
import { DateRange } from '../types';

export const useAzureBillingCosts = (selectedDates: DateRange) => {
  const api = useApi(azureResourcesApiRef);
  const {
    value: azureCosts,
    loading,
    error,
  } = useAsync(async () => {
    return api.getAzureCosts(selectedDates);
  }, [selectedDates]);

  return {
    azureCosts: azureCosts ?? { byService: [], byResourceGroup: [] },
    loading,
    error,
  } as const;
};
