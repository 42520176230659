import useAsync from 'react-use/lib/useAsync';

import { useApi } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';

import { azureResourcesApiRef } from '../api';
import { AzureCostAdvice, AzureCostAdviceSeverity } from '../types';
import { parseAzureResourceTagAnnotation } from '../utils';

const SeverityToNumber = {
  Low: 1,
  Medium: 2,
  High: 3,
};

export const useAzureResourceCostAdvice = () => {
  const { entity } = useEntity();
  const api = useApi(azureResourcesApiRef);
  const [tagKey, tagValue] = parseAzureResourceTagAnnotation(entity);

  const {
    value: costAdvice,
    loading,
    error,
  } = useAsync(async (): Promise<AzureCostAdviceSeverity[]> => {
    const resourcesResponse = await api.getAzureResourceCostAdvice({
      tagKey,
      tagValue,
    });

    const advice = (resourcesResponse?.data as AzureCostAdvice[]) ?? [];

    return advice.map(
      (item: AzureCostAdvice, index: number) =>
        ({
          id: index,
          advice: item.solution,
          costSavings: `${item.sum_savings} ${item.currency}`,
          severity:
            SeverityToNumber[item.impact as keyof typeof SeverityToNumber],
        } as AzureCostAdviceSeverity),
    );
  }, []);

  return { costAdvice, loading, error } as const;
};
