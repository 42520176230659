import { NavigateToTemplateButton } from '@internal/plugin-catalog-page-addons-react';
import React from 'react';

import { Entity, RELATION_PARENT_OF } from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';

import { Box, Button, ButtonGroup } from '@material-ui/core';
import AddRounded from '@material-ui/icons/AddRounded';
import HubIcon from '@material-ui/icons/DeviceHub';
import GitHub from '@material-ui/icons/GitHub';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import Settings from '@material-ui/icons/Settings';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';

const isTeam = (entity: Entity) =>
  !entity?.relations?.find(item => item.type === RELATION_PARENT_OF);

export const GroupActionContent = () => {
  const { entity } = useEntity();

  return (
    <Box>
      <ButtonGroup
        variant="outlined"
        aria-label="outlined primary button group"
      >
        {(entity.spec?.type === 'team' ||
          entity.spec?.type === 'cost-center' ||
          entity.spec?.type === 'supervisory-org' ||
          entity.spec?.type === 'sub-unit' ||
          entity.spec?.type === 'product-unit' ||
          entity.spec?.type === 'business-unit') && (
          <NavigateToTemplateButton
            startIcon={<Settings />}
            variant="outlined"
            template="team-config"
            text={
              entity.spec?.type === 'cost-center' ||
              entity.spec?.type === 'supervisory-org' ||
              entity.spec?.type === 'team'
                ? 'Configure Team'
                : 'Configure Unit'
            }
          />
        )}

        {(entity.spec?.type === 'cost-center' ||
          entity.spec?.type === 'supervisory-org' ||
          entity.spec?.type === 'team') && (
          <NavigateToTemplateButton
            startIcon={<PeopleIcon />}
            variant="outlined"
            template="extra-team-members"
            text="Add Extra Team Members"
          />
        )}

        <NavigateToTemplateButton
          startIcon={<GitHub />}
          variant="outlined"
          template="github-repository-ownership"
          text="Claim Repository Ownership"
        />

        <NavigateToTemplateButton
          startIcon={<SettingsInputComponentIcon />}
          variant="outlined"
          template="batch-github-repository-ownership"
          text="Batch Confirm Repository Ownership"
        />

        <NavigateToTemplateButton
          startIcon={<PeopleIcon />}
          variant="outlined"
          template="batch-update-entity-owners"
          text="Migrate Entities To Another Team"
        />

        <Button
          variant="outlined"
          startIcon={<AddRounded />}
          href="/catalog-import"
        >
          Import Catalog
        </Button>
        <Button
          variant="outlined"
          startIcon={<GitHub />}
          href="/create/templates/default/github-basic-repo-creation"
        >
          Create Repository
        </Button>
        {isTeam(entity) && (
          <NavigateToTemplateButton
            startIcon={<HubIcon />}
            variant="outlined"
            template="create-service"
            text="Add Service"
          />
        )}
      </ButtonGroup>
    </Box>
  );
};
