import React from 'react';

import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

import { GenericValuePicker } from './GenericValuePicker';

export type GenericValuePickerUiOptions = {
  allowedValues?: string[];
};

export const GenericValuePickerExtension = (
  props: FieldExtensionComponentProps<string, GenericValuePickerUiOptions>,
) => {
  const {
    schema: {
      title = 'Please choose',
      description = 'All the values available',
    },
    uiSchema,
    rawErrors,
    ...restProps
  } = props;

  const allowedValues: string[] = uiSchema['ui:options']?.allowedValues || [];
  const pickedValue = allowedValues?.length > 0 ? allowedValues[0] : '';
  return (
    <GenericValuePicker
      {...restProps}
      schema={{ title, description }}
      allowedValues={allowedValues}
      rawErrors={rawErrors}
      pickedValue={pickedValue}
    />
  );
};
