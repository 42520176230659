import useAsync from 'react-use/lib/useAsync';

import { useApi } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';

import { azureResourcesApiRef } from '../api';
import { AzureResourceGroup } from '../types';
import { parseAzureResourceTagAnnotation } from '../utils';

export const useAzureResources = () => {
  const { entity } = useEntity();
  const api = useApi(azureResourcesApiRef);

  const [tagKey, tagValue] = parseAzureResourceTagAnnotation(entity);

  const {
    value: resourceGroups,
    loading,
    error,
  } = useAsync(async (): Promise<AzureResourceGroup[]> => {
    const resourcesResponse = await api.getAzureResources({
      tagKey,
      tagValue,
    });

    return (resourcesResponse?.data as AzureResourceGroup[]) ?? [];
  }, []);

  return { resourceGroups, loading, error } as const;
};
