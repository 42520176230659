import { useAsync } from 'react-use';

import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

import { Member } from '../types';
import { fetchUserName } from '../util/fetchUserName';

export const useAddMemberFullname = (members: Member[]) => {
  const catalogApi = useApi(catalogApiRef);
  return useAsync(async () => {
    const allMembersWithName = members.map(async member => {
      return {
        ...member,
        fullName: await fetchUserName(member.userRef, catalogApi),
      };
    });
    return Promise.all(allMembersWithName);
  }, [members]);
};
