import Ajv from 'ajv';
import { Engine, Operator } from 'json-rules-engine';
import { Logger } from 'winston';

import { TechInsightsStore } from '@backstage-community/plugin-tech-insights-node';

import { Check, CheckResult } from '../types';
import * as validationSchema from './validation-schema.json';

export class JsonRulesEngine {
  checks: Map<string, Check>;
  factsStore: TechInsightsStore;
  customOperators: Operator[];
  checkSchemaValidator;
  logger: Logger;
  checksRegisteredPromise: Promise<void>;
  constructor(
    logger: Logger,
    checkList: Check[],
    customOperators: Operator[],
    factsDb: TechInsightsStore,
  ) {
    this.factsStore = factsDb;
    this.checks = new Map<string, Check>();

    this.customOperators = customOperators;
    const ajv = new Ajv({ verbose: true });
    this.checkSchemaValidator = ajv.compile(
      this.extendSchemaWithCustomOperators(customOperators),
    );
    this.logger = logger;
    this.checksRegisteredPromise = this.registerChecks(checkList);
  }
  initialize() {
    return this.checksRegisteredPromise;
  }

  async registerChecks(checks: Check[]) {
    for (const check of checks) {
      if (this.checks.has(check.id)) {
        this.logger.warn(
          `duplicate check ${check.id} ${JSON.stringify(check)}`,
        );
        continue;
      }
      const valid = await this.validate(check);
      if (!valid) {
        this.logger.warn(`invalid check ${check.id} ${JSON.stringify(check)}`);
        continue;
      }
      this.checks.set(check.id, check);
    }
  }

  extendSchemaWithCustomOperators(operators: Operator[]) {
    const extendedValidationSchema = JSON.parse(
      JSON.stringify(validationSchema),
    );
    operators.forEach(op => {
      extendedValidationSchema.definitions.condition.properties.operator.anyOf.push(
        { const: op.name },
      );
    });
    return extendedValidationSchema;
  }

  async getChecks() {
    return Array.from(this.checks.values());
  }

  async runChecks(
    entityRef: string,
    checkIds: string[],
  ): Promise<CheckResult[]> {
    const engine = new Engine([], { allowUndefinedFacts: true });

    this.customOperators.forEach(op => engine.addOperator(op));

    const factIds = checkIds.flatMap(checkId => {
      const check = this.checks.get(checkId);
      if (!check) {
        return [];
      }
      engine.addRule({
        conditions: check.rule.conditions,
        event: { type: check.id },
      });
      return Array.from(check.factIds) || [];
    });
    const facts = await this.factsStore.getLatestFactsByIds(factIds, entityRef);
    const factValues = Object.values(facts).reduce(
      (acc, it) => ({ ...acc, ...it.facts }),
      {},
    );
    const results = await engine.run(factValues);
    const checkResultsResponse: CheckResult[] = [];
    for (const passedResult of results.results) {
      const checkId = passedResult.event?.type;
      if (!checkId || !this.checks.has(checkId)) {
        continue;
      }
      const checkData = this.checks.get(checkId);
      const { name, description } = checkData!;
      checkResultsResponse.push({
        id: checkId,
        name: name,
        result: true,
        description,
      });
    }
    for (const failedResult of results.failureResults) {
      const checkId = failedResult.event?.type;
      if (!checkId || !this.checks.has(checkId)) {
        continue;
      }
      const checkData = this.checks.get(checkId);
      const { name, description } = checkData!;
      checkResultsResponse.push({
        id: checkId,
        name: name,
        result: false,
        description,
      });
    }
    return checkResultsResponse;
  }

  async validate(check: Check) {
    const validConditions = this.checkSchemaValidator({
      conditions: check.rule.conditions,
    });
    if (!validConditions) {
      this.logger.warn(`check failed: because conditions don't match`);
      return false;
    }
    const schemas = await this.factsStore.getLatestSchemas(
      Array.from(check.factIds),
    );

    if (!schemas) {
      this.logger.warn(`couldn't find fact schemas for ${check.id}`);
      return false;
    }
    for (const factId of check.factIds) {
      const schemaExists = schemas.find(
        schema => (schema.id as unknown as string | undefined) === factId,
      );
      if (schemaExists === undefined) {
        this.logger.warn(
          `Check ${check.id} has a fact ${factId} that is not registered in the fact schema`,
        );
        return false;
      }
    }
    return true;
  }
}
