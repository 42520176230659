import React, { useCallback } from 'react';

import { Select } from '@backstage/core-components';

import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

export const SubscriptionPicker = (props: {
  availableSubscriptions:
    | Array<{
        value: string;
        label: string;
      }>
    | undefined;
  selectedSubscription: string[] | string | number | number[];
  onSubscriptionInput: (
    _: any,
    value: {
      value: string;
      label: string;
    } | null,
  ) => Promise<void>;
  onSubscriptionSelect: (
    subscriptionId: string[] | string | number | number[],
  ) => Promise<void>;
}) => {
  const {
    availableSubscriptions,
    selectedSubscription,
    onSubscriptionInput,
    onSubscriptionSelect,
  } = props;

  const getSubscriptionName = useCallback(
    (subscriptionId: string) =>
      availableSubscriptions?.find(sub => sub.value === subscriptionId),
    [availableSubscriptions],
  );

  if (availableSubscriptions && availableSubscriptions.length < 5) {
    return (
      <Select
        onChange={onSubscriptionSelect}
        items={availableSubscriptions ?? []}
        label="Subscriptions"
        selected={selectedSubscription}
      />
    );
  }
  return (
    <Autocomplete
      onChange={onSubscriptionInput}
      renderInput={params => (
        <TextField
          {...params}
          type="text"
          label="Subscription"
          margin="dense"
          InputProps={params.InputProps}
          defaultValue={selectedSubscription.toString() ?? ''}
        />
      )}
      defaultValue={{
        value: selectedSubscription.toString() ?? '',
        label:
          getSubscriptionName(selectedSubscription.toString())?.label ?? '',
      }}
      options={availableSubscriptions ?? []}
      getOptionLabel={option => option.label}
    />
  );
};
