import {
  createApiRef,
  FetchApi,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';

import { Term } from './types';

export const glossaryApiRef = createApiRef<GlossaryApi>({
  id: 'glossary',
});

export interface GlossaryApi {
  find(term: string): Promise<any>;
  addAcronym(acronym: Term): Promise<any>;
}

export class GlossaryClient implements GlossaryApi {
  private readonly fetchApi: FetchApi;
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: {
    fetchApi: FetchApi;
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) {
    this.fetchApi = options.fetchApi;
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  private async getBaseUrl() {
    return await this.discoveryApi.getBaseUrl('glossary');
  }

  async addAcronym(acronym: Term): Promise<any> {
    const baseUrl = await this.getBaseUrl();
    const { token } = await this.identityApi.getCredentials();

    return await this.fetchApi.fetch(`${baseUrl}/add`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(acronym),
    });
  }

  async find(term: string): Promise<any> {
    const baseUrl = await this.getBaseUrl();
    return this.fetchApi.fetch(`${baseUrl}/find?term=${term}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }
}
