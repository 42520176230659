import { NavigateToTemplateButton } from '@internal/plugin-catalog-page-addons-react';
import React from 'react';

import { Entity } from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';

import ExtensionIcon from '@material-ui/icons/Extension';
import GitHubIcon from '@material-ui/icons/GitHub';

const isService = (entity: Entity) =>
  entity.kind.toLowerCase() === 'component' &&
  `${entity?.spec?.type}`?.toLowerCase() === 'service';

export const ComponentActionContent = () => {
  const { entity } = useEntity();

  return (
    isService(entity) && (
      <>
        <NavigateToTemplateButton
          startIcon={<GitHubIcon />}
          variant="outlined"
          template="update-service-repositories"
          text="Update Service Repositories"
        />

        <NavigateToTemplateButton
          startIcon={<ExtensionIcon />}
          variant="outlined"
          template="update-service-apis"
          text="Update Service APIs"
        />
      </>
    )
  );
};
