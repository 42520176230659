import { JSON_RULE_ENGINE_CHECK_TYPE } from '@backstage-community/plugin-tech-insights-backend-module-jsonfc';

import { TechInsightsCheckID } from '../../checkIDs';
import { TechInsightsRetrieverIDs } from '../../retrieverIDs';

export const repoHashBranchProtectionCheck = {
  id: TechInsightsCheckID.REPO_HAS_BRANCH_PROTECTION_CHECK,
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: 'Branch protection set',
  description:
    'The associated repositories have branch protection rules to enforce safe merging and commit practices.',
  factIds: [
    TechInsightsRetrieverIDs.GITHUB_REPO_BRANCH_PROTECTION_FACT_RETRIEVER,
  ],
  rule: {
    conditions: {
      all: [
        {
          fact: 'branchAllowsForcePushes',
          operator: 'equal',
          value: false,
        },
        {
          fact: 'mergeRequiresReviews',
          operator: 'equal',
          value: true,
        },
        {
          fact: 'mergeRequiresStatusChecks',
          operator: 'equal',
          value: true,
        },
      ],
    },
  },
};
