import { ConfigApi, createApiRef, FetchApi } from '@backstage/core-plugin-api';

import { Tenant } from './types';
import { deserialize, serialize } from './utils/jsonserializer';

export const kaapApiRef = createApiRef<KaapApi>({
  id: 'plugin.kaap',
});

export interface KaapApi {
  getTenants(token: string): Promise<Tenant[]>;
  createTenant(tenant: Tenant, token: string): Promise<void>;
  updateTenant(tenant: Tenant, token: string): Promise<void>;
  deleteTenant(tenant: Tenant): Promise<void>;
  getClusters(token: string): Promise<any>;
}

export class KaapApiClient implements KaapApi {
  private readonly fetchApi: FetchApi;
  private readonly baseUrl: string;

  constructor(fetchApi: FetchApi, configApi: ConfigApi) {
    this.fetchApi = fetchApi;
    this.baseUrl = `${configApi.getString('kaap.apiUrl')}/v1`;
  }

  async getTenants(token: string): Promise<Tenant[]> {
    const url = `${this.baseUrl}/tenants`;
    const response = await this.fetchApi.fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const { tenants: rawTenants } = await response.json();
    return rawTenants.map((t: any) => deserialize(t));
  }

  async getClusters(token: string): Promise<any> {
    const url = `${this.baseUrl}/clusters`;
    const response = await this.fetchApi.fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.json();
  }

  async createTenant(tenant: Tenant, token: string): Promise<void> {
    const url = `${this.baseUrl}/tenants`;
    const response = await this.fetchApi.fetch(url, {
      method: 'POST',
      body: JSON.stringify(serialize(tenant)),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.json();
  }

  async updateTenant(tenant: Tenant, token: string): Promise<void> {
    const url = `${this.baseUrl}/tenants/${tenant.name}`;
    const response = await this.fetchApi.fetch(url, {
      method: 'PUT',
      body: JSON.stringify(serialize(tenant)),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.json();
  }

  async deleteTenant(_tenant: Tenant): Promise<void> {
    throw new Error('Method not implemented.');
  }
}
