export type AzureManagedClusterNodePool = {
  availabilityZones: string[];
  count: number;
  maxCount: number;
  maxPods: number;
  minCount: number;
  name: string;
  mode: string;
  nodeImageVersion: string;
  osDiskSizeGB: string;
  powerState: {
    code: string;
  };
  orchestratorVersion: string;
  provisioningState: string;
  scaleDownMode: string;
  type: string;
  tags: Record<string, string>;
  vmSize: string;
  vnetSubnetID: string;
  osType: string;
  osSKU: string;
};

export type AzureManagedCluster = {
  id: string;
  name: string;
  type: string;
  tenantId: string;
  location: string;
  subscriptionId: string;
  resourceGroup: string;
  sku: {
    name: string;
    tier: string;
  };
  properties: {
    currentKubernetesVersion: string;
    provisioningState: string;
    powerState: {
      code: string;
    };
    fqdn: string;
    networkProfile: {
      networkPlugin: string;
    };
    agentPoolProfiles: AzureManagedClusterNodePool[];
  } & Record<string, unknown>;
  tags: Record<string, string>;
  identity: Record<string, string>;
};

export enum ResourceType {
  'NAMESPACES' = 'Namespaces',
  'SERVICES' = 'Services',
  'DEPLOYMENTS' = 'Deployments',
  'INGRESSES' = 'Ingresses',
  'PODS' = 'Pods',
  'SECRETS' = 'Secrets',
  'REPLICASETS' = 'Replica Sets',
  'DAEMONSETS' = 'Daemon Sets',
}

// should NOT change these commands
export enum ClusterResourceKubectlCommands {
  NAMESPACES = 'kubectl get namespaces',
  SERVICES = 'kubectl get services --all-namespaces',
  DEPLOYMENTS = 'kubectl get deployments --all-namespaces',
  INGRESSES = 'kubectl get ingress --all-namespaces',
  PODS = 'kubectl get pods --all-namespaces',
  SECRETS = 'kubectl get secrets --all-namespaces',
  REPLICASETS = 'kubectl get replicasets --all-namespaces',
  DAEMONSETS = 'kubectl get daemonsets --all-namespaces',
}

export type AzureManagedClusterNamespaceResult = {
  id: number;
  name: string;
  status: string;
  age: string;
};

export type AzureManagedClusterServiceResult = {
  id: number;
  namespace: string;
  name: string;
  type: string;
  clusterIP: string;
  externalIP: string;
  port: string;
  age: string;
};

export type AzureManagedClusterIngressResult = {
  id: number;
  namespace: string;
  name: string;
  class: string;
  hosts: string;
  address: string;
  port: string;
  age: string;
};

export type AzureManagedClusterDeploymentResult = {
  id: number;
  namespace: string;
  name: string;
  ready: string;
  upToDate: string;
  available: string;
  age: string;
};

export type AzureManagedClusterPodResult = {
  id: number;
  namespace: string;
  name: string;
  ready: string;
  status: string;
  restarts: string;
  age: string;
};

export type AzureManagedClusterReplicaSetResult = {
  id: number;
  namespace: string;
  name: string;
  desired: string;
  current: string;
  ready: string;
  age: string;
};

export type AzureManagedClusterDaemonSetResult = {
  id: number;
  namespace: string;
  name: string;
  desired: string;
  current: string;
  ready: string;
  upToDate: string;
  available: string;
  nodeSelector: string;
  age: string;
};

export type AzureManagedClusterSecretResult = {
  id: number;
  namespace: string;
  name: string;
  type: string;
  data: string;
  age: string;
};
