/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React, { ChangeEvent, ReactNode } from 'react';

import { FormControl, MenuItem, Select } from '@material-ui/core';

import { useStyles } from '../../hooks/useStyles';

type Props = {
  statusNumber: number;
  handleSelectedStatusChange:
    | ((
        event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
        child: ReactNode,
      ) => void)
    | undefined;
};

export const IdeaStatusSelector = ({
  statusNumber,
  handleSelectedStatusChange,
}: Props) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl}>
      <Select
        className={classes.select}
        value={statusNumber}
        onChange={handleSelectedStatusChange}
      >
        <MenuItem value={0}>Any status</MenuItem>
        <MenuItem value={1}>Draft</MenuItem>
        <MenuItem value={2}>Ready for Hacking</MenuItem>
        <MenuItem value={3}>Ready for Jury</MenuItem>
        <MenuItem value={4}>Ready for Finals</MenuItem>
        <MenuItem value={5}>Done Hacking</MenuItem>
      </Select>
    </FormControl>
  );
};
