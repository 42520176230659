import { ServiceEntity } from '@internal/plugin-catalog-model';

import { Entity } from '@backstage/catalog-model';
import { IdentityApi } from '@backstage/core-plugin-api';
import { CatalogApi } from '@backstage/plugin-catalog-react';

import { arrayToObjectReducer } from '../utils';

export const updateServiceApisFormBuilder = async (
  entity: Entity,
  formFields: string[],
  _identityApi?: IdentityApi,
  _catalogApi?: CatalogApi,
) => {
  const formData = formFields
    .map((field: string) => {
      let data: { [key in string]: string | string[] | undefined };
      switch (field) {
        case 'name':
          data = { [field]: entity.metadata.name };
          break;
        case 'consumesApis':
          data = {
            [field]: (entity as ServiceEntity).spec?.consumesApis ?? [],
          };
          break;
        case 'providesApis':
          data = {
            [field]: (entity as ServiceEntity).spec?.providesApis ?? [],
          };
          break;
        default:
          data = {};
          break;
      }
      return data;
    })
    .reduce(arrayToObjectReducer, {});

  return formData;
};
