import { GroupEntity } from '@internal/plugin-catalog-model';
import React, { useCallback } from 'react';

import {
  RELATION_CHILD_OF,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { InfoCard, InfoCardVariants } from '@backstage/core-components';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  getEntityRelations,
  useEntity,
} from '@backstage/plugin-catalog-react';

import { Divider, Grid, List } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import {
  GroupProfileManagersListItem,
  GroupProfileMissionVisionPurpose,
  GroupProfileParentsListItem,
  GroupProfileTitle,
  GroupProfileCardAction,
} from '../../GroupProfile';

export const GroupProfileCard = (props: { variant?: InfoCardVariants }) => {
  const catalogApi = useApi(catalogApiRef);
  const alertApi = useApi(alertApiRef);
  const { entity: group } = useEntity<GroupEntity>();

  const refreshEntity = useCallback(async () => {
    await catalogApi.refreshEntity(stringifyEntityRef(group));
    alertApi.post({ message: 'Refresh scheduled', severity: 'info' });
  }, [catalogApi, alertApi, group]);

  const parentRelations = getEntityRelations(group, RELATION_CHILD_OF, {
    kind: 'group',
  });

  if (!group) {
    return (
      <Alert severity="error" data-testid="group-alert">
        Group not found
      </Alert>
    );
  }

  const {
    metadata: { name, managers, missions, mission, vision, purpose },
    spec: { profile },
  } = group;

  return (
    <InfoCard
      title={
        <GroupProfileTitle
          title={profile?.displayName ?? name}
          picture={profile?.picture}
          group={group}
        />
      }
      variant={props.variant}
      action={
        <GroupProfileCardAction group={group} refreshEntity={refreshEntity} />
      }
    >
      <Grid container spacing={3}>
        <Grid item md={6} xl={6}>
          <List>
            <GroupProfileManagersListItem managers={managers ?? []} />
          </List>
        </Grid>
        <Grid item md={6} xl={6}>
          <List>
            <GroupProfileParentsListItem parents={parentRelations} />
          </List>
        </Grid>
        <Grid item md={12} xl={12}>
          <Divider />
        </Grid>
        <Grid item md={12} xl={12}>
          <GroupProfileMissionVisionPurpose
            missions={missions ?? []}
            mission={mission}
            vision={vision}
            purpose={purpose}
          />
        </Grid>
      </Grid>
    </InfoCard>
  );
};
