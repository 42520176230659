import { FactRetrieverContext } from '@backstage-community/plugin-tech-insights-node';
import { stringifyEntityRef } from '@backstage/catalog-model';

import { WatchtowerReader } from '../../WatchtowerReader';
import { TypedTechInsightFact, ConventionalCommitsFact } from '../../types';
import { getEntities } from '../utils/getEntities';
import { getRepoNameFromProjectSlug } from './utils';

type ConventionalCommitsFactHandler = (
  f: FactRetrieverContext,
) => Promise<TypedTechInsightFact<ConventionalCommitsFact>[]>;

export const createConvCommitsFactHandler = (
  watchtowerReader: WatchtowerReader,
): ConventionalCommitsFactHandler => {
  return async ({
    logger,
    entityFilter,
    tokenManager,
    discovery,
  }: FactRetrieverContext): Promise<
    TypedTechInsightFact<ConventionalCommitsFact>[]
  > => {
    const entities = await getEntities({
      logger,
      entityFilter,
      tokenManager,
      discovery,
    });

    const ciFacts: TypedTechInsightFact<ConventionalCommitsFact>[] = [];

    for (const entity of entities.items) {
      const repoName = getRepoNameFromProjectSlug(entity);

      if (!repoName) {
        logger.warn(`No slug found for entity ${stringifyEntityRef(entity)}`);
        continue;
      }

      try {
        const convCommitsResponse = await watchtowerReader.getRepoAttribute<{
          uses_conventional_commits: boolean;
        }>(repoName, 'conventional-commits');
        ciFacts.push({
          entity: {
            namespace: entity.metadata.namespace!,
            kind: entity.kind,
            name: entity.metadata.name,
          },
          facts: {
            usesConventionalCommits:
              convCommitsResponse?.uses_conventional_commits || false,
          },
        });
      } catch (error: unknown) {
        logger.error(
          `${
            (error as Error)?.message
          } while fetching conventional commits usage for ${repoName}`,
        );
      }
    }

    return ciFacts;
  };
};
