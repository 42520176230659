import { EntityCheckResults } from '../hooks/useCheckResults';
import { isCheckOfTier, tierCheckMapping } from './checks';

export enum Tier {
  Bronze = 'Bronze',
  Silver = 'Silver',
  Gold = 'Gold',
  Black = 'Black',
}

export const TierColors: Record<Tier, string> = {
  [Tier.Bronze]: '#CD7F32',
  [Tier.Silver]: '#C0C0C0',
  [Tier.Gold]: '#FFD700',
  [Tier.Black]: '#313145',
};

export const calculateTierFailingCheckCounts = (
  checkResults: EntityCheckResults,
) => {
  let bronzeFailingCheckCount = tierCheckMapping[Tier.Bronze.valueOf()].length;
  let silverFailingCheckCount = tierCheckMapping[Tier.Silver.valueOf()].length;
  let goldFailingCheckCount = tierCheckMapping[Tier.Gold.valueOf()].length;

  for (const checkResult of checkResults.results) {
    if (!checkResult.result) {
      continue;
    }

    if (isCheckOfTier(checkResult.checkId, Tier.Bronze)) {
      bronzeFailingCheckCount--;
    } else if (isCheckOfTier(checkResult.checkId, Tier.Silver)) {
      silverFailingCheckCount--;
    } else if (isCheckOfTier(checkResult.checkId, Tier.Gold)) {
      goldFailingCheckCount--;
    }
  }

  return {
    bronzeFailingCheckCount,
    silverFailingCheckCount,
    goldFailingCheckCount,
  };
};

export function determineTier(checkResults: EntityCheckResults): Tier | null {
  if (checkResults.results.length === 0) {
    return null;
  }

  const {
    bronzeFailingCheckCount,
    silverFailingCheckCount,
    goldFailingCheckCount,
  } = calculateTierFailingCheckCounts(checkResults);

  if (bronzeFailingCheckCount === 0) {
    if (silverFailingCheckCount === 0) {
      if (goldFailingCheckCount === 0) {
        return Tier.Gold;
      }
      return Tier.Silver;
    }
    return Tier.Bronze;
  }
  return null;
}
