import { CatalogApi } from '@backstage/catalog-client';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { ConfigApi } from '@backstage/core-plugin-api';
import { ScmAuthApi } from '@backstage/integration-react';

import { GithubWorkflowClient } from '../api';
import { Workflow, WorkflowState } from '../types';
import { getLatestWorkflowRuns } from './getLatestWorkflowRuns';

const MAX_WORKFLOW_NAME_LENGTH = 35;
const MAX_ACTOR_LENGTH = 15;
const MAX_MESSAGE_LENGTH = 35;

const getWorkflowNameFromTitle = (
  title: string | undefined,
): string | undefined =>
  title?.split('(').slice(0, -1)?.join('(')?.trim() ?? title;

const generateWorkflowNodeId = (name: string, title: string | undefined) => {
  const workflowName = getWorkflowNameFromTitle(title);
  if (workflowName) {
    return `${workflowName.substring(
      0,
      MAX_WORKFLOW_NAME_LENGTH,
    )} (${name.replace('wk-', '')})`;
  }
  return name.replace('wk-', '');
};

const getRepoNameFromTitle = (title: string | undefined): string | undefined =>
  title?.split('(').pop()?.replace(')', '').trim() ?? title;

const formatDate = (dateString: string | undefined) => {
  if (dateString === undefined) return dateString;

  const d = new Date(dateString);

  return d.toLocaleDateString();
};

const generateNodeCommitActor = (
  actor: string | undefined,
  dataString: string | undefined,
) => {
  return ` ${actor?.substring(0, MAX_ACTOR_LENGTH)} (${formatDate(
    dataString,
  )})`;
};

const generateNodeCommitMessage = (commitMessage: string | undefined) =>
  `${commitMessage?.substring(0, MAX_MESSAGE_LENGTH)}...`;

export const getWorkflows = async (
  workflows: Array<{ workflowRef: string; dependsOn?: string[] }> | undefined,
  catalogApi: CatalogApi,
  configApi: ConfigApi,
  scmAuthApi: ScmAuthApi,
): Promise<Workflow[]> => {
  const workflowRefs = new Set<string>();

  workflows?.map(w => {
    workflowRefs.add(w.workflowRef);
    w.dependsOn?.map(dep => workflowRefs.add(dep));
  });

  const workflowEntities = await catalogApi.getEntitiesByRefs({
    entityRefs: [...workflowRefs],
  });

  const githubWorkflowClient = new GithubWorkflowClient({
    configApi,
    scmAuthApi,
  });

  const latestWorkflowRuns = await getLatestWorkflowRuns(
    workflowEntities.items,
    githubWorkflowClient,
  );

  const wks: Workflow[] = [];

  workflowEntities.items.map(wk => {
    if (wk !== undefined) {
      const latestRun = latestWorkflowRuns.find(
        run =>
          run?.head_repository?.name ===
          wk.metadata.annotations?.['github.com/repository-name'],
      );

      wks.push({
        id: generateWorkflowNodeId(wk.metadata.name, wk.metadata.title),
        commitMessage: generateNodeCommitMessage(
          latestRun?.head_commit?.message,
        ),
        name: wk.metadata.name,
        commitActor:
          generateNodeCommitActor(
            latestRun?.head_commit?.author?.name,

            latestRun?.created_at,
          ) ??
          getRepoNameFromTitle(wk.metadata.title) ??
          wk.metadata.name,
        commitBranch: `branch: ${latestRun?.head_branch ?? 'main'}`,
        runId: latestRun?.id,
        entityRef: stringifyEntityRef(wk),
        repository: wk.metadata.annotations?.['github.com/repository-name'],
        path: wk.metadata.annotations?.['github.com/workflow-file-path'],
        state:
          (latestRun?.conclusion as WorkflowState) ??
          ('skipped' as WorkflowState),
      });
    }
  });
  return wks;
};
