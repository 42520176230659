import {
  PluginEndpointDiscovery,
  TokenManager,
} from '@backstage/backend-common';
import { CatalogClient } from '@backstage/catalog-client';

export const getRepositoriesByChildGroups = async (options: {
  kind: string;
  owners: string[];
  tokenManager: TokenManager;
  discovery: PluginEndpointDiscovery;
}) => {
  const { kind, owners, tokenManager, discovery } = options;

  const { token } = await tokenManager.getToken();

  const catalogClient = new CatalogClient({
    discoveryApi: discovery,
  });

  return catalogClient.getEntities(
    {
      filter: [
        {
          kind: kind,
          'relations.ownedBy': owners,
        },
      ],
      fields: [
        'kind',
        'metadata.name',
        'metadata.namespace',
        'metadata.tags',
        'spec.type',
        'relations',
      ],
    },
    { token },
  );
};
