import { JSON_RULE_ENGINE_CHECK_TYPE } from '@backstage-community/plugin-tech-insights-backend-module-jsonfc';

import { TechInsightsCheckID } from '../../checkIDs';
import { TechInsightsRetrieverIDs } from '../../retrieverIDs';

export const serviceBasicChecks = [
  {
    id: TechInsightsCheckID.SERVICE_SLACK_CHANNEL_CHECK,
    type: JSON_RULE_ENGINE_CHECK_TYPE,
    name: 'Has Slack channel',
    description:
      'Service has a dedicated communication channel for discussions and incident handling.',
    factIds: [TechInsightsRetrieverIDs.SERVICE_BASIC_FACT_RETRIEVER],
    rule: {
      conditions: {
        all: [
          {
            fact: 'hasSlackChannel',
            operator: 'equal',
            value: true,
          },
        ],
      },
    },
  },
  {
    id: TechInsightsCheckID.SERVICE_PAGERDUTY_INTEGRATION_CHECK,
    type: JSON_RULE_ENGINE_CHECK_TYPE,
    name: 'Has a PagerDuty service',
    description:
      'The service has PagerDuty integration set up for better incident management, enabling efficient alerting and response to critical issues',
    factIds: [TechInsightsRetrieverIDs.SERVICE_BASIC_FACT_RETRIEVER],
    rule: {
      conditions: {
        all: [
          {
            fact: 'hasPagerDutyIntegration',
            operator: 'equal',
            value: true,
          },
        ],
      },
    },
  },
  {
    id: TechInsightsCheckID.SERVICE_SONARQUBE_INTEGRATION_CHECK,
    type: JSON_RULE_ENGINE_CHECK_TYPE,
    name: 'Has a SonarQube project',
    description:
      'Service has a configured SonarQube project for analyzing code quality and technical debt.',
    factIds: [TechInsightsRetrieverIDs.SERVICE_BASIC_FACT_RETRIEVER],
    rule: {
      conditions: {
        all: [
          {
            fact: 'hasSonarQubeProject',
            operator: 'equal',
            value: true,
          },
        ],
      },
    },
  },
  {
    id: TechInsightsCheckID.SERVICE_MONITORING_DASHBOARD_CHECK,
    type: JSON_RULE_ENGINE_CHECK_TYPE,
    name: 'Has monitoring dashboard',
    description:
      'Service has link to monitoring dashboard displaying key metrics and alerts for observability.',
    factIds: [TechInsightsRetrieverIDs.SERVICE_BASIC_FACT_RETRIEVER],
    rule: {
      conditions: {
        all: [
          {
            fact: 'hasMonitoringDashboard',
            operator: 'equal',
            value: true,
          },
        ],
      },
    },
  },
  {
    id: TechInsightsCheckID.SERVICE_OPERATIONAL_STATUS_CHECK,
    type: JSON_RULE_ENGINE_CHECK_TYPE,
    name: 'Has lifecycle state',
    description:
      'Service has a defined lifecycle state (e.g., experimental, production, deprecated) indicating its current stage in the service lifecycle.',
    factIds: [TechInsightsRetrieverIDs.SERVICE_BASIC_FACT_RETRIEVER],
    rule: {
      conditions: {
        all: [
          {
            fact: 'hasOperationalStatusDefined',
            operator: 'equal',
            value: true,
          },
        ],
      },
    },
  },
];
