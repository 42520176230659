import React from 'react';
import useAsync from 'react-use/lib/useAsync';

import { UserEntity } from '@backstage/catalog-model';
import { Avatar } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

export const ManagerAvatar = (props: { email: string | undefined }) => {
  const { email } = props;

  const catalogApi = useApi(catalogApiRef);

  const { value: manager } = useAsync(async () => {
    const user = await catalogApi.getEntities({
      filter: {
        kind: 'User',
        'metadata.name': email?.toLowerCase().replace('@', '_') ?? '',
      },
    });

    return user.items[0] as UserEntity | undefined;
  }, [catalogApi, props.email]);

  if (!email || !manager) {
    return null;
  }

  const displayName =
    manager.spec?.profile?.displayName ?? manager.metadata.name;

  return (
    <Avatar
      data-testid="manager-avatar"
      displayName={displayName}
      picture={manager.spec.profile?.picture}
      customStyles={{
        position: 'absolute',
        top: '.5em',
        width: '35px',
        height: '35px',
        fontSize: '15px',
      }}
    />
  );
};
