// https://github.com/backstage/backstage/issues/15643
import React from 'react';

import {
  ANNOTATION_EDIT_URL,
  CompoundEntityRef,
  DEFAULT_NAMESPACE,
  parseEntityRef,
  Entity,
} from '@backstage/catalog-model';
import {
  HeaderIconLinkRow,
  IconLinkVerticalProps,
  InfoCardVariants,
  Link,
} from '@backstage/core-components';
import { useApi, useRouteRef } from '@backstage/core-plugin-api';
import {
  ScmIntegrationIcon,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import {
  getEntitySourceLocation,
  useEntity,
} from '@backstage/plugin-catalog-react';
import { techdocsPlugin } from '@backstage/plugin-techdocs';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Dashboard from '@material-ui/icons/Dashboard';
import DocsIcon from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';

import { AboutContent } from './ServiceEntity/AboutContent';

const TECHDOCS_ANNOTATION = 'backstage.io/techdocs-ref';
const TECHDOCS_EXTERNAL_ANNOTATION = 'backstage.io/techdocs-entity';

const useStyles = makeStyles({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',
  },
  fullHeightCard: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  gridItemCardContent: {
    flex: 1,
  },
  fullHeightCardContent: {
    flex: 1,
  },
});

export interface AboutCardProps {
  variant?: InfoCardVariants;
}

function entitySourceLocationFromProjectSlug(slug: string) {
  // Slug is in the format org/repo
  return {
    locationTargetUrl: `https://github.com/${slug}`,
    integrationType: 'github',
  };
}
export function ServiceAboutCard(props: AboutCardProps) {
  const { variant } = props;
  const classes = useStyles();
  const { entity } = useEntity();
  const scmIntegrationsApi = useApi(scmIntegrationsApiRef);
  const viewTechdocLink = useRouteRef(techdocsPlugin.routes.docRoot);
  const serviceDashboard =
    entity.metadata.annotations?.['monitoring/dashboard'];
  const locationFromProjectSlug =
    entity.metadata.annotations?.['github.com/project-slug'];

  const entitySourceLocation = locationFromProjectSlug
    ? entitySourceLocationFromProjectSlug(locationFromProjectSlug)
    : getEntitySourceLocation(entity, scmIntegrationsApi);
  const entityMetadataEditUrl =
    entity.metadata.annotations?.[ANNOTATION_EDIT_URL];

  const techdocsRef = getTechDocsEntityRef(entity);
  const viewSource: IconLinkVerticalProps = {
    label: 'View Source',
    disabled: !entitySourceLocation,
    icon: <ScmIntegrationIcon type={entitySourceLocation?.integrationType} />,
    href: entitySourceLocation?.locationTargetUrl,
  };
  const viewTechDocs: IconLinkVerticalProps = {
    label: 'View Techdocs',
    disabled:
      !(
        entity.metadata.annotations?.[TECHDOCS_ANNOTATION] ||
        entity.metadata.annotations?.[TECHDOCS_EXTERNAL_ANNOTATION]
      ) || !viewTechdocLink,
    icon: <DocsIcon />,
    href:
      viewTechdocLink &&
      (techdocsRef
        ? viewTechdocLink({
            namespace: techdocsRef.namespace || DEFAULT_NAMESPACE,
            kind: techdocsRef.kind,
            name: techdocsRef.name,
          })
        : viewTechdocLink({
            namespace: entity.metadata.namespace || DEFAULT_NAMESPACE,
            kind: entity.kind,
            name: entity.metadata.name,
          })),
  };
  const viewDashboard: IconLinkVerticalProps = {
    label: 'View Dashboard',
    disabled: !serviceDashboard,
    icon: <Dashboard />,
    href: serviceDashboard,
  };

  const subHeaderLinks = [viewSource, viewTechDocs, viewDashboard];

  let cardClass = '';
  if (variant === 'gridItem') {
    cardClass = classes.gridItemCard;
  } else if (variant === 'fullHeight') {
    cardClass = classes.fullHeightCard;
  }

  let cardContentClass = '';
  if (variant === 'gridItem') {
    cardContentClass = classes.gridItemCardContent;
  } else if (variant === 'fullHeight') {
    cardContentClass = classes.fullHeightCardContent;
  }

  return (
    <Card className={cardClass}>
      <CardHeader
        title={entity.metadata.title ?? 'Title'}
        action={
          <>
            <IconButton
              component={Link}
              aria-label="Edit"
              disabled={!entityMetadataEditUrl}
              title="Edit Entity"
              to={entityMetadataEditUrl ?? '#'}
            >
              <EditIcon />
            </IconButton>
          </>
        }
        subheader={<HeaderIconLinkRow links={subHeaderLinks} />}
      />
      <Divider />
      <CardContent className={cardContentClass}>
        <AboutContent entity={entity} />
      </CardContent>
    </Card>
  );
}

function getTechDocsEntityRef(entity: Entity): CompoundEntityRef | undefined {
  let techdocsRef: CompoundEntityRef | undefined;
  if (entity.metadata.annotations?.[TECHDOCS_EXTERNAL_ANNOTATION]) {
    try {
      techdocsRef = parseEntityRef(
        entity.metadata.annotations?.[TECHDOCS_EXTERNAL_ANNOTATION],
      );
    } catch {
      techdocsRef = undefined;
    }
  }
  return techdocsRef;
}
