import {
  hasGroupSelfManagedWarning,
  EntityGroupSelfManagedWarning,
  EntityRepositoryProposedOwnerWarning,
  hasRepositoryProposedOwner,
} from '@internal/plugin-catalog-page-addons-react';
import React from 'react';

import { useApi, configApiRef } from '@backstage/core-plugin-api';
import {
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  EntitySwitch,
  hasCatalogProcessingErrors,
  isOrphan,
  hasRelationWarnings,
  EntityRelationWarning,
} from '@backstage/plugin-catalog';

import { Grid } from '@material-ui/core';

export const EntityWarningContent = () => {
  const config = useApi(configApiRef);

  const showCatalogProcessingErrors =
    config.getOptionalBoolean('app.showCatalogProcessingErrors') ?? true;

  return (
    <>
      <EntitySwitch>
        <EntitySwitch.Case if={isOrphan}>
          <Grid item xs={12}>
            <EntityOrphanWarning />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
      {showCatalogProcessingErrors && (
        <EntitySwitch>
          <EntitySwitch.Case if={hasCatalogProcessingErrors}>
            <Grid item xs={12}>
              <EntityProcessingErrorsPanel />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>
      )}
      <EntitySwitch>
        <EntitySwitch.Case if={hasRelationWarnings}>
          <Grid item xs={12}>
            <EntityRelationWarning />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>

      <EntitySwitch>
        <EntitySwitch.Case if={hasGroupSelfManagedWarning}>
          <Grid item xs={12}>
            <EntityGroupSelfManagedWarning />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>

      <EntitySwitch>
        <EntitySwitch.Case if={hasRepositoryProposedOwner}>
          <Grid item xs={12}>
            <EntityRepositoryProposedOwnerWarning />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
    </>
  );
};
