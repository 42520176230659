import { groupRepositoryEntityHandler } from '../../handlers/entities';
import { TechInsightsRetrieverIDs } from '../../retrieverIDs';
import { TypedFactRetriever, GroupRepositoryEntityFact } from '../../types';

export const groupRepositoryEntityFactRetriever: TypedFactRetriever<GroupRepositoryEntityFact> =
  {
    id: TechInsightsRetrieverIDs.GROUP_REPOSITORY_ENTITY_FACT_RETRIEVER,
    version: '0.0.1',
    title: 'GROUP Repository Entity Fact Retriever',
    description: 'Generates facts regarding the repository entity of a group',
    entityFilter: [
      {
        kind: ['group'],
        'spec.type': ['business-unit', 'product-unit', 'supervisory-org'],
      },
    ],
    schema: {
      confirmedRepositoryCount: {
        type: 'integer',
        description:
          'The count of the repositories with the confirmed ownership',
      },
      proposedRepositoryCount: {
        type: 'integer',
        description:
          'The count of the repositories with the proposed ownership',
      },
      ownedRepositoryEntityRefs: {
        type: 'set',
        description:
          'The list of repository entityRefs recursively owned by the group',
      },
    },
    handler: groupRepositoryEntityHandler,
  };
