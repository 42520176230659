import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { IdentityApi } from '@backstage/core-plugin-api';
import { CatalogApi } from '@backstage/plugin-catalog-react';

import { arrayToObjectReducer } from '../utils';

const isManager = async (userEntityRef: string, catalogApi?: CatalogApi) => {
  if (!catalogApi) {
    return false;
  }

  const userEntity = await catalogApi.getEntityByRef(userEntityRef);

  return (
    userEntity?.metadata?.annotations?.['graph.microsoft.com/is-manager'] ===
    'true'
  );
};

export const onboardByManagerFormBuilder = async (
  entity: Entity,
  formFields: string[],
  identityApi?: IdentityApi,
  catalogApi?: CatalogApi,
) => {
  const currentUser = await identityApi?.getBackstageIdentity();
  const currentUserIsManager = currentUser
    ? await isManager(currentUser.userEntityRef, catalogApi)
    : false;

  const formData = formFields
    .map((field: string) => {
      let data: { [key in string]: string | string[] | undefined };

      switch (field) {
        case 'parent':
          data = { [field]: stringifyEntityRef(entity) };
          break;
        case 'managers':
          if (currentUser?.userEntityRef && currentUserIsManager) {
            data = { [field]: [currentUser?.userEntityRef] };
          } else {
            data = { [field]: [] };
          }
          break;
        default:
          data = {};
          break;
      }

      return data;
    })
    .reduce(arrayToObjectReducer, {});

  return formData;
};
