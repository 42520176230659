import { PieChart, useDrawingArea } from '@mui/x-charts';
import React from 'react';

import { Box, styled } from '@material-ui/core';

interface CircularValueDisplayProps {
  value: number;
  size: 'Regular' | 'Small';
  color: string;
}

const getStyledText = (fillColor: string, fontSize: number) =>
  styled('text')({
    fill: fillColor,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: fontSize,
    fontWeight: 'bold',
  });

interface PieCenterLabelProps {
  value: string;
  color: string;
  fontSize: number;
}

const ShadowEffect = () => {
  return (
    <defs>
      <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
        <feGaussianBlur in="SourceAlpha" stdDeviation="2" result="blur" />
        <feOffset in="blur" dx="1" dy="1" result="offsetBlur" />
        <feMerge>
          <feMergeNode in="offsetBlur" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
  );
};

function PieCenterLabel({ value, color, fontSize }: PieCenterLabelProps) {
  const { width, height, left, top } = useDrawingArea();
  const TextValue = getStyledText(color, fontSize);
  return (
    <>
      <ShadowEffect />
      <TextValue
        x={left + width / 2}
        y={top + height / 2}
        filter="url(#shadow)"
      >
        {value}
      </TextValue>
    </>
  );
}

export const CircularValueDisplay = ({
  value,
  size,
  color,
}: CircularValueDisplayProps) => {
  const [height, fontSize] = size === 'Regular' ? ['300px', 70] : ['130px', 40];

  return (
    <Box sx={{ width: '100%', height }}>
      <PieChart
        margin={{ right: 0 }}
        series={[
          {
            data: [
              { value, color },
              { value: 100 - value, color: '#E0E0E0' },
            ],
            innerRadius: '75%',
          },
        ]}
      >
        <PieCenterLabel value={`${value}`} color={color} fontSize={fontSize} />
      </PieChart>
    </Box>
  );
};
