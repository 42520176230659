import React, { useState } from 'react';

import {
  discoveryApiRef,
  IdentityApi,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';

import { Box, Button, TextField, Typography } from '@material-ui/core';

type ResponseState = {
  state: string;
  error?: Error;
};

async function fetchData(
  endpoint: string,
  payload: string,
  identityApi: IdentityApi,
) {
  const { token } = await identityApi.getCredentials();
  const response = await fetch(endpoint, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ payload }),
  });
  if (!response.ok) {
    throw new Error('Failed to fetch data');
  }
  return response.text();
}

export function SecretsSharing() {
  const identityApi = useApi(identityApiRef);
  const discoveryApi = useApi(discoveryApiRef);
  const [inputText, setInputText] = useState('');

  const [outputState, setOutputState] = useState<ResponseState>({ state: '' });

  const handleButtonClick = async (handleType: string) => {
    const endpointBase = await discoveryApi.getBaseUrl('secrets-sharing');
    const endpoint = `${endpointBase}/${handleType}`;
    try {
      const apiResponse = await fetchData(endpoint, inputText, identityApi);
      setOutputState({ state: apiResponse });
    } catch (e: unknown) {
      setOutputState({ state: '', error: e as Error });
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ rowGap: '10px', padding: '80px' }}
    >
      <Box>
        <h1>Secure Secrets Sharing</h1>
        <p>
          The form below should be used to wrap and unwrap sensitive information
          (eg: credentials), before sending and after receiving it over
          communication channels (eg: Slack, Emails, MS Teams, ...).
        </p>
        <p>
          <strong>NOTE</strong>: wrapped data can be unwrapped only once and
          will expire after 7 days.
        </p>
        <p>
          Please, enter sensitive information into the form below and press
          "Wrap" or "Unwrap":
        </p>
      </Box>
      <Box display="flex">
        <TextField
          type="text"
          fullWidth
          id="secretInput"
          value={inputText}
          onChange={e => setInputText(e.target.value)}
          multiline
          size="medium"
          placeholder="Input"
        />
      </Box>

      <Box display="flex" sx={{ p: -0.5, ml: 0, mt: 2 }}>
        <Button
          id="wrapButton"
          variant="outlined"
          onClick={() => {
            handleButtonClick('wrap');
          }}
        >
          Wrap
        </Button>
        <Button
          id="unwrapButton"
          variant="outlined"
          onClick={() => {
            handleButtonClick('unwrap');
          }}
          style={{
            backgroundColor: 'red',
            color: 'white',
            marginLeft: '10px',
          }}
        >
          Unwrap
        </Button>
      </Box>
      <Box display="flex" sx={{ p: -0.5, ml: 0, mt: 5 }}>
        {outputState?.error && <div>Error: {outputState.error?.message}</div>}
        {outputState && (
          <Box
            sx={{
              width: 100,
              height: 100,
              flexBasis: '100%',
              borderRadius: 1,
            }}
          >
            <Typography variant="h4">Result:</Typography>
            <TextField
              value={outputState.state}
              placeholder="Output"
              fullWidth
              multiline
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default SecretsSharing;
