import costPerResourceGroup from '../payloads/costPerResourceGroup.json';
import costPerService from '../payloads/costPerService.json';
import { AzureRequestType, DateRange } from '../types';

export const getPayloadForRequest = (
  requestType: AzureRequestType,
  dateRange: DateRange,
) => {
  let payload;
  switch (requestType) {
    case 'CostPerResourceGroup':
      payload = {
        ...costPerResourceGroup,
        timePeriod: dateRange,
      };
      break;
    case 'CostPerService':
      payload = {
        ...costPerService,
        timePeriod: dateRange,
      };
      break;
    default:
      payload = {};
  }
  return payload;
};
