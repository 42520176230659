import React from 'react';

import { getCompoundEntityRef } from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
} from '@material-ui/core';

import { useGetScorecardData } from '../../hooks';
import { Scorecard } from '../../types';
import { ScoreCharts } from '../ScoreCharts';
import { ScoreCheckTable } from '../ScoreChecksTable';

const useStyles = makeStyles({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',
  },
  gridItemCardContent: {
    flex: 1,
  },
});

export const ServiceScorecard = () => {
  const { entity } = useEntity();
  const { scorecard, loading, error } = useGetScorecardData(
    getCompoundEntityRef(entity),
  );

  const classes = useStyles();
  return (
    <Card className={classes.gridItemCard}>
      <CardHeader title="Scorecard" />
      <CardContent className={classes.gridItemCardContent}>
        {(!loading || !!error) && (
          <Grid container>
            <Grid item container md={6}>
              <ScoreCharts data={scorecard as Scorecard} />
            </Grid>
            <Grid item container md={6}>
              <ScoreCheckTable scorecard={scorecard as Scorecard} />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};
