import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { APIAuthClient, authApiRef } from './api';
import { rootRouteRef } from './routes';

export const ApiAuthPlugin = createPlugin({
  id: 'api-auth',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: authApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ discoveryApi, fetchApi, identityApi }) =>
        new APIAuthClient({ fetchApi, discoveryApi, identityApi }),
    }),
  ],
});

export const ApiAuthPage = ApiAuthPlugin.provide(
  createRoutableExtension({
    name: 'ApiAuthPage',
    component: () =>
      import('./components/APIKeysSettingsPage').then(
        m => m.APIKeysSettingsPage,
      ),
    mountPoint: rootRouteRef,
  }),
);
