import { GroupEntity } from '@internal/plugin-catalog-model';
import React from 'react';
import useAsync from 'react-use/lib/useAsync';

import {
  DEFAULT_NAMESPACE,
  UserEntity,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { Avatar } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

import { Box } from '@material-ui/core';

export const MemberAvatarList = (props: { group: GroupEntity }) => {
  const {
    metadata: { name: groupName, namespace: grpNamespace },
  } = props.group;

  const catalogApi = useApi(catalogApiRef);

  const groupNamespace = grpNamespace || DEFAULT_NAMESPACE;

  const { value: members } = useAsync(async () => {
    const membersList = await catalogApi.getEntities({
      filter: {
        kind: 'User',
        'relations.memberof': [
          stringifyEntityRef({
            kind: 'group',
            namespace: groupNamespace.toLocaleLowerCase('en-US'),
            name: groupName.toLocaleLowerCase('en-US'),
          }),
        ],
      },
    });
    return membersList.items as UserEntity[];
  }, [catalogApi, props.group]);
  if (!members || members.length === 0) {
    return <></>;
  }

  return (
    <Box display="flex" m={1} data-testid="member-avatars">
      {members.map((member: UserEntity, index: number) => {
        return (
          <Avatar
            key={index}
            displayName={
              member.spec.profile?.displayName ?? member.metadata.name
            }
            picture={member.spec.profile?.picture}
            customStyles={{
              marginRight: '1em',
              width: '35px',
              height: '35px',
              fontSize: '15px',
            }}
          />
        );
      })}
    </Box>
  );
};
