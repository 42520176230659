import { ClusterResourceKubectlCommands, ResourceType } from '../types';

export const getClusterResourceKubectlCommand = (
  resourceType: ResourceType,
): string | undefined => {
  const kubectlCommands: Partial<Record<ResourceType, string>> = {};

  const getKubectlCommandByKey = (kubectlCommandKey: string | undefined) =>
    kubectlCommandKey
      ? ClusterResourceKubectlCommands[
          kubectlCommandKey as keyof typeof ClusterResourceKubectlCommands
        ]
      : undefined;

  Object.keys(ResourceType).forEach(key => {
    kubectlCommands[key as ResourceType] = getKubectlCommandByKey(
      key ?? undefined,
    );
  });

  return kubectlCommands[resourceType];
};
