import { JSON_RULE_ENGINE_CHECK_TYPE } from '@backstage-community/plugin-tech-insights-backend-module-jsonfc';

import { TechInsightsCheckID } from '../../checkIDs';
import { TechInsightsRetrieverIDs } from '../../retrieverIDs';

export const CIMedianPRTimeToMergeCheck = {
  id: TechInsightsCheckID.REPO_CI_MEDIAN_PR_TIME_TO_MERGE_CHECK,
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: 'Median time to merge PRs <= 1 day',
  description:
    'The median time(p50) between opening and merging a pull request is equal to or under 1 day over the last 7 days.',
  factIds: [
    TechInsightsRetrieverIDs.GITHUB_REPO_CI_PR_MEDIAN_TIME_TO_MERGE_FACT_RETRIEVER,
  ],
  rule: {
    conditions: {
      all: [
        {
          fact: 'medianPRTimeToMergeHours',
          operator: 'lessThanInclusive',
          value: 24,
        },
      ],
    },
  },
};
