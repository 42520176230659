import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import { createCardExtension } from '@backstage/plugin-home';

import { rootRouteRef } from './routes';

export const githubTeamActionsPlugin = createPlugin({
  id: 'github-team-actions',
  routes: {
    root: rootRouteRef,
  },
});

export const GithubTeamActionsPage = githubTeamActionsPlugin.provide(
  createRoutableExtension({
    name: 'GithubTeamActionsPage',
    component: () => import('./components/Router').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);

export const HomePagePipelineStatus: any = githubTeamActionsPlugin.provide(
  createCardExtension({
    name: 'HomePagePipelineStatus',
    title: 'Pipeline Status',
    components: () => import('./components/home/PipelineStatus'),
  }),
);
