import { TechInsightsCheckID } from '@internal/plugin-techinsights-module-entities';
import React from 'react';

import {
  StatusError,
  StatusOK,
  Table,
  TableColumn,
} from '@backstage/core-components';

import { Typography } from '@material-ui/core';

import { CheckResult } from './types';

type OnboardingTableProps = {
  data: CheckResult[];
  isLoading?: boolean;
};

const StatusNA = () => (
  <Typography variant="subtitle2" color="textSecondary">
    N/A
  </Typography>
);

export const OnboardingTable = ({ data, isLoading }: OnboardingTableProps) => {
  const columns: TableColumn<{
    entity: string;
    type: string;
    manager: string;
    slack: string;
    confluence: string;
    bugReport: string;
    componentOrAPI: string;
  }>[] = [
    { title: 'Entity', field: 'entity', highlight: true },
    { title: 'Type', field: 'type' },
    {
      title: 'Manager',
      render: row => (row.manager === 'true' ? <StatusOK /> : <StatusError />),
      customSort: (rowA, rowB) => rowA.manager.localeCompare(rowB.manager),
    },
    {
      title: 'Slack Channel',
      render: row => (row.slack === 'true' ? <StatusOK /> : <StatusError />),
      customSort: (rowA, rowB) => rowA.slack.localeCompare(rowB.slack),
    },
    {
      title: 'Confluence',
      render: row =>
        row.confluence === 'true' ? <StatusOK /> : <StatusError />,
      customSort: (rowA, rowB) =>
        rowA.confluence.localeCompare(rowB.confluence),
    },
    {
      title: 'Bug Report',
      render: row =>
        row.bugReport === 'true' ? <StatusOK /> : <StatusError />,
      customSort: (rowA, rowB) => rowA.bugReport.localeCompare(rowB.bugReport),
    },
    {
      title: 'Component/API',
      render: row => {
        if (row.componentOrAPI === 'n/a') {
          return <StatusNA />;
        }

        return row.componentOrAPI === 'true' ? <StatusOK /> : <StatusError />;
      },
      customSort: (rowA, rowB) =>
        rowA.componentOrAPI.localeCompare(rowB.componentOrAPI),
    },
  ];

  const findResultByCheckID = (
    results: {
      checkId: string;
      result: boolean;
    }[],
    checkId: TechInsightsCheckID,
    isTeam: boolean = true,
  ): string => {
    if (!isTeam) {
      return 'n/a';
    }

    const result = results.find(r => r.checkId === checkId);
    return result ? `${result.result}` : `${false}`;
  };

  const records = data.map(d => {
    const { entity, results } = d;

    return {
      entity: entity.displayName,
      type: entity.type,

      manager: findResultByCheckID(results, TechInsightsCheckID.MANAGER_CHECK),
      slack: findResultByCheckID(
        results,
        TechInsightsCheckID.SLACK_CHANNEL_CHECK,
      ),
      confluence: findResultByCheckID(
        results,
        TechInsightsCheckID.CONFLUENCE_CHECK,
      ),
      bugReport: findResultByCheckID(
        results,
        TechInsightsCheckID.BUG_REPORT_CHECK,
      ),
      componentOrAPI: findResultByCheckID(
        results,
        TechInsightsCheckID.COMPONENT_OR_API_CHECK,
        entity.isTeam,
      ),
    };
  });

  return (
    <Table
      options={{ search: true, paging: true, pageSize: 10 }}
      columns={columns}
      data={records}
      isLoading={isLoading}
      filters={[
        {
          column: 'Type',
          type: 'multiple-select',
        },
      ]}
    />
  );
};
