import {
  FactRetrieverContext,
  TechInsightFact,
} from '@backstage-community/plugin-tech-insights-node';
import { Entity } from '@backstage/catalog-model';

import { getEntities } from './utils/getEntities';
import { isBugReportSet } from './utils/onboardingDetails';

export const teamBugReportHandler = async ({
  discovery,
  entityFilter,
  tokenManager,
}: FactRetrieverContext): Promise<TechInsightFact[]> => {
  const entities = await getEntities({ entityFilter, tokenManager, discovery });

  return entities.items.map((entity: Entity) => {
    const hasBugReport = isBugReportSet(entity);

    return {
      entity: {
        namespace: entity.metadata.namespace!,
        kind: entity.kind,
        name: entity.metadata.name,
      },
      facts: {
        hasBugReport,
      },
    };
  });
};
