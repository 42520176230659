import { JsonObject } from '@backstage/types';

import { CheckId } from '../../tier';

// a map of scorecard check IDs to software template names and form data
interface SoftwareTemplateExtraFromData {
  template: string; // name of the software template
  formData: Record<
    string,
    string | number | boolean | JsonObject | string[] | undefined
  >; // form data to be used when loading the software template
}

export const scorecardTemplateMapperGenerator = () => {
  const scorecardTemplateMapper = new Map<
    CheckId,
    SoftwareTemplateExtraFromData
  >();

  const UPDATE_FRONTSTAGE_TEMPLATE = 'update-frontstage-service';
  const UPDATE_REPOSITORY_README_TEMPLATE = 'service-readme';
  const UPDATE_REPOSITORY_CONVENTIONAL_COMMIT =
    'service-repository-conventional-commit';

  const UPDATE_REPOSITORY_TECH_DOCS = 'service-repository-techdocs';

  scorecardTemplateMapper.set(CheckId.GroupOwnerCheck, {
    template: UPDATE_FRONTSTAGE_TEMPLATE,
    formData: {
      serviceAttribute: 'owner', // https://github.com/tomtom-internal/sp-sde-portal/blob/main/templates/service/update-service/template.yaml
    },
  });

  scorecardTemplateMapper.set(CheckId.TitleCheck, {
    template: UPDATE_FRONTSTAGE_TEMPLATE,
    formData: {
      serviceAttribute: 'title', // https://github.com/tomtom-internal/sp-sde-portal/blob/main/templates/service/update-service/template.yaml
    },
  });

  scorecardTemplateMapper.set(CheckId.DescriptionCheck, {
    template: UPDATE_FRONTSTAGE_TEMPLATE,
    formData: {
      serviceAttribute: 'description', // https://github.com/tomtom-internal/sp-sde-portal/blob/main/templates/service/update-service/template.yaml
    },
  });

  scorecardTemplateMapper.set(CheckId.ServiceSonarQubeIntegrationCheck, {
    template: UPDATE_FRONTSTAGE_TEMPLATE,
    formData: {
      serviceAttribute: 'annotationSonarqubeProjectKey', // https://github.com/tomtom-internal/sp-sde-portal/blob/main/templates/service/update-service/template.yaml
    },
  });

  scorecardTemplateMapper.set(CheckId.ServicePagerDutyIntegrationCheck, {
    template: UPDATE_FRONTSTAGE_TEMPLATE,
    formData: {
      serviceAttribute: 'annotationPagerDutyIntegrationKey', // https://github.com/tomtom-internal/sp-sde-portal/blob/main/templates/service/update-service/template.yaml
    },
  });

  scorecardTemplateMapper.set(CheckId.ServiceMonitoringDashboardCheck, {
    template: UPDATE_FRONTSTAGE_TEMPLATE,
    formData: {
      serviceAttribute: 'annotationMonitoringDashboardUrl', // https://github.com/tomtom-internal/sp-sde-portal/blob/main/templates/service/update-service/template.yaml
    },
  });

  scorecardTemplateMapper.set(CheckId.ServiceSlackChannelCheck, {
    template: UPDATE_FRONTSTAGE_TEMPLATE,
    formData: {
      serviceAttribute: 'annotationSlackChannel', // https://github.com/tomtom-internal/sp-sde-portal/blob/main/templates/service/update-service/template.yaml
    },
  });

  scorecardTemplateMapper.set(CheckId.ServiceOperationalStatusCheck, {
    template: UPDATE_FRONTSTAGE_TEMPLATE,
    formData: {
      serviceAttribute: 'lifeCycle', // https://github.com/tomtom-internal/sp-sde-portal/blob/main/templates/service/update-service/template.yaml
    },
  });

  scorecardTemplateMapper.set(CheckId.ServiceOperationalTierCheck, {
    template: UPDATE_FRONTSTAGE_TEMPLATE,
    formData: {
      serviceAttribute: 'operationalTier', // https://github.com/tomtom-internal/sp-sde-portal/blob/main/templates/service/update-service/template.yaml
    },
  });

  scorecardTemplateMapper.set(CheckId.RepoHasReadmeCheck, {
    template: UPDATE_REPOSITORY_README_TEMPLATE,
    formData: {},
  });

  scorecardTemplateMapper.set(CheckId.RepoUsesConventionalCommitsCheck, {
    template: UPDATE_REPOSITORY_CONVENTIONAL_COMMIT,
    formData: {},
  });

  scorecardTemplateMapper.set(CheckId.TechDocsCheck, {
    template: UPDATE_REPOSITORY_TECH_DOCS,
    formData: {},
  });

  return scorecardTemplateMapper;
};
