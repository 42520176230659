import {
  ScorecardTable,
  TeamScorecardAboutCard,
} from '@internal/backstage-plugin-scorecard-common-react';
import Grid from '@mui/material/Grid';
import React from 'react';

import { getCompoundEntityRef } from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';

export const TeamScoreCard = () => {
  const { entity } = useEntity();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <TeamScorecardAboutCard entityRef={getCompoundEntityRef(entity)} />
      </Grid>
      <Grid item xs={12}>
        <ScorecardTable entityRef={getCompoundEntityRef(entity)} />
      </Grid>
    </Grid>
  );
};
