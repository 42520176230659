import { useEffect, useState } from 'react';

export type UseFeedbackBannerState = [
  string | null,
  (state: string | null) => void,
];

export const useFeedbackBanner = (): UseFeedbackBannerState => {
  const [isFeedbackBannerClosed, setFeedbackBannerClosed] = useState<
    string | null
  >(localStorage.getItem('homepage/feedbackBannerClosed'));

  useEffect(() => {
    const feedbackBannerClosed = localStorage.getItem(
      'homepage/feedbackBannerClosed',
    );
    if (feedbackBannerClosed !== null) {
      setFeedbackBannerClosed(JSON.parse(feedbackBannerClosed));
    }
  }, [isFeedbackBannerClosed]);

  return [isFeedbackBannerClosed, setFeedbackBannerClosed];
};
