import { useMemo } from 'react';

import { RepoNamePickerProps } from '../schema';

export const useUiSchemas = (props: RepoNamePickerProps) => {
  const { uiSchema } = props;
  const allowedHosts = useMemo(
    () => uiSchema?.['ui:options']?.allowedHosts ?? [],
    [uiSchema],
  );
  const allowedOrganizations = useMemo(
    () => uiSchema?.['ui:options']?.allowedOrganizations ?? [],
    [uiSchema],
  );
  const allowedOwners = useMemo(
    () => uiSchema?.['ui:options']?.allowedOwners ?? [],
    [uiSchema],
  );
  const allowedProjects = useMemo(
    () => uiSchema?.['ui:options']?.allowedProjects ?? [],
    [uiSchema],
  );
  return { allowedHosts, allowedOrganizations, allowedOwners, allowedProjects };
};
