import React from 'react';

import { Link } from '@backstage/core-components';

import { Box, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export const NonParticipantAlert = () => {
  return (
    <Box marginLeft="5px" marginRight="5px" marginBottom="3px">
      <Alert severity="warning">
        <Typography>
          You must <Link to="/tomtom-lab/register-participant">register</Link>{' '}
          first before you can join this idea.
        </Typography>
      </Alert>
    </Box>
  );
};
