import { colors, fonts } from '@internal/plugin-tomtom-theme-react';

import { Theme, makeStyles } from '@material-ui/core';

export const useLogoStyles = makeStyles(theme => ({
  svg: {
    width: 300,
    height: 57.6,
    margin: theme.spacing(6.55, 'auto'),
  },
}));

export const useHomePageStyles = makeStyles<Theme>(
  theme => ({
    content: {
      paddingTop: theme.spacing(0),
    },
    companyLogo: {
      display: 'flex',
    },
    infoCardsContainer: {
      paddingTop: theme.spacing(5),
    },
    prInfo: {
      '& [class*="makeStyles-container"]': {
        minHeight: '2.85rem',
      },
    },
    prOpenMaxHeight: {
      '& [class*="makeStyles-container"]': {
        maxHeight: '10rem',
      },
    },
    pipelineStatusMaxHeight: {
      '& [class*="makeStyles-container"]': {
        maxHeight: '20rem',
      },
    },
    prRequestedMaxHeight: {
      '& [class*="makeStyles-container"]': {
        maxHeight: '30rem',
      },
    },
    toolkit: {
      '& ul': {
        gap: 24,
        columnGap: 27,
        '& a': {
          '& p': {
            width: 80,
          },
          '& > :first-child': {
            color: colors.primary.black,
            backgroundColor: colors.gray.main,
          },
          '&:hover': {
            '& > :first-child': {
              color: colors.primary.white,
              backgroundColor: theme.palette.primary.main,
            },
            '& > :nth-child(2) p': {
              color: theme.palette.primary.main,
            },
          },
        },
      },
    },
    announcements: {
      '& div': {
        height: 'auto !important',
        '& ul li div span': {
          fontFamily: fonts.proximaNova,
          fontWeight: theme.typography.fontWeightRegular,
        },
      },
      '& ul li': {
        '& > div:first-child': {
          padding: 0,
        },
        '& svg, & a, & p': {
          color: colors.primary.black,
          fontFamily: fonts.proximaNovaBold,
        },
        '& a, & p': {
          fontSize: 14,
        },
        '& p': {
          fontFamily: fonts.proximaNova,
          color: colors.primary.grayText,
        },
        '& a[href*="/announcements/create"]': {
          color: theme.palette.primary.main,
        },
      },
    },
    newAnnouncementBanner: {
      '& [class*="makeStyles-bannerIcon"]': {
        display: 'none',
        visibility: 'hidden',
      },
      '& > div:first-child > div::before': {
        content: JSON.stringify('🔊'),
        paddingRight: 10,
      },
      '& [class*="makeStyles-topPosition"]': {
        marginBottom: 20,
      },
      '& > div:first-child > div:first-child': {
        backgroundColor: colors.primary.black,
        '& a': {
          color: colors.primary.white,
        },
      },
    },
    starredEntities: {
      '& a[class*="MuiLink-root"]': {
        textDecoration: 'underline !important',
        fontSize: 16,
        color: colors.primary.grayText,
      },
    },
    supportBannerIconWrapper: {
      backgroundColor: theme.palette.info.main,
      paddingTop: theme.spacing(1.875),
      marginRight: theme.spacing(3.75),
      '& svg': {
        fontSize: '2.5rem',
      },
    },
    supportBannerActionWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      marginRight: theme.spacing(-1),
      paddingLeft: theme.spacing(2),
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      '& button, & a': {
        textTransform: 'capitalize',
      },
    },
    supportBannerSendFeedbackButton: {
      borderRadius: theme.spacing(0.25),
    },
  }),
  { name: 'FrontstageHomepage' },
);
