/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import {
  ApiHolder,
  createApiRef,
  DiscoveryApi,
  FetchApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';

import { Assessment, Participant, Event, ListEventFilter } from './types';

export const tomtomLabApiRef = createApiRef<TomtomLabApi>({
  id: 'tomtom-lab',
});

export interface TomtomLabApi {
  updateIdea(idea: any): Promise<any>;

  registerIdea(idea: any): Promise<any>;

  getIdeaById(id: number): Promise<any>;

  getIdeaByRef(entityRef: string): Promise<any>;

  getMembers(id: number): Promise<any>;

  deleteMember(id: number, userId: string): Promise<void>;

  addMember(id: number, userId: string): Promise<void>;

  getIdeas(limit?: number, order?: string, eventId?: number): Promise<any>;

  deleteIdea(id: number): Promise<void>;

  submitSolution(solution: any): Promise<any>;

  updateAssessment(assessment: Assessment): Promise<any>;

  createAssessment(assessment: Assessment): Promise<any>;

  getSelfAssessment(ideaId: number): Promise<any>;

  getJuryAssessment(ideaId: number, userRef: String): Promise<any>;

  getJuryAssessmentsForIdea(ideaId: number): Promise<Assessment[]>;

  getActiveEventParticipants(): Promise<any>;

  registerParticipant(participant: Participant): Promise<any>;

  getActiveEventParticipants(): Promise<ReadonlyArray<Participant>>;

  getActiveEventParticipant(
    userRef: string,
  ): Promise<Readonly<Participant> | null>;

  updateParticipant(participant: Participant): Promise<{ status: string }>;

  getEvents(filter: ListEventFilter): Promise<Event[]>;

  getEventById(id: number): Promise<Event | null>;

  addVote(ideaId: number, voterId: string): Promise<{ status: string }>;

  addExpertVote(ideaId: number, voterId: string): Promise<{ status: string }>;

  getUserVotes(voterId: string): Promise<any>;

  getUserVoteCount(voterId: string): Promise<any>;

  getIdeaVotes(ideaId: number): Promise<any>;

  getIdeaVoteCount(
    ideaId: number | undefined,
    bypassCache: boolean,
  ): Promise<any>;

  removeVote(ideaId: number, voterId: string): Promise<{ status: string }>;

  removeExpertVote(
    ideaId: number,
    voterId: string,
  ): Promise<{ status: string }>;
}

/** @public */
export const isTomtomLabAvailable = async (
  entity: Entity,
  context: { apis: ApiHolder },
): Promise<boolean> => {
  const tomtomLabClient = context.apis.get(tomtomLabApiRef);
  if (tomtomLabClient === undefined) {
    return false;
  }
  const entityRef = stringifyEntityRef({
    kind: entity.kind,
    name: entity.metadata.name,
    namespace: entity.metadata.namespace,
  });
  const response = await tomtomLabClient.getIdeaByRef(entityRef);
  const idea = await response.json();
  return idea.data.length > 0;
};

export class TomtomLabClient implements TomtomLabApi {
  private readonly identityApi: IdentityApi;
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;
  private readonly baseUrl: Promise<string>;

  constructor(options: {
    identityApi: IdentityApi;
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
  }) {
    this.identityApi = options.identityApi;
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
    this.baseUrl = this.getBaseUrl();
  }

  private async getBaseUrl(): Promise<string> {
    return this.discoveryApi.getBaseUrl('tomtom-lab');
  }

  private getDefaultHeaders(): Record<string, string> {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  async updateIdea(idea: any): Promise<any> {
    const baseUrl = await this.baseUrl;

    return await this.fetchApi
      .fetch(`${baseUrl}/ideas`, {
        method: 'PUT',
        headers: this.getDefaultHeaders(),
        body: JSON.stringify(idea),
      })
      .then(resp => resp.json());
  }

  async registerIdea(idea: any): Promise<any> {
    const baseUrl = await this.baseUrl;

    return await this.fetchApi
      .fetch(`${baseUrl}/ideas`, {
        method: 'POST',
        headers: this.getDefaultHeaders(),
        body: JSON.stringify(idea),
      })
      .then(resp => resp.json());
  }

  async getIdeaById(id: number): Promise<any> {
    const baseUrl = await this.baseUrl;

    const response = await this.fetchApi.fetch(
      `${baseUrl}/ideas/${encodeURIComponent(id)}`,
    );

    return response.ok ? response : null;
  }

  async getIdeaByRef(entityRef: string): Promise<any> {
    const baseUrl = await this.baseUrl;

    const response = await this.fetchApi.fetch(
      `${baseUrl}/ideas/${encodeURIComponent(entityRef)}`,
    );

    return response.ok ? response : null;
  }

  async getMembers(id: number): Promise<any> {
    const baseUrl = await this.baseUrl;

    return await this.fetchApi
      .fetch(`${baseUrl}/ideas/${encodeURIComponent(id)}/members`)
      .then(resp => resp.json());
  }

  async addMember(id: number, userId: string): Promise<void> {
    const baseUrl = await this.baseUrl;
    const { picture } = await this.identityApi.getProfileInfo();

    await this.fetchApi.fetch(
      `${baseUrl}/ideas/${encodeURIComponent(id)}/member/${encodeURIComponent(
        userId,
      )}`,
      {
        method: 'PUT',
        headers: this.getDefaultHeaders(),
        body: JSON.stringify({ picture }),
      },
    );
  }

  async deleteMember(id: number, userId: string): Promise<void> {
    const baseUrl = await this.baseUrl;

    await this.fetchApi.fetch(
      `${baseUrl}/ideas/${encodeURIComponent(id)}/member/${encodeURIComponent(
        userId,
      )}`,
      { method: 'DELETE' },
    );
  }

  async getIdeas(
    limit?: number,
    order?: string,
    eventId?: number,
  ): Promise<any> {
    const baseUrl = await this.baseUrl;
    const params = {
      ...(limit ? { limit: limit.toString() } : {}),
      ...(order ? { order } : {}),
      ...(eventId ? { eventId: eventId.toString() } : {}),
    };
    const query = new URLSearchParams(params);
    const url = `ideas?${query.toString()}`;

    const data = await this.fetchApi.fetch(`${baseUrl}/${url}`);
    if (!data.ok) {
      throw await ResponseError.fromResponse(data);
    }
    return data.json();
  }

  async deleteIdea(id: number): Promise<void> {
    const baseUrl = await this.baseUrl;

    await this.fetchApi.fetch(`${baseUrl}/ideas/${encodeURIComponent(id)}`, {
      method: 'DELETE',
    });
  }

  async submitSolution(solution: any): Promise<any> {
    const baseUrl = await this.baseUrl;

    const data = await this.fetchApi.fetch(
      `${baseUrl}/ideas/${solution.ideaId}/solutions`,
      {
        method: 'POST',
        headers: this.getDefaultHeaders(),
        body: JSON.stringify(solution),
      },
    );

    if (!data.ok) {
      throw await ResponseError.fromResponse(data);
    }
    return data.json();
  }

  async updateAssessment(assessment: Assessment): Promise<any> {
    const baseUrl = await this.baseUrl;

    const data = await this.fetchApi.fetch(`${baseUrl}/assessments`, {
      method: 'PUT',
      headers: this.getDefaultHeaders(),
      body: JSON.stringify(assessment),
    });

    if (!data.ok) {
      throw await ResponseError.fromResponse(data);
    }
    return data.json();
  }

  async createAssessment(assessment: Assessment): Promise<any> {
    const baseUrl = await this.baseUrl;

    const data = await this.fetchApi.fetch(`${baseUrl}/assessments`, {
      method: 'POST',
      headers: this.getDefaultHeaders(),
      body: JSON.stringify(assessment),
    });

    if (!data.ok) {
      throw await ResponseError.fromResponse(data);
    }
    return data.json();
  }

  async getSelfAssessment(ideaId: number): Promise<any> {
    const baseUrl = await this.baseUrl;
    const response = await this.fetchApi.fetch(
      `${baseUrl}/assessments/${ideaId}`,
      {
        method: 'GET',
        headers: this.getDefaultHeaders(),
      },
    );

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    const payload = await response.json();
    return payload.data;
  }

  async getActiveEventParticipants(): Promise<ReadonlyArray<Participant>> {
    const baseUrl = await this.baseUrl;
    const response = await this.fetchApi.fetch(`${baseUrl}/participants`, {
      method: 'GET',
      headers: this.getDefaultHeaders(),
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    const payload = await response.json();
    return payload.data;
  }

  async getJuryAssessment(ideaId: number, userRef: string): Promise<any> {
    const baseUrl = await this.baseUrl;
    const response = await this.fetchApi.fetch(
      `${baseUrl}/assessments/${ideaId}/${userRef}`,
      {
        method: 'GET',
        headers: this.getDefaultHeaders(),
      },
    );

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    const payload = await response.json();
    return payload.data;
  }

  async getJuryAssessmentsForIdea(ideaId: number): Promise<Assessment[]> {
    const baseUrl = await this.baseUrl;
    const response = await this.fetchApi.fetch(
      `${baseUrl}/assessments/jury/scores/${ideaId}`,
      {
        method: 'GET',
        headers: this.getDefaultHeaders(),
      },
    );

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    const payload = await response.json();
    return payload.data;
  }

  async registerParticipant(participant: Participant): Promise<any> {
    const baseUrl = await this.discoveryApi.getBaseUrl('tomtom-lab');

    return await this.fetchApi
      .fetch(`${baseUrl}/participants`, {
        method: 'POST',
        headers: this.getDefaultHeaders(),
        body: JSON.stringify(participant),
      })
      .then(resp => resp.json());
  }

  async getActiveEventParticipant(
    userRef: string,
  ): Promise<Readonly<Participant> | null> {
    const baseUrl = await this.baseUrl;
    const data = await this.fetchApi.fetch(
      `${baseUrl}/participants/${userRef}`,
      {
        method: 'GET',
        headers: this.getDefaultHeaders(),
      },
    );

    if (!data.ok) {
      return null;
    }

    const body = await data.json();
    return body.data;
  }

  async updateParticipant(
    participant: Participant,
  ): Promise<{ status: string }> {
    const baseUrl = await this.baseUrl;
    return this.fetchApi
      .fetch(
        `${baseUrl}/participants/${encodeURIComponent(participant.userRef)}`,
        {
          method: 'PUT',
          headers: this.getDefaultHeaders(),
          body: JSON.stringify(participant),
        },
      )
      .then(resp => resp.json());
  }

  async getEvents(filter: ListEventFilter): Promise<Event[]> {
    const baseUrl = await this.baseUrl;
    const params = new URLSearchParams(filter as Record<string, string>);
    const response = await this.fetchApi.fetch(
      `${baseUrl}/events?${params.toString()}`,
      {
        method: 'GET',
        headers: this.getDefaultHeaders(),
      },
    );

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    const payload = await response.json();
    return payload.data;
  }

  async getEventById(id: number): Promise<Event | null> {
    const baseUrl = await this.baseUrl;
    const response = await this.fetchApi.fetch(`${baseUrl}/events/${id}`, {
      method: 'GET',
      headers: this.getDefaultHeaders(),
    });

    if (!response.ok) {
      return null;
    }

    const payload = await response.json();
    return payload.data;
  }

  async addVote(ideaId: number, voterId: string): Promise<{ status: string }> {
    const baseUrl = await this.baseUrl;

    const response = await this.fetchApi.fetch(`${baseUrl}/idea-votes/add`, {
      method: 'POST',
      headers: this.getDefaultHeaders(),
      body: JSON.stringify({ ideaId, voterId }),
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return response.json();
  }

  async addExpertVote(
    ideaId: number,
    voterId: string,
  ): Promise<{ status: string }> {
    const baseUrl = await this.baseUrl;

    const response = await this.fetchApi.fetch(
      `${baseUrl}/idea-votes/experts/add`,
      {
        method: 'POST',
        headers: this.getDefaultHeaders(),
        body: JSON.stringify({ ideaId, voterId }),
      },
    );

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return response.json();
  }

  async getUserVotes(voterId: string): Promise<any> {
    const baseUrl = await this.baseUrl;

    const response = await this.fetchApi.fetch(
      `${baseUrl}/idea-votes/users/${encodeURIComponent(voterId)}`,
      {
        method: 'GET',
        headers: this.getDefaultHeaders(),
      },
    );

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return response.json();
  }

  async getUserVoteCount(voterId: string): Promise<any> {
    const baseUrl = await this.baseUrl;

    const response = await this.fetchApi.fetch(
      `${baseUrl}/idea-votes/users/${encodeURIComponent(voterId)}/count`,
      {
        method: 'GET',
        headers: this.getDefaultHeaders(),
      },
    );

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return response.json();
  }

  async getIdeaVotes(ideaId: number): Promise<any> {
    const baseUrl = await this.baseUrl;

    const response = await this.fetchApi.fetch(
      `${baseUrl}/idea-votes/ideas/${encodeURIComponent(ideaId)}`,
      {
        method: 'GET',
        headers: this.getDefaultHeaders(),
      },
    );

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return response.json();
  }

  async getIdeaVoteCount(ideaId: number, bypassCache: boolean): Promise<any> {
    const baseUrl = await this.baseUrl;
    const response = await this.fetchApi.fetch(
      `${baseUrl}/idea-votes/ideas/${encodeURIComponent(ideaId)}/count`,
      {
        method: 'GET',
        headers: this.getDefaultHeaders(),
        cache: bypassCache ? 'reload' : 'default',
      },
    );

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return response.json();
  }

  async removeVote(
    ideaId: number,
    voterId: string,
  ): Promise<{ status: string }> {
    const baseUrl = await this.baseUrl;

    const response = await this.fetchApi.fetch(`${baseUrl}/idea-votes/remove`, {
      method: 'DELETE',
      headers: this.getDefaultHeaders(),
      body: JSON.stringify({ ideaId, voterId }),
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return response.json();
  }

  async removeExpertVote(
    ideaId: number,
    voterId: string,
  ): Promise<{ status: string }> {
    const baseUrl = await this.baseUrl;

    const response = await this.fetchApi.fetch(
      `${baseUrl}/idea-votes/experts/remove`,
      {
        method: 'DELETE',
        headers: this.getDefaultHeaders(),
        body: JSON.stringify({ ideaId, voterId }),
      },
    );

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return response.json();
  }
}
