const redirectAction = (url?: string) => {
  if (url) {
    window.location.href = url;
  }
};

const openTemplateAction = (templateUrl?: string) =>
  redirectAction(templateUrl);
const redirectToDashboardAction = () => redirectAction('watchtower-dashboard');

export const ActionForCheck = {
  // repoUsesLatestCheckoutActionVersionCheck: openTemplateAction,
  groupOwnerCheck: openTemplateAction,
  titleCheck: openTemplateAction,
  descriptionCheck: openTemplateAction,
  techDocsCheck: openTemplateAction,
  serviceSlackChannelCheck: openTemplateAction,
  servicePagerDutyIntegrationCheck: openTemplateAction,
  serviceSonarQubeIntegrationCheck: openTemplateAction,
  serviceMonitoringDashboardCheck: openTemplateAction,
  serviceOperationalStatusCheck: openTemplateAction,
  serviceOperationalTierCheck: openTemplateAction,
  repoHasReadmeCheck: openTemplateAction,
  repoUsesConventionalCommitsCheck: openTemplateAction,
  componentCISuccessCheck: redirectToDashboardAction,
  repoCIMedianBuildDurationCheck: redirectToDashboardAction,
  repoCIMedianPRTimeToMergeCheck: redirectToDashboardAction,
};
