import useAsync from 'react-use/lib/useAsync';

import { Entity } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

export const useProductUnits = () => {
  const catalogApi = useApi(catalogApiRef);

  const {
    value: productUnitEntities,
    loading,
    error,
  } = useAsync(async () => {
    return catalogApi.getEntities({
      filter: [
        {
          kind: ['group'],
          'spec.type': ['product-unit'],
        },
      ],
    });
  }, []);

  const productUnits: Entity[] = productUnitEntities
    ? productUnitEntities.items
    : [];

  return { productUnits, loading, error };
};
