import { IdeaExportData } from '../types';
import { getDataForExport, makeCsv } from './csvExport';

const formatColumns = () => {
  return [
    { header: 'Title', accessor: 'title' },
    { header: 'Teaser', accessor: 'teaser' },
    { header: 'Event', accessor: 'event' },
    { header: 'Status', accessor: 'status' },
    { header: 'Last Updated At', accessor: 'date' },
    { header: 'Responsible', accessor: 'responsible' },
    { header: 'Members', accessor: 'members' },
  ];
};

const formatData = (dataToExport: IdeaExportData[]) => {
  return dataToExport.map((row: IdeaExportData) => {
    return {
      ...row,
    };
  });
};

export const exportIdeasAsCSV = (
  dataToExport: IdeaExportData[],
  fileName: string,
) => {
  const formatedData = formatData(dataToExport);
  const formatedColumns = formatColumns();
  makeCsv(getDataForExport(formatedData, formatedColumns), `${fileName}.csv`);
};
