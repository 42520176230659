import { FactRetrieverContext } from '@backstage-community/plugin-tech-insights-node';
import { Entity, stringifyEntityRef } from '@backstage/catalog-model';

import { WatchtowerReader } from '../../WatchtowerReader';
import { DependabotPRsFact, TypedTechInsightFact } from '../../types';
import { getEntities } from '../utils/getEntities';
import { getRepoNameFromProjectSlug } from './utils';

export const createReposHasdependabotPRsFactHandler = (
  watchtowerReader: WatchtowerReader,
) => {
  return async ({
    logger,
    entityFilter,
    tokenManager,
    discovery,
  }: FactRetrieverContext): Promise<
    TypedTechInsightFact<DependabotPRsFact>[]
  > => {
    const entities = await getEntities({
      logger,
      entityFilter,
      tokenManager,
      discovery,
    });

    const entityFactsPromise = entities.items.map(async (entity: Entity) => {
      const repoName = getRepoNameFromProjectSlug(entity);
      if (!repoName) {
        logger.warn(`No slug found for entity ${stringifyEntityRef(entity)}`);
        return null;
      }

      try {
        const dependabotResponse = await watchtowerReader.getRepoAttribute<{
          open_dependabot_prs: boolean;
        }>(repoName, 'open-dependabot-prs');
        if (dependabotResponse) {
          return {
            entity: {
              namespace: entity.metadata.namespace!,
              kind: entity.kind,
              name: entity.metadata.name,
            },
            facts: {
              open_dependabot_prs: dependabotResponse.open_dependabot_prs,
            },
          };
        }
      } catch (error) {
        logger.error(
          `Error while fetching open dependabot PRs fact for ${repoName}`,
          error as any,
        );
        return null;
      }

      return null;
    });
    const facts = await Promise.all(entityFactsPromise);
    return facts.filter(
      fact => !!fact,
    ) as TypedTechInsightFact<DependabotPRsFact>[];
  };
};
