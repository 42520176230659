import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';

import {
  CatalogNameExtension,
  catalogTeamNameValidation,
  GenericValueInputPicker,
  GenericValuePickerExtension,
  RepoNamePicker,
  GithubTeamPickerExtension,
  AzSecurityGroupPickerExtension,
  AzureSubscriptionPickerExtension,
  AzureLogin,
  AzureLoginSchema,
  GithubLogin,
  GithubLoginSchema,
} from './components';
import { CatalogUserPicker } from './components/CatalogUserPicker';
import { MyCostCentersPicker } from './components/MyCostCentersPicker';
import { RepoNamePickerSchema } from './components/RepoNamePicker/schema';
import { repoNamePickerValidation } from './components/RepoNamePicker/validation';
import { UrlInput, validateUrlInput } from './components/UrlInput';

export const GenericValueInputPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'GenericValueInputPicker',
    component: GenericValueInputPicker,
  }),
);

export const GenericValuePickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'GenericValuePicker',
    component: GenericValuePickerExtension,
  }),
);

export const CatalogNamePickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'CatalogNamePicker',
    component: CatalogNameExtension,
    validation: catalogTeamNameValidation,
  }),
);

export const RepoNamePickerExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'RepoNamePicker',
    component: RepoNamePicker,
    validation: repoNamePickerValidation,
    schema: RepoNamePickerSchema,
  }),
);

export const GithubTeamPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'GithubTeamPicker',
    component: GithubTeamPickerExtension,
  }),
);

export const AzSecurityGroupPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'AzSecurityGroupPicker',
    component: AzSecurityGroupPickerExtension,
  }),
);

export const AzureSubscriptionPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'AzureSubscriptionPicker',
    component: AzureSubscriptionPickerExtension,
  }),
);

export const CatalogUserPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'CatalogUserPicker',
    component: CatalogUserPicker,
  }),
);

export const AzureLoginFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'AzureLogin',
    component: AzureLogin,
    schema: AzureLoginSchema,
  }),
);

export const GithubLoginFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'GithubLogin',
    component: GithubLogin,
    schema: GithubLoginSchema,
  }),
);

export const UrlInputExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'UrlInput',
    component: UrlInput,
    validation: validateUrlInput,
  }),
);

export const MyCostCentersPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'MyCostCentersPicker',
    component: MyCostCentersPicker,
  }),
);
