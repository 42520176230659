import ExtensionIcon from '@mui/icons-material/Extension';
import GroupIcon from '@mui/icons-material/Group';
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_Cell,
  useMaterialReactTable,
  MRT_RowData,
} from 'material-react-table';
import React, { useCallback, useMemo } from 'react';

import { CompoundEntityRef } from '@backstage/catalog-model';

import { Box, Link, makeStyles } from '@material-ui/core';

import { useGetScorecardData } from '../../hooks';
import { Scorecard, ScorecardNode, Tier } from '../../types';
import { getCategories } from '../../utils';
import { ScoreCell } from '../ScoreCell/ScoreCell';
import { TierBadge } from '../TierBadge';

export interface ScorecardTableProps {
  message?: string;
  entityRef: CompoundEntityRef;
}

type CellType = MRT_Cell<MRT_RowData> & { renderedCellValue: string };

const refToRoute = (ref: string) => {
  const [kind, namespacedName] = ref.split(':');
  const [namespace, name] = namespacedName.split('/');
  return `${namespace}/${kind}/${name}`;
};
const useStyles = makeStyles({
  nameColumContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  nameColumIcon: {
    marginRight: 10,
    marginLeft: 0,
    display: 'block',
  },
});

export function ScorecardTable(props: ScorecardTableProps) {
  const { scorecard, loading, error } = useGetScorecardData(props.entityRef);
  const classes = useStyles();
  const categories = getCategories(scorecard as Scorecard);

  const columns = useMemo(
    () => [
      {
        accessorFn: (row: MRT_RowData) => `${row.itemName}`,
        header: 'Name',
        Cell: (cell: CellType) => {
          const url = `/catalog/${refToRoute(cell.row.original.itemRef)}`;
          const Icon =
            cell.row.original.itemType === 'Service'
              ? ExtensionIcon
              : GroupIcon;
          return (
            <Box className={classes.nameColumContainer}>
              <Icon color="disabled" className={classes.nameColumIcon} />
              <Link href={url}>{cell.renderedCellValue as string}</Link>
            </Box>
          );
        },
      },
      {
        header: 'Overall Score',
        accessorFn: (dataRow: Scorecard) => dataRow.overallScore,
        Cell: ScoreCell,
        muiTableHeadCellProps: {
          align: 'center',
        },
      },
      {
        header: 'Tier',
        accessorFn: (dataRow: Scorecard) => dataRow.tier,
        muiTableHeadCellProps: {
          align: 'center',
          size: 'small',
        },
        maxSize: 50,
        Cell: (cell: any) => (
          <TierBadge
            tier={cell?.renderedCellValue as string as Tier}
            compactModeEnabled={false}
          />
        ),
      },
      ...categories.map(category => ({
        muiTableHeadCellProps: {
          align: 'center',
        },
        header: category,
        accessorFn: (dataRow: Scorecard) => dataRow.categories?.[category],
        Cell: ScoreCell,
      })),
    ],
    [categories, classes.nameColumContainer, classes.nameColumIcon],
  );

  const getSubRows = useCallback((originalRow: ScorecardNode) => {
    const itemType = (originalRow as Scorecard)?.itemType;
    if (itemType === 'Service') return [];
    return (originalRow as Scorecard)?.items;
  }, []);

  const table = useMaterialReactTable({
    columns: columns as MRT_ColumnDef<ScorecardNode, any>[],
    data: (scorecard as Scorecard)?.items ?? {},
    enableSorting: false,
    enableExpanding: true,
    enableColumnActions: false,
    paginateExpandedRows: false,
    displayColumnDefOptions: {
      'mrt-row-expand': {
        size: 20,
      },
    },
    muiTablePaperProps: {
      sx: {
        borderRadius: '20px',
        paddingTop: 4,
      },
    },
    getSubRows,
    state: {
      isLoading: loading,
      density: 'compact',
    },
    enableTopToolbar: false,
  });

  if (error) {
    return <div>{props.message || 'Error'}</div>;
  }

  return <MaterialReactTable table={table} />;
}
