import React, { useCallback, useEffect, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';

import { UserEntity } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  humanizeEntityRef,
} from '@backstage/plugin-catalog-react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

import { TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';

type OptionType = {
  label: string;
  id: string;
  email?: string;
};

const getOptionLabel = (option: OptionType) => option.label;

const getOptionSelected = (option: OptionType, value: OptionType) =>
  option.id === value.id && value !== undefined;

export const UserPicker = ({
  onChange,
  rawErrors,
  required,
  formData,
  idSchema,
  schema,
}: FieldExtensionComponentProps<string[]>) => {
  const [values, setValues] = useState<OptionType[]>([]);
  const [options, setOptions] = useState<OptionType[]>([]);
  const [isRequired, setSelectRequired] = useState<boolean>(required ?? false);
  const catalogApi = useApi(catalogApiRef);

  const { value: entities, loading } = useAsync(() =>
    catalogApi.getEntities({ filter: { kind: 'User' } }),
  );

  const onSelect = useCallback(
    (_: any, value: OptionType[]) => {
      const cleanedValues = value.map(v => v.id.replace('user:', ''));
      setValues(value);
      onChange(cleanedValues);
    },
    [onChange],
  );

  useEffect(() => {
    if (!loading && entities && entities.items.length > 0) {
      const entityRefs =
        entities?.items.map(e => {
          const user = e as UserEntity;
          const label = user.spec.profile?.displayName ?? humanizeEntityRef(e);
          const email = user.spec.profile?.email;
          return {
            label,
            id: humanizeEntityRef(e),
            email,
          };
        }) ?? [];

      setOptions(entityRefs);
    }
  }, [entities, loading]);

  useEffect(() => {
    if (formData && formData.length > 0) {
      (async () => {
        const updatedValues: OptionType[] = [];
        for (const selectedUser of formData) {
          const entity = await catalogApi.getEntityByRef(
            `user:default/${selectedUser}`,
          );
          if (entity) {
            const option: OptionType = {
              label:
                (entity as UserEntity).spec.profile?.displayName ??
                humanizeEntityRef(entity),
              id: humanizeEntityRef(entity),
            };
            updatedValues.push(option);
          }
        }
        setValues(updatedValues);
      })();
    }
  }, [catalogApi, formData, entities]);

  useEffect(() => {
    if (values.length > 0) {
      setSelectRequired(false);
    } else {
      setSelectRequired(required ?? false);
    }
  }, [required, setSelectRequired, values.length]);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: OptionType) => `${option.label}${option.email}`,
  });

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <Autocomplete
        multiple
        id={idSchema?.$id}
        options={options}
        getOptionLabel={getOptionLabel}
        filterSelectedOptions
        loading={loading}
        value={values}
        openOnFocus
        onChange={onSelect}
        getOptionSelected={getOptionSelected}
        filterOptions={filterOptions}
        renderInput={params => (
          <TextField
            {...params}
            label={schema.title}
            margin="dense"
            helperText={schema.description}
            FormHelperTextProps={{ margin: 'dense', style: { marginLeft: 0 } }}
            variant="outlined"
            required={isRequired}
            InputProps={params.InputProps}
          />
        )}
      />
    </FormControl>
  );
};
