import { FactRetrieverContext } from '@backstage-community/plugin-tech-insights-node';

import { WatchtowerReader } from '../../WatchtowerReader';
import { CIPRMedianMergeTimeFact, TypedTechInsightFact } from '../../types';
import { getEntities } from '../utils/getEntities';
import { getRepoNameFromProjectSlug, weekRange } from './utils';

type PeriodValue = {
  start: string;
  end: string;
  value: number;
};

type PRMedianDurationHandler = (
  f: FactRetrieverContext,
) => Promise<TypedTechInsightFact<CIPRMedianMergeTimeFact>[]>;

type CIPRMedianDurationOutput = {
  start_date: string;
  end_date: string;
  unit: string;
  periods: PeriodValue[];
};
type CIPRMedianDurationInput = {
  start_date: string;
  end_date: string;
  repository_names: string[];
  percentile: number;
};

export function createCIPRMedianMergeDuration(
  watchtowerReader: WatchtowerReader,
): PRMedianDurationHandler {
  return async ({
    logger,
    entityFilter,
    tokenManager,
    discovery,
  }: FactRetrieverContext): Promise<
    TypedTechInsightFact<CIPRMedianMergeTimeFact>[]
  > => {
    const entities = await getEntities({
      logger,
      entityFilter,
      tokenManager,
      discovery,
    });

    const ciFacts: TypedTechInsightFact<CIPRMedianMergeTimeFact>[] = [];

    for (const entity of entities.items) {
      const repoName = getRepoNameFromProjectSlug(entity);

      if (!repoName) {
        continue;
      }

      try {
        const { start, end } = weekRange();
        const payload = {
          repository_names: [repoName],
          start_date: start,
          end_date: end,
          percentile: 0.5,
        };
        const medianPRMergeTime = await watchtowerReader.getStats<
          CIPRMedianDurationInput,
          CIPRMedianDurationOutput
        >('pr/merge-time', payload);
        const medianPRMergeDuration = getMedianPRMergeDurationHours(
          medianPRMergeTime?.periods,
        );
        if (medianPRMergeDuration) {
          ciFacts.push({
            entity: {
              namespace: entity.metadata.namespace!,
              kind: entity.kind,
              name: entity.metadata.name,
            },
            facts: { medianPRTimeToMergeHours: medianPRMergeDuration! },
          });
        } else {
          logger.info(
            `No prMedianMergeTime Fact for repo ${repoName} :: ${JSON.stringify(
              medianPRMergeTime,
            )}`,
          );
        }
      } catch (error) {
        logger.error(
          `Error while fetching median PR Merge time for ${repoName}`,
          error as any,
        );
      }
    }

    return ciFacts;
  };
}

export function getMedianPRMergeDurationHours(
  periodValues?: PeriodValue[],
): number {
  const medianMergeSecs = periodValues?.[0]?.value ?? 0;
  return medianMergeSecs / 3600;
}
